import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators'
import { SecurityService} from '../services/security.service';
import { Injectable } from '@angular/core';
import { MsalService } from "@azure/msal-angular";
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
@Injectable()
export class RoleGuardService implements CanActivate {
    private username: string;
    private userInfo: any = '';
    constructor(public router: Router, private securityService: SecurityService,
                private auth: MsalService, private msalService: MsalService) {
    }
    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        this.username = this.msalService.instance.getActiveAccount().username;
        return this.securityService.validateUser(this.username)
        .map(data => {
//            console.log(data);
            const rv = route.data.expectedRoles.some(er => data.roles.some(ur => ur.name === er));
            if (!rv) {
                this.router.navigate(['unauthorized']);
            }
            return rv;
        });
    }
    public doesUserHaveRole(roleName: string): boolean {
        let userHasRole = false;
        console.log('logged in user is '+localStorage.getItem("loggedInUsername"));
        if (!localStorage['loggedInUserRoles']) {
            if (!localStorage.getItem("msal.client.info")) {
                this.auth.loginRedirect();
            }
            this.userInfo = this.securityService.getUser();
            if (this.userInfo ===  '')
            {
              this.securityService.validateUser(localStorage.getItem("loggedInUsername"))
                .subscribe(data => {
                    this.userInfo = data;
                    localStorage.setItem('loggedInUserRoles', JSON.stringify(this.userInfo.roles.map(x => x.name)));
                });
            }
        }
        if (localStorage['loggedInUserRoles']) {
            if (JSON.parse(localStorage['loggedInUserRoles']).indexOf(roleName) !== -1) {
                userHasRole = true;
            }
        }
        return userHasRole;
    }
}
