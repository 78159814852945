import { Component, OnInit, ViewChild } from '@angular/core';
import { UserClient, UserModel, RoleClient, RoleModel, JobFunction } from '../services/forecast-api-client.service';
import { SecurityService } from '../services/security.service';
import { ConfirmationService } from "primeng/api";
import { MessageService, Message } from 'primeng/api';
import { concatMap } from 'rxjs/operators';
import { LoadingService } from '../loading/res-loading.service';
import { AngularFrameworkComponentWrapper } from 'ag-grid-angular/lib/angularFrameworkComponentWrapper';
import { CheckboxCellRendererComponent } from '../custom/checkbox-cell-renderer/checkbox-cell-renderer.component';
import {  ButtonRendererComponent } from '../custom/button-renderer/button-renderer.component';
import { DelegatesClient, LeaderDelegatesModel} from '../services/forecast-api-client.service';
import { AgGridAngular } from 'ag-grid-angular';
import { AutoComplete } from 'primeng/autocomplete';
import { InterComponentMessageService } from '../services/inter-component-message-service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-delegates',
  templateUrl: './delegates.component.html',
  styleUrls: ['./delegates.component.scss'],
  providers: [ConfirmationService, MessageService, SecurityService, InterComponentMessageService]
})
export class DelegatesComponent implements OnInit {
    // growlMsgs: GrowlModule[] = [];
    public isEditable: boolean = false;
    public displayDialog: boolean = false;
    public isDuplicateUser: boolean = false;
    public seasonalDelegations: Array<LeaderDelegatesModel> = null;
    public users: Array<UserModel>;
    public user = new UserModel();
    public loggedInUser: UserModel;
    public jobFunctions: JobFunction[];
    public delegateGridColumnDefs: any;
    public noRowsTemplate: string;
    public filteredUsersSingle: UserModel[];
    public suggestion: UserModel | any;
    @ViewChild('agGridDelegates') agGridDelegates: AgGridAngular;
    @ViewChild('pauto') pAutoComplete: AutoComplete;

    constructor(private userClient: UserClient,
                private securityService: SecurityService,
                private confirmationService: ConfirmationService,
                private messageService: MessageService,
                private delegatesClient: DelegatesClient,
                private appComponent: AppComponent,
                private icmsService: InterComponentMessageService) {

                  this.noRowsTemplate =
                  'No delegates have been assigned';
                  this.delegateGridColumnDefs = [
                    {headerName: 'Name', field: 'fullName', sortable: true, filter: true, width: 340,
                    cellRenderer: params => {
                      // put the value in bold
                      return '<a href="mailTo:' + params.data.email + '">' + params.data.fullName + '</a>';
                  }},
                    {
                      headerName: '', width: 70,
                      cellRenderer: 'buttonRenderer',
                      cellRendererParams: {
                        onClick: this.onDeleteDelegate.bind(this),
                        label: 'Delete'
                      }
                    }
                  ];
              }

    ngOnInit() {
      this.appComponent.currentSelection = 'delegates';
      this.userClient.getFunctionsAll()
      .subscribe(data => {
        this.jobFunctions = data;
      });
      this.GetuserInfo();
      this.getProspectiveUsers();
    }
    public frameworkComponents = {
      checkboxCellRenderer: CheckboxCellRendererComponent,
      buttonRenderer: ButtonRendererComponent
    };
  
  
    public GetuserInfo() {
      this.loggedInUser = this.securityService.getUser();
      if (this.loggedInUser ===  null || this.loggedInUser === undefined) {
        this.securityService.validateUser(localStorage.getItem("loggedInUsername"))
            .subscribe(data => {
              this.loggedInUser = data;
              this.getDelegations();
        });
      } else {
        this.getDelegations();
      }
    }
    public  getDelegations() {
      this.delegatesClient.getDelegations(this.loggedInUser.id)
      .subscribe(data => {
        this.seasonalDelegations = data;
        console.log(data);
      },
      error => {
        this.messageService.add({severity:'error', summary:'Error', detail: 'Delegations could not be retrieved'});
      });
    }
    getProspectiveUsers() {
      this.userClient.getUsersInRoles(['Biology Leader', 'Field Scientist'])
      .subscribe(data => {
        this.users = data.filter(x => x.id !== this.loggedInUser.id);
        console.log(data);
      },
      error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Users list could not be retrieved'});
      });
    }
    public getTitleStyle(seasonalDelegationModel: LeaderDelegatesModel) {
      return 'clsDelegationTitleBar';
    }
    public clearValue() {
      console.log('clear value called');
      this.suggestion = undefined;
    }
    public addPickedUser() {
      if (this.suggestion !== undefined) {
        let dg = this.suggestion;
        let delegation = this.seasonalDelegations[0];
        if (delegation.delegates.some(x => x.id === dg.id)) {
          this.messageService.add({severity: 'warn', summary: 'Error', detail: 'Delegate already exists.'});
          return;
        }

        this.delegatesClient.addDelegate(this.loggedInUser.id, dg.id)
        .subscribe(data => {
          const myClonedArray: UserModel[] = [];
          delegation.delegates.push(dg);
          delegation.delegates.sort((x, y) => x.fullName.localeCompare(y.fullName));
          delegation.delegates = delegation.delegates.slice();
          this.filteredUsersSingle = [];
          console.log(this.suggestion);
          this.suggestion = undefined;
          this.pAutoComplete.inputEL.nativeElement.value = '';
          this.messageService.add({ severity: 'success', summary: '', detail: 'Delegate information successfully added.' });
        },
        error => {
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Delegate could not be added.'});
        });
      }
    }
    public setSuggestion(event) {
      this.suggestion = event;
    }
    public filterUserSingle(event) {
      this.filteredUsersSingle = this.filterUser(event.query, this.users);
    }
    public filterUser(query, users: UserModel[]): UserModel[] {
      let filtered : any[] = [];
      for (let i = 0; i < users.length; i++) {
          let user = users[i];
          if (user.fullName.toLowerCase().indexOf(query.toLowerCase()) === 0) {
              filtered.push(user);
          }
      }
      return filtered;
  }
    public onDeleteDelegate(e) {
      this.confirmationService.confirm({
        message: 'You are about to remove '
        + e.rowData.firstName + ' ' + e.rowData.lastName
        + ' as a delegate. Are you sure you want to do this?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        accept: () => {
          const ai = e.rowData;
          this.delegatesClient.deleteDelegate(this.loggedInUser.id, ai.id)
          .subscribe(data => {
            const index = this.seasonalDelegations[0].delegates.findIndex(d => d.id === ai.id);
            this.seasonalDelegations[0].delegates = this.seasonalDelegations[0].delegates.filter(x => x.id !== ai.id);
            this.messageService.add({severity: 'info', summary: 'Success', detail: 'Delegate removed.'});
          },
          error => {
            console.log(error);
            this.messageService.add({severity: 'error', summary: 'Error',
            detail: 'Delegate could not removed. Cannot remove a delegate who has forecasts already in their name!'});
          });
        }
      });
    }
    public  showDialog() {
        this.user = new UserModel();
        this.user.optInEmailNotification = true;
        this.user.isActive = true;
        this.displayDialog = true;
    }
    public  onRequestUserAccess() {
      this.displayDialog = true;
    }
    public  onEnterUserId() {
      let regexp = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$');
      console.log('regex validation of email ' + regexp.test(this.user.email));
      if (!regexp.test(this.user.email)) {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Invalid email id. Please retry.' });
        return;
      }
      this.messageService.add({ severity: 'info', summary: '', detail: 'Search user information. Wait ...' });
      this.userClient.getADUser(this.user.email)
      .subscribe(data => {
          if (data === null) {
              this.messageService.add({ severity: 'warn', summary: '', detail: 'User information not found. Check spelling.' });
          } else {
            this.user = data;
            this.user.optInEmailNotification = true;
            this.user.isActive = false;
            this.user.awaitingApproval = true;
            this.user.functionId = this.jobFunctions.find(x => x.functionName === 'Field Sciences').id;
            this.userClient.getByEmailId(this.user.email)
              .subscribe(um => {
                if (um.isActive) {
                  this.messageService.add({ severity: 'warn', summary: '', detail: 'User already exists and is active.' });
                  this.user = new UserModel();
                }
                if (um.awaitingApproval) {
                  this.messageService.add({ severity: 'warn', summary: '', detail: 'User is awaiting approval already.' });
                  this.user = new UserModel();
                }
              },
              error => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'User information successfully retrieved.' });
              });
          }
      },
      error => {
          this.messageService.add({ severity: 'error', summary: '',
            detail: 'Error when retrieving user information. Check with admin.' });
          console.log(error);
      });
    }
    public  save() {
        this.createToDb(this.user);
    }
    public  createToDb(newUser) {
        const createUser = this.userClient.postUser(newUser)
        .pipe (
            concatMap(result => {
                return this.userClient.getUsers();
            })
        );
        createUser.subscribe((data) => {
            this.messageService.add({ severity: 'success', summary: '',
              detail: 'Request for access successfully saved' });
            this.displayDialog = false;
            this.isDuplicateUser = false;
            this.isEditable = false;
            this.user = new UserModel();
           },
        error => {
            this.messageService.add({ severity: 'error', summary: '',
              detail: 'Error when saving user. Duplicated user email.' });
            console.log(error);
        });
    }
    public cancel() {
        this.displayDialog = false;
        this.isDuplicateUser = false;
        this.isEditable = false;
        this.user = new UserModel();
    }
}

