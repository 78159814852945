import {Component} from '@angular/core';

import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
    template: `
    <div class="container">
    <p ><label class="fa fa-info-circle"><span style="margin-left: 14px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
    Do you want to save changes?</span></label></p>
    <div class="action-buttons">
    <p-button icon="fa fa-check" (click)="close(true, null, null)"  label="Save"></p-button>
    <p-button (click)="close(null, true, null)"  label="Don't Save"></p-button>
    <p-button icon="fa fa-close" (click)="close(null, null, true)"  label="Cancel"></p-button>
    </div>
  </div>
    `,
    styles:[
        `.container{
            display:flex;
            flex-direction: column;
        }
        .action-buttons{
            display: flex;
            justify-content: space-evenly;
    margin-top: 20px;
        }
        `
    ]
})
export class ConfirmationDialog {

            
    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

    ngOnInit() {
    }

    close(save, leave, cancel) {
        this.ref.close({save, leave, cancel});
    }
}