import { Component, Inject, OnInit, Input, InputDecorator, EventEmitter, Output, OutputDecorator, ViewChild } from '@angular/core';
import { COMPOSITE_SEARCH_SETTINGS, MaterialSearchConfig } from '../services/environment-service/material-search-config';
import { CompositeInventoryClient, CompositeInventoryResult, FormulationClient, MaterialResult } from '../services/gsscommon-api-client.service';
import {CoformulantClient,CompoundClient, BioNexusClient } from '../services/gsscommon-api-client.service';
import { DataView } from 'primeng/dataview';
import { MessageService } from 'primeng/api';
import { TupleService } from './tuple.service';
import { RequestType } from '../request-type/request-type.enum';
import { QuantityUnitOfMeasureModel, QuantityUomClient, UserClient } from '../services/forecast-api-client.service';
import { ConfigurationSettings } from '../configuration-settings';

@Component({
  selector: 'app-composite-search',
  templateUrl: './composite-search.component.html',
  styleUrls: ['./composite-search.component.scss']
})
export class CompositeSearchComponent implements OnInit {

  @Output('markDuplicates') markDuplicates=  new EventEmitter<Array<MaterialResult>>();
  @Input('isGlpEligible') isGlpEligible: boolean = false;
  @Output() AddCartItem =  new EventEmitter<MaterialResult>();
  @Output() ShowMessage = new EventEmitter<string>();
  @ViewChild('dv') dataView: DataView;
  inventoryUOM:string ='';
  materialType: string = "Coformulant";
  SelectedRepo:string = "";
  materialNameHeader:string;
  materialTypeId: string = "1";
  displayName:string ='';
  searchText: string;
  aiC: string;
  concept: string;
  composition: string;
  lotNumber: string;
  uom: string = "mg"
  isGlp: boolean = false;
  showMore: boolean =false;
  showField: boolean = false;
  results: Array<MaterialResult> = [];
  displayDialog:boolean = false;
  displayDialogBioNexus: boolean = false;
  materialInfo:CompositeInventoryResult;
  cols:any=[];
  RequestTypeName:string = RequestType.InStock;
  disableRequestType:boolean = true;
  @Input() existingCartItemRequestType: string = '';
  uomOptions: QuantityUnitOfMeasureModel[];
  selectedUom: QuantityUnitOfMeasureModel;
  selectedMaterialRepoName: any;
  selectedMaterialId: any;
  selectedMaterialHeader: any;
  public isHidden: boolean = false;
  public displayApproverDialog:boolean = false;
  public euCenterFeature = ConfigurationSettings.CurrentEnvironment.featureFlags.euCenter;
  constructor(@Inject(COMPOSITE_SEARCH_SETTINGS)
  public compositeSearchSettings: MaterialSearchConfig,
   private coformulantClient: CoformulantClient,
   private formulationClient: FormulationClient,
   private bioNexusClient: BioNexusClient,
   protected messageService: MessageService,
   protected compoundClient:CompoundClient,protected commonService:TupleService,
   private userClient:UserClient,
   private quantityUomClient: QuantityUomClient,
   private compositeInventoryClient: CompositeInventoryClient) {
    console.log(compositeSearchSettings);
  }

  public get requestType(): typeof RequestType {
    return RequestType;
  }
  ngOnChanges()
  {
    this.UpdateDisableRequestType();
  }

  OnUnitOfMeasureChange(obj:any)
  {
    this.uom = this.selectedUom.uomDisplay;
    this.getMaterialDetails(this.selectedMaterialRepoName,this.selectedMaterialId, this.uom, this.selectedMaterialHeader);
  }

  GetUserDetailsFromAD(email:string)
  {
    this.userClient.getADUser(email).subscribe(data => {
      this.displayName = data?.firstName + ' ' + data?.lastName ;
      if(this.displayName == null || this.displayName == undefined)
      {
        this.displayName = email;
      }
    });

  }
  UpdateDisableRequestType()
  {
    this.disableRequestType= true;
    switch(this.existingCartItemRequestType){
      case RequestType.GLP :
        this.RequestTypeName = RequestType.GLP;
        break;

        case RequestType.InStock :
        this.RequestTypeName = RequestType.InStock;
        break;
        default :

        this.RequestTypeName = RequestType.InStock;
        this.disableRequestType = false;
        break;
    }
  }

  ngOnInit(): void {
    console.log('test');
    console.log('isGlpEligible = ' + this.isGlpEligible);
    this.cols = [
      { field: 'lotId', header: 'Lot No', width: '142px' },
      { field: 'manufacturerLotNumber', header: 'Mfg Lot No', width: '142px' },
      { field: 'dateOfManufacture', header: 'Date of Mfg', width: '142px' },
      { field: 'inventoryType', header: 'Inventory Type', width: '142px' },
      { field: 'expirationDate', header: 'Exp Date', width: '142px' },
      { field: 'available', header: 'Available Qty', width: '142px' }
    ];
    this.getUomDetails();

  }

  private getUomDetails(){
    this.quantityUomClient.get3(true)
    .subscribe(uomResult => {
      this.uomOptions=uomResult;
    });
  }

  getMaterialDetailsForBioNexus(material: MaterialResult) {
    console.log(material.materialName);
    console.log('inventory button pressed !');
    this.materialNameHeader = material.materialName;
    this.displayDialogBioNexus = true;
  }

  public isBioResult(result){
    if((result.sourceSystem && result.sourceSystem.toLowerCase() === "bionexus")
        || (result.isBioFormulation && result.isBioFormulation === true)){
      return true;
    }
    return false;
  }

  private getMaterialDetails(repoName,materialId,uom,displayHeader = null){
    this.selectedMaterialRepoName = repoName;
    this.selectedMaterialId = materialId;
    this.selectedMaterialHeader = displayHeader;
    this.uom = uom;
    this.compositeInventoryClient.retrieveCompositeInventoryWithStorageLoc(repoName,materialId , uom, false)
    .subscribe(materilInfo => {
      this.materialInfo = materilInfo[0];
      const result  = this.materialInfo.compositeInventoryByLot?.filter(d=> d.available > 0 &&
         (d.location === this.RequestTypeName));
      this.materialInfo.compositeInventoryByLot = result;
      if(this.materialInfo.compositeInventoryByLot.length > 0)
      {
      if(repoName == 'CORE')
        {
            this.materialInfo.compositeInventoryByLot = this.materialInfo.compositeInventoryByLot.sort((a, b) => {
                return b.lotId - a.lotId;
             });
        }
      else
        {
          this.materialInfo.compositeInventoryByLot.sort((a, b) => (a.lotNumber > b.lotNumber) ? 1 : -1);
        }
    }
      this.materialNameHeader =displayHeader == null ?  materialId : displayHeader;
      this.selectedUom= this.uomOptions.find(uom => uom.uomDisplay == this.uom);
      this.SelectedRepo = repoName;
    this.inventoryUOM = uom;
      this.displayDialog = true;
    });
  }
  getSearchLabelText(): string {
    switch (this.materialType)
    {
      case this.commonService.configurations?.Coformulant:
      case this.commonService.configurations?.FieldInert:
        return this.commonService.configurations?.EnterTradeName;
      case this.commonService.configurations?.FACTSFormulationName:
        return this.commonService.configurations?.EnterFormulation;
      case this.commonService.configurations?.FACTSSynonym:
        return this.commonService.configurations?.EnterSynonym;
      case this.commonService.configurations?.CortevaTradeName:
        return this.commonService.configurations?.EnterCortevaTradeName;
      case this.commonService.configurations?.ThirdPartyTradeName:
        return this.commonService.configurations?.EnterPartyTradeName;
      case this.commonService.configurations?.FACTSFormulationNameGlp:
        return this.commonService.configurations?.EnterFormulationLot;
      case this.commonService.configurations?.COREMaterialId:
        return this.commonService.configurations?.EnterCoreMaterialId;
      case this.commonService.configurations?.CORESynonym:
        return this.commonService.configurations?.EnterSynonym;
      case this.commonService.configurations?.COREMaterialLotGlp:
        return this.commonService.configurations?.EnterCoreMaterialLotId;
      case this.commonService.configurations?.FACTTSNGlp:
        return this.commonService.configurations?.EnterTSNLot;

      case this.commonService.configurations?.TsnGlp:
        return this.commonService.configurations?.EnterTsn;
      case this.commonService.configurations?.BioMaterialLotId:
        return this.commonService.configurations?.EnterBioMaterialLotId;
      case this.commonService.configurations?.BioMaterialId:
        return this.commonService.configurations?.EnterBioMaterialId;
      case this.commonService.configurations?.BionexusSynonymGlp:
      case this.commonService.configurations?.BionexusSynonymStock:
        return this.commonService.configurations?.EnterSynonym;
    }
    return '';
  }
  onTypeChange() {
    var newMaterialType = '';
    switch (this.materialTypeId) {
      case '1': {
        newMaterialType = this.commonService.configurations?.Coformulant;
        break;
      }
      case '2': {
        newMaterialType = this.commonService.configurations?.FieldInert;
        break;
      }
      case '3': {
        newMaterialType = this.commonService.configurations?.FACTSFormulationName;
        break;
      }
      case '4': {
        newMaterialType = this.commonService.configurations?.FACTSSynonym;
        break;
      }
      case '5': {
        newMaterialType = this.commonService.configurations?.CortevaTradeName;
        break;
      }
      case '6': {
        newMaterialType = this.commonService.configurations?.ThirdPartyTradeName;
        break;
      }
      case '7': {
        newMaterialType = this.commonService.configurations?.FACTSFormulationNameGlp;
        break;
      }
      case '8': {
        newMaterialType = this.commonService.configurations?.COREMaterialId;
        break;
      }
      case '9': {
        newMaterialType = this.commonService.configurations?.CORESynonym;
        break;
      }
      case '10': {
        newMaterialType = this.commonService.configurations?.COREMaterialLotGlp;
        break;
      }
      case '12': {
        newMaterialType = this.commonService.configurations?.FACTTSNGlp;
        break;
      }
      case '11': {
        newMaterialType = this.commonService.configurations?.TsnGlp;
        break;
      }
      case '13': {
        newMaterialType = this.commonService.configurations?.BioMaterialLotId;
        break;
      }
      case '14': {
        newMaterialType = this.commonService.configurations?.BionexusSynonymGlp;
        break;
      }
      case '15': {
        newMaterialType = this.commonService.configurations?.BioMaterialId;
        break;
      }
      case '16': {
        newMaterialType = this.commonService.configurations?.BionexusSynonymStock;
        break;
      }
    }
    if (newMaterialType !== this.materialType) {
      this.materialType = newMaterialType;
      this.resetValues();
    }
  }
  resetValues() {
    this.results = [];
    this.searchText = '';
    this.aiC = '';
    this.concept = '';
    this.composition = '';
    this.lotNumber = '';
  }
  getSearchCriteria(): string {
    switch (this.materialType)
    {
      case this.commonService.configurations?.Coformulant:
      case this.commonService.configurations?.FieldInert:
      case this.commonService.configurations?.CortevaTradeName:
        return this.searchText;
      case this.commonService.configurations?.ThirdPartyTradeName:
        return this.searchText;
      case this.commonService.configurations?.FACTSSynonym:
      case this.commonService.configurations?.COREMaterialId:
      case this.commonService.configurations?.CORESynonym:
      case this.commonService.configurations?.COREMaterialLotGlp:
      case this.commonService.configurations?.TsnGlp:
        return this.searchText;
        case this.commonService.configurations?.FACTTSNGlp:
        return this.searchText;
      case this.commonService.configurations?.FACTSFormulationName:
        return this.aiC + "-" + this.concept + "-" + this.composition;
      case this.commonService.configurations?.FACTSFormulationNameGlp:
        {
        return this.aiC + "-" + this.concept + "-" + this.composition + "-" + this.lotNumber;
        }
      case this.commonService.configurations?.BioMaterialId:
      case this.commonService.configurations?.BioMaterialLotId:
      case this.commonService.configurations?.BionexusSynonymGlp:
      case this.commonService.configurations?.BionexusSynonymStock:
        return this.searchText;
      default:
        return this.searchText;
    }
    return '';
  }

  addToCart(result: MaterialResult) {
    if(result.approverEmail != null && result.approverEmail != undefined && result.approverEmail != ""){
      this.userClient.getADUser(result.approverEmail).subscribe(data => {
        if(data != null){
          result.approverFullName = data['firstName'] + ' ' + data['lastName'];
          this.proceedForAddToCart(result);
          //this.displayApproverDialog = true;
        }
        else{
            this.displayApproverDialog = true;
        }
      });
    }
    else{
      this.proceedForAddToCart(result);
    }
  }

  proceedForAddToCart(result :MaterialResult){
    result["searchCriteria"] = this.getSearchCriteria();
    result['materialSearchType'] = this.materialType;
    this.AddCartItem.emit(result);
  }

  search() {
    this.results = [];
    switch (this.materialType)
    {
      case this.commonService.configurations?.Coformulant:
        if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== '')
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmpty, detail: '' });
          return false;
        }
        this.coformulantClient.retrieveCoformulantsByName(this.searchText)
          .subscribe(a => {
            this.results = a;
            this.markDuplicates.emit(this.results);
            this.isGlp =false;
            console.log(this.results);
          });
          break;
        case this.commonService.configurations?.FieldInert:
          if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== '')
          {
            this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmpty, detail: '' });
            return false;
          }
          this.coformulantClient.retrieveFieldInertsByName(this.searchText)
          .subscribe(a => {
            this.results = a;
            this.markDuplicates.emit(this.results);
            this.isGlp =false;
            console.log(this.results);
          });
          break;
          case this.commonService.configurations?.FACTSFormulationName:
            if(this.aiC == undefined || this.aiC.length == 0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterAIC, detail: '' });
return false;
            }
            else if(this.concept == undefined || this.concept.length == 0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterConcept, detail: '' });
return false;
            }
            else if(this.composition == undefined || this.composition.length == 0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterComposition, detail: '' });
return false;
            }

            if(!(/[a-zA-Z0-9]/.test(this.aiC)) || this.aiC.length>10)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.AICAlphaNumeric, detail: '' });
              return false;

            }

          this.formulationClient.retrieveFormulationsByFormulationId(this.getSearchCriteria())
          .subscribe(a => {
            this.results = a;
            this.markDuplicates.emit(this.results);
            this.results.sort((a, b) => (a.stage == "LEAD") ? -1 : 1);
            console.log(this.results);
          });
          break;
          case this.commonService.configurations?.FACTSSynonym:
              if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 250)
              {
                this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '250'), detail: '' });
                return false;
              }
              this.formulationClient.retrieveFormulationsBySynonym(this.getSearchCriteria())
              .subscribe(a => {
                this.results = a;
                this.markDuplicates.emit(this.results);
                this.results.sort((a, b) => (a.stage == "LEAD") ? -1 : 1);
                console.log(this.results);
              }, (error)=>{
                this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
              });
              break;
          case this.commonService.configurations?.ThirdPartyTradeName:
            if(this.getSearchCriteria() == undefined || this.getSearchCriteria().length > 100 ||  this.getSearchCriteria().length ==0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '100'), detail: '' });
              return false;
            }
            this.formulationClient.retrieveFormulationsByThirdPartyTradeName(this.getSearchCriteria())
            .subscribe(a => {
              this.results = a;
              this.markDuplicates.emit(this.results);
              this.results.sort((a, b) => (a.stage == "LEAD") ? -1 : 1);
              console.log(this.results);
            }, (error)=>{
              this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
            });
            break;

            case this.commonService.configurations?.CortevaTradeName:
            if(this.getSearchCriteria() == undefined || this.getSearchCriteria().length > 100 || this.getSearchCriteria().length ==0)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '100'), detail: '' });
              return false;
            }
            this.formulationClient.retrieveFormulationsByCortevaTradeName(this.getSearchCriteria())
            .subscribe(a => {
              this.results = a;
              this.markDuplicates.emit(this.results);
              this.results.sort((a, b) => (a.stage == "LEAD") ? -1 : 1);
              console.log(this.results);
            }, (error)=>{
              this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
            });
            break;
            case this.commonService.configurations?.COREMaterialId:

              if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 20)
              {
                this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: '' });
                return false;
              }
                this.compoundClient.retrieveCompoundByMaterialId(this.getSearchCriteria())
                .subscribe(a => {
                  this.results = a;
                  this.markDuplicates.emit(this.results);
                  console.log(this.results);
                });

                break;
                case this.commonService.configurations?.CORESynonym:
                  if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 100)
                  {
                    this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '100'), detail: '' });
                    return false;
                  }
                  this.compoundClient.retrieveCompoundBySynonym(this.getSearchCriteria())
                  .subscribe(a => {
                    this.results = a;
                    this.markDuplicates.emit(this.results);
                    console.log(this.results);
                  }, (error)=>{
                    this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
                  });

                    break;
                case this.commonService.configurations?.COREMaterialLotGlp:
                  if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 9)
                  {
                    this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '9'), detail: '' });
                    return false;
                  }

                    this.compoundClient.retrieveCompoundLotsByLotId(this.getSearchCriteria())
                    .subscribe(a => {
                      this.results = a;
                      this.markDuplicates.emit(this.results);
                      if(this.results[0].approverEmail != null && this.results[0].approverEmail != undefined)
                        {
                          this.GetUserDetailsFromAD(this.results[0].approverEmail)
                        }
                      this.results.forEach(element => {
                         if(element.recertificationDate != null)
                         {
                         if(element.recertificationDate.getTimezoneOffset()/60 > 0)
                         {
                          element.recertificationDate.setHours(element.recertificationDate.getHours()+ (element.recertificationDate.getTimezoneOffset()/60) );
                         }
                         else
                         {
                          element.recertificationDate.setHours(element.recertificationDate.getHours()- (element.recertificationDate.getTimezoneOffset()/60) );
                         }

                         }
                         if(element.certificationDate != null)
                         {
                           if (element.certificationDate.getTimezoneOffset()/60 > 0)
                           {
                            element.certificationDate.setHours(element.certificationDate.getHours()+ (element.certificationDate.getTimezoneOffset()/60) );
                           }
                           else
                           {
                          element.certificationDate.setHours(element.certificationDate.getHours()- (element.certificationDate.getTimezoneOffset()/60) );
                           }
                         }
                      });
                      this.markDuplicates.emit(this.results);
                      console.log(this.results);
                    });
              break;
      case this.commonService.configurations?.FACTSFormulationNameGlp:
        if(this.aiC == undefined || this.aiC.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterAIC, detail: '' });
return false;
        }
        else if(this.concept == undefined || this.concept.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterConcept, detail: '' });
return false;
        }
        else if(this.composition == undefined || this.composition.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterComposition, detail: '' });
return false;
        }
        else if(this.lotNumber == undefined || this.lotNumber.length == 0)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.EnterLot, detail: '' });
return false;
        }

        this.formulationClient.retreveConfidentialLots(this.getSearchCriteria(), undefined, undefined)
          .subscribe(a => {
            this.results = a;

            this.markDuplicates.emit(this.results);
            console.log(this.results);
          });
        break;
        case this.commonService.configurations?.TsnGlp:
          if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 100)
          {
            this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '100'), detail: '' });
            return false;
          }

          this.compoundClient.retrieveCompoundByTSN(this.getSearchCriteria())
              .subscribe(a => {
                this.results = a;
                if(this.results[0].approverEmail != null && this.results[0].approverEmail != undefined)
              {
                this.GetUserDetailsFromAD(this.results[0].approverEmail)
              }
                this.markDuplicates.emit(this.results);
                console.log(this.results);
              }, (error)=>{
                this.ShowMessage.emit(this.commonService.configurations?.CompositeSearchTimeoutError);
              });

            break;

        case this.commonService.configurations?.FACTTSNGlp:
          if(this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 20)
            {
              this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: '' });
              return false;
            }
            this.formulationClient.retrieveFormulationsByTSN(this.getSearchCriteria()).subscribe(x => {
              this.results = x;


              this.markDuplicates.emit(this.results);
              console.log(this.results);
            });
        break;

      // bionexus material search starts here
      case this.commonService.configurations?.BioMaterialId:
        if (this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 20)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: '' });
          return false;
        }
        this.bioNexusClient.retrieveBioNexusMaterialById(this.getSearchCriteria().toUpperCase()).subscribe((res:MaterialResult[]) => {
          this.results = res;
          this.markDuplicates.emit(this.results);
        }, (error)=>{
          if(error.title){
            this.ShowMessage.emit(error.title);
          }
          else
            this.ShowMessage.emit(error);
        });
        break;

      case this.commonService.configurations?.BioMaterialLotId:
        if (this.getSearchCriteria()== undefined ||this.getSearchCriteria()== ''  ||this.getSearchCriteria().length > 20)
        {
          this.messageService.add({ severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: '' });
          return false;
        }
        this.bioNexusClient.retrieveBioNexusLotByLotId(this.getSearchCriteria().toUpperCase()).subscribe((res:MaterialResult[]) => {
          res = res.filter(a => a.isGLP == true);
          this.results = res;
          this.markDuplicates.emit(this.results);
        }, (error)=>{
          if(error.title){
            this.ShowMessage.emit(error.title);
          }
          else
            this.ShowMessage.emit(error);
        });
        break;

      case this.commonService.configurations?.BionexusSynonymStock:
        if (this.getSearchCriteria() == undefined || this.getSearchCriteria() == '' || this.getSearchCriteria().length > 20)
        {
          this.messageService.add({severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: ''});
          return false;
        }
        this.bioNexusClient.retrieveBioNexusMaterialsBySynonymForStock(this.getSearchCriteria().toUpperCase()).subscribe((res:MaterialResult[]) => {
          this.results = res;
          this.markDuplicates.emit(this.results);
        }, (error)=>{
          if(error.title){
            this.ShowMessage.emit(error.title);
          }
          else
            this.ShowMessage.emit(error);
        });
        break;

      case this.commonService.configurations?.BionexusSynonymGlp:
        if (this.getSearchCriteria() == undefined || this.getSearchCriteria() == '' || this.getSearchCriteria().length > 20)
        {
          this.messageService.add({severity: 'error', summary: this.commonService.configurations?.SearchCriteriaCannotBeEmptyWithLimit.replace("{0}", '20'), detail: ''});
          return false;
        }
        this.bioNexusClient.retrieveBioNexusMaterialsBySynonymForGlp(this.getSearchCriteria().toUpperCase()).subscribe((res:MaterialResult[]) => {
          res.map(obj => {
            obj.isGLP = true;
          })
          this.results = res;
          this.markDuplicates.emit(this.results);
        }, (error)=>{
          if(error.title){
            this.ShowMessage.emit(error.title);
          }
          else
            this.ShowMessage.emit(error);
        });


    }
    this.dataView.first = 0;
  }

  RestrictUnwantedCharecters(e)
  {

      switch(this.materialType)
      {
        case this.commonService.configurations?.CortevaTradeName:
        case this.commonService.configurations?.ThirdPartyTradeName:
            if(e.key == "%" || e.key == "&")
              {
                return false;
              }
              break;
        case this.commonService.configurations?.COREMaterialId:
        case this.commonService.configurations?.COREMaterialLotGlp:
            if(this.searchText.length >= 9)
              {
                e.preventDefault();
                return false;
              }
            if(/^[0-9\s]*$/.test(e.key))
              {
                return true;
              }
            else
              {
                e.preventDefault();
                return false;
              }
            break;
        case this.commonService.configurations?.FACTTSNGlp:
                if(this.searchText.length >= 21)
                  {
                    e.preventDefault();
                    return false;
                  }
                  return true;
                break;
        case this.commonService.configurations?.BioMaterialLotId:
          if(/^[a-zA-Z0-9\s]*$/.test(e.key))
          {
            return true;
          }
        else
          {
            e.preventDefault();
            return false;
          }
        break;
      }

  }
  private getProductName(item: MaterialResult): string {
    let res: string = "";
    switch (item.sourceSystem) {
      case 'COFORM':
        res = item.materialName;
        break;
      case 'CORE':
        res = item.commonName === null ? item.materialName : item.commonName + ' (' + item.materialName + ')';
        break;
      case 'FACTS':
        return item['preferredSynonym'] === null ? item.materialName : item.materialName +' ('+ item['preferredSynonym'] + ')';
        break;
      default:
        res = item.materialName;
        break;
    }
    return res;
  }



  AllowAlphaNumeric(e)
  {
    if (/^[a-zA-Z0-9\s]*$/.test(e.key)) {
      return true;
      } else {
          e.preventDefault();
          return false;
      }

  }

  AllowOnlyNumeric(e)
  {
    if (/^[0-9\s]*$/.test(e.key)) {
      return true;
      } else {
          e.preventDefault();
          return false;
      }

  }

  attributeValues(att: any) {
    let val: any = '';
    if (att.values.length === 0 || (att.values.length === 1 && att.values[0] === '')) {
      val = '';
    } else {
      (att.values).forEach((vl, ind) => {
        if (ind === (att.values).length - 1) {
          val += vl;
        } else {
          val += vl + ', '
        }
      });
    }
    // let parsed = Date.parse(val);
    // let isDate = (isNaN(val) && !isNaN(parsed) ? true : false);
    // if (isDate) {
    //   const date = moment(val).format('DD MMM YYYY');
    //   if (new Date(date).getFullYear() < 2100) {
    //     val = date;
    //   }
    // }
    return val;
  }

}
