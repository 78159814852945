<div class="row">
  <div class="col-md-12 text-center">
    <h1 class="text-jumbo text-ginormous">Oops! We can't seem to find the page you're looking for.</h1>
    <h6>Error code: 404</h6>
    <p></p>
    <button class="btn btn-primary m-1" (click)="gotoDashboard()">Back To Dashboard</button>
    <p></p>
    <p></p>
    <div>
      <!-- <img class="img-fluid rounded mb-4" width="626" height="365" src="assets/images/image_3.jpg" alt=""> -->
    </div>
  </div>
</div>
