import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class InterComponentMessageService {

  // Observable string sources
  private delegateClonedSource = new Subject<number>();
  private delegateCloneReceivedSource = new Subject<number>();

  // Observable string streams
  cloneAnnounced$ = this.delegateClonedSource.asObservable();
  cloneConfirmed$ = this.delegateCloneReceivedSource.asObservable();

  // Service message commands
  announceClones(targetSeasonId: number) {
    this.delegateClonedSource.next(targetSeasonId);
  }

  confirmClones(targetSeasonId: number) {
    this.delegateCloneReceivedSource.next(targetSeasonId);
  }
}