import { CompositeInventoryByLot } from './../services/gsscommon-api-client.service';
import { Component, OnInit, Inject } from "@angular/core";
import { inject, waitForAsync } from "@angular/core/testing";
import { ActivatedRoute } from "@angular/router";
import {
  COMPOSITE_SEARCH_SETTINGS,
  MaterialSearchConfig,
} from "../services/environment-service/material-search-config";
import {
  CompositeInventoryClient,
  CompositeInventoryResult,
  ReservationSearchInput,
  ReservationsClient,
  ReservationsSearchResults,
} from "../services/gsscommon-api-client.service";
import {
  QuantityUomClient,
  ForecastItemClient,
  ReservationClient,
  UserModel,
} from "../services/forecast-api-client.service";
import { ConfigurationSettings } from "../configuration-settings";
import { RoleGuardService } from "../services/role-gaurd-service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-material-inventory",
  templateUrl: "./material-inventory.component.html",
  styleUrls: ["./material-inventory.component.scss"],
})
export class MaterialInventoryComponent implements OnInit {
  stateOptions: any[];
  selectedMesaure = "mg";
  selectedUom;
  updatedTimeStamp: String = new Date().toLocaleString();
  cols: any[];
  materialInfo: CompositeInventoryResult;
  materialId;
  repoName;
  materialName;
  seedForecastItemId;
  public euCenterFeature =
    ConfigurationSettings.CurrentEnvironment.featureFlags.euCenter;
  isAdministrator = false;
  isMaterialPlanner = false;
  isOrderCoordinator = false;
  selectedInventoryType = "STOCK";
  inventoryList = [
    { name: "Stock", value: "STOCK" },
    { name: "GLP", value: "GLP", disabled:false },
    { name: "Both", value: "BOTH", disabled:false },
  ];
  inventoryBreakDown = {
    STOCK: { INDY: {}, FRANCE: {}, SGS: {}, SUB: {} },
    GLP: { INDY: {}, FRANCE: {}, SGS: {}, SUB: {} },
  };
  reservations : ReservationsSearchResults[] = [];
  indyWarehouseName = "Indy";
  inventoryTotalAvailable = 0;
  constructor(
    @Inject(COMPOSITE_SEARCH_SETTINGS)
    public materialSearchSettings: MaterialSearchConfig,
    private compositeInventoryClient: CompositeInventoryClient,
    private activatedRoute: ActivatedRoute,
    private quantityUomClient: QuantityUomClient,
    private http: HttpClient,
    private reservationClient: ReservationClient,
    private roleGuardService: RoleGuardService,
    private forecastItemClient: ForecastItemClient,
    private alchemistReservationsClient : ReservationsClient,
  ) {}

  ngOnInit() {
    this.isMaterialPlanner =
      this.roleGuardService.doesUserHaveRole("Material Planner");
    this.isAdministrator =
      this.roleGuardService.doesUserHaveRole("Administrator");
    this.isOrderCoordinator =
      this.roleGuardService.doesUserHaveRole("Order Coordinator");

    this.materialId = this.activatedRoute.snapshot.paramMap.get("materialId");
    this.repoName = this.activatedRoute.snapshot.paramMap.get("repoName");
    this.inventoryList.find(x=>x.value=="GLP").disabled =(this.repoName == 'COFORM');
    this.inventoryList.find(x=>x.value=="BOTH").disabled =(this.repoName == 'COFORM');
    this.indyWarehouseName = this.euCenterFeature ? "Indianapolis" : "Indy";

    this.forecastItemClient
      .getForecastItemByMaterial(this.repoName, this.materialId)
      .subscribe((data) => {
        this.materialName = data.productName;
        this.getMaterialDetails();
      });

    this.getUomDetails();

    this.cols = [
      { field: "lotNumber", header: "*Repo Lot No./ID" },
      { field: "manufacturerLotNumber", header: "Mfg Lot No" },
      { field: "dateOfManufacture", header: "Date of Mfg" },
      { field: "expirationDate", header: "Exp Date" },
      { field: "inventoryType", header: "Inventory Type" },
      { field: "inventoryLocation", header: "Warehouse Loc." },
      { field: "physical", header: "Physical" },
      { field: "reserved", header: "Reserved" },
      { field: "inProgress", header: "In Progress" },
      { field: "available", header: "Available" },
      { field: "isReach", header: "REACH" },
    ];
  }

  private async copyToClipboard(va) {
    await navigator.clipboard.writeText(va);
    console.log(await navigator.clipboard.readText());
  }
  private getMaterialDetails() {
    this.compositeInventoryClient
      .retrieveCompositeInventoryWithStorageLoc(
        this.repoName,
        this.materialId,
        this.selectedMesaure,
        true
      )
      .subscribe((materilInfo) => {
        if (this.repoName === "CORE") {
          this.cols[0].field = "lotId";
        } else {
          this.cols[0].field = "lotNumber";
        }
        this.materialInfo = materilInfo[0];
        let input = new ReservationSearchInput();
        input.productSourceSystem = this.repoName;
        input.productIdentifier = this.materialId;
        input.requestedUOM = this.selectedMesaure;
        this.alchemistReservationsClient.search(input).subscribe(x=>{
          this.reservations = x;
          this.breakDownInventory(this.materialInfo);
       });
        this.materialInfo.compositeInventoryByLot =
          this.materialInfo.compositeInventoryByLot
            .filter((a) => a.physical > 0 || a.reserved > 0)
            .sort((a, b) => (a.available > b.available ? -1 : 1));

      });
  }

  isLotExist(lots:CompositeInventoryByLot[] = [], lotNumber, isGlp, warehouseName,reservationTypeId){
    let repos = ['FACTS', 'COFORM', 'BIONEXUS'];
   if(reservationTypeId){
    if(this.euCenterFeature){
      warehouseName = reservationTypeId == 2 ? "France" : this.indyWarehouseName;
    }else{
      warehouseName = reservationTypeId == 2 ? "SGS": this.indyWarehouseName;
    }
  }
    return lots.some(x=> ((this.repoName =="CORE" && x.lotId == lotNumber) || (repos.includes(this.repoName) && x.lotNumber == lotNumber))
                    && ((!isGlp && x.inventoryType.toLowerCase().includes('stock')) || (isGlp && x.inventoryType.toLowerCase().includes('glp')))
                    && (warehouseName == x.inventoryLocation));
  }

  breakDownInventory(materialInfo) {
    let indyStock = materialInfo.compositeInventoryByLot.filter(
      (x) => x.inventoryType.toLowerCase().includes('stock') && x.inventoryLocation == this.indyWarehouseName
    );
    let indyGLP = materialInfo.compositeInventoryByLot.filter(
      (x) => x.inventoryType.toLowerCase().includes('glp') && x.inventoryLocation == this.indyWarehouseName
    );

    let stockAvailable = 0;
    let glpAvailable = 0;
    let indyStockNoLotReservation = 0;
    let sgsStockNoLotReservation = 0;
    let franceStockNoLotReservation = 0;
    let indyGLPNoLotReservation = 0;
    let sgsGLPNoLotReservation = 0;
    let franceGLPNoLotReservation = 0;

    let indyStockPhysical = indyStock.reduce((f, x) => f + x.physical, 0);
    let indyStockReserved = indyStock.reduce((f, x) => f + x.reserved, 0);
    let indyStockAvailable = indyStock.reduce((f, x) => f + x.available, 0);

    let indyGLPPhysical = indyGLP.reduce((f, x) => f + x.physical, 0);
    let indyGLPReserved = indyGLP.reduce((f, x) => f + x.reserved, 0);
    let indyGLPAvailable = indyGLP.reduce((f, x) => f + x.available, 0);

    if (this.euCenterFeature) {
      let franceStock = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('stock') && x.inventoryLocation == "France"
      );
      let franceGLP = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('glp') && x.inventoryLocation == "France"
      );

      indyStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == this.indyWarehouseName).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      indyGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == this.indyWarehouseName).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      franceStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == 'France').reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      franceGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == 'France').reduce((a,r)=> a+ r.reservationAmountConverted ,0);

      let franceStockPhysical = franceStock.reduce((f, x) => f + x.physical, 0);
      let franceStockReserved = franceStock.reduce((f, x) => f + x.reserved, 0);
      let franceStockAvailable = franceStock.reduce(
        (f, x) => f + x.available,
        0
      );

      let franceGLPPhysical = franceGLP.reduce((f, x) => f + x.physical, 0);
      let franceGLPReserved = franceGLP.reduce((f, x) => f + x.reserved, 0);
      let franceGLPAvailable = franceGLP.reduce((f, x) => f + x.available, 0);

      this.inventoryBreakDown.STOCK.INDY = {
        physical: indyStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation,
        available: indyStockAvailable - indyStockNoLotReservation,
      };

      this.inventoryBreakDown.GLP.INDY = {
        physical: indyGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation,
        available: indyGLPAvailable - indyGLPNoLotReservation,
      };

      this.inventoryBreakDown.STOCK.FRANCE = {
        physical: franceStockPhysical,
        reserved: franceStockReserved + franceStockNoLotReservation,
        available: franceStockAvailable - franceStockNoLotReservation,
      };
      this.inventoryBreakDown.GLP.FRANCE = {
        physical: franceGLPPhysical,
        reserved: franceGLPReserved + franceGLPNoLotReservation,
        available: franceGLPAvailable - franceGLPNoLotReservation,
      };

      stockAvailable = indyStockAvailable - indyStockNoLotReservation + franceStockAvailable - franceStockNoLotReservation;
      glpAvailable = indyGLPAvailable - indyGLPNoLotReservation +  franceGLPAvailable - franceGLPNoLotReservation;

      this.inventoryBreakDown.STOCK.SUB = {
        physical: indyStockPhysical + franceStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation + franceStockReserved + franceStockNoLotReservation,
        available: stockAvailable,
      };
      this.inventoryBreakDown.GLP.SUB = {
        physical: indyGLPPhysical + franceGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation + franceGLPReserved + franceGLPNoLotReservation,
        available: glpAvailable,
      };

    } else {
      let sgsStock = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('stock') && x.inventoryLocation == "SGS"
      );
      let sgsGLP = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('glp') && x.inventoryLocation == "SGS"
      );

      indyStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 1).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      indyGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 1).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      sgsStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 2).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      sgsGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 2).reduce((a,r)=> a+ r.reservationAmountConverted ,0);

      let sgsStockPhysical = sgsStock.reduce((f, x) => f + x.physical, 0);
      let sgsStockReserved = sgsStock.reduce((f, x) => f + x.reserved, 0);
      let sgsStockAvailable = sgsStock.reduce((f, x) => f + x.available, 0);

      let sgsGLPPhysical = sgsGLP.reduce((f, x) => f + x.physical, 0);
      let sgsGLPReserved = sgsGLP.reduce((f, x) => f + x.reserved, 0);
      let sgsGLPAvailable = sgsGLP.reduce((f, x) => f + x.available, 0);

      this.inventoryBreakDown.STOCK.INDY = {
        physical: indyStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation,
        available: indyStockAvailable - indyStockNoLotReservation,
      };

      this.inventoryBreakDown.GLP.INDY = {
        physical: indyGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation,
        available: indyGLPAvailable - indyGLPNoLotReservation,
      };

      this.inventoryBreakDown.STOCK.SGS = {
        physical: sgsStockPhysical,
        reserved: sgsStockReserved + sgsStockNoLotReservation,
        available: sgsStockAvailable - sgsStockNoLotReservation,
      };
      this.inventoryBreakDown.GLP.SGS = {
        physical: sgsGLPPhysical,
        reserved: sgsGLPReserved + sgsGLPNoLotReservation,
        available: sgsGLPAvailable - sgsGLPNoLotReservation,
      };

      stockAvailable = indyStockAvailable - indyStockNoLotReservation + sgsStockAvailable - sgsStockNoLotReservation;
      glpAvailable = indyGLPAvailable - indyGLPNoLotReservation + sgsGLPAvailable - sgsGLPNoLotReservation;

      this.inventoryBreakDown.STOCK.SUB = {
        physical: indyStockPhysical + sgsStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation + sgsStockReserved + sgsStockNoLotReservation,
        available: stockAvailable,
      };
      this.inventoryBreakDown.GLP.SUB = {
        physical: indyGLPPhysical + sgsGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation + sgsGLPReserved + sgsGLPNoLotReservation,
        available: glpAvailable,
      };
    }
      this.inventoryTotalAvailable = stockAvailable + glpAvailable;
    //console.log(this.inventoryBreakDown);
  }

  private Reconcile() {
    this.reservationClient
      .reconcileReservations(this.repoName, this.materialId)
      .subscribe((x) => {
        this.getMaterialDetails();
      });
    }
    downloadReconcileReport(){
      let headers = new HttpHeaders();
      headers =  headers.set('Accept', 'application/pdf');
      this.http.get<Blob>(
        ConfigurationSettings.REST_API_URL + `/api/Reservation/ReconciliationReport/${this.repoName}/${this.materialId}/${this.selectedMesaure}/${this.materialName}`,
        { headers, responseType: 'blob' as 'json' }).subscribe(
          blob => {
              const url = window.URL.createObjectURL(blob);
              var fileLink = document.createElement('a');
              fileLink.href = url;
              fileLink.download = 'ReconciliationReport.xlsx';
              fileLink.click();
          });
    }
  private getUomDetails() {
    this.quantityUomClient.get3(true).subscribe((uomResult) => {
      this.stateOptions = uomResult;
      this.selectedUom = uomResult.find(
        (uom) => uom.uomDisplay === this.selectedMesaure
      );
    });
  }

  OnUnitOfMeasureChange(obj: any) {
    this.selectedMesaure = this.selectedUom.uomDisplay;
    this.getMaterialDetails();

    this.updatedTimeStamp = new Date().toLocaleString();
  }

  RefreshClick() {
    this.updatedTimeStamp = new Date().toLocaleString();
  }
}
