<div class="grid">
  <div class="md:col-3">
    <h4 id="formulation-production-title" class="formulation-title">Formulation Production</h4>
  </div>
  <div class="md:col-9">
  </div>
</div>
<div class="mt-15" id="no-records-message" *ngIf="formulationRequests.length === 0">
  No records found
</div>
<div *ngIf="formulationRequests">
  <div class="mt-15">
    <p-dataView #dv1 [value]="formulationRequests" [lazy]="true" (onPage)="loadRawMaterials($event)"
      id="formulationsDataView" [paginator]="true" [rows]="pageSize" paginatorPosition="both" layout="list"
      styleClass="hover" [totalRecords]="totalRecords">
      <ng-template pTemplate="header">
        <div style="width: 100%" style="background-color: #f4f4f4;border: 1px solid darkgray"
          class="clsDataViewHeaderVisiblePart">
          <div class="grid" style="width: 100%;">
            <div class="col-3 xl:col-3 lg:col-3 md:col-6 sm:col-12">
              <div class="grid" style="margin-top: 1px;">
                <div class="col-2">
                  <div (click)="toggleFilterAccordion($event, 0)">
                    <i style="margin-left:10px" class="fa fa-filter fa-2x" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="col-8">
                  <p-dropdown id="savedFiltersDropdown" [style]="{'width':'100%'}" optionLabel="name"
                    [(ngModel)]="selectedSavedFilter" (click)="stopDefaults()" [options]="savedFilters"
                    (onChange)="updateApplySelectedFilterButtonState()" placeholder="Please select a filter">
                  </p-dropdown>
                </div>
                <div class="col-2">
                  <button type="button" style="margin-left: 1em" id="applySelectedFilterButton" pButton
                    (click)="applySelectedFilter(selectedSavedFilter)" label="Apply"
                    [ngClass]="{'disabled': isApplySelectedFilterButtonDisabled}"></button>
                </div>
              </div>
            </div>
            <div class="col-8 xl:col-8 lg:col-12 md:col-12 sm:col-12">
              <div class="grid" style="margin-left:3px;margin-top:1px;" *ngIf="false">
                <div class="col-2 xl:col-2 lg:col-12 md:col-12 sm:col-12 clsFilterDiv"
                  *ngFor="let searchCriteria of searchCriteriaList">
                  {{GetFilterValues(searchCriteria)}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf='isAccordionOpen' class='clsFilterFormDiv'>
          <form [formGroup]="filterFormulationRequestsForm" class="clsFilterForm" id="filterFormulationRequestsForm"
            style="padding-left: 15px; padding-left: 15px;text-align: left;background: white; text-align: left;">
            <div class="grid" style="width: 100%;">
              <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Formulation</div>
              <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <input type="text" id="formulationFilterOption" (keyup.enter)="filterFormulationRequests()"
                  formControlName="formulationFilterOption" pInputText class="p-inputtext">
              </div>
              <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Date Needed</div>
              <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <p-calendar id="dateNeededFilterOption" selectionMode="range" [(ngModel)]="dateNeededFilterOptionValue"
                  formControlName="dateNeededFilterOption" dateFormat="dd-M-yy" [showIcon]="true"
                  (keyup.enter)="filterFormulationRequests()">
                </p-calendar>
              </div>
              <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Forecaster</div>
              <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <p-autoComplete id="forecasterFilterOption" (keyup.enter)="filterFormulationRequests()"
                  formControlName="forecasterFilterOption" placeholder="Forecaster"
                  (completeMethod)="autoCompleteUsers($event)" [suggestions]="filterOptionsAutoCompleteUsersList"
                  [(ngModel)]="selectedForecasterFilterOption" field="label"
                  (onSelect)="onSelectFilterOptionUser($event, 'selectedForecasterFilterOption')" [minLength]="1">
                </p-autoComplete>
              </div>
            </div>
            <div class="grid" style="width: 100%">
              <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Program #</div>
              <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <input type="text" id="programNumberFilterOption" (keyup.enter)="filterFormulationRequests()"
                  formControlName="programNumberFilterOption" pInputText class="p-inputtext">
              </div>
              <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Production Owner</div>
              <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <p-autoComplete id="productionOwnerFilterOption" (keyup.enter)="filterFormulationRequests()"
                  formControlName="productionOwnerFilterOption" placeholder="Production Owner"
                  (completeMethod)="autoCompleteUsers($event)" [suggestions]="filterOptionsAutoCompleteUsersList"
                  [(ngModel)]="selectedProductionOwnerFilterOption" field="label"
                  (onSelect)="onSelectFilterOptionUser($event, 'selectedProductionOwnerFilterOption')" [minLength]="1">
                </p-autoComplete>
              </div>
              <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Material Planner</div>
              <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <p-autoComplete id="materialPlannerFilterOption" (keyup.enter)="filterFormulationRequests()"
                  formControlName="materialPlannerFilterOption" placeholder="Material Planner"
                  (completeMethod)="autoCompleteUsers($event)" [suggestions]="filterOptionsAutoCompleteUsersList"
                  [(ngModel)]="selectedMaterialPlannerFilterOption" field="label"
                  (onSelect)="onSelectFilterOptionUser($event, 'selectedMaterialPlannerFilterOption')" [minLength]="1">
                </p-autoComplete>
              </div>
            </div>
            <div class="grid" style="width: 100%">
              <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Status</div>
              <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <p-multiSelect id="statusFilterOption" [options]="statusFilterOptions"
                  formControlName="statusFilterOption" [style]="{'width': '186px'}" optionLabel="label" appendTo="body"
                  defaultLabel="Select" [showHeader]="false" [filter]="false" [(ngModel)]="selectedStatusFilterOption">
                </p-multiSelect>
              </div>
              <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Production Location</div>
              <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <p-dropdown id="productionLocationFilterOption" [options]="productionLocationFilterOptions"
                  formControlName="productionLocationFilterOption" optionLabel="label"
                  [(ngModel)]="selectedProductionLocationFilterOption">
                </p-dropdown>
              </div>
              <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Filter Name</div>
              <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <input type="text" id="filterNameFilterOption" pInputText class="p-inputtext"
                  formControlName="filterNameFilterOption">
                <p-message id="filterNameFilterOptionErrorMessage" severity="error" text="Filter Name is Required"
                  *ngIf="showFilterButtoMessage"></p-message>
              </div>
              <div class="col-3 xl:col-3 lg:col-6 md:col-6 sm:col-6">
                <div class="grid">
                  <div class="col-3"><button type="button" id="applyFilterButton" style="min-width:80px;" pButton
                      label="Apply" (click)="filterFormulationRequests()"></button></div>
                  <div class="col-3"><button type="button" id="saveFilterButton" style="min-width:80px;" pButton
                      label="Save" (click)="saveFilterOptions()"
                      [ngClass]="{'disabled': isSaveFilterButtonDisabled}"></button></div>
                  <div class="col-3"><button type="button" id="clearFilterButton" style="width: 80px;" pButton
                      label="Clear All" (click)="clearAllFilterOptions()"></button></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="listItem" let-i="rowIndex">
        <div class="formulations" id="formulations">
          <p-header *ngIf="i === 0">
            <div id="formulations-header" class="form-group grid bg-blue-600 text-white"
              style="margin: 2px;padding: 4px;">
              <div id="formulationRequestHeader" class="md:col-1 formulation-title" style="cursor: pointer;">
                Formulation Request&nbsp;
                <i class="pi pi-sort-alt" (click)="sortItemsByFormulation()"></i>
              </div>

              <div id="programNumberHeader" class="md:col-1 formulation-title">Program #</div>
              <div id="dateNeededHeader" class="md:col-1 formulation-title" style="cursor: pointer;">
                Date Needed&nbsp;
                <i class="pi pi-sort-alt" (click)="sortItemsByDate()"></i>
              </div>
              <div id="quantityForecastedHeader" class="md:col-1 formulation-title">Quantity Forecasted (kg)</div>
              <div id="quantityAvailableHeader" class="md:col-1 formulation-title">Quantity Available</div>
              <div id="statusHeader" class="md:col-1 formulation-title" style="cursor: pointer;">
                Status&nbsp;
                <i class="pi pi-sort-alt" (click)="sortItemsByStatus()"></i>
              </div>
              <div id="statusHeader" class="md:col-1 formulation-title">Forecast Type</div>
              <div class="md:col-4 grid" style="padding-left: 20px;margin-top: 2px;">
                <div id="productionOwnerHeader" class="formulation-title">Production Owner</div>
                <div id="productionLocationHeader" class="formulation-title"
                  style="margin-left: 80px;">Production Location</div>
                <div id="actionsHeader" class="formulation-title"></div>
              </div>
            </div>
          </p-header>
          <p-header (click)="handleHeaderClick(item, $event)"
            [ngStyle]="{'cursor': 'pointer','margin':'2px','background-color': (i%2==0 ? '#a64d79' : '#8594a3') ,'color': (i%2==0 ? 'white' : 'black')}">
            <div class="form-group grid" id="aggregateDtls">
              <div id="formulationRequest_{{i}}" class="md:col-1 formulation-title">
                <div [ngClass]="{'formulation-open': item.isOrderTabOpen}">
                  <i [ngClass]="item.isOrderTabOpen ? 'pi-caret-down' : 'pi-caret-right'" class="pi"></i>
                  {{ removeTextInParentheses(item.formulationRequest) }}
                </div>
              </div>
              <div id="programNumber_{{i}}" class="md:col-1 formulation-title">
                {{item.programNumber}}
              </div>
              <div id="dateNeeded_{{i}}" class="md:col-1 formulation-title">
                {{formatDate(item.dateNeeded)}}
              </div>
              <div id="quantityForecasted_{{i}}" class="md:col-1 formulation-title">
                {{item.totalQuantityInKg | number: '1.3-3'}} kg
              </div>
              <div id="quantityAvailable_{{i}}" class="md:col-1 formulation-title" style="justify-content: center">
                <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                  style="max-width: 30px !important"
                  (click)="inventoryCheck.getMaterialDetails('FACTS', item.factID, 'mL', item.formulationRequest)"
                  class="p-button-label"></button>
              </div>
              <div id="status_{{i}}" class="md:col-1">
                <div class="status"
                  [ngStyle]="{'color':(i%2==0 ? 'white' : 'black'),'fontsm:col-1md:col-1-weight':'bold'}" [ngClass]="{
                  'new':  item.status === 'New',
                  'awaiting': item.status === 'Awaiting FARM' ,
                  'forecast': item.status === 'Submitted',
                  'available': item.status === 'RM Available',
                  'ordered': item.status === 'Ordered',
                  'fulfilled': item.status === 'Order Fulfilled',
                  'cancelled': item.status === 'Cancelled'
                }">
                  <span>{{item.status}}</span>
                </div>
              </div>
              <div id="forecastType_{{i}}" class="md:col-1 formulation-title" style="padding-left: 25px;">
                {{item.forecastType}}
              </div>
              <div class="md:col-4 formulation-title" style="align-content: center;">
                <!-- For Production Manager -->
                <div *ngIf="isProdManager && (item.status == 'New' || item.status == 'Awaiting FARM')"
                  class="formulation-title control-container">
                  <div id="owner_{{ i }}">
                    <div>
                      <p-autoComplete id="autoCompleteUsers_{{i}}" (completeMethod)="filterUsers($event)"
                        [suggestions]="filteredUsers" [size]="20" placeholder="Owner Search" [minLength]="2"
                        [(ngModel)]="item.productionOwner" [itemTemplate]="customItemTemplate" [disabled]="item.disableLocationAndOwner"
                        (onSelect)="onSelectAutoCompleteUsers($event, i)"
                        class="for-autocomplete-height"></p-autoComplete>
                    </div>
                  </div>
                  <div id="productionLocation_{{i}}">
                    <p-dropdown [options]="productionLocations" [(ngModel)]="selectedCountries[i]" [disabled]="item.disableLocationAndOwner"
                      (click)="shouldProcessClickEvent = false" optionLabel="label" placeholder="Select Location"
                      (click)="onDropdownClick($event)" (onChange)="onProductionLocationDropdownChange($event, i)"
                      class="left-aligned-dropdown" style="margin-left: 20px;"></p-dropdown>
                  </div>
                </div>
                <div *ngIf="isProdManager && !(item.status == 'New' || item.status == 'Awaiting FARM')"
                  class="formulation-title control-container">
                  <div id="owner_{{ i }}">
                    <div>
                      <p-autoComplete id="autoCompleteUsers_{{i}}" (completeMethod)="filterUsers($event)"
                        [suggestions]="filteredUsers" [size]="20" placeholder="Owner Search" [minLength]="2"
                        [(ngModel)]="item.productionOwner" [itemTemplate]="customItemTemplate"
                        (onSelect)="onSelectAutoCompleteUsers($event, i)"
                        class="for-autocomplete-height" [disabled]="true"></p-autoComplete>
                    </div>
                  </div>
                  <div id="productionLocation_{{i}}">
                    <p-dropdown [options]="productionLocations" [(ngModel)]="selectedCountries[i]"
                      (click)="shouldProcessClickEvent = false" optionLabel="label" placeholder="Select Location"
                      (click)="onDropdownClick($event)" (onChange)="onProductionLocationDropdownChange($event, i)"
                      class="left-aligned-dropdown" [disabled]="true" style="margin-left: 20px;"></p-dropdown>
                  </div>
                </div>

                <!-- For Read-Only (Disabled) -->
                <div
                  *ngIf="(!isProdManager)"
                  class="formulation-title control-container">
                  <div id="owner_{{ i }}">
                    <div>
                      <p-autoComplete id="autoCompleteUsers_{{i}}" (completeMethod)="filterUsers($event)"
                        [suggestions]="filteredUsers" [size]="20" placeholder="Owner Search" [minLength]="2"
                        [(ngModel)]="item.productionOwner" [itemTemplate]="customItemTemplate"
                        (onSelect)="onSelectAutoCompleteUsers($event, i)" class="for-autocomplete-height"
                        [disabled]="true"></p-autoComplete>
                    </div>
                  </div>
                  <div id="productionLocation_{{i}}">
                    <p-dropdown [options]="productionLocations" [(ngModel)]="selectedCountries[i]"
                      (click)="shouldProcessClickEvent = false" optionLabel="label" placeholder="Select Location"
                      (click)="onDropdownClick($event)" (onChange)="onProductionLocationDropdownChange($event, i)"
                      class="left-aligned-dropdown" [disabled]="true" style="margin-left: 20px;"></p-dropdown>
                  </div>
                </div>

                <div id="actions_{{i}}" class="formulation-title">
                  <button pButton type="button" title="Save" icon="fa fa-save fa-3x"
                    *ngIf="((isProdManager && !(item.disableLocationAndOwner)) && (item.status == 'New' || item.status == 'Awaiting FARM'))"
                    (click)="onSaveButtonClick(i)" style="margin-left: 20px;"></button>

                  <ng-container *ngIf="(isProdManager && (item.status == 'New' || item.status == 'Awaiting FARM')) || 
                  ((!isProdManager && item.productionOwnerId === userInfo.id) && (item.status == 'New' || item.status == 'Awaiting FARM'))">
                    <button pButton type="button" title="Add Forecast" icon="fa fa-plus fa-3x" id="addForecast_{{i}}"
                      (click)="navigateToFormulationRawMaterialWithParams(item)" style="margin-left: 20px;"></button>
                  </ng-container>
                  
                  <ng-container
                    *ngIf="(item.status == 'New' || item.status == 'Awaiting FARM') && (isProdManager || item.productionOwnerId === userInfo.id)">
                    <button pButton type="button" title="Cancel Farmulation Request" icon="fa fa-trash fa-3x"
                      id="cancelForecast_{{i}}" *ngIf="item.isDirty == false"
                      (click)="cancelFormulationRequest(item.programNumber)" style="margin-left: 20px;"></button>
                  </ng-container>
                </div>
              </div>
            </div>
          </p-header>
          <ng-container *ngIf="item.isAggregatesTabOpen">
            <div class="form-group grid details bg-gray-300 pt-0 mt-3" id="aggregateTab"
              style="background-color: #f4f4f4 !important;">
              <div class="md:col-span-1 header-column">
                <label id="formulationRequestLabel" class="font-bold">Formulation Request</label>
              </div>
              <div class="md:col-span-1 header-column">
                <label id="farmNumberLabel" class="font-bold">FARM #</label>
              </div>
              <div class="md:col-span-1 header-column">
                <label id="dateSubmittedLabel" class="font-bold">Date Submitted /<br /> Date Needed</label>
              </div>
              <div class="md:col-span-1 header-column">
                <label id="quantityForecastedLabel" class="font-bold">Quantity Forecasted / In kg</label>
              </div>
              <div class="md:col-span-1 header-column">
                <label id="forecasterLabel" class="font-bold">Forecaster</label>
              </div>
              <div class="md:col-span-1 header-column">
                <label id="forecastCountryLabel" class="font-bold">Forecast Country</label>
              </div>
              <div class="md:col-span-1 header-column">
                <label id="commentsAndNotesLabel" class="font-bold">Comments and Notes</label>
              </div>
              <div class="md:col-span-1 header-column">
                <label id="materialPlannerLabel" class="font-bold">Material Planner</label>
              </div>
              <div class="md:col-span-1 header-column">
                <label id="reachLabel" class="font-bold">EU Comp.</label>
              </div>
              <div class="md:col-span-1 header-column">
                <label id="forecasterTypeLabel" class="font-bold">Forecast Type</label>
              </div>
            </div>
            <div *ngFor="let request of item.individualFormulationRequests; let index = index;"
              id="aggRequest_{{index}}">
              <div class="form-group grid details" id="aggregateTabDtls__{{index}}">
                <div class="md:col-1 col-value">
                  <div id="formulationRequest_{{index}}">{{ request.formulationRequest }}</div>
                </div>
                <div class="md:col-1 col-value farmNumber">
                  <div id="farmNumber_{{index}}">{{ request.forecastItemId }}</div>
                </div>
                <div class="md:col-1 col-value">
                  <div id="dateSubmitted_{{index}}">{{ formatDate(request.dateSubmitted) }} /<br /> {{
                    formatDate(request.dateNeeded) }}</div>
                </div>
                <div class="md:col-1 col-value">
                  <div id="quantityForecasted_{{index}}">{{ request.quantityForecasted | number: '1.3-3' }}
                    {{request.orderQuantityUom}} /
                    <br /> {{request.quantityInKg | number: '1.3-3'}} kg
                  </div>
                </div>
                <div class="md:col-1 col-value">
                  <div id="forecaster_{{index}}">{{ request.forecaster }}</div>
                </div>
                <div class="md:col-1 col-value">
                  <div id="forecasterCountry_{{index}}">{{ request.forecasterCountry }}</div>
                </div>
                <div class="md:col-1 col-value">
                  <div class="formulation-value">
                    <div *ngIf="request.commentsAndNotes && request.commentsAndNotes.length > 0">
                      <span id="commentsIcon_{{index}}" class="comments-icon" (click)="openPopup(request, '', false)">
                        <i class="pi pi-comment cursor-pointer"></i>
                      </span>
                    </div>
                    <div *ngIf="!request.commentsAndNotes || request.commentsAndNotes.length === 0">
                      No Comments
                    </div>
                  </div>
                </div>
                <div class="md:col-1 col-value">
                  <div id="materialPlanner_{{index}}">{{ request.materialPlanner }}</div>
                </div>
                <div class="md:col-1 col-value">
                  <div id="reach_{{index}}">{{ request.isReachApproved ? 'Yes' : 'No' }}</div>
                </div>
                <div class="md:col-1 col-value">
                  <div id="forecastType_{{index}}">{{ request.forecastType }}</div>
                </div>
              </div>
            </div>
            <div *ngIf="item.materialComposition.length > 0">
              <div class="material-composition">
                <div class="md:col-12" style="display: flex; padding-left: 20px;">
                  <p-header (click)="item.isCompositionTabOpen =  !item.isCompositionTabOpen" class="header">
                    <div>
                      <div style="padding-right: 20px; font-weight: bold;"><i style="margin-right: 8px;" class="pi"
                          [ngClass]="item.isCompositionTabOpen ?'pi-caret-down':'pi-caret-right'"></i>Raw Material
                        Status - {{item.formulationRequest}} </div>
                    </div>
                  </p-header>
                </div>
              </div>
              <ng-container *ngIf="item.isCompositionTabOpen">
                <div class="form-group grid details pt-0 mt-3" id="compositionTab"
                  style="background-color: #f4f4f4 !important;">
                  <div class="md:col-1 header-column-1" *ngIf="!allMaterialsCancelled(item.materialComposition)">
                    <input type="checkbox" id="{{item.programNumber}}"
                      (change)="onSelectAllChange(item.programNumber,index,this.formulationRequests)"> Select All
                  </div>
                  <div class="md:col-1 header-column-2">
                    <label id="materialNameLabel" class="font-bold">Material Name</label>
                  </div>
                  <div class="md:col-1 header-column-1">
                    <label id="quantityForecastedLabel" class="font-bold">Quantity Forecasted (kg)</label>
                  </div>
                  <div class="md:1 header-column-1">
                    <label id="quantityAvailableLabel" class="font-bold">Quantity Available</label>
                  </div>
                  <div class="md:1 header-column-1">
                    <label id="farmEntryDateLabel" class="font-bold">Farm Entry Date</label>
                  </div>
                  <div class="md:1 header-column-1">
                    <label id="farmNumberLabel" class="font-bold">FARM #</label>
                  </div>
                  <div class="md:1 header-column-1">
                    <label id="procurementStatusLabel" class="font-bold">Procurement Status</label>
                  </div>
                  <div class="md:1 header-column-3">
                    <label id="reachApprovedLabel" class="font-bold">EU Comp.</label>
                  </div>
                  <div class="md:1 header-column-1">
                    <label id="materialPlannerLabel" class="font-bold">Material Planner</label>
                  </div>
                  <div class="md:1 header-column-1">
                    <label id="procurementCommentsLabel" class="font-bold">Procurement Comments</label>
                  </div>
                  <div class="md:1 header-column-1">
                    <label id="cpssNumberLabel" class="font-bold">CPSS # / Date</label>
                  </div>
                </div>

                <div *ngFor="let material of item.materialComposition; let index = index;"
                  id="rawMaterialComponent_{{index}}">
                  <div class="form-group grid details" id="compositionTabDtls__{{index}}">
                    <div class="md:col-1 col-value-1" *ngIf="!allMaterialsCancelled(item.materialComposition)">
                      <ng-container *ngIf="material.isCancelled == true">
                        <input type="checkbox" hidden id="{{item.programNumber}}_{{index}}"
                          (change)="onCheckboxChange(material, index, item.programNumber,this.formulationRequests)">
                      </ng-container>
                      <ng-container *ngIf="material.isCancelled != true">
                        <input type="checkbox" id="{{item.programNumber}}_{{index}}"
                          (change)="onCheckboxChange(material, index, item.programNumber,this.formulationRequests)">
                      </ng-container>
                    </div>

                    <div class="md:1 col-value-2">
                      <ng-container *ngIf="material.isCancelled === true">
                        <div id="materialName_{{index}}" class="cancelMaterial">{{ material.materialName }} - cancelled
                        </div>
                      </ng-container>
                      <ng-container *ngIf="material.isCancelled !== true">
                        <div id="materialName_{{index}}">{{ material.materialName }} </div>
                      </ng-container>
                    </div>
                    <div class="md:1 col-value-1">
                      <ng-container *ngIf="material.isCancelled === true">
                        <div id="quantityForecasted_{{index}}" class="cancelMaterial">{{ material.quantityForecasted }}
                          (kg)
                        </div>
                      </ng-container>
                      <ng-container *ngIf="material.isCancelled !== true">
                        <div id="quantityForecasted_{{index}}">{{ material.quantityForecasted }} kg</div>
                      </ng-container>
                    </div>
                    <div class="md:1 col-value-1">
                      <ng-container *ngIf="material.isCancelled === true">
                        <div id="quantityAvailable_{{index}}" class="cancelMaterial" style="justify-content: center">
                          <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                            style="max-width: 30px !important"
                            (click)="inventoryCheck.getMaterialDetails('FACTS', material.id, 'mL', material.materialName)"
                            class="p-button-label"></button>
                        </div>

                      </ng-container>
                      <ng-container *ngIf="material.isCancelled !== true">
                        <div id="quantityAvailable_{{index}}" style="justify-content: center">
                          <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                            style="max-width: 30px !important"
                            (click)="inventoryCheck.getMaterialDetails('FACTS', material.id, 'mL', material.materialName)"
                            class="p-button-label"></button>
                        </div>
                      </ng-container>
                    </div>
                    <div class="md:1 col-value-1">
                      <ng-container *ngIf="material.isCancelled === true">
                        <div id="farmEntryDate_{{index}}" class="cancelMaterial">{{ formatDate(material.farmEntryDate)
                          }}</div>
                      </ng-container>
                      <ng-container *ngIf="material.isCancelled !== true">
                        <div id="farmEntryDate_{{index}}">{{ formatDate(material.farmEntryDate) }}</div>
                      </ng-container>
                    </div>
                    <div class="md:1 col-value-1">
                      <ng-container *ngIf="material.isCancelled === true">
                        <div id="farmNumber_{{index}}" class="cancelMaterial">{{ material.forecastItemId }}</div>
                      </ng-container>
                      <ng-container *ngIf="material.isCancelled !== true">
                        <div id="farmNumber_{{index}}">{{ material.forecastItemId }}</div>
                      </ng-container>
                    </div>
                    <div class="md:1 col-value-1">
                      <ng-container *ngIf="material.isCancelled === true">
                        <div id="procurementStatus_{{index}}" class="cancelMaterial">{{ material.procurementStatus }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="material.isCancelled !== true">
                        <div id="procurementStatus_{{index}}">{{ material.procurementStatus }}</div>
                      </ng-container>
                    </div>
                    <div class="md:1 col-value-3">
                      <ng-container *ngIf="material.isCancelled === true">
                        <div id="reachApproved_{{index}}" class="cancelMaterial">{{ material.reachApproved ? 'Yes' :
                          'No' }}</div>
                      </ng-container>
                      <ng-container *ngIf="material.isCancelled !== true">
                        <div id="reachApproved_{{index}}">{{ material.reachApproved ? 'Yes' : 'No' }}</div>
                      </ng-container>
                    </div>
                    <div class="md:1 col-value-1">
                      <ng-container *ngIf="material.isCancelled === true">
                        <div id="materialPlanner_{{index}}" class="cancelMaterial">{{ material.materialPlanner }}</div>
                      </ng-container>
                      <ng-container *ngIf="material.isCancelled !== true">
                        <div id="materialPlanner_{{index}}">{{ material.materialPlanner }}</div>
                      </ng-container>
                    </div>
                    <div class="md:1 col-value-1">
                      <div class="formulation-value">
                        <div *ngIf="material.procurementComments && material.procurementComments.length > 0">
                          <span id="commentsIcon_{{index}}" class="comments-icon"
                            (click)="openPopup(material.procurementComments, material.materialName, true)">
                            <i class="pi pi-comment cursor-pointer"></i>
                          </span>
                        </div>
                        <ng-container *ngIf="material.isCancelled === true">
                          <div class="cancelMaterial"
                            *ngIf="!material.procurementComments || material.procurementComments.length === 0">
                            No Comments
                          </div>
                        </ng-container>
                        <ng-container *ngIf="material.isCancelled !== true">
                          <div *ngIf="!material.procurementComments || material.procurementComments.length === 0">
                            No Comments
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="md:1 col-value-1">
                      <ng-container *ngIf="material.isCancelled === true">
                        <div id="cpssDate_{{index}}" class="cancelMaterial">
                          {{ material.cpssNumber }}/{{ formatDate(material.cpssDate)}}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="material.isCancelled !== true">
                        <div id="cpssDate_{{index}}">
                          {{ material.cpssNumber }}/{{ formatDate(material.cpssDate)}}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="form-group col-1">
                  <div *ngIf="!allMaterialsCancelled(item.materialComposition)">
                    <button type="button" class="cancelSelectorButton" pButton label="Cancel"
                      (click)="onCancelButtonClick(item.programNumber, item.productionOwnerEmail, this.selectedItems)"></button>
                  </div>
                </div>
                <div class="col-11">
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <ng-template pTemplate="paginatorleft" let-state id="paginatorleft2">
        <span class="ui-paginator-first" style="padding-left:10px;"> Total Items : {{totalRecords}}
          {{ totalRecords == formulationRequests.length ? '' : '(Showing Items: '+ formulationRequests.length + ')'}}
        </span>
      </ng-template>
      <ng-template pTemplate="paginatorright" id="paginatorright2" let-state>
        <span class="ui-paginator-first" style="padding-right:10px;">{{((pageIndex - 1) * pageItemNumber) + 1}} -
          {{(formulationRequests.length !== pageItemNumber ? totalRecords : pageIndex * pageItemNumber)}} of
          {{totalRecords}} Items</span>
      </ng-template>
    </p-dataView>

    <p-dialog [header]="popupHeader" [(visible)]="popupVisible" [draggable]="false" [modal]="true"
      [dismissableMask]="true">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let comment of selectedRequest">
            <td>{{ extractName(comment) }}</td>
            <td>{{ extractDate(comment) }}</td>
            <td>{{ extractCommentText(comment) }}</td>
          </tr>
        </tbody>
      </table>
    </p-dialog>
  </div>
</div>
<app-inventory-check #inventoryCheck></app-inventory-check>