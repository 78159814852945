import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { FormulationTypeModel } from '../models/formulationtype.model';
import { ConfigurationSettings } from "../configuration-settings"

@Injectable()
export class FormulationTypeService {

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public getFormulationTypes(): any {
        let url = ConfigurationSettings.REST_API_URL + "/api/formulationTypes"
        return this._http.get(url).pipe(
            tap(x => console.log("Fetched formulation types")));
    }

    public updateFormulationType(ft: FormulationTypeModel): any {
        let url = ConfigurationSettings.REST_API_URL + "/api/formulationTypes"
        let body = JSON.stringify(ft);
        return this._http.put(
            url,
            body, this.httpOptions).pipe(
                tap(x => console.log("Updated formulationType"))
            );
    }

}