import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ConfigurationSettings } from '../configuration-settings';
import { catchError, map, tap } from 'rxjs/operators';
import { ForecastLocationModel } from '../models/forecastLocation.model';

@Injectable({
  providedIn: 'root'
})
export class ForecastlocationService {

  url = ConfigurationSettings.REST_API_URL + '/api/ForecastItemDestination/';
  httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private _http: HttpClient) { }

    public getForecastLocations(forecastId:number): any {
       
        return this._http.get(this.url + "GetDestinations/"+ forecastId ).pipe(
            tap(x => console.log('Fetched forecast location')));
    }

    public CreateForecastLocation(forecastLocation:ForecastLocationModel): any {
       
      return this._http.post(this.url ,forecastLocation ).pipe(
          tap(x => console.log('Created forecast location')));
  }
  public UpdateForecastLocation(forecastLocation:ForecastLocationModel): any {
       
    return this._http.put(this.url+ forecastLocation.Id ,forecastLocation ).pipe(
        tap(x => console.log('Updated forecast location')));
}
}
