import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { QuantityUomService } from '../quantityuom.service';
import { ConcentrationUomService } from '../concentrationuom.service';
import { ForecastItemClient, FormulationTypesClient, ForecastItemModel, QuantityUnitOfMeasureModel, InventoryStateModel,
         ForecastTypeClient, UserClient, ForecastingSeasonClient, UserModel,
         ForecastStateClient, QuantityUomClient, ConcentrationUomClient, InventoryStateClient} from '../forecast-api-client.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CloneForecastResolver implements Resolve<Observable<any>> {
    constructor(
        private forecastItemClient: ForecastItemClient,
        private uomService: QuantityUomClient,
        private cuonService: ConcentrationUomClient,
        private forecastTypeClient: ForecastTypeClient,
        private formulationTypeClient: FormulationTypesClient,
        private userClient: UserClient,
        private forecastingSeasonClient: ForecastingSeasonClient,
        private forecastStateClient: ForecastStateClient,
        private inventoryStateClient: InventoryStateClient ){}
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) : Observable<any> {
        let id = route.params['id'];
        return forkJoin(
            this.uomService.get3(false),
            this.cuonService.get(false),
            this.forecastStateClient.getForecastStates(),
            this.inventoryStateClient.getInventoryStates(),
            this.forecastTypeClient.getForecastTypes(),
            this.formulationTypeClient.get2(true),
            this.userClient.getUsersInRoles(['Biology Leader']),
            this.forecastingSeasonClient.getForecastingSeasons(),
            this.forecastItemClient.getForecastItem(id)
        )
        .pipe(
            map(([uoms, cuoms, forecastStates, inventoryStates, forecastTypes,
                  formulationTypes, reviewers, forecastingSeasons,
                  editForecastItem]) => {
                uoms = uoms.filter(x => x.id === editForecastItem.quantityUnitOfMeasureId ||
                        x.isEnabled || editForecastItem.forecastItemDestination.some(y => y.packSizeUomId === x.id));
                cuoms = cuoms.filter(x => x.isEnabled ||
                        editForecastItem.forecastItemActiveIngredient.some(a => x.id === a.concentrationUomId));
                editForecastItem.reviewerUserId = null;
                editForecastItem.delegatorUserId = null;
                editForecastItem.reviewerUserName = null;
                editForecastItem.delegatorUserName = null;
                editForecastItem.reviewerLastName = null;
                editForecastItem.isApproved = null;
                editForecastItem.delegatorLastName = null;
                editForecastItem.dateSubmitted = null;
                editForecastItem.createdAt = null;
                editForecastItem.lastUpdatedAt = null;
                if (editForecastItem.materialId === null || editForecastItem.materialId === undefined) {
                    editForecastItem.productName = null;
                }

                if (editForecastItem.altMaterialId === null || editForecastItem.altMaterialId === undefined) {
                    editForecastItem.acceptableAlternativeProduct = null;
                }

                editForecastItem.stateId = forecastStates.find(fs => fs.stateName === 'Data Entry').id;
                const todoStateId = inventoryStates.find(is => is.stateName === 'To Do').id;
                editForecastItem.stateName = 'Data Entry';
                editForecastItem.forecastItemDestination.forEach(dst => {
                    dst.inventoryLocationId = null;
                    dst.materialPlanningGroup = null;
                    dst.materialPlanningGroupId = null;
                    dst.inventoryLocation = null;
                    dst.checkedForReservationExpiry = null;
                    dst.inventoryOrderStateId = todoStateId;
                    dst.inventoryOrderStateName = 'To Do'; });
                formulationTypes.forEach(x => { x.formulationTypeLabelDesc = x.formulationTypeCode + ' - ' + x.description; });
                return {uoms, cuoms, forecastStates, forecastTypes, formulationTypes,
                reviewers, forecastingSeasons, editForecastItem};
            })
        );
    }
}
