import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaterialComposition, RawMaterial, RawMaterialRequest } from '../models/rawmaterial.model';
import { ProductLocation } from '../models/productlocation.model';
import { Observable } from 'rxjs';
import { ConfigurationSettings } from "../configuration-settings"
import { FormulationRequestsFilterModel } from '../models/formulation-requests-filter.model';
import { RawMaterialRequestStatusModel } from '../models/rawMaterialRequestStatus.model';
import { SearchFormulaModel } from './forecast-api-client.service';
import { RawMaterialCompositionModel } from '../models/rawMaterialComposition.model';
import { RawMaterialDelegateDesignationModel } from '../models/rawMaterialDelegateDesignation.model';
import { ForecastItemModel } from '../models/forecast-item-forRawmaterial.model';


@Injectable({
  providedIn: 'root'
})
export class FormulationrequestService {

  constructor(private http: HttpClient) { }

  getRawMaterialsList(pageSize: number, pageIndex: number, body: FormulationRequestsFilterModel | undefined): Observable<RawMaterial[]> {
    let url = ConfigurationSettings.REST_API_URL + `/api/RawMaterials/rawMaterialInfo?pageSize=${pageSize}&pageIndex=${pageIndex}`;
    return this.http.post<RawMaterial[]>(url, body);
  }

  // getRawMaterialsInfo(): Observable<RawMaterial[]> {
  //   return this.http.get<RawMaterial[]>('api/raw-materials');
  // }

  getProductLocations(): Observable<ProductLocation[]> {
    let url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/productlocations"
    return this.http.get<ProductLocation[]>(url);
  }

  updateProductionLocationAndOwner(programNumber: string, productionOwnerId: string, productionLocationId: string, managerEmail: string, productionOwnerName: string, productionOwnerEmail: string): Observable<any> {
    let url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/updateProductionLocationAndOwner"

    const payload = {
      programNumber: programNumber,
      productionOwnerId: productionOwnerId,
      productionLocationId: productionLocationId,
      managerEmail: managerEmail,
      productionOwnerName: productionOwnerName,
      productionOwnerEmail: productionOwnerEmail

    };

    return this.http.patch(url, payload);
  }

  updateStatus(programNumber: any, productionOwnerId: null, productionLocationId: null, statusId: any): Observable<any> {
    let url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/UpdateRawMaterialStatus"
    const payload = {
      programNumber: programNumber,
      productionOwnerId: productionOwnerId,
      productionLocationId: productionLocationId,
      status: statusId
    };
    return this.http.patch(url, payload);
  }

  cancelNotification(materialComposition: MaterialComposition[], productionOwner: string) {
    const url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/cancelNotification";
    const payload = {
      materialCompositions: materialComposition,
      productionOwnerEmail: productionOwner
    };
    return this.http.post(url, payload);
  }

  updateRawMaterialCancellation(body: MaterialComposition[]) {
    const url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/updateRawMaterialCancellation";
    return this.http.post(url, body);
  }

  getAllRawMaterialRequestStatus(): Observable<RawMaterialRequestStatusModel[]> {
    let url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/rawMaterialRequestStatus"
    return this.http.get<RawMaterialRequestStatusModel[]>(url);
  }

  getProductionLocationById(id: number): Observable<ProductLocation> {
    let url = ConfigurationSettings.REST_API_URL + `/api/RawMaterials/productionLocation/${id}`
    return this.http.get<ProductLocation>(url);
  }

  getFormulationProductionSearchFormulas(ownerId: number): Observable<SearchFormulaModel[]> {
    let url = ConfigurationSettings.REST_API_URL + `/api/Search/GetFormulationProductionSearchFormulas/${ownerId}`
    return this.http.get<SearchFormulaModel[]>(url);
  }

  getSpecialControlCompositionById(materialId: number): Observable<any> {
    let url = ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl + `/Formulations/RetrieveSpecialControlCompositionById/${materialId}`;
    return this.http.get<any>(url);
  }

  getConfidentialLotByLotName(lotName: string): Observable<any> {
    let url = ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl + `/Formulations/RetrieveConfidentialLotByLotName/${lotName}`;
    return this.http.get<any>(url);
  }

  saveRawMaterialComposition(body: RawMaterialCompositionModel[]) {
    const url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/saveRawMaterialComposition";
    return this.http.post(url, body);
  }

  saveDelegateDesignation(body: RawMaterialDelegateDesignationModel) {
    const url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/delegateDesignation";
    return this.http.post(url, body);
  }

  createForecastItem(body: ForecastItemModel[]) {
    const url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/createForecastItem";
    return this.http.post(url, body);
  }

  getRecipientAddresses(email: string): any {
    let url = ConfigurationSettings.CPSS_API_URL + "/recipients/" + email + "/addresses";
    return this.http.get(url)
  }

  updateShippingAddress(programNumber: string, cpssShipAddressId: number, recipientEmail: string): Observable<any> {
    let url = ConfigurationSettings.REST_API_URL + "/api/RawMaterials/updateShippingAddress"

    const payload = {
      programNumber: programNumber,
      cpssShipAddressId: cpssShipAddressId,
      recipientEmail: recipientEmail
    };

    return this.http.patch(url, payload);
  }

  getCompoundLotsByLotId(lotId: string): Observable<any> {
    const url = ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl + `/Compounds/RetrieveCompoundLotsByLotId/${lotId}`;
    return this.http.get<any>(url);
  }

  cancelFormulationRequest(programNumber: string): Observable<any> {
    const url = `${ConfigurationSettings.REST_API_URL}/api/RawMaterials/cancelFormulationRequest?programNumber=${programNumber}`;
    return this.http.put(url, {});
  }

  getForecastItemDestinationByForecastItemId(forecastItemId: number): Observable<any> {
    const url = ConfigurationSettings.REST_API_URL + `/api/ForecastItemDestination/GetDestinations/${forecastItemId}`;
    return this.http.get<any>(url);
  }

  getCoformulantsByName(name: string): Observable<any> {
    const url = ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl + `/Coformulants/RetrieveCoformulantsByName/${name}`;
    return this.http.get<any>(url);
  }

  getFieldInertsByName(name: string): Observable<any> {
    const url = ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl + `/Coformulants/RetrieveFieldInertsByName/${name}`;
    return this.http.get<any>(url);
  }

  getFormulationsByFormulationId(id: string): Observable<any> {
    const url = ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl + `/Formulations/RetrieveFormulationsByFormulationId/${id}`;
    return this.http.get<any>(url);
  }

  getConfidentialCompostionbyId(id: number): Observable<any> {
    const url = ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl + `/Formulations/ConfidentialCompostionbyId/${id}`;
    return this.http.get<any>(url);
  }

  getCompoundbyMaterialId(id: string): Observable<any> {
    const url = ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl + `/Compounds/RetrieveCompoundbyMaterialId/${id}`;
    return this.http.get<any>(url);
  }

  getBionexusMaterialbyId(id: string): Observable<any> {
    const url = ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl + `/Bionexus/RetrieveBionexusMaterialbyId/${id}`;
    return this.http.get<any>(url);
  }
}