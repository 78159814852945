<div class="grid">
    <div class="col-5 lg:col-offset-1 xl:col-offset-1">
        <h4 style="font-weight: bold;">Formulation Types</h4>
    </div>
</div>
<div class="grid">
    <div class="col-11 lg:col-offset-1 xl:col-offset-1">
        <ag-grid-angular id="agGridFormulationTypes"
            style="width: 540px; height: 500px;"
            class="ag-theme-alpine"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [frameworkComponents]="frameworkComponents"
            [context]="context"
            (gridReady)="onGridReady($event)"
            [pagination]="true"
            [paginationPageSize]="25"
            >
        </ag-grid-angular>
    </div>
</div>

<p-toast></p-toast>
