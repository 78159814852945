<div class="grid">
    <div class="col-3 md:col-3"><h4 style="font-weight: bold;">Approval</h4></div>
</div>
<div *ngIf="forecastApprovals">
  <p-dataView #dv [value]="forecastApprovals" id="forecastApprovalsView" [paginator]="true" [rows]="PAGE_SIZE" [lazy]="true"
    [totalRecords]="totalCount" pageLinkSize="3" (onPage)="pageChanged($event)" paginatorPosition="bottom" layout="list"
    styleClass="hover">

    <ng-template pTemplate="header">
<div class="grid" style="width: 100%;color:black">
    <div class="col-2 md:col-2 header-column" style="text-align: left;">
      <label style="font-weight: bold;text-align: center;">Material</label>
    </div>
    <div class="col-1 md:col-1 header-column" style="text-align: left;">
      <label style="font-weight: bold;">Forecast No</label>
    </div>
    <div class="col-1 md:col-1 header-column" style="text-align: left;">
        <label style="font-weight: bold;">Lot Number</label>
      </div>
      <div class="col-1 md:col-1 quantity header-column" style="text-align: right;">
      <label style="font-weight: bold;;">Quantity</label>
    </div>
    <div class="col-1 md:col-1 header-column" style="text-align: left;">
      <label style="font-weight: bold;">Forecaster</label>
    </div>
    <div class="col-2 md:col-2 header-column" style="text-align: left;">
        <label style="font-weight: bold;text-align: left;">Requester(s)</label>
      </div>

        <div class="col-1 md:col-1 header-column" style="text-align: left;">
      <label style="font-weight: bold;">Date Needed</label>
    </div>

    <div class="col-2 md:col-2 header-column"  style="text-align: left;">
      <label style="font-weight: bold;text-align: left;">Destinations</label>
    </div>
    <div class="col-1 md:col-1 header-column"  style="text-align: left;">
      <label style="font-weight: bold;">Actions</label>
    </div>
</div>
</ng-template>
<ng-template let-forecastApproval pTemplate="listItem" let-i="rowIndex">
    <div class="grid" [ngStyle]="{'width': '100%','min-height':'40px','margin-bottom':'0px','background-color':(i%2==0 ? 'white' : 'lightgray'),'color':(i%2==0 ? 'black' : 'black')}">
        <div class="col-2 md:col-2 col-value"  style="text-align: left;">
          <div>
            <a href="" target="_blank" [routerLink]="['/forecastitemEdit/'+forecastApproval.forecastItemId]"
              style="text-decoration: underline">{{forecastApproval.productName}}
            </a>
          </div>
        </div>
        <div class="col-1 md:col-1 col-value"  style="text-align: left;">
          <div>
            <a href="" target="_blank" [routerLink]="['/forecastitemEdit/'+forecastApproval.forecastItemId]"
              style="text-decoration: underline">{{forecastApproval.forecastItemId}}
            </a>
          </div>
        </div>
        <div class="col-1 md:col-1 col-value" style="text-align: left;">
          <div>{{forecastApproval.lotNumber}}</div>
        </div>
        <div class="col-1 md:col-1 col-value quantity"  style="text-align: right;">
          <span>{{forecastApproval.quantityWithUomBreakup}}</span>
        </div>
        <div class="col-1 md:col-1 col-value" style="text-align: left;">
          <a style="text-decoration: underline" href="mailto:{{forecastApproval.forecasterEmail}}"> {{forecastApproval.forecaster}}</a>
      </div>
        <div class="col-2 md:col-2 col-value">
        <div *ngFor="let requester of forecastApproval.requesters">
            <div class="grid" style="text-align: left;">
                <div class="col-12 md:col-12 col-value">{{requester}}</div>
            </div>
        </div>
        </div>


        <div class="col-1 md:col-1 col-value">
          <div>{{forecastApproval.earliestDateNeeded}}</div>
        </div>

        <div class="col-2 md:col-2 col-value">
          <div *ngFor="let destination of forecastApproval.destinations">
              <div class="grid" style="text-align: left;">
                  <div class="col-12 md:col-12 col-value">{{destination}}</div>
              </div>
          </div>
        </div>
        <div class="col-1 md:col-1">
            <div class="grid" style="margin-left:-60px;">
                <div class="col-6 md:col-6">
                    <button pButton type="button" label="Reject" (click)="rejectItem(forecastApproval.forecastItemId)"
                    style="width:77px;margin-top:2px;background-color: #d33f49 !important;color:white;border:none"
                    ></button>
                </div>
                  <div class="col-6 md:col-6">
                    <button pButton type="button" label="Approve" (click)="approveItemConfirmation(forecastApproval.forecastItemId)"
                    style="margin-top:2px;background-color:#0072ce;color:white;border:none"></button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template pTemplate="paginatorleft" let-state id="paginatorleft2">
  <span class="ui-paginator-first" style="padding-left:10px;"> Total Items : {{totalCount}}
    {{ totalCount == forecastApprovals.length ? '' : '(Showing Items: '+ forecastApprovals.length + ')'}} </span>
</ng-template>
<ng-template pTemplate="paginatorright" id="paginatorright2" let-state>
  <span class="ui-paginator-first" style="padding-right:10px;">{{ totalCount > 0 ? ((pageIndex - 1) * PAGE_SIZE) + 1 : ''}}{{totalCount > 0 ? '-' : '' }}
    {{(forecastApprovals.length !== PAGE_SIZE ? totalCount : pageIndex * PAGE_SIZE)}} of {{totalCount}} Items</span>
</ng-template>
</p-dataView>
</div>

<p-dialog id="dialogRejectComment" resizable="false" header='Reject/Review Forecast' [(visible)]="showRejectCommentDialog"
[styleClass]="'clsDialog'"
 [modal]="true"
  [dismissableMask]="true">
  <div class="grid">
    <div class="col-12 md:col-12"><h6 style="font-weight: bold;">*Please select a reject reason</h6></div>
    </div>
    <div class="grid" style="margin-top:5px;">
        <div class="col-12 md:col-12">
            <p-dropdown [style]="{'minWidth':'300px'}"  id="ddrOrderBy" [options]="rejectReasons" optionLabel="name"
            [(ngModel)]="selectedRejectReason" (ngModelChange)="onChangeSelectedRejectReason()" >
            </p-dropdown>
        </div>
    </div>
  <div class="grid" style="margin-top:5px;">
    <div class="col-11">
      <textarea placeholder="*Add comments for reject here.." name="commentText" [value]="rejectComment" id="txtComment"
      (input)="this.rejectComment=$event.target.value;" style="height: 200px;overflow-y: scroll;border-width: 1px;;"
      [rows]="10" [cols]="80"></textarea>
    </div>
  </div>
  <div class="grid">
    <div class="col-7 md:col-7"></div>
    <div class="col-5 md:col-5 text-right" style="padding-right: 20px;" >
      <button type="button" style="background-color: #0072ce !important;color:white" pButton icon="pi pi-refresh" id="btnReturnForReview" [disabled]="!rejectComment"   (click)="ReturnForReview()" label="Return for Review"></button>
      <button style="margin-left: 10px;background-color: #0072ce !important;color:white" type="button" pButton icon="pi pi-times" id="btnReject" (click)="RejectForecastWithComment();" label="Reject" [disabled]="rejectComment===null||rejectComment===undefined||rejectComment===''||disableOnReject"  ></button>
    </div>
  </div>
</p-dialog>

<p-dialog id="dialogApproveConfirmation" resizable="false" header='Confirmation Message'
  [(visible)]="showApproveConfirmationDialog" [styleClass]="'clsApproveDialog'" [modal]="true" [dismissableMask]="true">
  <div class="form-group grid">
    <div class="col-12">
      <i class="pi pi-exclamation-triangle" style="font-size: 2rem !important;color: #0072ce !important;"></i><label style="margin-left: 9px;margin-top: -5px;">Are you sure you want to approve this request?</label>
    </div>
  </div>
  <p-footer>
    <div class="grid">
      <div class="col-6 md:col-6" style="text-align: left;">
        <p-checkbox id="chkShowApproveDialog" [ngStyle]="{'padding-left': '5px'}" name="chkShowApproveDialog"
          [(ngModel)]="isDontShowApproveDialog" binary="true"></p-checkbox>
        <label style="padding-left: 3px;margin-top: 3px;" for="isDontShowApproveDialog">Don't show this message again</label>
      </div>
      <div class="col-6 md:col-6">
        <button style="background-color: #0072ce !important;color:white" type="button" pButton icon="pi pi-times"
          id="btnReject" (click)="CloseApproveConfirmation();" label="Cancel"></button>
        <button type="button" style="margin-left: 3px;background-color: #0072ce !important;color:white" pButton
          icon="pi pi-check" id="btnReturnForReview" (click)="approveItem()" label="Approve" [disabled]="disableOnApprove"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>

