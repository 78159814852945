import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormulationrequestService } from '../services/formulationrequest.service';
import { UserClient, CountryClient, SearchCriteriaModel, SearchFormulaModel, ForecastStateModel, SearchClient } from '../services/forecast-api-client.service';
import { CommonService } from '../services/common.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { AppComponent } from '../app.component';
import { FormulationRequestsFilterModel } from '../models/formulation-requests-filter.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MaterialComposition, RawMaterial } from '../models/rawmaterial.model';
import { DatePipe } from '@angular/common';
import { MessageService } from 'primeng/api';
import { DataView } from 'primeng/dataview';

@Component({
  selector: 'app-formulation-requests',
  templateUrl: './formulation-requests.component.html',
  styleUrls: ['./formulation-requests.component.scss']
})

export class FormulationRequestsComponent implements OnInit {
  @ViewChild('dv1') dataView: DataView;
  pageIndex: number = 1;
  pageItemNumber: number = 25;
  totalRecords: number;
  totalRecordsCount: number = 0;
  cancelledVal = 'Cancelled';
  indexes: number[] = [];

  openAccordion: boolean;
  pageSize: number;
  selectedCountries: any[] = [];
  selectedRequest: any | null = null;
  popupVisible: boolean = false;
  popupHeader: string = '';
  item: any;
  orderItems: any[] = [];
  displayCommentsDialog = false;
  filteredUsers: any[] = [];
  filteredUsersValue: any[] = [];
  productionLocations: any[];
  countryModels: any;
  filter: any;
  userInfo: any;
  shouldProcessClickEvent = true;
  formulationRequests: RawMaterial[] = [];
  test: RawMaterial[] = [];
  selectedMaterials: boolean[] = [];
  selectAll = false;
  datePipe: DatePipe;

  // Filter Function Variables:
  isAccordionOpen: boolean;
  isFilterAccordionOpen: boolean = false;
  filterIndexes = [];
  isDesc: boolean = false;
  searchCriteriaList: SearchCriteriaModel[];
  formulationRequestsFilter: FormulationRequestsFilterModel;
  customFormulationRequestsFilterObject: any;
  filterFormulationRequestsForm: UntypedFormGroup;
  dateNeededFilterOptionValue: any[];
  productionLocationFilterOptions: any[] = [
    { label: 'Select', value: 0 }
  ];
  statusFilterOptions: any[];
  selectedProductionOwnerFilterOption: any;
  selectedMaterialPlannerFilterOption: any;
  selectedForecasterFilterOption: any;
  selectedProductionLocationFilterOption: any;
  selectedStatusFilterOption: any[] = [];
  filterOptionsAutoCompleteUsersList: any[] = [];
  isSaveFilterButtonDisabled: boolean = true;
  isApplySelectedFilterButtonDisabled: boolean = true;
  showFilterButtoMessage: boolean = false;
  selectedSavedFilter: any;
  savedFilters: SearchFormulaModel[] = [];
  isUpdateFilterCriteria: boolean = false;
  unusedCheckbox = false;
  isProdManager: boolean = false;
  selectedItems: any[] = [];
  sortingOrder: 'asc' | 'desc' = 'asc';
  sortingOrderFormulation: 'asc' | 'desc' = 'asc';
  sortingOrderStatus: 'asc' | 'desc' = 'asc';


  constructor(
    private formulationService: FormulationrequestService,
    private userClient: UserClient,
    private commonService: CommonService,
    private router: Router,
    public countryClient: CountryClient,
    private securityService: SecurityService,
    private appComponent: AppComponent,
    private formBuilder: UntypedFormBuilder,
    private searchClient: SearchClient,
    private cdr: ChangeDetectorRef,
    public messageService: MessageService
  ) {
    this.pageSize = 25;
    this.totalRecords = 0;
    this.datePipe = new DatePipe('en-US');
  }

  ngOnInit(): void {
    this.appComponent.currentSelection = 'formulation-requests';

    this.userInfo = this.securityService.getUser();
    this.isProdManager = this.userInfo?.isProdManager;
    if (this.userInfo === undefined || this.userInfo === null) {
      const loggedInUsername = localStorage.getItem("loggedInUsername");
      if (loggedInUsername !== undefined && loggedInUsername !== null) {
        this.securityService.validateUser(loggedInUsername)
          .subscribe(
            (data: any) => {
              this.userInfo = data;
              this.getData();
            },
            (error: any) => {
              this.messageService.add({ severity: 'info', summary: 'User not logged in', detail: 'Your session has expired.' });
            }
          );
      } else {
        this.messageService.add({ severity: 'info', summary: 'User not logged in', detail: 'Your session has expired.' });
      }
    } else {
      this.getData();
    }

    this.getProductionLocations();
    this.initializeFilter();
    this.getFormulationList();
    this.filterFormulationRequestsForm.valueChanges.subscribe(() => {
      this.updateSaveFilterButtonState();
    });
  }

  hasFormulationSpecialistRole(userInfo): boolean {
    for (const role of userInfo.roles) {
      if (role.name === "Formulation Specialist") {
        return true;
      }
    }
    return false;
  }

  sortItemsByDate() {
    this.formulationRequests.sort((a, b) => {
      const dateA = new Date(a.dateNeeded).getTime();
      const dateB = new Date(b.dateNeeded).getTime();

      if (this.sortingOrder === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    this.toggleDateSortingOrder();
  }

  sortItemsByFormulation() {
    this.formulationRequests.sort((a, b) => {
      const programNumberA = a.programNumber.toLowerCase();
      const programNumberB = b.programNumber.toLowerCase();

      if (this.sortingOrderFormulation === 'asc') {
        return programNumberA.localeCompare(programNumberB);
      } else {
        return programNumberB.localeCompare(programNumberA);
      }
    });
    this.toggleSortingOrderFormulation();
  }

  sortItemsByStatus() {
    this.formulationRequests.sort((a, b) => {
      const statusA = a.status.toLowerCase();
      const statusB = b.status.toLowerCase();

      if (this.sortingOrderStatus === 'asc') {
        return statusA.localeCompare(statusB);
      } else {
        return statusB.localeCompare(statusA);
      }
    });
    this.toggleSortingOrderStatus();
  }


  toggleSortingOrderFormulation() {
    this.sortingOrderFormulation = this.sortingOrderFormulation === 'asc' ? 'desc' : 'asc';
  }

  toggleDateSortingOrder() {
    this.sortingOrder = this.sortingOrder === 'asc' ? 'desc' : 'asc';
  }

  toggleSortingOrderStatus() {
    this.sortingOrderStatus = this.sortingOrderStatus === 'asc' ? 'desc' : 'asc';
  }

  initializeFilter() {
    this.buildFilterFormulationRequestsForm();
    this.populateProductionLocationFilterOptions();
    this.populateStatusFilterOptions();
    this.populateSavedFilters(this.savedFilters, this.userInfo.id);
    this.formulationRequestsFilter = this.getFilterFormulationRequestsFormData(this.filterFormulationRequestsForm);
  }

  mapFormulationRequests(data: RawMaterial[]) {
    this.formulationRequests = this.addReachToFormulationRequests(data);
    this.formulationRequests = this.updateProductionOwnertoFormulationSpecialist(data);
    this.totalRecords = this.formulationRequests.length > 0 ? this.formulationRequests[0].totalCount : 0;

    this.selectedCountries = this.formulationRequests.map(item => {
      const selectedLocation = this.productionLocations.find(location => location.label === item.productionLocation);
      return selectedLocation;
    });
  }

  addReachToFormulationRequests(formulationRequests) {
    formulationRequests.forEach(formulationRequest => {
      let reachApproved = false;

      for (const individualFormulationRequest of formulationRequest.individualFormulationRequests) {
        if (individualFormulationRequest.isReachApproved) {
          reachApproved = true;
          break;
        }
      }

      formulationRequest.reachApproved = reachApproved;
    })

    return formulationRequests;
  }
  updateProductionOwnertoFormulationSpecialist(formulationRequests) {
    /* formulationRequests.forEach(formulationRequest => {
      let newProductionOwner = 0;
      let newProductionName ="";
       for (const individualFormulationRequest of formulationRequest.individualFormulationRequests) {
          if (individualFormulationRequest.excelInclusion) {
           newProductionOwner = individualFormulationRequest.forecasterId;
           newProductionName =  individualFormulationRequest.Forecaster;
           break;
         }else{
           newProductionOwner = individualFormulationRequest.productionOwner;
           newProductionName =  individualFormulationRequest.Forecaster;
           break;
         }
       }     
       formulationRequest.productionOwnerId = newProductionOwner;
       formulationRequest.productionOwner = newProductionName;
     })
 */
    return formulationRequests;
  }

  openPopup(request: any, name: string, isProcurement: boolean): void {
    this.selectedRequest = isProcurement ? request.split('\n') : request.commentsAndNotes.split('\n');
    this.popupHeader = isProcurement ? `Procurement Comments For ${name}` : `Comments and Notes For Farm - ${request.forecastItemId}`;
    this.popupVisible = true;
  }

  extractName(comment: string): string {
    return comment.split(' - ')[0].trim();
  }

  extractDate(comment: string): string {
    return comment.split(' - ')[1].trim();
  }

  extractCommentText(comment: string): string {
    return comment.split(' - ').slice(2).join(' - ').trim();
  }

  formatDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    return this.datePipe.transform(date, 'dd-MMM-yyyy');
  }

  getData() {
    if (this.userInfo === null || this.userInfo === undefined || this.userInfo.id === undefined) {
      this.messageService.add({
        severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: 'Your session has expired. Please login again.'
      });
      this.router.navigate(['login']);
      return;
    }
  }

  resetClickEventFlag() {
    this.shouldProcessClickEvent = true;
  }

  handleHeaderClick(item: any, event: Event) {
    const target = event.target as HTMLElement;
    const isControlClick = target.closest('.p-autocomplete') || target.closest('.p-button') || target.closest('.p-dropdown');

    if (isControlClick) {
      return;
    }

    if (item.isOrderTabOpen) {
      item.isOrderTabOpen = false;
      item.isAggregatesTabOpen = false;
      item.isCompositionTabOpen = true;
    } else {
      item.isOrderTabOpen = true;
      item.isAggregatesTabOpen = true;
      item.isCompositionTabOpen = false;
    }
  }

  toggleSelectAll() {
    console.log('Before toggle:', this.selectedMaterials);
    this.selectAll = !this.selectAll;
    this.selectedMaterials.fill(this.selectAll);
    console.log('After toggle:', this.selectedMaterials);
  }

  onSelectAllChange(programNumber: any, index: number, req: any) {
    console.log('Before toggle:', req[0].programNumber)

    for (let i = 0; i < req.length; i++) {
      if (req[i].programNumber == programNumber) {
        for (let ii = 0; ii < req[i].materialComposition.length; ii++) {
          console.log('Before toggle:', req[0].materialComposition)
          const MultipleRecordsMaterial = {
            programNumber: req[i].programNumber,
            id: req[i].materialComposition[ii].id,
            materialName: req[i].materialComposition[ii].materialName,
            dateNeeded: req[i].materialComposition[ii].dateNeeded,
            quantityForecasted: req[i].materialComposition[ii].quantityForecasted,
            productionOwnerId: req[i].productionOwnerId,
            productionLocationId: req[i].productionLocationId,
            materialId: req[i].factID,
            userId: this.userInfo.id,
            reach: req[i].materialComposition[ii].reachApproved
          };
          var listNumber = programNumber + '_' + ii;
          let element0 = document.getElementById(programNumber) as HTMLInputElement;
          let element = document.getElementById(listNumber) as HTMLInputElement;
          if (element0.checked) {
            element.checked = true;
            this.selectedItems.push(MultipleRecordsMaterial);
          } else {
            element.checked = false;
            this.selectedItems = [];
          }
        };
      }
    }
  }
  onCheckboxChange(material: any, index: number, programNumber: any, req: any) {
    // console.log('Before toggle:',req[0].programNumber)
    var count = 0;
    var cancelledCount = 0;
    for (let i = 0; i < req.length; i++) {
      if (req[i].programNumber == programNumber) {
        for (let ii = 0; ii < req[i].materialComposition.length; ii++) {
          if (req[i].materialComposition[ii].isCancelled) {
            cancelledCount++;
          }
          var listNumber = programNumber + '_' + ii;
          let element0 = document.getElementById(programNumber) as HTMLInputElement;
          let element = document.getElementById(listNumber) as HTMLInputElement;
          if (element.checked) {
            count++;
            if (count == req[i].materialComposition.length - cancelledCount) {
              element0.checked = true;
            }
          } else {
            element0.checked = false;
          }
        };
      }
    }

    const selectedIndex = this.selectedItems.findIndex(item => item.materialName == material.materialName && item.quantityForecasted == material.quantityForecasted);

    if (selectedIndex === -1) {
      material.programNumber = programNumber;
      this.selectedItems.push(material);
    } else {
      if (selectedIndex !== -1) {
        this.selectedItems.splice(selectedIndex, 1);
      }

    }
  }


  onCancelButtonClick(programNumber: any, productionOwner: string, selectedItem: any) {
    this.selectedItems = this.selectedItems.filter(item => item.programNumber === programNumber);
    if (this.selectedItems.length > 0) {
      let element0 = document.getElementById(programNumber) as HTMLInputElement;
      if (element0.checked) {
        this.updateStatus(programNumber, null, null, this.cancelledVal);
      }
      this.cancelNotification(this.selectedItems, productionOwner);
      this.updateRawMaterialCompCancellation(this.selectedItems);
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'There were no checkboxes selected.' });
    }
    this.getFormulationList();
    this.router.navigate(['formulation-requests'])
  }

  allMaterialsCancelled(materialComposition: any[]): boolean {
    return materialComposition.every(material => material.isCancelled);
  }

  onDropdownClick(event: Event) {
    const target = event.target as HTMLElement;
    const isDropdownToggle = target.closest('.p-dropdown');

    if (isDropdownToggle) {
      event.stopPropagation();
    }
  }

  getProductionLocations() {
    this.formulationService.getProductLocations().subscribe(data => {
      this.productionLocations = data.map(item => ({
        label: item.productionLocationName,
        value: item.countryId,
        value2: item.id
      }));
      console.log('Fetched Product Locations:', this.productionLocations);
    });
  }

  onProductionLocationDropdownChange(event: any, i: any) {
    this.formulationRequests[i].productionLocation = event.value.label;
    this.formulationRequests[i].productionLocationId = event.value.value;
    this.item[i].isDirty = true;
  }

  setOrderStatus(item: any) {
    if (!this.shouldProcessClickEvent) {
      return;
    }


    if (item.isOrderTabOpen) {
      item.isOrderTabOpen = false;
      item.isAggregatesTabOpen = false;
      item.isCompositionTabOpen = false;
    } else {
      item.isOrderTabOpen = true;
      item.isAggregatesTabOpen = true;
      item.isCompositionTabOpen = false;
    }
  }

  getFormulationList() {
    this.formulationService.getRawMaterialsList(this.pageSize, this.pageIndex, this.formulationRequestsFilter).subscribe(
      (response: RawMaterial[]) => {
        this.formulationRequests = response;
        this.formulationRequests = this.addReachToFormulationRequests(this.formulationRequests);
        this.formulationRequests = this.updateProductionOwnertoFormulationSpecialist(this.formulationRequests);
        this.totalRecords = response.length > 0 ? response[0].totalCount : 0;
        this.mapFormulationRequests(response);
        this.formulationRequests.forEach(item => item.isDirty = false);
      },
      (error: any) => {
        console.error('Error retrieving formulation list:', error);
      }
    );
  }

  loadRawMaterials(event) {
    this.pageIndex = (event.first / this.pageItemNumber) + 1;
    this.getFormulationList();
  }

  removeTextInParentheses(text: string): string {
    return text.replace(/\([^)]*\)/g, '');
  }

  loadOrderItems(event: any) {
    if (this.item) {
      const materialComposition = this.item.materialComposition;
      this.orderItems = materialComposition;
    }
  }

  filterUsers(event: any) {
    let query = event.query;
    if (query) {
      this.userClient.getMatchingUsers(query).subscribe(
        (data: any[]) => {
          this.filteredUsers = data.map(user => (user.fullName));
          this.filteredUsersValue = data.map(user => ({ fullName: user.fullName, id: user.id }));
        },
        (error: any) => {
          console.error('Error fetching users:', error);
        }
      );
    } else {
      this.filteredUsers = [];
    }
  }

  onSelectAutoCompleteUsers(event: any, i: any) {
    this.formulationRequests[i].productionOwner = event;
    this.formulationRequests[i].productionOwnerId = this.filteredUsersValue.find(user => user.fullName === event).id;
    this.formulationRequests[i].isDirty = true;
  }

  navigateToFormulationRawMaterialWithParams(item: any) {
    const id = item.programNumber;
    const queryParams = {
      programNumber: item.programNumber,
      factsId: item.formulationRequest,
      dateNeeded: item.dateNeeded,
      quantityForecasted: item.totalQuantityInKg,
      productionOwnerId: item.productionOwnerId,
      productionLocationId: this.filterLocations(item.productionLocationId),
      materialId: item.factID,
      userId: this.userInfo.id,
      reach: item.reachApproved,
      forecastType: item.forecastType
    };
    console.log('Navigating to formulation-raw-material with params:', id, queryParams);

    this.router.navigate(['formulation-raw-material', id], { queryParams });
  }

  filterLocations(id) {
    const location = this.productionLocations.find(location => location.value === id);
    return location ? location.value : null;
  }

  onSaveButtonClick(i: any) {
    console.log(this.formulationRequests[i]);
    var programNumber = this.formulationRequests[i].programNumber;
    var productionOwnerName = this.formulationRequests[i].productionOwner;
    var productionOwnerEmail = this.formulationRequests[i].productionOwnerEmail;


    let productionOwnerId: string | null;
    if (this.formulationRequests[i].productionOwnerId !== null) {
      productionOwnerId = this.formulationRequests[i].productionOwnerId;
    } else {
      productionOwnerId = null;
    }

    let productionLocationId: string | null;
    if (this.formulationRequests[i].productionLocationId !== null) {
      productionLocationId = this.formulationRequests[i].productionLocationId;
    } else {
      productionLocationId = null;
    }
    if (productionLocationId !== null && productionOwnerId !== null) {
      this.updateProductionLocationAndOwner(programNumber, productionLocationId, productionOwnerId, productionOwnerName, productionOwnerEmail);
    }
  }

  updateProductionLocationAndOwner(programNumber: any, productionLocationId: any, productionOwnerId: any, productionOwnerName: string, productionOwnerEmail: string) {
    this.formulationService.updateProductionLocationAndOwner(programNumber, productionOwnerId, productionLocationId, this.userInfo.email, productionOwnerName, productionOwnerEmail).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update data successfully!' });
        this.getFormulationList();
        this.router.navigate(['formulation-requests'])
      },
      (error) => {
        console.error('Error while updating data:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update data' });
      }
    );
  }

  updateStatus(programNumber: any, productionLocationId: any, productionOwnerId: any, status: any) {
    this.formulationService.updateStatus(programNumber, null, null, status).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update data successfully!' });
        this.getFormulationList();
        this.router.navigate(['formulation-requests'])
      },
      (error) => {
        console.error('Error while updating data:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update data' });
      }
    );
  }

  cancelNotification(materialComposition: MaterialComposition[], productionOwner: string) {
    this.formulationService.cancelNotification(materialComposition, productionOwner).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Cancel Notification Sent!' });
      },
      (error) => {
        console.error('Error while updating data:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to send Cancel Notification' });
      }
    );
  }
  updateRawMaterialCompCancellation(material: MaterialComposition[]) {

    this.formulationService.updateRawMaterialCancellation(material).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Raw Material Canceled!' });
      },
      (error) => {
        console.error('Error while updating data:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update Raw Material Cancellation' });
      }
    );
  }
  toggleFilterAccordion(event, index) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const idx = this.filterIndexes.findIndex(i => i === index);
    if (idx < 0) {
      this.isAccordionOpen = true;
      this.filterIndexes.push(index);
    } else {
      this.isAccordionOpen = false;
      this.filterIndexes.splice(idx, 1);
    }
    this.filterIndexes = [...this.filterIndexes];
  }

  filterFormulationRequests() {
    this.pageIndex = 1;
    this.dataView.first = 0;
    this.formulationRequestsFilter = this.getFilterFormulationRequestsFormData(this.filterFormulationRequestsForm);
    this.getFormulationList();
  }

  buildFilterFormulationRequestsForm() {
    this.filterFormulationRequestsForm = this.formBuilder.group({
      'id': new UntypedFormControl(0),
      'formulationFilterOption': new UntypedFormControl(''),
      'dateNeededFilterOption': new UntypedFormControl(''),
      'forecasterFilterOption': new UntypedFormControl(''),
      'programNumberFilterOption': new UntypedFormControl(''),
      'productionOwnerFilterOption': new UntypedFormControl(''),
      'materialPlannerFilterOption': new UntypedFormControl(''),
      'statusFilterOption': new UntypedFormControl(''),
      'productionLocationFilterOption': new UntypedFormControl(''),
      'filterNameFilterOption': new UntypedFormControl('')
    });
  }

  getFilterFormulationRequestsFormData(form) {
    var filter = new FormulationRequestsFilterModel();

    var formulationFilterOption = form.get('formulationFilterOption').value;
    filter.formulation = (formulationFilterOption == null || formulationFilterOption.length == 0) ? null : formulationFilterOption.trim();

    var programNumberFilterOption = form.get('programNumberFilterOption').value;
    filter.programNumber = (programNumberFilterOption == null || programNumberFilterOption.length == 0) ? null : programNumberFilterOption.trim();

    var forecasterFilterOption = form.get('forecasterFilterOption').value;
    filter.forecasterId = (forecasterFilterOption == null || forecasterFilterOption.length == 0) ? null : forecasterFilterOption.id;

    var productionOwnerFilterOption = form.get('productionOwnerFilterOption').value;
    filter.productionOwnerId = (productionOwnerFilterOption == null || productionOwnerFilterOption.length == 0) ? null : productionOwnerFilterOption.id;

    var materialPlannerFilterOption = form.get('materialPlannerFilterOption').value;
    filter.materialPlannerId = (materialPlannerFilterOption == null || materialPlannerFilterOption.length == 0) ? null : materialPlannerFilterOption.id;

    var dateNeededFilterOption = form.get('dateNeededFilterOption').value;
    filter.fromDate = (dateNeededFilterOption == null || dateNeededFilterOption.length == 0) ? null : dateNeededFilterOption[0];
    filter.toDate = (dateNeededFilterOption == null || dateNeededFilterOption.length == 0) ? null : dateNeededFilterOption[1];

    var productionLocationFilterOption = form.get('productionLocationFilterOption').value;
    filter.productionLocationId = (productionLocationFilterOption == null || productionLocationFilterOption.length == 0) ? null : productionLocationFilterOption.value;

    var statusFilterOption = form.get('statusFilterOption').value;
    filter.statusIdList = statusFilterOption && statusFilterOption.length > 0 ? statusFilterOption.map(option => option.value) : null;

    return filter;
  }

  autoCompleteUsers(event: any) {
    let query = event.query;
    this.userClient.getMatchingUsers(query).subscribe(data => {
      this.filterOptionsAutoCompleteUsersList = data;
      this.filterOptionsAutoCompleteUsersList.forEach(user => {
        user.label = `${user.fullName} (${user.email})`;
      });
    });
  }

  onSelectFilterOptionUser(event: any, selectedFilterOption: string) {
    if (event) {
      this[selectedFilterOption] = {
        ...event,
        label: event.fullName
      };
    }
  }

  populateSavedFilters(savedFilters, userId) {
    this.formulationService.getFormulationProductionSearchFormulas(userId).subscribe(data => {
      this.addDefaultFilters(savedFilters, userId);
      data.forEach(savedFilter => {
        savedFilters.push(savedFilter);
      })
    });
  }

  populateProductionLocationFilterOptions() {
    this.formulationService.getProductLocations().subscribe(data => {
      const newOptions = data.map(item => ({
        label: item.productionLocationName,
        value: item.id
      }));

      this.productionLocationFilterOptions.push(...newOptions);
    });
  }

  populateStatusFilterOptions() {
    this.formulationService.getAllRawMaterialRequestStatus().subscribe(data => {
      this.statusFilterOptions = data.map(item => ({
        label: item.name,
        value: item.id
      }));
    });
  }

  clearAllFilterOptions() {
    this.filterFormulationRequestsForm.reset();
    this.isApplySelectedFilterButtonDisabled = true;
    this.selectedSavedFilter = null;
    this.filterFormulationRequests();
  }

  saveFilterOptions() {
    if (this.isSaveFilterButtonDisabled) {
      this.showFilterButtoMessage = true;
      setTimeout(() => {
        this.showFilterButtoMessage = false;
      }, 5000);
      return;
    }

    var searchFormula = new SearchFormulaModel();
    var searchCriteria = new SearchCriteriaModel();
    this.searchCriteriaList = [];

    searchFormula.name = this.filterFormulationRequestsForm.get('filterNameFilterOption').value;
    searchFormula.ownerId = this.userInfo.id;
    searchFormula.enabled = true;
    searchFormula.isShared = false;
    searchFormula.dashboard = "Formulation Production";
    searchFormula.searchCriteria = [];

    var filter = this.getFilterFormulationRequestsFormData(this.filterFormulationRequestsForm);

    if (filter.formulation) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Formulation";
      searchCriteria.criteriaValue = filter.formulation;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (filter.programNumber) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Program Number";
      searchCriteria.criteriaValue = filter.programNumber;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (filter.forecasterId) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Forecaster";
      searchCriteria.criteriaValue = filter.forecasterId;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (filter.productionOwnerId) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Production Owner";
      searchCriteria.criteriaValue = filter.productionOwnerId;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (filter.materialPlannerId) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Material Planner";
      searchCriteria.criteriaValue = filter.materialPlannerId;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (filter.productionLocationId) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Production Location";
      searchCriteria.criteriaValue = filter.productionLocationId;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (filter.fromDate) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "DateNeeded From Date";
      searchCriteria.criteriaValue = filter.fromDate;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (filter.toDate) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "DateNeeded To Date";
      searchCriteria.criteriaValue = filter.toDate;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (filter.statusIdList && filter.statusIdList.length > 0) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Status";
      var statusIdArrary = [];
      filter.statusIdList.forEach(
        (value) => {
          statusIdArrary.push(value);
        });
      searchCriteria.criteriaValue = statusIdArrary.toString();
      this.searchCriteriaList.push(searchCriteria);
    }

    if (this.searchCriteriaList.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select at least one search criteria' });
      return;
    }

    searchFormula.searchCriteria = this.searchCriteriaList;

    if (this.selectedSavedFilter) {
      if (this.selectedSavedFilter.name == searchFormula.name) {
        this.isUpdateFilterCriteria = true;
      }
    }

    if (this.isUpdateFilterCriteria) {
      searchFormula.id = this.selectedSavedFilter.id;
      searchFormula.searchCriteria.forEach(searchCriteria => searchCriteria.searchFormulaId = searchFormula.id)
      this.searchClient.updateSearchFormula(searchFormula.id, searchFormula).subscribe(
        (response) => {
          const index = this.savedFilters.findIndex(x => x.name === searchFormula.name);
          this.savedFilters[index] = searchFormula;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Update filter successfully!' });
          this.isUpdateFilterCriteria = false;
          this.selectedSavedFilter = searchFormula;
        },
        (error: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to update filter! ${error}` });
        }
      );
    } else {
      if (this.savedFilters.filter(x => x.name === searchFormula.name).length > 0) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to create filter, there's an existing filter with the same name!` });
        return;
      }

      this.searchClient.createSearchFormula(searchFormula).subscribe(
        (response) => {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Create filter successfully!' });

          this.searchClient.getFormulationProductionSearchFormulas(this.userInfo.id).subscribe(data => {
            const filter = data.filter(x => x.name === searchFormula.name)[0];
            searchFormula.id = filter.id;
            this.selectedSavedFilter = searchFormula;
            this.savedFilters.push(searchFormula);
          });

          this.isApplySelectedFilterButtonDisabled = false;
        },
        (error: any) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: `Failed to create filter! ${error}` });
        }
      );
    }

    this.filterFormulationRequests();
  }

  // save button is disabled when filter name is empty, and at least have one criteria
  updateSaveFilterButtonState() {
    this.isSaveFilterButtonDisabled = !this.filterFormulationRequestsForm.get('filterNameFilterOption').value;
  }

  // apply selected filter button is disabled when no filter is selected
  updateApplySelectedFilterButtonState() {
    this.isApplySelectedFilterButtonDisabled = !this.selectedSavedFilter;
  }

  stopDefaults() {
    this.openAccordion = false;
  }

  applySelectedFilter(data) {
    if (this.isApplySelectedFilterButtonDisabled) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: `Please select a filter` });
      return;
    }

    this.filterFormulationRequestsForm.reset();
    this.selectedProductionLocationFilterOption = {};
    this.formulationRequestsFilter = this.mapSearchFormulaModelToFormulationRequestsFilterModel(data);
    this.setFilterFormulationRequestsFormData(this.filterFormulationRequestsForm, this.formulationRequestsFilter)
    this.pageIndex = 1;
    this.dataView.first = 0;
    this.getFormulationList();
  }

  mapSearchFormulaModelToFormulationRequestsFilterModel(data) {
    const filter = new FormulationRequestsFilterModel();
    filter.name = data.name;

    const criteriaMapping = {
      'Formulation': 'formulation',
      'Program Number': 'programNumber',
      'Forecaster': 'forecasterId',
      'Production Owner': 'productionOwnerId',
      'Material Planner': 'materialPlannerId',
      'Production Location': 'productionLocationId',
      'DateNeeded From Date': 'fromDate',
      'DateNeeded To Date': 'toDate',
      'Status': 'statusIdList',
    };

    data.searchCriteria.forEach((searchCriteria) => {
      const propertyName = criteriaMapping[searchCriteria.criteriaName];
      if (propertyName) {
        if (propertyName === 'statusIdList') {
          filter[propertyName] = searchCriteria.criteriaValue.split(",").map(Number);
        } else {
          filter[propertyName] = searchCriteria.criteriaValue;
        }
      }
    });

    return filter;
  }

  setFilterFormulationRequestsFormData(form, filter: FormulationRequestsFilterModel) {
    form.get('filterNameFilterOption').setValue(filter.name);

    if (filter.formulation !== null) {
      form.get('formulationFilterOption').setValue(filter.formulation);
    }

    if (filter.programNumber !== null) {
      form.get('programNumberFilterOption').setValue(filter.programNumber);
    }

    this.setUserOptionIfNotNull(filter.forecasterId, 'selectedForecasterFilterOption');
    this.setUserOptionIfNotNull(filter.productionOwnerId, 'selectedProductionOwnerFilterOption');
    this.setUserOptionIfNotNull(filter.materialPlannerId, 'selectedMaterialPlannerFilterOption');

    if (filter.productionLocationId != null) {
      var productionLocationId = Number(filter.productionLocationId);
      this.selectedProductionLocationFilterOption = this.productionLocationFilterOptions.find(location => location.value == productionLocationId);
      form.get('productionLocationFilterOption').setValue(this.selectedProductionLocationFilterOption);
    }

    if (filter.toDate !== undefined && filter.toDate !== null) {
      this.dateNeededFilterOptionValue = [new Date(filter.fromDate), new Date(filter.toDate)];
    } else if (filter.fromDate !== undefined && filter.fromDate !== null) {
      this.dateNeededFilterOptionValue = [new Date(filter.fromDate), null];
    }

    if (filter.statusIdList != null) {
      var statusIdList = filter.statusIdList;
      this.selectedStatusFilterOption = [];
      statusIdList.forEach(statusId => {
        this.selectedStatusFilterOption.push(this.statusFilterOptions.find(status => status.value == statusId));
      })
    }
  }

  setUserOptionIfNotNull(userId, optionName) {
    if (userId !== undefined && userId !== null) {
      this.userClient.getUser(Number(userId)).subscribe((user) => {
        this[optionName] = {
          id: userId,
          label: user.fullName
        };
      });
    }
  }

  addDefaultFilters(savedFilters, userId) {
    var formulationRequestsAssignedToMe = new SearchCriteriaModel();
    formulationRequestsAssignedToMe.criteriaName = "Production Owner";
    formulationRequestsAssignedToMe.criteriaValue = userId;

    var formulationRequestsAssignedToMeFilter = new SearchFormulaModel();
    formulationRequestsAssignedToMeFilter.name = "Formulation requests assigned to me";
    formulationRequestsAssignedToMeFilter.searchCriteria = [
      formulationRequestsAssignedToMe
    ];

    savedFilters.push(formulationRequestsAssignedToMeFilter);
  }

  cancelFormulationRequest(programNumber: string) {
    this.formulationService.cancelFormulationRequest(programNumber).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Formulation Cancelled successfully!' });
        this.getFormulationList();
        this.router.navigate(['formulation-requests'])
      },
      (error) => {
        console.error('Cancellation Failed:', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update data' });
      }
    );
  }
}
