<div class="col-12">
    <div class="grid">
        <div class="col-3">
            <h4>Material Search</h4>
        </div>
        <div class="col-9" style="text-align: right;">
            <a target="_blank" style="text-decoration: underline;" href="{{compositeSearchSettings.factsUrl}}">*FACTS</a>
        </div>
    </div>
    <div class="grid" style="margin-top: -1.75rem;">
        <div class="col-12" style="text-align: right;">
            <a target="_blank" style="text-decoration: underline;" href="{{compositeSearchSettings.coreUrl}}">*Compound Utilities</a>
        </div>
    </div>
    <div class="grid">
      <div class="col-12" style="text-align: right;">
        <a target="_blank" style="text-decoration: underline;" href="{{compositeSearchSettings.raceUrl}}">*RACE</a>
      </div>
    </div>
    <div class="grid">
      <div class="col-12" style="text-align: right;">
        <span style="font-weight: bold;">*Requires VPN</span>
    </div>
  </div>
  <div class="grid">
      <div class="col-12" style="display: inline-flex;">

        <div class="clsRequestType" [ngClass]="{'clsBlue' :  RequestTypeName ==  requestType?.InStock}">
          <p-radioButton label='Stock' [disabled]="disableRequestType" name="requestType" value="{{requestType?.InStock}}"
            [(ngModel)]="RequestTypeName"></p-radioButton>
        </div>
        <div class="clsRequestType"
          [ngClass]="{'clsBlue' :  RequestTypeName == requestType?.GLP , 'clsDisabled' :  !isGlpEligible }">
          <p-radioButton label='GLP' [disabled]="disableRequestType || !isGlpEligible" name="requestType"
            value="{{requestType?.GLP}}" [(ngModel)]="RequestTypeName"></p-radioButton>
        </div>
      </div>

    </div>
    <div class="grid" style="border: 4px solid black; margin-top: 30px; margin-bottom: 30px;">
        <div class="col-2">
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <span style='font-weight:600'>Co-Formulants</span>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <p-radioButton label='Co-Formulant' name="materialTypeId" value="1" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <p-radioButton label='Field Inert' name="materialTypeId" value="2" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
        </div>
        <div class="col-1"></div>
        <div class="col-2" style="margin-left:30px;">
            <div class="col-12">
                <span style='font-weight:600'>Formulations</span>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <p-radioButton label='FACTS Formulation ID' name="materialTypeId" value="3" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <p-radioButton label='Synonym' name="materialTypeId" value="4" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <p-radioButton label='Corteva Trade Name' name="materialTypeId" value="5" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <p-radioButton label='3rd Party Trade Name' name="materialTypeId" value="6" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="isGlpEligible && RequestTypeName != requestType?.InStock">
                <p-radioButton label='FACTS Formulation ID (GLP)' name="materialTypeId" value="7" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="isGlpEligible && RequestTypeName != requestType?.InStock">
              <p-radioButton label='TSN (GLP)' name="materialTypeId" value="12" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
          </div>
        </div>
        <div class="col-1"></div>
        <div class="col-2"  style="margin-left:30px;">
            <div class="col-12">
                <span style='font-weight:600'>Compounds/Technicals</span>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <p-radioButton label='CORE Material ID' name="materialTypeId" value="8" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <p-radioButton label='Synonym' name="materialTypeId" value="9" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="isGlpEligible  && RequestTypeName != requestType?.InStock">
                <p-radioButton label='CORE Material Lot ID (GLP)' name="materialTypeId" value="10" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="isGlpEligible  && RequestTypeName != requestType?.InStock">
              <p-radioButton label='TSN (GLP)' name="materialTypeId" value="11" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
        </div>

        <div class="col-1"></div>
        <div class="col-2"  style="margin-left:30px;">
            <div class="col-12">
                <span style='font-weight:600'>Bionexus Search</span>
            </div>
            <div class="col-12" *ngIf="isGlpEligible  && RequestTypeName != requestType?.InStock">
                <p-radioButton label='BIO Material Lot ID' name="materialTypeId" value="13" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="isGlpEligible  && RequestTypeName != requestType?.InStock">
              <p-radioButton label='Synonym' name="materialTypeId" value="14" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
              <p-radioButton label='BIO Material ID' name="materialTypeId" value="15" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
            <div class="col-12" *ngIf="RequestTypeName != requestType?.GLP">
                <p-radioButton label='Synonym' name="materialTypeId" value="16" [(ngModel)]="materialTypeId" (click)="onTypeChange();"></p-radioButton>
            </div>
        </div>
    </div>
    <div class="grid" style='padding-top:10px;'>
            <div class='col-12' style="display: flex; justify-content: center;">
                <span style='font-weight:600'>{{getSearchLabelText()}}</span>
            </div>
            <div class='col-12' style='display: flex; justify-content: center;'  *ngIf="materialType==='BioMaterialId' || materialType ==='BioMaterialLotId' || materialType === 'BionexusSynonymGlp' || materialType === 'BionexusSynonymStock'">
              <div class="grid">
                  <div class='col-9'>
                          <input #txtSearch (keypress)="RestrictUnwantedCharecters($event)" [(ngModel)]="searchText" type="text" class="searchTextBox3" pInputText
                              tabindex="0" (keyup.enter)="search()">
                  </div>
                  <div class='col-3' style="text-align: end;">
                      <i aria-hidden="true" title="Search" class="fa fa-search fa-2x" style="margin-top:5px;color:blue;cursor:pointer" (click)="search()"></i>
                  </div>
              </div>
          </div>
            <div class='col-12' style='display: flex; justify-content: center;'  *ngIf="materialType!=='FACTSFormulationName'&&materialType!=='FACTSFormulationNameGlp'&&materialType!=='BioMaterialId' && materialType!=='BioMaterialLotId' && materialType !== 'BionexusSynonymGlp'&& materialType !== 'BionexusSynonymStock'">
                <div class="grid">
                    <div class='col-9'>
                            <input #txtSearch (keypress)="RestrictUnwantedCharecters($event)" [(ngModel)]="searchText" type="text" class="searchTextBox3" pInputText
                                tabindex="0" (keyup.enter)="search()">
                    </div>
                    <div class='col-3' style="text-align: end;">
                        <i aria-hidden="true" title="Search" class="fa fa-search fa-2x" style="margin-top:5px;color:blue;cursor:pointer" (click)="search()"></i>
                    </div>
                </div>
            </div>
            <div class='col-12' style='display: flex; justify-content: center;'  *ngIf="materialType==='FACTSFormulationName'">
                <div class="grid" style='margin-left:100px;'>
                    <div class='col-3'>
                        <div class='col-12' style='display: flex; justify-content: center;' >
                            <span><h6>AiC*</h6></span>
                        </div>
                        <div class='col-12'>
                            <input #txtAiC [(ngModel)]="aiC" (keypress)="AllowAlphaNumeric($event)" type="text" class="searchTextBox" pInputText
                            tabindex="0" (keyup.enter)="search()">
                        </div>
                    </div>
                    <div class='col-3'>
                        <div class='col-12' style='display: flex; justify-content: center;' >
                            <span><h6>Concept*</h6></span>
                        </div>
                        <div class='col-12'>
                            <input #txtConcept [(ngModel)]="concept" (keypress)="AllowOnlyNumeric($event)" type="text" class="searchTextBox" pInputText
                            tabindex="0" (keyup.enter)="search()">
                        </div>
                    </div>
                    <div class='col-3'>
                        <div class='col-12' style='display: flex; justify-content: center;' >
                            <span><h6>Composition*</h6></span>
                        </div>
                        <div class='col-12'>
                            <input #txtComposition [(ngModel)]="composition" (keypress)="AllowOnlyNumeric($event)" type="text" class="searchTextBox" pInputText
                            tabindex="0" (keyup.enter)="search()">
                        </div>
                    </div>
                    <div class='col-3'>
                        <div class='col-12'>
                            <i aria-hidden="true" title="Search" class="fa fa-search fa-2x" style="color:blue;margin-top: 45px;margin-left:3px;;cursor:pointer" (click)="search()"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class='col-12' style='display: flex; justify-content: center;'  *ngIf="materialType==='FACTSFormulationNameGlp'">
                <div class="grid" style='margin-left:100px;'>
                    <div class='col-2'>
                        <div class='col-12' style='display: flex; justify-content: center;' >
                            <span><h6>AiC*</h6></span>
                        </div>
                        <div class='col-12'>
                            <input #txtAiC   [(ngModel)]="aiC" (keypress)="AllowAlphaNumeric($event)" maxlength="10" type="text" class="searchTextBox2" pInputText
                            tabindex="0" (keyup.enter)="search()">
                        </div>
                    </div>
                    <div class='col-2' style="margin-left:15px;">
                        <div class='col-12' style='display: flex; justify-content: center;' >
                            <span><h6>Concept*</h6></span>
                        </div>
                        <div class='col-12'>
                            <input #txtConcept [(ngModel)]="concept" (keypress)="AllowOnlyNumeric($event)" type="text" class="searchTextBox2" pInputText
                            tabindex="0" (keyup.enter)="search()">
                        </div>
                    </div>
                    <div class='col-2' style="margin-left:15px;">
                        <div class='col-12' style='display: flex; justify-content: center;' >
                            <span><h6>Composition*</h6></span>
                        </div>
                        <div class='col-12'>
                            <input #txtComposition [(ngModel)]="composition" (keypress)="AllowOnlyNumeric($event)" type="text" class="searchTextBox2" pInputText
                            tabindex="0" (keyup.enter)="search()">
                        </div>
                    </div>
                    <div class='col-2' style="margin-left:15px;">
                        <div class='col-12' style='display: flex; justify-content: center;' >
                            <span><h6>Lot*</h6></span>
                        </div>
                        <div class='col-12'>
                            <input #txtLot [(ngModel)]="lotNumber" (keypress)="AllowOnlyNumeric($event)" type="text" class="searchTextBox2" pInputText
                            tabindex="0" (keyup.enter)="search()">
                        </div>
                    </div>
                    <div class='col-3'>
                        <div class='col-12'>
                            <i aria-hidden="true" title="Search" class="fa fa-search fa-2x" style="color: blue;margin-top: 45px;margin-left:5px;cursor:pointer" (click)="search()"></i>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
<div class='col-12'>
    Search Results
</div>
<div class='col-12'>

    <p-dataView #dv layout="list" [paginator]="true" [rows]="5" [value]="results"
    paginatorPosition="both" [hidden]="isHidden" pageLinks="3"
    [alwaysShowPaginator]="true" styleClass="hover search">
    <!-- </p-header> -->
    <ng-template let-result pTemplate="listItem">
        <div class="col-12 ui-dataview-content ui-widget-content">
            <div class="col-12">
                <div class="grid resShadow ">
                  <div class="col-10">
                    <div *ngIf="materialType==='Coformulant'" class="grid">
                      <div class='col-12'>
                        <span style="padding-right: 5px;">Trade Name: {{result.tradeName}}</span>
                        <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                                (click)="inventoryCheck.getMaterialDetails('COFORM',result.materialIdInTargetSystem,'mg',getProductName(result))" class="p-button-label"></button>

                      </div>
                      <div class='col-12'>
                        <span>Common Name: {{result.commonName}}</span>
                      </div>
                      <div class='col-12'>
                        <span>IUPAC: {{result.iupacName}}</span>
                      </div>
                    </div>
                    <div *ngIf="materialType==='FieldInert'" class="grid">
                      <div class='col-11'>
                        <div class='grid'>
                          <div class='col-12'>
                            <span style="padding-right: 5px;">Trade Name: {{result.tradeName}}</span>
                        <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                                (click)="inventoryCheck.getMaterialDetails('COFORM',result.materialIdInTargetSystem,'mL',getProductName(result))" class="p-button-label"></button>
                          </div>
                          <div class='col-12'>
                            <span>Common Name: {{result.commonName}}</span>
                          </div>
                          <div class='col-12'>
                            <span>IUPAC: {{result.iupacName}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-1">
                        <span><i class='fa fa-2x fa-flag' style="margin-top: 5px;"></i>  Field Inert</span>
                      </div>
                    </div>

                    <div *ngIf="materialType==='FACTSFormulationName'||materialType==='CortevaTradeName' ||materialType==='ThirdPartyTradeName'||materialType==='FACTSSynonym'" class="grid">
                      <div class='col-12'>
                        <span style="padding-right: 5px;">Material Name: {{result.materialName}}</span>
                        <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                                (click)="inventoryCheck.getMaterialDetails('FACTS',result.materialIdInTargetSystem,'mL',getProductName(result))" class="p-button-label"></button>
                      </div>
                      <div class='col-12'>
                        <span> Approver: <a style="text-decoration: underline;" *ngIf="result.approverEmail" href="mailto:{{result.approverEmail}}">{{result.approverFullName ==  null ? result.approverEmail : result.approverFullName}}</a> </span>
                      </div>
                      <div class='col-12'>
                        <span>Stage: {{result.stage}}</span>
                      </div>
                      <div class='col-12'>
                        <span>Formulation Type: {{result.formulationTypeDesc}}</span>
                      </div>
                      <div class='col-12' style="padding-bottom: 0px !important;">
                        <span>Actives:</span>
                        <div class="col-12" *ngFor="let active of result.actives">
                          <div class="grid" style="padding-left: 50px;">
                            <div class="col-3" style="padding-top: 0px !important;padding-bottom:0px !important">{{active.ingredientName}}</div>
                            <div class="col-2" style="padding-top: 0px !important;padding-bottom:0px !important">{{active.concentrationPrimaryUom}}</div>
                            <div class="col-2" style="padding-top: 0px !important;padding-bottom:0px !important">{{active.concentrationSecondaryUom}}</div>
                          </div>
                        </div>
                      </div>
                      <div class='col-12'>
                        <span>Synonym:</span>
                        <div class="col-12" *ngFor="let synonym of result.synonyms">
                          <div class="grid" style="padding-left: 50px;">
                            <div class="col-12">{{synonym}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="materialType==='FACTSFormulationNameGlp'" class="grid">
                      <div class='col-12'>
                        <span style="padding-right: 5px;">Formulation ID: {{result.lotNumber }}</span>
                        <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                                (click)="inventoryCheck.getMaterialDetails('FACTS',result.materialIdInTargetSystem,'mL',getProductName(result))" class="p-button-label"></button>
                      </div>
                      <div class='col-12'>
                        <span>GLP Lot Approver: <a style="text-decoration: underline;" *ngIf="result.approverEmail" href="mailto:{{result.approverEmail}}">{{result.approverFullName ==  null ? result.approverEmail : result.approverFullName}}</a> </span>
                      </div>
                      <div class='col-12'>
                        <span>Stage: {{result.stage}}</span>
                      </div>
                      <div class='col-12' >
                          <span>Available Amount : {{result.quantity | number : '1.3-3'}}  {{result.quantityUom}}</span>
                        </div>

                      <div class='col-12'>
                        <span>Certification Date: {{result.certificationDate | date: 'dd-MMM-yyyy'}}</span>
                      </div>
                      <div class='col-12'>
                        <span>RE-Certification Date: {{result.recertificationDate | date: 'dd-MMM-yyyy'}}</span>
                      </div>
                      <div class='col-12'>
                        <span>Formulation Type: {{result.formulationTypeDesc}}</span>
                      </div>
                      <div class='col-12' style="padding-bottom: 0px !important;">
                        <span>Actives:</span>
                        <div class="col-12" *ngFor="let active of result.actives">
                          <div class="grid" style="padding-left: 50px;">
                            <div class="col-3" style="padding-top: 0px !important;padding-bottom:0px !important">{{active.ingredientName}}</div>
                            <div class="col-2" style="padding-top: 0px !important;padding-bottom:0px !important">{{active.concentrationPrimaryUom}}</div>
                            <div class="col-2" style="padding-top: 0px !important;padding-bottom:0px !important">{{active.concentrationSecondaryUom}}</div>
                          </div>
                        </div>
                      </div>
                      <div class='col-12'>
                        <span>Synonym:</span>
                        <div class="col-12" *ngFor="let synonym of result.synonyms">
                          <div class="grid" style="padding-left: 50px;">
                            <div class="col-12">{{synonym}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="materialType==='FACTTSNGlp'" class="grid">
                      <div class='col-12'>
                        <span>Formulation ID: {{result.lotNumber }}</span>
                      </div>
                      <div class='col-12'>
                        <span>GLP Lot Approver: <a style="text-decoration: underline;" *ngIf="result.approverEmail" href="mailto:{{result.approverEmail}}">{{result.approverFullName ==  null ? result.approverEmail : result.approverFullName}}</a></span>
                      </div>
                      <div class='col-12'>
                        <span>Stage: {{result.stage}}</span>
                      </div>
                      <div class='col-12' >
                        <span>TSN: {{result.tsn}}</span>
                      </div>
                      <div class='col-12' >
                          <span>Available Amount : {{result.quantity | number : '1.3-3'}}  {{result.quantityUom}}</span>
                        </div>
                        <div class='col-12' >
                          <span>GLP Status : {{result.glpStatus}}</span>
                        </div>
                      <div class='col-12'>
                        <span>Purity: {{result.certifiedPurity}}</span>
                      </div>
                      <div class='col-12'>
                        <span>Certification Date: {{result.certificationDate | date: 'dd-MMM-yyyy'}}</span>
                      </div>
                      <div class='col-12'>
                        <span>RE-Certification Date: {{result.recertificationDate | date: 'dd-MMM-yyyy'}}</span>
                      </div>
                      <div class='col-12'>
                        <span>Formulation Type: {{result.formulationTypeDesc}}</span>
                      </div>
                      <div class='col-12' style="padding-bottom: 0px !important;">
                        <span>Actives:</span>
                        <div class="col-12" *ngFor="let active of result.actives">
                          <div class="grid" style="padding-left: 50px;">
                            <div class="col-3" style="padding-top: 0px !important;padding-bottom:0px !important">{{active.ingredientName}}</div>
                            <div class="col-2" style="padding-top: 0px !important;padding-bottom:0px !important">{{active.concentrationPrimaryUom}}</div>
                            <div class="col-2" style="padding-top: 0px !important;padding-bottom:0px !important">{{active.concentrationSecondaryUom}}</div>
                          </div>
                        </div>
                      </div>
                      <div class='col-12'>
                        <span>Synonym:</span>
                        <div class="col-12" *ngFor="let synonym of result.synonyms">
                          <div class="grid" style="padding-left: 50px;">
                            <div class="col-12">{{synonym}}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div *ngIf="materialType==='COREMaterialId' || materialType==='CORESynonym'" class="grid" style="padding:10px">
                      <div class='col-12'>
                        <span style="padding-right:5px;">Material Name: {{result.materialName}}</span>
                        <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                              (click)="inventoryCheck.getMaterialDetails('CORE',result.materialName,'mg',getProductName(result))" class="p-button-label"></button>
                      </div>
                      <div class='col-12'>
                        <span> Approver: <a style="text-decoration: underline;" *ngIf="result.approverEmail" href="mailto:{{result.approverEmail}}">{{result.approverEmail}}</a> </span>
                      </div>
                      <div class='col-12'>
                        <div class="col-12">
                          <span>Synonyms: </span>
                          <span *ngIf="result.synonyms" class="col-12">{{result.synonyms.join("; ")}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="materialType==='COREMaterialLotGlp' || materialType==='TsnGlp'" class="grid" style="padding:10px">
                      <div class='col-12'>
                        <span style="padding-right:5px;">Material Name: {{result.materialName}}</span>
                          <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                                (click)="inventoryCheck.getMaterialDetails('CORE',result.materialName,'mg',getProductName(result))" class="p-button-label"></button>
                      </div>
                      <div class='col-12'>
                        <span>GLP Lot Approver: <a style="text-decoration: underline;" *ngIf="result.approverEmail" href="mailto:{{result.approverEmail}}">{{displayName}}</a> </span>
                      </div>
                      <div class='col-12'>
                        <span>Lot ID : {{result.lotId}}</span>
                      </div>
                      <div class='col-12' >
                        <span>Available Amount : {{result.quantity | number : '1.3-3'}}  {{result.quantityUom}}</span>
                      </div>
                      <div class='col-12' >
                        <span>TSN : {{result.tsn}}</span>
                      </div>
                      <div class='col-12' >
                        <span>Sample Use : {{result.sampleUse}}  </span>
                      </div>
                      <div class='col-12'>
                        <span>Purity :  {{result.certifiedPurity}} </span>
                      </div>
                      <div class='col-12'>
                        <span>Certification Date : {{result.certificationDate  | date: 'dd-MMM-yyyy'}}</span>
                      </div>
                      <div class='col-12'>
                        <span>GLP Status : {{result.glpStatus}}</span>
                      </div>
                      <div class='col-12'>
                        <span>Re-Certification Date : {{result.recertificationDate  | date: 'dd-MMM-yyyy'}}</span>
                      </div>
                      <div class='col-12'>
                        <div class="col-12">
                          <span>Synonyms: </span>
                          <span *ngIf="result.synonyms" class="col-12">{{result.synonyms.join("; ")}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="materialType === 'BioMaterialId' || materialType === 'BioMaterialLotId' || materialType === 'BionexusSynonymStock' || materialType === 'BionexusSynonymGlp'">
                      <div class='col-12'>
                        <span style="padding-right: 5px;">Material Name: {{result.materialName}}</span>
                        <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory"
                        (click)="inventoryCheck.getMaterialDetails(result.sourceSystem,result.materialIdInTargetSystem,'mL',getProductName(result))"
                        class="p-button-label"></button>
                      </div>
                      <div class="col-12">
                        <span style="padding-right: 5px;">Material Id: {{result.materialId}}</span>
                      </div>
                      <div class="col-12" *ngIf="materialType === 'BioMaterialLotId' || materialType === 'BionexusSynonymGlp'">
                        <span style="padding-right: 5px;">Material Lot ID: {{result.lotNumber}}</span>
                      </div>
                      <div class='col-12'>
                        <span> Approver: <a style="text-decoration: underline;" *ngIf="result.approverEmail" href="mailto:{{result.approverEmail}}">{{result.approverFullName ==  null ? result.approverEmail : result.approverFullName}}</a> </span>
                      </div>
                      <div class='col-12' *ngIf="materialType === 'BioMaterialLotId' || materialType === 'BionexusSynonymGlp'">
                        <span> Lot Attributes :
                          <div *ngFor="let att of result.lotAttributes" class="grid" style="padding-left: 100px;">
                            <div class="col-3" style="padding-top: 0px !important;padding-bottom:0px !important">
                              {{att.name}}
                            </div>
                            <div class='col-5' style="padding-top: 0px !important;padding-bottom:0px !important">
                              {{attributeValues(att)}}
                            </div>
                          </div>
                        </span>
                      </div>
                      <div class='col-12'>
                        <span> Material Attributes :
                          <div *ngFor="let att of result.materialAttributes" class="grid" style="padding-left: 100px;">
                            <div class="col-3" style="padding-top: 0px !important;padding-bottom:0px !important">
                              {{att.name}}
                            </div>
                            <div class='col-5' style="padding-top: 0px !important;padding-bottom:0px !important">
                              {{attributeValues(att)}}
                            </div>
                          </div>
                        </span>
                      </div>
                      <div class='col-12'>
                        <span>Synonym:</span>
                        <div class="col-12" *ngFor="let synonym of result.synonyms">
                          <div class="grid" style="padding-left: 50px;">
                            <div class="col-12">{{synonym}}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-2">
                    <div *ngIf="compositeSearchSettings.showCartButton">
                      <div class="col-12">
                          <button pButton type="button" icon="pi pi-shopping-cart" label="Add to Cart"
                              (click)="addToCart(result)" class="p-button-success float-right"
                              *ngIf="!result.isAdded">
                          </button>
                          <button pButton type="button" icon="pi pi-shopping-cart" label="Added to Cart"
                              class="p-button-warning float-right margin-top-0 cursor-no-drop"
                              *ngIf="result.isAdded">
                          </button>
                      </div>
                    </div>
                    <div *ngIf="!compositeSearchSettings.showCartButton">
                      <div class="col-12">
                          <button pButton type="button" title="Select and proceed to forecast" icon="fa fa-plus fa-3x" label=""
                              (click)="addToCart(result)" class="p-button-success float-right"
                              *ngIf="!result.isAdded">
                          </button>
                          <img *ngIf="isBioResult(result)" style="height:35px;"  class="float-right"
                          src="assets/primeng/images/bionexus-logo.png"/>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="paginatorleft" let-state>
        <span class="ui-paginator-first padding-left-10px"> Total Items : {{results.length}}
            {{ dv.totalRecords == results.length ? '' : '(Showing Items: '+ dv.totalRecords + ')'}} </span>
    </ng-template>
    <ng-template pTemplate="paginatorright" let-state>
        <span
            class="ui-paginator-first padding-right-10px">{{ dv.totalRecords > 0 ? (state.page * state.rows) + 1 : 0 }}
            -
            {{ (state.rows * (state.page + 1)) > state.totalRecords ? state.totalRecords : (state.rows * (state.page + 1)) }}
            of {{dv.totalRecords}} Items</span>
    </ng-template>
</p-dataView>
</div>

<p-dialog id="dialogWrongApprover"  header="Approver's Email Error" [(visible)]="displayApproverDialog"
   [draggable]="false" [modal]="true"
    [dismissableMask]="true">
    <div style="width: 600px;" >
      <div class="row m-0 p-5">
        <div class="col-md-2">
          <i class="pi pi-info-circle info custom-info"></i>
        </div>
        <div class="col-md-10 p-0" style="font-size: 1rem;">
          Approver's email is not found in the enterprise directory. Please contact repo owner.
        </div>
      </div>
    </div>
    <p-footer>
      <div>
        <button type="button" pButton icon="p-button-icon p-button-icon-left pi pi-check" id="btnCancelPopu" (click)="displayApproverDialog=false" label="OK"></button>
      </div>
    </p-footer>
</p-dialog>

<app-inventory-check [isGlpEligible]="isGlpEligible" [requestTypeName]="RequestTypeName" [materialType]="materialType" #inventoryCheck></app-inventory-check>

<p-dialog id="dialogAddLocation2"  header="{{materialNameHeader}}" [(visible)]="displayDialogBioNexus"
  [styleClass]="'clsDialogStyle'" [draggable]="false" [style.width]="'1050px'"
  [style.minWidth]="'450px'" [style.minHeight]="'450px'" [modal]="true"
  [dismissableMask]="true">
<!-- <div class="inventory-details" style="background-color: lightgrey;">
  <p-table [columns]="cols" [value]="materialInfo?.compositeInventoryByLot">
  <ng-template pTemplate="header" let-columns>
    <tr style="background-color: rgb(169, 166, 166);">
      <td colspan="6">
        <div class="material-info">
          <div class="material-type">
             <div class="list" *ngIf="materialInfo" style="border: 1px solid red;">
                <div class="type" style="width: 140px;">
                   <div class="value">
                      Total Physical
                   </div>
                   <div class="label">
                      {{materialInfo.totalPhysical  | number : '1.3-3' }} {{selectedUom?.uomDisplay}}
                   </div>
                </div>
                <div class="type" style="width: 160px;">
                   <div class="value">
                     Total Indy Reserved
                   </div>
                   <div class="label">
                      {{materialInfo.totalIndyReserved  | number : '1.3-3' }} {{selectedUom?.uomDisplay}}
                   </div>
                </div>
                <div class="type" style="width: 150px;">
                  <div class="value">
                    Total SGS Reserved
                  </div>
                  <div class="label">
                     {{materialInfo.totalSGSReserved  | number : '1.3-3' }} {{selectedUom?.uomDisplay}}
                  </div>
               </div>
                <div class="type" style="width: 150px;">
                   <div class="value">
                      Total Available
                   </div>
                   <div class="label">
                      {{materialInfo.totalAvailable  | number : '1.3-3' }} {{selectedUom?.uomDisplay}}
                   </div>
                </div>
             </div>
          </div>
          <div class="material-name">
            <table>
              <tr>
                <td style="text-align: center;font-weight: bold;color: black;">
                  <u>Disclaimer:</u>
                </td>
              </tr>
              <tr>
                  <td style="text-align: left;line-height: normal;color: black;">
                    "Total Available" and sum of "Available Qty." may not equal one another. The quantity under "Total Available" shows the current amount available to order in CPSS (without a forecast).  "Total Available" accounts for all reservations for this material.    "Available Qty." by lot only, as shown below, accounts for reservations sent for that particular lot, and does not account for reservations sent without a specific lot assigned.
                  </td>
              </tr>
            </table>
         </div>
       </div>
      </td>
    </tr>
    <div>
      <div style="padding-top: 10px;"><label style="font-weight: bold;">*Unit of Measure</label></div>
        <div class="uomDiv" style="padding-bottom: 10px;">
          <p-selectButton [(ngModel)]="selectedUom" (onChange)="OnUnitOfMeasureChange($event)" [options]="uomOptions" optionValue="uomDisplay"
          optionLabel="uomDisplay" id="selectButtonOrderItemctrnUOM"></p-selectButton>
        </div>
    </div>
    <tr>
        <th *ngFor="let col of columns" style="background-color: #a9a6a6;">
          {{col.header}}
        </th>
     </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr *ngIf="rowData['available'] != '0'&&(rowData['glpStatus'].toString().toLowerCase()==='stock'||isGlpEligible)">
      <td *ngFor="let col of columns; let i = index"    [ngClass]="{'clsAlternativeBackground': i % 2 ==0}">
        <ng-container *ngIf="col.field === 'dateOfManufacture' || col.field === 'expirationDate'; else nonDateFields">
          {{ rowData[col.field] | date:"dd-MMM-yyyy" :'UTC'}}
       </ng-container>
       <ng-template #nonDateFields>
           <ng-container *ngIf="col.field === 'available' ; else nonNumericValues">
              {{(rowData[col.field] < 0 ? 0 : rowData[col.field])  | number : '1.3-3' }}   {{inventoryUOM}}
           </ng-container>
            <ng-template #nonNumericValues>
              <ng-container *ngIf="col.field==='lotId' ; else nonLot">
                <span *ngIf="SelectedRepo=='FACTS' || SelectedRepo == 'COFORM'">{{ rowData['lotNumber'] }}</span>
                <span *ngIf="SelectedRepo=='CORE' ">{{ rowData['lotId'] }}</span>
              </ng-container>
              <ng-template #nonLot>

                <span > {{ rowData[col.field] }}</span>
              </ng-template>
            </ng-template>
          </ng-template>

      </td>
    </tr>

  </ng-template>
  </p-table>
</div> -->
<p-footer>
  <div class="row"><div class="col-md-10" style="text-align: initial;font-style: italic;"></div>
  <div class="col-md-2"><button type="button" pButton icon="fa fa-close" id="btnCancelPopup" (click)="displayDialogBioNexus=false" label="Close"></button></div></div>
</p-footer>
</p-dialog>
