import { Component, OnInit } from '@angular/core';
import { CheckboxCellRendererComponent } from '../custom/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { FormulationTypeService } from '../services/formulationtype.service';
import { FormulationTypeModel } from '../models/formulationtype.model';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../loading/res-loading.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-formulation-type',
  templateUrl: './formulation-type.component.html',
  styleUrls: ['./formulation-type.component.scss'],
  providers: [FormulationTypeService, ConfirmationService, MessageService]
})
export class FormulationTypeComponent implements OnInit {
  public context;
  private gridApi;
  private gridColumnApi;

  columnDefs = [
    {headerName: 'Code', field: 'formulationTypeCode', sortable: true, filter: true, width: 110},
    {headerName: 'Description', field: 'description', sortable: true, filter: true, width: 260},
    { headerName: 'Is Enabled', field: 'isEnabled', sortable: true,
      filter: true,
      cellRenderer: 'checkboxCellRenderer', width: 130 }
  ];
  frameworkComponents = {
    checkboxCellRenderer: CheckboxCellRendererComponent
  };

rowData: Array<FormulationTypeModel>;

constructor(private fts: FormulationTypeService,
            private confirmationService: ConfirmationService,
            private appComponent: AppComponent,
            private messageService: MessageService) {
              this.context = { componentParent: this };
  }

  ngOnInit() {
    this.appComponent.currentSelection = 'formulationtype';
    this.fts.getFormulationTypes().subscribe(response => this.rowData = response,
      error => {
          this.messageService.add({ severity: 'error', summary: '', detail: error.message });
      },
      () => { console.log(this.rowData); } );
}
public checkBoxUpdated(dt: FormulationTypeModel) {
  console.log(dt);
  this.fts.updateFormulationType(dt).subscribe(res => console.log(res),
    error => {
        console.log(error);
        this.messageService.add({ severity: 'error', summary: '', detail: error.message });

    },
    () => {
        this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' });
        this.ngOnInit();
    });
  }
onGridReady(params) {
  this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;
}
}
