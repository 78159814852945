import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root',
})
export class UserInfoService {
    public constructor(private msal: MsalService) { }

    public getRequestor(): RequestorModel {
        const user = this.msal.instance.getActiveAccount();
        const myToken = (user.idToken as any) as TokenWrapper;
        const requestor = new RequestorModel();

        if (myToken) {
            requestor.requestorId = myToken.sub;
        }

        requestor.email = user.username;
        const names = user.name.split(',', 2);
        requestor.name = user.username;
        requestor.firstName = names[1].trim();
        requestor.lastName = names[0].trim();

        return requestor;
    }
}

export class TokenWrapper {
    sub: string;
}
export class RequestorModel {
    requestorId: string;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
}
