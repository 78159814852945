<p-dataView #dv id="ForecastDestinations" [value]="destinations" layout="grid" styleClass="hover">
  <ng-template let-forecastLocationItem let-rowIndexValue="rowIndex" pTemplate="gridItem" id="templateForecastDestinations" *ngIf="destinations">
    <div style="padding:.5em;" class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-3" [ngClass] = "((forecastStatus === 'To Do' && forecastLocationItem.inventoryOrderState?.id == 1 && materialPlannerUserId != null) || (forecastStatus === 'To Order' && forecastLocationItem.inventoryOrderState?.id == 1) || (forecastStatus === 'Follow Up' && forecastLocationItem.inventoryOrderState?.id == 1 && materialPlannerUserId != null)) ? 'borderShadow' : 'resShadow'">
      <p-panel [styleClass]="forecastLocationItem.forecastClassName" id="{{forecastLocationItem.id}}"
        [style]="{'text-align':'left'}">
        <p-header style="width: 100%">
          <span class="form-panel-subheader">{{forecastLocationItem.destinationCountryName}}</span>
          <span [style]="{'float':'right','margin-right':'10px'}" [title]="'View forecast'"
            id="{{'ForecastLocationEdit' + forecastLocationItem.id}}">
            <span class="form-panel-reserved font-weight-bold"
              *ngIf="(forecastLocationItem.inventoryOrderState.id == inventoryInStockState.id||forecastLocationItem.inventoryOrderState.id == inventoryOrderedState.id || forecastLocationItem.inventoryOrderState.id == inventoryToOrderState.id)&&forecastLocationItem.reserved===true">Reserved</span>
            </span>
        </p-header>
        <div style="min-height: 200px;" id="{{'forecastItem' + forecastLocationItem.id}}">
          <div class="forecastItem-detail">
            <div class="grid">
              <div class="col-5"> <span class="form-label-header">Status:</span> </div>
              <div class="col-7">
                <p-multiSelect #multiSelect [options]="forecastLocationItem.inventoryOrderStateDropdown"
                               [disabled]="forecastLocationItem.inventoryOrderState.id == inventoryOutsourcedState.id 
                               || forecastLocationItem.inventoryOrderState.id == inventoryCancelledState.id 
                               || forecastLocationItem.inventoryOrderState.id == inventoryOrderedState.id 
                               || (forecastLocationItem.checkedForReservationExpiry !== null && forecastLocationItem.checkedForReservationExpiry)
                               || forecastLocationItem.inventoryOrderState.id == inventoryToOrderState.id"
                               [(ngModel)]="forecastLocationItem.selectedInventoryOrderStates" optionLabel="stateName"
                               appendTo="body" selectionLimit='1' [filter]='false'
                               (onChange)="updateDestination($event,forecastLocationItem)"
                               [style]="{'width': '100%'}" [showHeader]="false">
                              <ng-template let-option pTemplate="item">
                                <span
                                      [ngStyle]="(option.stateName==='Ordered'||(forecastLocationItem.inventoryOrderState.id===inventoryInStockState.id&&option.stateName!=='Cancelled'))?{'color':'#ccc', 'cursor':'default'}: ''">
                                  {{option.stateName}}
                                </span>
                              </ng-template>                
                </p-multiSelect>
              </div>
            </div>
          </div>
          <div class="forecastItem-detail">
            <div class="grid">
              <div class="col-5"><span class="form-label-header">Lot: </span></div>
              <div class="col-7"><span class="form-label-content" id="{{'lot' + forecastLocationItem.id}}">
                  <input type="text" pInputText class="form-control" id="lotNumber" pattern="[A-Za-z0-9- ]{0,20}"
                         (paste)="onLotNumberPaste($event)" (focusout)="onLotNumberUpdate(forecastLocationItem)"
                         (keypress)="AllowAlphaNumericDash($event, forecastLocationItem)"
                         [(ngModel)]="forecastLocationItem.lotNumber"  
                         [disabled]="forecastLocationItem.inventoryOrderState.id == inventoryOutsourcedState.id
                         ||forecastLocationItem.inventoryOrderState.id == inventoryCancelledState.id
                         || (forecastLocationItem.checkedForReservationExpiry !== null && forecastLocationItem.checkedForReservationExpiry)"
                         oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);">
                </span>
              </div>
            </div>
          </div>
          <div class="forecastItem-detail" style="padding-top: 5px;height: 35px;">
            <div
                 *ngIf="forecastLocationItem.inventoryOrderState && (forecastLocationItem.inventoryOrderState?.stateName=='In Stock'
                       || forecastLocationItem.inventoryOrderState?.stateName=='To Order' || forecastLocationItem.inventoryOrderState?.stateName=='Ordered')">
              <div class="grid">
                <div class="col-5"> <span class="form-label-header" style="display: block; margin-top: -3px;">Wh Loc: </span> </div>
                <div class="col-7">
                  <p-dropdown id="selectSampleInventoryLocation" [options]="sampleInventoryLocations"
                                   [(ngModel)]="forecastLocationItem.inventoryLocation"
                                   [ngStyle]="{'display': 'inline-grid', 'width':'100%', 'height':'30px' }"
                                  [disabled]="(forecastLocationItem.checkedForReservationExpiry !== null && forecastLocationItem.checkedForReservationExpiry)"
                                  optionLabel="locationName" (onChange)="onInventoryLocChange($event,forecastLocationItem)">
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="forecastItem-detail" style="padding-top: 5px;height: 35px;">
            <div class="grid">
              <div class="col-5" *ngIf="repoName != 'COFORM'"> <span class="form-label-header" style="display: block; margin-top: -3px;">Strg Loc:</span> </div>
              <div class="col-6" *ngIf="repoName != 'COFORM'">
                <p-selectButton  id="selectSampleStorageLocation" [options]="forecastLocationItem.forecastTypes"
                                class="location-select" [(ngModel)]="forecastLocationItem.forecastType" 
                                [disabled]="forecastLocationItem.inventoryOrderState.id==inventoryOutsourcedState.id
                                ||forecastLocationItem.inventoryOrderState.id==inventoryCancelledState.id
                                || (forecastLocationItem.checkedForReservationExpiry !== null && forecastLocationItem.checkedForReservationExpiry)"
                                optionLabel="forecastType1" (onChange)="onStorageLocChange($event,forecastLocationItem)">
                </p-selectButton>
              </div>
              <div class="col-1">
                <button pButton type="button" [ngStyle]="{'margin-left': repoName != 'COFORM' ? '-28px' : '198px' }" 
                [disabled]="!forecastLocationItem.enableReserve" label="Reserve" style="width: 48px; padding-left: 3px;"
                        (click)="saveStorageLoc(forecastLocationItem)"></button>
              </div>
            </div>
          </div>
          <div class="forecastItem-detail">
            <div class="grid">
              <div class="col-5"> <span class="form-label-header">Quantity: </span></div>
              <div class="col-7"> 
                <span class="form-label-content" id="{{'quantity' + forecastLocationItem.id}}">
                  <ng-container *ngIf="isShowOrderedQtyHyperLink(forecastLocationItem); else qty">
                      <a style="text-decoration: underline;" (click)="onGetOrderedQuantity(forecastLocationItem, rowIndexValue)" class="orderedQtyLink">
                        {{forecastLocationItem.quantity}} {{forecastLocationItem.uomDisplayName}}
                        <span *ngIf="destinationOrderedQuantity[rowIndexValue] !== undefined">&nbsp;(</span> 
                        {{destinationOrderedQuantity[rowIndexValue] !== undefined ? destinationOrderedQuantity[rowIndexValue] : ''}} {{destinationOrderedQuantity[rowIndexValue] !== undefined ? forecastLocationItem.uomDisplayName : ''}}
                        <span *ngIf="destinationOrderedQuantity[rowIndexValue] !== undefined">&nbsp;Ordered in CPSS)</span> 
                      </a>
                  </ng-container>
                  <ng-template #qty>
                    {{forecastLocationItem.quantity}} {{forecastLocationItem.uomDisplayName}}
                  </ng-template>
                </span>
              </div>
            </div>
          </div>
          <div class="forecastItem-detail">
            <div class="grid">
              <div class="col-5"><span class="form-label-header">Date Needed: </span> </div>
              <div class="col-7">
                <span class="form-label-content"
                      id="{{'dateNeeded' + forecastLocationItem.id}}">
                      {{forecastLocationItem.dateNeeded | date:'dd-MMM-yyyy'}}
                </span>
                <span class="form-label-content"
                      id="{{'dateNeededLatest' + forecastLocationItem.id}}" *ngIf="forecastLocationItem.dateNeededLatest">
                    - {{forecastLocationItem.dateNeededLatest | date:'dd-MMM-yyyy'}}
                </span>
                <span class="form-label-content"
                      id="{{'dateNeededLatest' + forecastLocationItem.id}}" *ngIf="(forecastLocationItem.dateNeededLatest == null || forecastLocationItem.dateNeededLatest == undefined) && forecastLocationItem.seasonExpirationDate" >
                    - {{forecastLocationItem.seasonExpirationDate | date:'dd-MMM-yyyy'}}
                </span>
              </div>
            </div>
          </div>
        <div class="forecastItem-detail">
          <div class="grid">
            <div class="col-5"><span class="form-label-header">Manufacturer: </span></div>
            <div class="col-7"><span class="form-label-content"
                id="{{'manufacturer' + forecastLocationItem.id}}">{{forecastLocationItem.manufacturer}}</span>
            </div>
          </div>

        </div>
        <div class="forecastItem-detail">
          <div class="grid">
            <div class="col-5"><span class="form-label-header">Reach Approved:</span></div>
            <div class="col-7"><span class="form-label-content" id="{{'reachApproved' + forecastLocationItem.id}}" > {{forecastLocationItem.isReachApproved ? "Yes" : "No"}}</span>
            </div>
          </div>

        </div>
        <div class="forecastItem-detail">
          <div class="grid">
            <div class="col-5"><span class="form-label-header">Forecast Id: </span></div>

            <div class="col-7"> <span class="form-label-content"
                id="{{'programNumber' + forecastLocationItem.id}}"><a style="text-decoration: underline;"  target="_blank" [title]="forecastLocationItem.forecastItemId" [routerLink]="['/forecastitemEdit',forecastLocationItem.forecastItemId]">{{forecastLocationItem.forecastItemId}}</a></span>
            </div>
          </div>
        </div>
        <div class="forecastItem-detail">
          <div class="grid">
            <div class="col-5"><span class="form-label-header">Submitted by: </span></div>
            <div class="col-7"><span class="form-label-content"
                id="{{'submittedBy' + forecastLocationItem.id}}">{{forecastLocationItem.forecaster}}</span>
            </div>
          </div>
        </div>
        <div class="forecastItem-detail">
          <div class="grid">
            <div class="col-5"><span class="form-label-header">Labels: </span></div>
            <div class="col-7"><span class="form-label-content"
                id="{{'raceLabels' + forecastLocationItem.id}}" [pTooltip]="forecastLocationItem.raceLabels?.replaceAll('||','\r\n')">
                {{(forecastLocationItem.raceLabels && forecastLocationItem.raceLabels.length > 20) ? forecastLocationItem.raceLabels.substring(0,20)?.replaceAll('||',', ') +'...': forecastLocationItem.raceLabels}} 
              </span>
            </div>
            
          </div>
        </div>
        <div class="forecastItem-detail">
          <div class="grid">
            <div class="col-5"><span class="form-label-header">Requester(s):</span></div>

            <div class="col-7" id="{{'SampleRequester' + forecastLocationItem.id}}">
              <div (click)="ShowRequestor(forecastLocationItem.id)" *ngIf="!forecastLocationItem.showRequester && forecastLocationItem.forecastItemDestinationRequester.length>3" >
                <div
                  *ngFor="let forecastItemDestinationRequester of forecastLocationItem.forecastItemDestinationRequester.slice(0,3);let itemIndex=index">
                  {{forecastItemDestinationRequester.requestingUserName}}<span *ngIf="itemIndex==2">...</span>
                </div>
              </div>

              <div *ngIf="!forecastLocationItem.showRequester && forecastLocationItem.forecastItemDestinationRequester.length<=3">
                <div
                  *ngFor="let forecastItemDestinationRequester of forecastLocationItem.forecastItemDestinationRequester.slice(0,3);">
                  {{forecastItemDestinationRequester.requestingUserName}}
                </div>
              </div>
              <div style="overflow-y: scroll;max-height: 70px;" *ngIf="forecastLocationItem.showRequester">
                <div
                  *ngFor="let forecastItemDestinationRequester of forecastLocationItem.forecastItemDestinationRequester">
                  {{forecastItemDestinationRequester.requestingUserName}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="forecastItem-detail">
          <div class="grid">
            <div class="col-5"><span class="form-label-header">Comment:</span></div>
            <div class="col-7">
              <span class="form-label-content" *ngIf="forecastLocationItem?.comment?.length<16"
                style="display: inline-block;" id="{{'comment' + forecastLocationItem.id}}"
                title="{{forecastLocationItem.comment}}">
                <a (click)="ShowComment(forecastLocationItem.id)">{{forecastLocationItem.comment}}</a>
              </span>
              <span *ngIf="forecastLocationItem?.comment?.length>15">
                <a (click)="ShowComment(forecastLocationItem.id)">{{forecastLocationItem.comment | slice:0:15}}...</a>
              </span>
              <span *ngIf="!(forecastLocationItem?.comment)">
                <a (click)="ShowComment(forecastLocationItem.id)">No Comment</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <p-footer style="width: 100%">
        <span *ngIf="forecastLocationItem.qualifiedForAutoInventory && forecastLocationItem.inventoryOrderState.id !== inventoryToDoState.id" [title]="'Auto-inventory checked'">
          <svg xmlns="http://www.w3.org/2000/svg" class="fontawesomesvg" preserveAspectRatio="none" viewBox="0 0 384 512">
            <path d="M336 64h-53.88C268.9 26.8 233.7 0 192 0S115.1 26.8 101.9 64H48C21.5 64 0 85.48 0 112v352C0 490.5 21.5 512 48 512h288c26.5 0 48-21.48 48-48v-352C384 85.48 362.5 64 336 64zM192 64c17.67 0 32 14.33 32 32s-14.33 32-32 32S160 113.7 160 96S174.3 64 192 64zM282.9 262.8l-88 112c-4.047 5.156-10.02 8.438-16.53 9.062C177.6 383.1 176.8 384 176 384c-5.703 0-11.25-2.031-15.62-5.781l-56-48c-10.06-8.625-11.22-23.78-2.594-33.84c8.609-10.06 23.77-11.22 33.84-2.594l36.98 31.69l72.52-92.28c8.188-10.44 23.3-12.22 33.7-4.062C289.3 237.3 291.1 252.4 282.9 262.8z"/>
          </svg>
        </span>
        <span [style]="{'float':'right','margin-top':'10px'}" class="form-panel-expired"
          id="{{'ForecastLocationEditFooter' + forecastLocationItem.id}}" [title]="'Forecast expired'">
          <i class="fa fa-hourglass hourglass" *ngIf="forecastLocationItem.checkedForReservationExpiry!==null&&forecastLocationItem.checkedForReservationExpiry">
          </i>
        </span>
        <!-- <span [style]="{'float':'right'}" class="form-panel-expired"
          id="{{'ForecastLocationEditFooter' + forecastLocationItem.id}}" [title]="'Forecast expired'">
          <svg xmlns="http://www.w3.org/2000/svg" class="fontawesomesvg hourglass" preserveAspectRatio="none" viewBox="0 0 384 512">
            <path d="M0 32C0 14.3 14.3 0 32 0H64 320h32c17.7 0 32 14.3 32 32s-14.3 32-32 32V75c0 42.4-16.9 83.1-46.9 113.1L237.3 256l67.9 67.9c30 30 46.9 70.7 46.9 113.1v11c17.7 0 32 14.3 32 32s-14.3 32-32 32H320 64 32c-17.7 0-32-14.3-32-32s14.3-32 32-32V437c0-42.4 16.9-83.1 46.9-113.1L146.7 256 78.9 188.1C48.9 158.1 32 117.4 32 75V64C14.3 64 0 49.7 0 32zM96 64V75c0 25.5 10.1 49.9 28.1 67.9L192 210.7l67.9-67.9c18-18 28.1-42.4 28.1-67.9V64H96zm0 384H288V437c0-25.5-10.1-49.9-28.1-67.9L192 301.3l-67.9 67.9c-18 18-28.1 42.4-28.1 67.9v11z"/>
          </svg>
        </span> -->
      </p-footer>
    </p-panel>
    </div>
  </ng-template>

</p-dataView>
<p-dialog id="dialogConfirmation" header='{{dialogCancelConfirmTitle}}' [(visible)]="dialogCancelConfirm"
  [styleClass]="'clsDialog'" [modal]="true" [dismissableMask]="true" [contentStyle]="{'overflow-y': 'hidden', 'overflow-x': 'hidden',
        'max-height': '80vh','min-height':'50px'}" [resizable]="false">
  <div class="form-group row" *ngIf="!hasUsedQty">
    <div class="col-12">
      <div ><label class="fa fa-info-circle"> <span
            style="margin-left: 14px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">Are
            you sure you want to mark the selected location to Cancelled status?</span> </label></div>
    </div>
  </div>
  <div *ngIf="hasUsedQty">
    <div class="form-group row">
      <div class="col-1" style="text-align: center;font-size: 33px;color: #007ad9;">
        <i class="fa fa-exclamation-triangle"></i>
      </div>
      <div class="col-11">
        <p>Are you sure you would like to cancel it?</p>
        <p>You are about to cancel a forecast that has an associated CPSS request against it.</p>

        <p> The unused quantity of <b style="font-weight:bold ;">  {{remainingQty}}</b> will be cancelled from this forecast</p>

      </div>
    </div>
  </div>

  <p-footer style="height:5px;">
    <div class="form-group row" styleClass="disable-scroll" style="margin-bottom:0px">
      <div class="col-md-12">
        <button style="margin-left:340px;" type="button" pButton id="btnYesCancel" pButton icon="fa fa-check"
          (click)="cancelAndReset()" label="Yes, Cancel"></button>
        <button style="margin-left:2px" type="button" pButton id="btnNoCancel" pButton icon="fa fa-close"
          (click)="dialogCancelConfirm=false;" label="No"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog id="dialogConfirmation" header='Cancellation Request Notice' [(visible)]="dialogCancelError"
  [styleClass]="'clsDialog'" [modal]="true" [dismissableMask]="true" [contentStyle]="{'overflow-y': 'hidden', 'overflow-x': 'hidden',
        'max-height': '80vh','min-height':'50px'}" [resizable]="false">
  <div class="form-group row">
    <div class="col-12">
      <div ><label class="fa fa-info-circle"> <span
            style="margin-left: 14px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
            CPSS request is already in process. Cancellation is not allowed</span> </label></div>
    </div>
  </div>
  <p-footer style="height:5px;">
    <div class="form-group row" styleClass="disable-scroll" style="margin-bottom:0px">
      <div class="col-md-12">
        <button style="margin-left:2px" type="button" pButton id="btnNoCancel" pButton icon="fa fa-check"
          (click)="dialogCancelError=false;" label="Done"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog id="dialogOutsourcedConfirmation" header='Outsourced Confirmation' [(visible)]="dialogOutsourceConfirm"
  [styleClass]="'clsDialog'" [modal]="true" [dismissableMask]="true" [contentStyle]="{'overflow-y': 'hidden', 'overflow-x': 'hidden',
        'max-height': '80vh','min-height':'50px'}" [resizable]="false">
  <div class="form-group row" *ngIf="!hasUsedQty">
    <div class="col-12">
      <div ><label class="fa fa-info-circle"> <span
            style="margin-left: 14px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">Are
            you sure you want to mark the selected location to Outsourced status?</span> </label>
      </div>
    </div>
  </div>
  <p-footer style="height:5px;">
    <div class="form-group row" styleClass="disable-scroll" style="margin-bottom:0px">
      <div class="col-md-12">
        <button style="margin-left:340px;" type="button" pButton id="btnYesCancel" pButton icon="fa fa-check"
          (click)="outsourceIt()" label="Yes, Outsourced"></button>
        <button style="margin-left:2px" type="button" pButton id="btnNoCancel" pButton icon="fa fa-close"
          (click)="dialogOutsourceConfirm=false;" label="No"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog id="dialogShowComment" header='Comments' [(visible)]="dialogShowComment" [resizable]="false" [styleClass]="'clsDialog'"
  [modal]="true" [dismissableMask]="true" [contentStyle]="{'overflow-y': 'hidden', 'overflow-x': 'hidden',
  'min-height': '420px', 'min-width':'700px','height': '420px', 'width':'700px'}">
  <div class="form-group row">
    <div class="col-10"></div>
    <div class="col-2"><button (click)="openCommentDialog();" pButton type="button"
        style="width: 80px;padding: 0 0.75rem;margin-top:0px !important;border:none !important;" class="w-full" label="Add" icon="fa fa-plus"
        iconPos="right"></button></div>
  </div>
  <div class="form-group row">
    <div class="col-12" style="max-height: 300px; overflow: auto;">
      <p-dataView #dv [value]="forecastComments" id="forecastCommentsView" [lazy]="true" [totalRecords]="totalCount"
        layout="list" styleClass="hover" emptyMessage="No comments">
        <ng-template let-forecastComment pTemplate="listItem" let-i="rowIndex">
          <div class="form-group row"
            [ngStyle]="{'font-size':'14px','width': '100%','min-height':'40px','border': '0px','margin-left': '5px','margin-bottom':'5px','background-color':(i%2==0 ? 'white' : 'lightgray'),'color':(i%2==0 ? 'black' : 'black')}">
            <div class="col-3">
              <p style="font-size: 14px !important;">
                <a style="font-weight: bold" href="mailto:{{forecastComment.user.email}}"><u>{{forecastComment.user.firstName}}
                    {{forecastComment.user.lastName}}</u></a>
              </p>
              <p  style="font-size: 14px !important;">
                {{commonService.convertUTCToLocalTime(forecastComment.dateEntered) | date: 'dd-MMM-yyyy' }}
              </p>
            </div>
            <div class="col-9">
              <div  style="font-size: 14px !important;">{{forecastComment.comment}}</div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-10"></div>
    <div class="col-2"><button (click)="dialogShowComment=false;" pButton type="button"
        style="width: 80px;padding: 0 0.75rem;margin-top:0px !important;border:none;" class="w-full" label="Close" icon="fa fa-check"
        iconPos="left"></button></div>
  </div>
</p-dialog>
<p-dialog id="dialogAcceptComment" header='New Comment' [(visible)]="dialogAcceptCommentDialog"
  [styleClass]="'clsDialog'" [modal]="true" [dismissableMask]="true">
  <div class="form-group row">
    <div class="col-12">
      <textarea name="commentText" [value]="commentText" (input)="commentText = $event.target.value;"
        style="height: 200px;overflow-y: scroll;border-width: 1px;;" id="txtComment" [rows]="10" [cols]="50"></textarea>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-md-12">
      <button type="button" pButton id="btnSaveComment" [disabled]="commentText == ''" (click)="onComment()"
        label="Save"></button>
      <button style="margin-left: 10px;" type="button" pButton id="btnCancel" (click)="dialogAcceptCommentDialog=false;"
        label="Cancel"></button>
    </div>
  </div>
</p-dialog>
