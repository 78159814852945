import { Component, Inject, Optional, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, NgZone, ApplicationRef  } from '@angular/core';
import { LayoutService, AuthStatus } from '@corteva-research/ngx-components-core';
import { MsalBroadcastService } from "@azure/msal-angular";
import { MsalService } from "@azure/msal-angular";
import { Subscription } from "rxjs/Subscription";
import { Router, ActivatedRoute, RoutesRecognized  } from '@angular/router';
import { LoadingService } from './loading/res-loading.service';
import { ConfigurationSettings } from './configuration-settings';
import { SecurityService} from './services/security.service';
import { MonitoringService} from './services/monitor.service';
import { RoleGuardService } from './services/role-gaurd-service';
import { UserModel, UserClient } from './services/forecast-api-client.service';
import { ConfirmationService, SelectItem, MessageService } from "primeng/api";
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, mergeMap, pairwise, takeUntil } from 'rxjs/operators';
import * as signalR from "@microsoft/signalr";
import { LogLevel } from 'msal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ConfigurationSettings, SecurityService, RoleGuardService, MessageService]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  public loggedIn: boolean;
  public receiveSignalR: boolean;
  public isApprover: boolean;
  public isManager: boolean;
  public isBiologyLeader: boolean;
  public isMaterialPlanner: boolean;
  public isFormulationSpecialist:boolean;
  public isCommercialUser:boolean;
  public isFieldScientist: boolean;
  public isOrderCoordinator: boolean;
  public isWarehouseStaff: boolean;
  public isReceivingTeam: boolean;
  public isIFSLeadership: boolean;
  public username: string;
  public currentSelection = 'dashboard';
  private subscription: Subscription;
  public userModel: UserModel;
  public currentEnvironment = ConfigurationSettings.CURRENT_ENVIRONMENT_NAME;
  public formulationProductionFeature = ConfigurationSettings.CurrentEnvironment.featureFlags.formulationProduction;
  public previousRouteURL = '';
  public firstName: string;
  public jobFunctions: SelectItem[] = [];
  public selectedFunction: number;
  public displayRequestAccess: boolean = false;
  public isSaveEnabled: boolean = false;
  public user: UserModel = new UserModel();
  appVersion= ConfigurationSettings.APP_VERSION;
  HelpUrl=ConfigurationSettings.HELP_URL;
  FAQUrl=ConfigurationSettings.FAQ_URL;
  private readonly _destroying$ = new Subject<void>();
  public connection: signalR.HubConnection;
  public theMessage:string;
  public displayMaintenanceMessage: boolean = false;
  public disableOnSave = false;

  constructor(private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private securityService: SecurityService,
    private roleGuardService: RoleGuardService,
    private cdr: ChangeDetectorRef,
    private appRef: ApplicationRef,
    private monitoringService: MonitoringService,
    private messageService: MessageService,
    private userClient: UserClient) {
  }

  ngOnInit() {
    this.username = this.authService.instance.getActiveAccount()?.username;
    this.initSignalR();
    if (this.username !== '') {
      localStorage.setItem("loggedInUsername",this.username);
      this.validateUser();
//      console.log(this.activatedRoute.snapshot.pathFromRoot.toString());
//      this.router.navigateByUrl(window.location.href);
    }

    this.broadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      filter(() => { return this.authService.instance.getAllAccounts().length > 0; }),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.loggedIn = true;
      this.username = this.authService.instance.getActiveAccount()?.username;
      localStorage.setItem("loggedInUsername",this.username);
      this.validateUser();
    });
  this.setPreviousRouteURL();

}
public ngAfterViewInit(): void {
  const el = document.querySelector("res-app-settings-options res-navigation-group");
  el.removeAttribute("hidden");
}
public setPreviousRouteURL() {
  this.router.events
    .pipe(filter((e: any) => e instanceof RoutesRecognized),
      pairwise()
    ).subscribe((e: any) => {
      this.previousRouteURL = e[0].urlAfterRedirects === '/login' || e[0].urlAfterRedirects === '' ? 
                                this.previousRouteURL : e[0].urlAfterRedirects;
      console.log(e[0].urlAfterRedirects); // previous url
    });
}
public initSignalR() {
  this.connection = new signalR.HubConnectionBuilder()
  .configureLogging(signalR.LogLevel.Information)
  .withUrl(ConfigurationSettings.CurrentEnvironment.ShellAPIPath+"/hub")
  .build();

  this.connection.start().then(function () {  
    console.log('SignalR Connected!');  
    this.connection.send("sendGreeting", 'I just logged in!')
    .then(() => {});
  });  

  this.connection.on("SendGreeting", (message: string) => {
    this.theMessage = message;
    this.displayMaintenanceMessage = true;
  });
  this.connection.on("NewUserWelcome", (message: string) => {
    this.messageService.add({ severity: 'success', summary: '', detail: message});
  });
}

public closeMaintenanceMessage() {
  this.theMessage = "";
  this.displayMaintenanceMessage = false;
}

  public cancelAccessRequest() {
    this.displayRequestAccess = false;
  }
  public saveAccessRequest() {
    this.disableOnSave = true;
    this.userClient.postUser(this.user)
    .subscribe(data => {
      this.messageService.add({ severity: 'success', summary: '',
      detail: 'Thank You, Your Request Has been Submitted.'});
      this.displayRequestAccess = false;
      this.disableOnSave = false;
    });
  }
  public changeFunction() {
    this.user.functionId = this.selectedFunction;
    this.isSaveEnabled = this.selectedFunction !== 0;
  }
  private loadJobFunctions() {
    if (this.jobFunctions.length === 0) {
      this.jobFunctions = [];
      this.jobFunctions.push({label: 'Select Function', value: 0});
      this.userClient.getFunctionsAll()
      .subscribe(data => {
          const fxs = data.map(x => ({label: x.functionName, value: x.id}));
          fxs.forEach(x => this.jobFunctions.push(x));
      },
      error => {
        this.authService.loginRedirect();
      });
    }
  }

  public getPermissions() {
    this.isBiologyLeader = this.roleGuardService.doesUserHaveRole('Biology Leader');
    this.isMaterialPlanner = this.roleGuardService.doesUserHaveRole('Material Planner');
    this.isFieldScientist = this.roleGuardService.doesUserHaveRole('Field Scientist');
    this.isManager = this.roleGuardService.doesUserHaveRole('Administrator');
    this.isOrderCoordinator = this.roleGuardService.doesUserHaveRole('Order Coordinator');
    this.isWarehouseStaff = this.roleGuardService.doesUserHaveRole('Warehouse Staff');
    this.isFormulationSpecialist = this.roleGuardService.doesUserHaveRole('Formulation Specialist');
    this.isCommercialUser = this.roleGuardService.doesUserHaveRole('Commercial User');
    this.isReceivingTeam = this.roleGuardService.doesUserHaveRole('Receiving Team');
    this.isIFSLeadership = this.roleGuardService.doesUserHaveRole('IFS Leadership');
  }
  redirectTo(uri) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri]));
  }

  public isMgr() {
    return this.roleGuardService.doesUserHaveRole('Administrator');
  }
  /**
   * Method to validate User through API
   */
  public validateUser() {
    if (!(this.username === undefined || this.username === 'guest' || this.username === '')) {
      localStorage.setItem("loggedInUsername", this.username);
      this.userClient.getByEmailId(this.username)
        .subscribe(data => {
          this.userModel = data;
        },
        error => {
          //  this.router.navigate(['unauthorized']);
          this.loadJobFunctions();
          this.userClient.getADUser(this.username)
          .subscribe(data => {
              if (data === null) {
                  this.messageService.add({ severity: 'warn', summary: '', detail: 'User information not found. Check spelling.' });
              } else {
                  this.user = data;
                  this.user.optInEmailNotification = true;
                  this.user.isActive = false;
                  this.user.awaitingApproval = true;
                  if (this.user.firstName === undefined || this.user.firstName === null || this.user.firstName === '') {
                    this.user.firstName = this.username.substring(0, this.username.indexOf('@'));
                    this.user.lastName = this.user.firstName;
                  }
                  // this.isSaveEnabled = true;
                  this.displayRequestAccess = true;
              }
          },
          error => {
              this.messageService.add({ severity: 'error', summary: '', detail: 'Your session has expired, please login again.' });
              this.authService.loginRedirect();
              //this.router.navigate(['login']);
              console.log(error);
          });
        },
          () => {
            console.log(this.userModel);
            if (!this.userModel.isActive || this.userModel.roles === null || this.userModel.roles.length === 0) {
              localStorage.removeItem("loggedInUsername");
              localStorage.removeItem("loggedInUserRoles");
              this.messageService.add({ severity: 'warn', summary: '', detail: 'Thank You, Your Request Has Already Been Submitted' });
            }
            else {
              localStorage.setItem("loggedInUserModel", JSON.stringify(this.userModel));
              localStorage.setItem("loggedInUsername", this.username);
              localStorage.setItem("loggedInUserRoles", JSON.stringify(this.userModel.roles.map(x => x.name)));
              console.log(localStorage["loggedInUserRoles"]);
              console.log(this.securityService.getUser());
              this.getPermissions();
              this.isApprover = this.userModel.isApprover;
              console.log(this.isManager);
              console.log('router url is ' + this.router.url);
              if (this.previousRouteURL === '' || this.previousRouteURL.includes('login') || this.previousRouteURL === '/') {
//                this.router.navigate(['/dashboard']);
              }
              else {
                this.router.navigateByUrl(this.previousRouteURL);
              }
            }
          });
        }
  }
  ngOnDestroy() {
    localStorage.removeItem("loggedInUsername");
    localStorage.removeItem("loggedInUserRoles");
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
