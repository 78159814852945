
<div   style="margin-top: 20px;" *ngIf="!isAdministrator&&(isReceivingTeam)" >
  <h4>There are no published reports for your assigned roles</h4>
</div>
<div   style="margin-top: 20px;" *ngIf="userInfo.functionName == 'Actives & Product Technology' || (!isReceivingTeam && !isOrderCoordinator)" >
  <h4>Forecast Reports</h4>
  <ul>
<li><a (click)="ReportPopup(e)" href="" title="Forecast Summary">Forecast Summary Report</a></li>
<li><a (click)="ReportPopup(e,true)" href="" *ngIf="userInfo.functionName == 'Actives & Product Technology' ||  isIFSLeadership || isAdministrator || isMaterialPlanner" title="Order Summary">Order Summary Report</a></li>
</ul>
</div>
<div *ngIf="isAdministrator" style="margin-top: 30px;">
  <h4>Admin Reports</h4>
  <ul>
    <li><a href="" (click)="countriesReportClick(e)" title="Countries Report">Countries Report</a></li>
    <li><a (click)="countriesByRegionReportClick(e)" href="/reporting" title="Countries By Region Report">Countries By Region Report</a></li>
    <li><a (click)="notImplementedClick(e)" href="" title="Users Report">Users Report</a></li>
    <li><a (click)="notImplementedClick(e)" href="" title="Quantity Units of Measure Report">Quantity Units of Measure Report</a></li>
    <li><a (click)="notImplementedClick(e)" href="" title="Concentration Units of Measure Report">Concentration Units of Measure Report</a></li>
    <li><a (click)="notImplementedClick(e)" href="" title="Formulation Types Report">Formulation Types Report</a></li>
  </ul>
</div>
<div *ngIf="isBiologyLeader" style="margin-top: 30px;">
  <h4>Biology Leader Reports</h4>
  <ul>
    <li><a (click)="ReportPopup(e)" href="" title="My Forecasts Report">Forecasts Report</a></li>
    <li><a (click)="notImplementedClick(e)" href="" title="My Delegates Report">Delegates Report</a></li>
  </ul>
</div>
<div *ngIf="isMaterialPlanner || isOrderCoordinator" style="margin-top: 30px;">
  <h4>Material Planner Reports</h4>
  <ul>
    <li><a (click)="ReportPopup(e)" href="" *ngIf="isOrderCoordinator" title="Forecast Summary">Forecast Summary Report</a></li>
    <li><a (click)="ReportPopup(e,true)" href="" *ngIf="isOrderCoordinator" title="Order Summary">Order Summary Report</a></li>
    <li><a (click)="procurementReportClick(e)" href="" *ngIf="isMaterialPlanner||isOrderCoordinator" title="Procurement Report">Procurement Report</a></li>
    <li><a (click)="notImplementedClick(e)" href=""  *ngIf="isMaterialPlanner||isOrderCoordinator" title="Shipments Report">Shipments Report</a></li>
  </ul>
</div>
<p-toast></p-toast>
<p-dialog id="dialogForecastSummary" header='Select a year'    [(visible)]="displayForecastSummaryDialog" 
  [styleClass]="'clsDialog'"
   [modal]="true"
    [dismissableMask]="true">
    <div class="form-group grid">
      <div class="md:col-6">Please select a year</div>
      <div class="md:col-6">
        <p-dropdown [options]="forecastingSeasons" optionLabel="name" id="ddrForecastSummaryForecastingSeasons"
            name="FSeason"  [(ngModel)]="selectedDate"></p-dropdown>
      </div>
    </div>
    <div class="form-group grid" style="margin-top:200px;">
      <div class="md:col-12"> 
        <button type="button" pButton icon="fa fa-check" *ngIf="!isOrderSummary" id="btnRunForecastSummaryReport"   (click)="ForecastSummaryReport(e)" label="Run"   ></button>
        <button type="button" pButton icon="fa fa-check" id="btnRunOrderSummaryReport"  *ngIf="isOrderSummary" (click)="OrderSummaryReport(e)" label="Run"   ></button>
      </div>
    </div>
  </p-dialog>
