
<div>
    <h2>Manage UserRoles</h2>
</div>
<div>
    <p-table #dt [columns]="userroleTableHeaders" [value]="userroles" [paginator]="true" [rows]="10" selectionMode="multiple"
        [(selection)]="selectedUsers" [reorderableColumns]="true" [resizableColumns]="true">
        <ng-template pTemplate="caption">
            <div class="ui-helper-clearfix">
                <input type="text" pInputText size="50" placeholder="Global Filter"
                    (input)="dt.filterGlobal($event.target.value, 'contains')" style="width:auto;float:right">
                <i class="fa fa-search" style="margin:4px 4px 0 0;float:right"></i>
            </div>
            <div class="ui-helper-clearfix" style="margin-top:5px">
                <button pButton type="button" label="Add new" (click)="AddnewItem()" icon="fa fa-plus-square"
                    style="float:left"></button>
                <button type="button" pButton icon="fa fa-file-o" iconPos="left" label="All Data"
                    (click)="dt.exportCSV()" style="float:right;margin-left:5px"></button>
                <button type="button" pButton icon="fa fa-file" iconPos="left" label="Selection Only"
                    (click)="dt.exportCSV({selectionOnly:true})" style="float:right;margin-left:5px"></button>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.field"
                    [ngStyle]="{'display': col.display,'text-align':'center'}" pReorderableColumn pResizableColumn>
                    {{col.header}}
                    <p-sortIcon [field]="col.field" ariaLabel="Activate to sort"
                        ariaLabelDesc="Activate to sort in descending order"
                        ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th *ngFor="let col of columns" [ngStyle]="{'text-align':'center'}">
                    <input pInputText type="text"
                        (input)="dt.filter($event.target.value, col.field, col.filterMatchMode='contains')">
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns" [ngStyle]="{'display': col.display}">
                    {{rowData[col.field]}}
                </td>
                <td style="text-align:center">
                    <button type="button" pButton icon="fa fa-fw fa-edit" label="Edit" (click)=Edit(rowData)></button>
                    <button type="button" pButton icon="fa fa-fw fa-trash" label="Delete" (click)=Delete(rowData) style="margin-left:5px"></button>                    
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog header="{{dialogHeaderText}} UserRole" [(visible)]="displayDialog" [style]="{'min-width':'700px'}" [modal]="true"
    [dismissableMask]="true">
<form>
        <div class="form-group row" hidden>
            <div class="col-md-3"><label for="UserRoleId">User Role Id</label></div>
            <div class="col-md-6"><input type="text" id="UserRoleId" name="UserRoleId" placeholder="User Role Id"
                    [(ngModel)]="userrole.UserRoleId" pInputText class="col-md-12"></div>
            <div class="col-md-3">
                <!--Validation Errors-->
            </div>
        </div>        
        <div class="form-group row" hidden>
            <div class="col-md-3"><label for="RoleId">Role Id</label></div>
            <div class="col-md-6"><input type="text" id="RoleId" name="RoleId" placeholder="Role Id"
                    [(ngModel)]="userrole.RoleId" pInputText class="col-md-12"></div>
            <div class="col-md-3">
                <!--Validation Errors-->
            </div>
        </div>        
        <div class="form-group row" hidden>
            <div class="col-md-3"><label for="UserId">User Id</label></div>
            <div class="col-md-6"><input type="text" id="UserId" name="UserId" placeholder="User Id"
                    [(ngModel)]="userrole.UserId" pInputText class="col-md-12"></div>
            <div class="col-md-3">
                <!--Validation Errors-->
            </div>
        </div>        
        <div class="form-group row">
            <div class="col-md-3"><label for="CreatedDate">Created Date</label></div>
            <div class="col-md-6"><input type="text" id="CreatedDate" name="CreatedDate" placeholder="Created Date"
                    [(ngModel)]="userrole.CreatedDate" pInputText class="col-md-12"></div>
            <div class="col-md-3">
                <!--Validation Errors-->
            </div>
        </div> 
        <div class="form-group row">
            <div class="col-md-3"><label for="CreatedBy">Created By</label></div>
            <div class="col-md-6"><input type="text" id="CreatedBy" name="CreatedBy" placeholder="Created By"
                    [(ngModel)]="userrole.CreatedBy" pInputText class="col-md-12"></div>
            <div class="col-md-3">
                <!--Validation Errors-->
            </div>
        </div> 
        <div class="form-group row">
            <div class="col-md-3"><label for="ModifiedDate">Modified Date</label></div>
            <div class="col-md-6"><input type="text" id="ModifiedDate" name="ModifiedDate" placeholder="Modified Date"
                    [(ngModel)]="userrole.ModifiedDate" pInputText class="col-md-12"></div>
            <div class="col-md-3">
                <!--Validation Errors-->
            </div>
        </div> 
        <div class="form-group row">
            <div class="col-md-3"><label for="ModifiedBy">Modified By</label></div>
            <div class="col-md-6"><input type="text" id="ModifiedBy" name="ModifiedBy" placeholder="Modified By"
                    [(ngModel)]="userrole.ModifiedBy" pInputText class="col-md-12"></div>
            <div class="col-md-3">
                <!--Validation Errors-->
            </div>
        </div> 
        </form>
<p-footer>
        <button type="button" pButton icon="fa fa-check" (click)="Save()" label="Save" *ngIf="!isEditable"></button>
        <button type="button" pButton icon="fa fa-save" (click)="Update()" *ngIf="isEditable" label="Update"></button>
        <!--<button type="button" pButton icon="fa-remove" (click)="Delete()" [hidden]="!isEditable" label="Delete"></button>-->
        <!--This delete moved into grid column beside edit button.-->
        <button type="button" pButton icon="fa fa-close" (click)="Cancel()" label="Cancel"></button>
    </p-footer>
</p-dialog>
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
