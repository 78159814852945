export class FormulationRequestsFilterModel {
  name: string;
  formulation: string;
  programNumber: string;
  productionOwnerId: string;
  forecasterId: string;
  materialPlannerId: string;
  fromDate: string;
  toDate: string;
  productionLocationId: string;
  statusIdList: number[];
 
  FormulationRequestsFilterModel() {
    this.name = "";
    this.formulation = "";
    this.programNumber = "";
    this.productionOwnerId = "";
    this.forecasterId = "";
    this.materialPlannerId = "";
    this.fromDate = "";
    this.toDate = "";
    this.productionLocationId = "";
    this.statusIdList = [];
  }
 }
 