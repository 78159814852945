
<div class="grid" *ngIf="datLoaded">
  <div class="col-12 md:col-12" >
    <h4 style="font-weight: bold;">My Dashboard</h4>
  </div>
  <div class="col-6">
  <div class="grid clsForecasts"  >
    <div class="col-1"></div>
    <div class="col-5" >
      <p-chart type="pie" id="chartDashboard" [data]="chartData" [options]="options" width="20vw" height="40vh"></p-chart>
    </div>
    <div class="col-1"></div>
    <div class="col-5">
      <div class="grid">
        <div class="col-12 clsForecastTitle">
            My Forecasts
          </div>
      </div>
      <div class="grid">
        <div class="col-12">
          <div class="grid" *ngFor="let state of forecastState" style="width: 100%">
            <div  class="col-10" >{{state.stateName}}</div>
            <div  class="col-2 clsStateCount" [ngClass]="{clsDataEntry :  commonService.configurations.ForecastStatus.DataEntry == state.stateName ,
              clsAwaitingReview :  commonService.configurations.ForecastStatus.AwaitingReview == state.stateName ,
              clsSubmitted :  commonService.configurations.ForecastStatus.Submitted == state.stateName ,
              clsInProcess :  commonService.configurations.ForecastStatus.InProcess == state.stateName ,
              clsFulfilled :  commonService.configurations.ForecastStatus.Completed == state.stateName ,
              clsDelegateReview :  commonService.configurations.ForecastStatus.DelegateReview == state.stateName,
              clsArchived :  commonService.configurations.ForecastStatus.Archived == state.stateName,
              clsProcurement :  commonService.configurations.ForecastStatus.Procurement == state.stateName
            }"><span style="float: right;">{{state.count}}</span>
          </div>
        </div></div>
        </div>
      </div>
    </div>
  </div>
  </div>

