
/*
    This is class to write message at console
*/

export class ConsoleLog {
    public message(message): void {
        console.log("-----> " + message);
    }

    public methodStart(methodName): void {
        this.message("Method:: " + methodName + " - Start")
    }

    public methodEnd(methodName): void {
        this.message("Method:: " + methodName + " - End")
    }
}