import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResearchComponentsCoreModule } from '@corteva-research/ngx-components-core';
import { ResearchComponentsMsalIntegrationModule } from '@corteva-research/ngx-components-msal-integration';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, 
  MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, 
  MSAL_INTERCEPTOR_CONFIG, ProtectedResourceScopes } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from "@azure/msal-browser";

import * as signalR from "@microsoft/signalr";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { LoadingComponent } from './loading/loading.component';
import { UnauthorizedComponent } from './login/unauthorized.component';
import { LoadingService } from './loading/res-loading.service';
import { httpSetHeaders } from './services/interceptor-service/httpSetHeaders.interceptor';
import { LoaderInterceptor } from './services/interceptor-service/loader.interceptor';
import { SecurityService } from './services/security.service';
import { RoleGuardService } from './services/role-gaurd-service';
import { ConcentrationUomService } from './services/concentrationuom.service';
import { QuantityUomService } from './services/quantityuom.service';
import { ErrorHandlerService } from './services/errorhandler.service';
import { MonitoringService } from './services/monitor.service';
import { UserComponent } from './user/user.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { DataViewModule } from 'primeng/dataview';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { SplitButtonModule } from 'primeng/splitbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FieldsetModule } from 'primeng/fieldset';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { UserRoleComponent } from './userrole/userrole.component';
import { ConfirmationService } from 'primeng/api';
import { ConfigurationSettings } from './configuration-settings';
import { QuantityUomComponent } from './quantityuom/quantity-uom.component';
import { ConcentrationUomComponent } from './concentrationuom/concentration-uom.component';
import { FormulationTypeComponent } from './formulationtype/formulation-type.component';
import { CheckboxCellRendererComponent } from './custom/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportingComponent } from './reporting/reporting.component';

import { environment } from '../environments/environment';
import { API_BASE_URL, CountryModel } from './services/forecast-api-client.service';
import { CountryComponent } from './country/country.component';
import { CountryClient } from './services/forecast-api-client.service';
import { ButtonRendererComponent } from './custom/button-renderer/button-renderer.component';
import { CommonService } from './services/common.service';
import { InterComponentMessageService } from './services/inter-component-message-service';
import { ForecastItemService } from './services/forecastItem.service';
import { DatePipe } from '@angular/common';
import { ForecastLocationComponent } from './forecast-location/forecast-location.component';
import { ForecastlocationService } from './services/forecastlocation.service';
import { ForecastItemComponent } from './forecast-item/forecast-item.component';
import { DelegatesComponent } from './delegates/delegates.component';
import { SubmittedForecastsNewComponent } from './submitted-forecast-new/submitted-forecasts-new.component';
import { ProcurementComponent } from './procurement/procurement.component';
import { PassThruGuardService } from './services/passthru-gaurd-service';
import { ProcurementDetailComponent } from './procurement-detail/procurement-detail.component';
import { JwtTokenService } from './services/jwt-token-service.service';
import { ConfirmationDialog } from './services/route-guards/confirmation-dialog.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { COMPOSITE_SEARCH_SETTINGS } from './services/environment-service/material-search-config';
import { CompositeSearchComponent } from './composite-search/composite-search.component';
import { TupleService } from './composite-search/tuple.service';
import { GSSCOMMON_API_BASE_URL } from './services/gsscommon-api-client.service';
import { MaterialInventoryComponent } from './material-inventory/material-inventory.component';
import { ForecastDestinationsComponent } from './forecast-destinations/forecast-destinations.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { ForecastsNewComponent } from './forecasts-new/forecasts-new.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AfterLoginComponent } from './after-login/after-login.component';
import { AfterLogoutComponent } from './after-logout/after-logout.component';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavigateComponent } from './navigate/navigate.component';
import { MaintenanceMessageComponent } from './maintenance-message/maintenance-message.component';
import { FormulationRequestsComponent } from './formulation-requests/formulation-requests.component';
import { FormulationRawMaterialComponent } from './formulation-raw-material/formulation-raw-material.component';
import { InventoryCheckComponent } from './inventory-check/inventory-check.component';

export function MsalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(ConfigurationSettings.CurrentEnvironment.msal.config);
}

export function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const interceptorSettings: MsalInterceptorConfiguration = { ...ConfigurationSettings.CurrentEnvironment.msal.interceptor };
  // make sure the proectedResourceMap is a map and not an array
  if (Array.isArray(interceptorSettings.protectedResourceMap)) {
    interceptorSettings.protectedResourceMap = new Map<string, (string | ProtectedResourceScopes)[] | null>(interceptorSettings.protectedResourceMap);
  }
  return interceptorSettings;
}

export function MsalGuardConfigFactory(): MsalGuardConfiguration {
  return ConfigurationSettings.CurrentEnvironment.msal.guard;
}


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        LoadingComponent,
        UnauthorizedComponent,
        AfterLoginComponent,
        AfterLogoutComponent,
        AccessDeniedComponent,
        NoPermissionsComponent,
        PageNotFoundComponent,
        NavigateComponent,
        UserComponent,
        UserRoleComponent,
        CountryComponent,
        QuantityUomComponent,
        ConcentrationUomComponent,
        FormulationTypeComponent,
        CountryComponent,
        CheckboxCellRendererComponent,
        DashboardComponent,
        ReportingComponent,
        ButtonRendererComponent,
        ForecastItemComponent,
        ForecastLocationComponent,
        DelegatesComponent,
        SubmittedForecastsNewComponent,
        ProcurementComponent,
        ProcurementDetailComponent,
        ConfirmationDialog,
        CompositeSearchComponent,
        MaterialInventoryComponent,
        ForecastDestinationsComponent,
        ApprovalsComponent,
        ForecastsNewComponent,
        MaintenanceMessageComponent,
        FormulationRequestsComponent,
        MaintenanceMessageComponent,
        FormulationRawMaterialComponent,
        InventoryCheckComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AgGridModule.withComponents([CheckboxCellRendererComponent]),
        AgGridModule.withComponents([ButtonRendererComponent]),
        AppRoutingModule,
        MatStepperModule, MatInputModule, MatCardModule,
        FormsModule, ReactiveFormsModule,
        MsalModule.forRoot(new PublicClientApplication(ConfigurationSettings.CurrentEnvironment.msal.config), ConfigurationSettings.CurrentEnvironment.msal.guard, ConfigurationSettings.CurrentEnvironment.msal.interceptor),
        ResearchComponentsCoreModule.forRoot('farm'),
        ResearchComponentsMsalIntegrationModule,
        TableModule,
        ButtonModule,
        InputMaskModule,
        InputTextModule,
        EditorModule,
        DialogModule,
        DropdownModule,
        CalendarModule,
        ToastModule,
        MessageModule,
        MessagesModule,
        RadioButtonModule,
        MultiSelectModule,
        ConfirmDialogModule,
        PanelModule,
        CardModule,
        CarouselModule,
        ChartModule,
        DataViewModule,
        TabViewModule,
        CheckboxModule,
        ProgressSpinnerModule,
        SelectButtonModule,
        InputSwitchModule,
        InputTextareaModule,
        SplitButtonModule,
        OverlayPanelModule,
        FieldsetModule,
        AccordionModule,
        AutoCompleteModule,
        DynamicDialogModule,
        TooltipModule
    ],
    providers: [
        CountryClient,
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
//        { provide: HTTP_INTERCEPTORS, useClass: httpSetHeaders, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        MsalService,
        MsalBroadcastService,
        LoadingService,
        RoleGuardService,
        PassThruGuardService,
        MonitoringService,
        SecurityService,
        InterComponentMessageService,
        ConfirmationService,
        ForecastItemService,
        {
            provide: MSAL_INSTANCE,
            useFactory: MsalInstanceFactory,
            deps: []
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MsalGuardConfigFactory,
            deps: []
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MsalInterceptorConfigFactory,
            deps: []
        },
        { provide: API_BASE_URL, useValue: ConfigurationSettings.REST_API_URL },
        { provide: COMPOSITE_SEARCH_SETTINGS, useValue: ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS },
        { provide: GSSCOMMON_API_BASE_URL, useValue: ConfigurationSettings.COMPOSITE_SEARCH_SETTINGS.gssCommonUrl },
        CommonService,
        TupleService,
        DatePipe,
        ForecastlocationService,
        DialogService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }
