<h4>Send a maintenance message</h4>
<div class="grid">
    <div class="col-12">
        <p-editor [style]="{ height: '250px' }" [(ngModel)]="theMessage"></p-editor>
    </div>
</div>
<div class="grid">
    <div class="col-12" style="float: right">
        <button type="button" pButton id="btnSendMessage" (click)="sendMessage()" label="Send"   ></button>
    </div>
</div>

