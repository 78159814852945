<div class="grid">
  <div class="md:col-3"><h4 style="font-weight: bold;">Procurement</h4></div>
  <div class="md:col-9">
    <button pButton type="button" id="btnNewOrderRequest" label="New Order Request" style="float:right;" 
      icon="fa fa-plus" iconPos="right" (click)="NewOrderRequest()"></button>
  </div>
</div>
<p-dataView #dv id="procurementDataView" [paginator]="false" layout="grid"
styleClass="hover">
<ng-template pTemplate="header">
  <div style="width: 100%" style="background-color: #f4f4f4;border: 1px solid darkgray" class="clsDataViewHeaderVisiblePart">
    <div class="grid" style="width: 100%;">
      <div class="col-3 xl:col-3 lg:col-3 md:col-6 sm:col-12">
        <div class="grid" style="margin-top: 1px;">
          <div class="col-2">
            <div (click)="myFunction($event, 0)" >
              <i style="margin-left:10px" class="fa fa-filter fa-2x" aria-hidden="true"></i>
            </div>
          </div>
          <div class="col-8" >
            <p-dropdown #ddown id="ddrFilterDropdown" [style]="{  'width':'100%'}"   optionLabel="name" 
            [(ngModel)]="selectedFilter" (click)="StopDefaults()" [options]="searchFilters" 
            appendTo="body" >
            </p-dropdown>
          </div>
          <div class="col-2"  >
            <button type="button" style="margin-left: 1em" id="btnApplySelectedFilter" pButton (click)="ApplySelectedFilter($event)" label="Apply"></button>
          </div>
        </div>
      </div> 
      <div class="col-3 xl:col-8 lg:col-3 md:col-6 sm:col-12">
        <div class="grid" style="margin-top: -0.7rem">
        </div>
      </div>
    </div>   
  </div>
   <div *ngIf='isAccordionOpen' class='clsFilterFormDiv'>
    <form [formGroup]="filterForm" class='clsFilterForm' style="padding-left: 15px;" id="formFilterForm">
    <div class="grid" style="width: 100%">
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Plant Code</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <input type="text" id="txtPlaneCode" placeholder="Plant Code" (keyup)=" EnterToFilter($event)"  formControlName="PlantCode" pInputText class="ui-inputtext"  >
        <input type="hidden" id="hdnId" formControlName="id"> 
      </div>
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Material Planner</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <p-autoComplete [suggestions]="filteredMaterialPlanners" id="autoCompleteMaterialPlanners"
          (completeMethod)="filterMaterialPlanners($event)" field="fullName" dataKey="id" [size]="20"
           formControlName="MaterialPlannerUserId"
           (keyup.enter)="EnterToFilter($event)" placeholder="Material Planner">
        </p-autoComplete>
      </div>
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Date Needed</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <p-calendar id="calendarDateNeeded" selectionMode="range" name="DateNeeded" formControlName="DateNeeded"
          (keyup.enter)="EnterToFilter($event)" [showIcon]="true" dateFormat="dd-M-yy"></p-calendar>
      </div>
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Comments</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">  
        <input type="text" id="txtKeyword" placeholder="Comments"  (keyup)=" EnterToFilter($event)" formControlName="Keyword"  pInputText class="ui-inputtext"  >          
        </div>
      <div class="col-3 xl:col-3 lg:col-8 md:col-8 sm:col-8">
        <p-message severity="error" text="Minimum 3 characters required" 
        *ngIf="filterForm.controls['Keyword'].touched && filterForm.controls['Keyword'].errors != null && filterForm.controls['Keyword'].errors.minlength  "></p-message>
      </div> 
    </div>
    <div class="grid" style="width: 100%">
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Order#</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <input type="text" id="txtOrderNo" placeholder="Order Number" formControlName="OrderNumber"  (keyup)=" EnterToFilter($event)" pInputText class="ui-inputtext"  >
      </div>
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Material</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <input type="text" id="txtProduct" placeholder="Material"  (keyup)=" EnterToFilter($event)" formControlName="Product"  pInputText class="ui-inputtext"  >
      </div>
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Forecast#</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8"><input type="text" id="txtForecastNumber"
          formControlName="ForecastNumber" placeholder="Forecast Number"  (keyup.enter)="EnterToFilter($event)"
          (keypress)="AllowOnlyIntegers($event)" pInputText class="p-inputtext">
      </div>
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Forecaster</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <p-autoComplete [suggestions]="filteredUsers" id="autoCompleteUsers" 
          (completeMethod)="filterUsers($event)" field="fullName" [size]="20" formControlName="Forecaster"
          (keyup.enter)="EnterToFilter($event)" placeholder="Forecaster" [minLength]="2">
        </p-autoComplete>
      </div>
    </div>
    <div class="grid" style="width: 100%">
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Status</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <p-multiSelect [options]="orderStatusDropdown" formControlName="Status" id="ddrOrderStatus"
        [style]="{'width': '186px'}" optionLabel="status"
          appendTo="body" defaultLabel="Select" [showHeader]="false" [filter]="false">
          <ng-template let-option pTemplate="item">
            {{option.status}}
          </ng-template>
        </p-multiSelect>
      </div> 
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Sort Order </div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
            <p-dropdown id="ddrOrderBy" (keyup)="EnterToFilter($event)" [options]="orderBy" 
            formControlName="OrderBy" optionLabel="name" appendTo="body" clickOutside [showClear]="false">
            </p-dropdown>
            <button type="button" style="margin-left:3px;" id="btnSort" pButton
              [icon]="!isSortOrderDesc ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"
              (click)="ChangeSortOrder()">
            </button>
      </div>
      <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Filter Name</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <input placeholder="Filter Name"  type="text" id="txtFilterName"  (keyup)=" EnterToFilter($event)"   pInputText class="ui-inputtext" formControlName="Name">
        <p-message severity="error" text="Name required" *ngIf="!filterForm.controls['Name'].valid&&filterForm.controls['Name'].dirty"></p-message>
      </div>
      <div class="col-3 xl:col-3 lg:col-6 md:col-6 sm:col-6">
          <div class="grid" >
              <div class="col-3">
                <button type="button" style="min-width:80px;"  id="btnApplyFilter" [disabled]="!disabledFilterSave"   pButton (click)="FilterData(false)" label="Apply"></button>  
              </div>
            <div class="col-3"><button type="button" style="min-width:80px;"  [disabled]="(MaterialsAssignedToMe != null && filterForm.controls['Name'].value == MaterialsAssignedToMe.name) || filterForm.controls['Name'].value === null || !filterForm.controls['Name'].valid || ( filterForm.controls['Keyword'].value.length > 0 && filterForm.controls['Keyword'].value.length < 3) " id="btnSaveFilter" pButton   (click)="FilterData(true)"  label="Save"></button> </div>
            <div class="col-3"><button type="button" id="btnClearFilter" style="width:80px;" pButton (click)="clearFilter()" label="Clear All"></button> </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<div></div>
</p-dataView>

<div class="form-group grid mt-15" *ngIf="orderItems.length > 0">
  <div class="col-5"></div>
  <div class="col-7">
    <div style="float:right">
     <button pButton type="button" label="Expand All" (click)="expandAll()"></button>
     <button pButton type="button" label="Collapse All" (click)="collapseAll()" style="margin-left: 15px;" ></button>
    <button pButton type="button" id="btnSaveTop" style="margin-left: 15px;" 
     label="Save" [disabled]="!isFormChanged || isAdditionalQuantityInvalid || isQuantityNeededInvalid || isOrderQuantityInvalid" (click)="saveOrders()" ></button>     
    <button [disabled]="!isFormChanged" pButton type="button" style="margin-left: 15px;" id="btnCancelTop"
     label="Cancel" (click)="discardChanges()" ></button>
    </div>
  </div>
</div>
<div class="mt-15" *ngIf="orderItems.length == 0">
   No records found 
</div>
<div  *ngIf="orderItems.length > 0  && showOrderItems">
  <div class="mt-15">
    <p-dataView #dv1 
      [value]="orderItems" 
      [lazy]="true" 
      (onPage)="loadOrderItems($event)" 
      id="procuremtDataView"
      [paginator]="true" 
      [rows]="25" 
      paginatorPosition="both"
      layout="list" styleClass="hover" [totalRecords]="totalRecordsCount">
      <ng-template let-item pTemplate="listItem" let-i="rowIndex">
        <div class="order" style="width: 100%; border: 1px solid #dee2e6; margin:2px 0; display: flex; flex-direction: column ">
          <p-header (click)=" setOrderStatus(item)
            " [ngStyle]="{'cursor': 'pointer','width': '100%','background-color': (i%2==0 ? '#a64d79' : 'lightgray') ,'color': (i%2==0 ? 'white' : 'black')}">
            <div class="row">
              <div class="col-md-4">

                <div style="line-height: 40px;padding-left: 10px;color:white;font-weight: 700;"> <i
                    [ngClass]="item.isOrderTabOpen ?'pi-caret-down':'pi-caret-right'" class="pi"></i><u><a
                    style="text-decoration:underline;" target="_blank" href="" 
                    [routerLink]="['/procurementDetail/'+item.id+'/false']"
                    [ngStyle]="{'color':(i%2==0 ? 'white' : 'black')}"> {{item.productName}} 
                    <span *ngIf="item.brandName"> / {{item.brandName}}</span>
                  </a></u></div>
              </div>
              <div class="col-md-2">
                <div class="status" [ngStyle]="{'color':(i%2==0 ? 'white' : 'black')}"
                [ngClass]="{
                  'to-do-new':  item.orderStatus.status === 'New',
                  'received': item.orderStatus.status === 'Received' ,
                  'follow-up': item.orderStatus.status === 'Follow-up',
                  'partial-receipt': item.orderStatus.status === 'Partial Receipt',
                  'ordered': item.orderStatus.status === 'Ordered',
                  'in-stock': item.orderStatus.status === 'In Inventory',
                  'outsourced': item.orderStatus.status === 'Outsourced',
                  'cancelled': item.orderStatus.status === 'Cancelled'
                }"
                  >
                  <span>{{item.orderStatus.status}}</span>
                </div>
              </div>
              <div [class]="item.repoName == 'FACTS' ? 'col-md-2' : 'col-md-3'" [ngStyle]="{'line-height': '40px','color':(i%2==0 ? 'white' : 'black'), 'font-weight':'bold'}">
                {{item.productType}}</div>
                <div class="col-md-1" *ngIf="item.repoName == 'FACTS'" (click)="$event.stopPropagation();" [ngStyle]="{'line-height': '40px','color':(i%2==0 ? 'white' : 'black'), 'font-weight':'bold'}">
                  <p-checkbox id="chkIsManufactured" inputId="{{item.id}}" style="padding-right: 5px;padding-bottom: 10px;" 
                  [(ngModel)]="item.isManufactured" binary="true" label="Add to FP" 
                  (onChange)="onCheckAddtoFP($event, item)" [disabled]="item.isManufactured" >
                  </p-checkbox>
                </div>
                <div class="col-md-1" [ngStyle]="{'line-height': '40px','color':(i%2==0 ? 'white' : 'black'), 'font-weight':'bold'}">
                  {{item.earliestDateNeeded | date: 'dd-MMM-yyyy'}}</div>
                  <div class="col-md-2" [ngStyle]="{'line-height': '40px'}">
                  <p-dropdown [ngStyle]="{'float':'right'}" [ngClass]="{'myLabelWhite' : i%2==0,  'myLabelBlack': i%2!=0}" [options]="materialPlanners"
                  class="p-dropdown-materialplanner"                 
                  optionLabel="fullName"
                  id="ddrMaterialPlanner{{item.id}}" [hidden]="false" [showClear]="true" 
                  placeholder="Material Planner" (click)="StopOpening($event)"
                  [ngModel]="getSelectedmaterialPlanner(item.materialPlannerUserId)"
                  (onChange)="setSelectedMaterialPlanner($event, item)"
                  appendTo="body"></p-dropdown>
              </div>
            </div>
          </p-header>
          <div class="form-group row" *ngIf="item.isOrderTabOpen" style="width: 100%; padding:15px;margin:0">
            <div class="col-md-2">
              <label style="font-weight: bold;">Formulation #</label>
              <div>{{item.formulationNumber}}</div>
            </div>
            <div class="col-md-1">
              <label style="font-weight: bold;">Forecasted</label>
              <div>{{item.forecastedQuantityWithUoms}}</div>
            </div>
            <div class="col-md-2">
              <label style="font-weight: bold;">Non Forecasted</label>
              <div>{{item.nonForecastedQuantityWithUoms}}</div>
            </div>
            <div class="col-md-2">
              <label style="font-weight: bold;">Additional Quantity</label>
              <div>
                <input (ngModelChange)="onProcurementValueChange(i)" type="number" pInputText
                  class="ui-inputtext w-50" (keyup)="additionalQuantityFilter($event, item.additionalQuantity)" [(ngModel)]="item.additionalQuantity" />
                <label
                  style="font-weight: bold; padding-left: 10px">{{item.estimatedOrderQuantityUom?.uomDisplay}}</label>
                  <p-message _ngcontent-bob-c218="" severity="error" text="Additional Quantity is invalid" *ngIf="isAdditionalQuantityInvalid"
                    ng-reflect-severity="error" ng-reflect-text="Additional Quantity is invalid" class="ng-star-inserted">
                    <div aria-live="polite" class="p-inline-message p-component p-inline-message-error ng-star-inserted"
                      ng-reflect-ng-class="[object Object]"><span class="p-inline-message-icon pi pi-times-circle"
                        ng-reflect-ng-class="pi pi-times-circle"></span><span class="p-inline-message-text ng-star-inserted">Additional Quantity is
                        invalid</span>
                    </div>
                  </p-message>
              </div>
            </div>
            <div class="col-md-3">
              <label style="font-weight: bold;">Quantity Needed</label>
              <div>
                <input (ngModelChange)="onProcurementValueChange(i)" type="number" pInputText
                  class="ui-inputtext w-25 " (keyup)="quantityNeedFilter($event, item.estimatedOrderQuantity)" [(ngModel)]="item.estimatedOrderQuantity">
                <p-dropdown (ngModelChange)="onProcurementValueChange(i)" [options]="quantityUOMs" class="ml-10"
                  [(ngModel)]="item.estimatedOrderQuantityUom"
                  [style]="{'minWidth':'30px', 'width': '100px','margin-left':'2px'}" 
                  optionLabel="uomDisplay"
                  placeholder="Select" ></p-dropdown>
                  <p-message _ngcontent-bob-c218="" severity="error" text="Quantity Needed is invalid" *ngIf="isQuantityNeededInvalid"
                    ng-reflect-severity="error" ng-reflect-text="Quantity Needed is invalid" class="ng-star-inserted">
                    <div aria-live="polite" class="p-inline-message p-component p-inline-message-error ng-star-inserted"
                      ng-reflect-ng-class="[object Object]"><span class="p-inline-message-icon pi pi-times-circle"
                        ng-reflect-ng-class="pi pi-times-circle"></span><span class="p-inline-message-text ng-star-inserted">Quantity Needed is
                        invalid</span>
                    </div>
                  </p-message>
              </div>
            </div>
            <div class="col-md-2">
              <label style="font-weight: bold;">Status</label>
              <div>
                <p-dropdown (ngModelChange)="onProcurementValueChange(i)" [options]="item.orderStatusDropdown"
                  optionLabel="status" placeholder="Select" [(ngModel)]="item.orderStatus"
                  (onClick)="filterstatus(item)"></p-dropdown>
              </div>
            </div>
          </div>
          <div class="order-details" *ngIf="item.isOrderTabOpen">
            <div class="procurement type">
              <p-header (click)="item.isProcurementTabOpen =  !item.isProcurementTabOpen" class="header"
              [ngStyle]="{'background-color':(i%2==0 ? '#b77093' : '#dddddd'),'color':(i%2==0 ? 'white' : 'black')}"
              >
                <div class="row">
                  <div class="col-md-12">

                    <div style="line-height: 30px;padding-left:10px; font-weight: bold;"><i class="pi"
                        [ngClass]="item.isProcurementTabOpen ?'pi-caret-down':'pi-caret-right'"></i>Procurement
                      Information</div>
                  </div>
                </div>
              </p-header>
              <div *ngIf="item.isProcurementTabOpen" class="form-group row details ">
                <div class="col-md-2">
                  <label style="font-weight: bold;">Supplier/Plant code</label>
                  <div>
                    <input type="text" (ngModelChange)="onProcurementValueChange(i)" pInputText class="ui-inputtext"
                      [(ngModel)]="item.supplierPlantCode">
                  </div>
                </div>
                <div class="col-md-3">
                  <label style="font-weight: bold;">Order Quantity</label>
                  <div>
                    <input type="number" (ngModelChange)="onProcurementValueChange(i)" pInputText class="ui-inputtext"
                      [style]="{'width':'40%'}" [(ngModel)]="item.quantityOrdered" (keyup)="orderQuantityFilter($event, item.quantityOrdered)">
                    <p-dropdown (ngModelChange)="onProcurementValueChange(i)" [options]="quantityUOMs" class="ml-10"
                      [(ngModel)]="item.orderQuantityUom"
                      [style]="{'minWidth':'30px', 'width': '100px','margin-left':'2px'}" optionLabel="uomDisplay"
                      placeholder="Select"></p-dropdown>
                      <p-message _ngcontent-bob-c218="" severity="error" text="Order Quantity is invalid" *ngIf="isOrderQuantityInvalid"
                        ng-reflect-severity="error" ng-reflect-text="Order Quantity is invalid" class="ng-star-inserted">
                        <div aria-live="polite" class="p-inline-message p-component p-inline-message-error ng-star-inserted"
                          ng-reflect-ng-class="[object Object]"><span class="p-inline-message-icon pi pi-times-circle"
                            ng-reflect-ng-class="pi pi-times-circle"></span><span class="p-inline-message-text ng-star-inserted">Order Quantity is
                            invalid</span>
                        </div>
                      </p-message>
                  </div>
                </div>
                <div class="col-md-2">
                  <label style="font-weight: bold;">Date Ordered</label>
                  <div>
                    <p-calendar id="DateOrdered" (ngModelChange)="onProcurementValueChange(i)" name="DateNeeded"
                      [(ngModel)]="item.orderSubmissionDate" [minDate]="minDate" [showIcon]="true"
                      dateFormat="dd-M-yy"></p-calendar>
                  </div>
                </div>
                <div class="col-md-3">
                  <label style="font-weight: bold;">Order # / Conf #</label>
                  <div>
                    <input type="text" (ngModelChange)="onProcurementValueChange(i)" pInputText class="ui-inputtext"
                      [(ngModel)]="item.orderConfirmationNumber">
                  </div>
                </div>
              </div>
            </div>
            <div class="forecast type" >
              <p-header (click)="item.isForecastTabOpen =  !item.isForecastTabOpen" class="header"
              [ngStyle]="{'background-color':(i%2==0 ? '#b77093' : '#dddddd'),'color':(i%2==0 ? 'white' : 'black')}">
                <div class="row">
                  <div class="col-md-12">
                    <div style="line-height: 30px;padding-left:10px; font-weight: bold;"><i class="pi"
                        [ngClass]="item.isForecastTabOpen ?'pi-caret-down':'pi-caret-right'"></i>Forecast/Request
                      Details</div>
                  </div>
                </div>
              </p-header>
              <ng-container  *ngIf="item.isForecastTabOpen">
              <div class="form-group row details">
                <div class="col-md-1 header-column">
                  <label style="font-weight: bold;">Country</label>
                </div>
                <div class="col-md-1 quantity header-column">
                  <label style="font-weight: bold;">Quantity</label>
                </div>
                <div class="col-md-1 header-column">
                  <label style="font-weight: bold;">Date Needed</label>
                </div>
                <div class="col-md-2 header-column">
                  <label style="font-weight: bold;">Alternative Product</label>
                </div>
                <div class="col-md-2 header-column">
                  <label style="font-weight: bold;">Date Received</label>
                </div>
                <div class="col-md-2 header-column">
                  <label style="font-weight: bold;">Requester</label>
                </div>
                <div class="col-md-1 header-column">
                  <label style="font-weight: bold;">Order Type</label>
                </div>
                <div class="col-md-1 header-column">
                  <label style="font-weight: bold;">Request Number</label>
                </div>
                <div class="col-md-1 header-column">
                  <label style="font-weight: bold;">Forecast Number</label>
                </div>
              </div>
              <div *ngFor="let ordDest of item.orderItemDestination">
                <div>
                  <div *ngFor="let ordForcastDest of ordDest.orderItemForecastDestination">
                    <div class="form-group row details">
                      <div class="col-md-1 col-value">
                        <div>{{ordDest.countryName}}</div>
                      </div>
                      <div class="col-md-1 col-value quantity">
                        <div>{{ordForcastDest.quantity}} {{ordForcastDest.uomDisplay}}</div>
                      </div>
                      <div class="col-md-1 col-value">
                        <div>{{ordForcastDest.dateNeeded | date: "dd-MMM-yyyy"}}</div>
                      </div>
                      <div class="col-md-2 col-value">
                        <div>{{ordForcastDest.alternativeProduct}}</div>
                      </div>
                      <div class="col-md-2 col-value">
                        <div>{{ordForcastDest.requestSubmissionDate | date: "dd-MMM-yyyy"}}</div>
                      </div>
                      <div class="col-md-2 col-value">
                        <div>{{ordForcastDest.requesterName}}</div>
                      </div>
                      <div class="col-md-1 col-value">
                        <div>{{ordForcastDest.orderType?.orderType1}}</div>
                      </div>
                      <div class="col-md-1 col-value">
                        <div>
                          {{ordForcastDest.rdsimsRequestNumber}}
                        </div>
                      </div>
                      <div class="col-md-1 col-value">
                        <div>
                          {{ordForcastDest.forecastItemId}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="paginatorleft" let-state id="paginatorleft2">
        <span class="ui-paginator-first" style="padding-left:10px;"> Total Items : {{totalRecords}}
          {{ totalRecords == orderItems.length ? '' : '(Showing Items: '+ orderItems.length + ')'}} </span>
      </ng-template>
      <ng-template pTemplate="paginatorright" id="paginatorright2" let-state>
        <span class="ui-paginator-first" style="padding-right:10px;">{{((pageIndex - 1) * pageItemNumber) + 1}} -
          {{(orderItems.length !== pageItemNumber ? totalRecords : pageIndex * pageItemNumber)}} of {{totalRecords}} Items</span>
      </ng-template>
    </p-dataView>
  </div>
</div>
<div class="form-group row mt-15" *ngIf="orderItems.length > 0">
  <div class="col-md-5"></div>
  <div class="col-md-7">
    <div style="float:right">
     <button pButton type="button" label="Expand All" (click)="expandAll()"></button>
     <button pButton type="button" label="Collapse All" (click)="collapseAll()" style="margin-left: 15px;" ></button>
    <button pButton type="button" id="btnSaveTop" style="margin-left: 15px;" 
     label="Save" [disabled]="!isFormChanged || isAdditionalQuantityInvalid || isQuantityNeededInvalid || isOrderQuantityInvalid" (click)="saveOrders()" ></button>     
    <button [disabled]="!isFormChanged" pButton type="button" style="margin-left: 15px;" id="btnCancelTop"
     label="Cancel" (click)="discardChanges()" ></button>
    </div>
  </div>
</div>
<p-toast></p-toast>
