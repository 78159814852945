import { Injectable, ErrorHandler } from '@angular/core';
import { MonitoringService} from '../services/monitor.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  constructor(private monitoringService: MonitoringService) {
  }

  handleError(error: Error) {
      this.monitoringService.logException(error); // Manually log exception
  }
}