
<div style="margin-left:10px;width: 610px; ">
  <div class="grid">
  <div class="col-8">
      <h4 style="font-weight: bold;">Quantity Units of Measure</h4>
  </div>
  <div class="col-4">
    <button pButton type="button" id="btnCreateUOMQuantity" label="Add Unit of Measure" icon="fa fa-plus-square" iconPos="right" 
    (click)="CreateUOMQuantity()" style="float: right;"></button>
  </div>
  </div>
  <div class="grid">
    <div class="col-12">
      <ag-grid-angular id="agGridCreateUOMQuantity"
        style="width: 610px; height: 500px;"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [frameworkComponents]="frameworkComponents"
        [context]="context"
        (gridReady)="onGridReady($event)"
        [pagination]="true"
        [paginationPageSize]="25"
        (rowDoubleClicked)='EditUOMQuantity($event)'
        >
      </ag-grid-angular>
    </div>
  </div>
</div>
<p-toast></p-toast>

<p-dialog header="{{headerText}}" [(visible)]="displayDialog"
  [draggable]="true"
 [style]="{'min-width':'700px'}" [modal]="true"
    [dismissableMask]="true">
  <form>
    <div class="form-group row" hidden>
      <div class="col-md-3"><label for="Id">Id</label></div>
      <div class="col-md-6"><input type="text" id="txtCreateUOMQuantityId" name="Id" placeholder="Id"
              [(ngModel)]="quantityUomModel.id" pInputText class="col-md-12"></div>
      <div class="col-md-3">
          <!--Validation Errors-->
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="UomDisplay">Abbreviation</label></div>
      <div class="col-md-6"><input type="text" id="txtQuantityUomDisplay" name="UomDisplay" placeholder="Abbreviation"
        [(ngModel)]="quantityUomModel.uomDisplay" pInputText class="col-md-12" required></div>
      <div class="col-md-3">
          <!--Validation Errors-->
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="Description">Description</label></div>
      <div class="col-md-6"><input type="text" id="txtQuantityUOMDescription" name="Description" placeholder="Description"
                [(ngModel)]="quantityUomModel.description" pInputText class="col-md-12"></div>
      <div class="col-md-3">
            <!--Validation Errors-->
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="isEditable">Is Enabled</label></div>
      <div class="col-md-6"><p-checkbox id="chkQuantityUOMIsEnabled" inputId="{{quantityUomModel.id}}" name="quantityCheckboxGroup"
        [(ngModel)]="quantityUomModel.isEnabled" binary="true"  
         ></p-checkbox></div>
      <div class="col-md-3">
          <!--Validation Errors-->
      </div>
    </div>
  </form>
  <p-footer>
      
    <button type="button" pButton icon="fa fa-check" (click)="save()" id="btnCreateUOMQuantitySave" label="Save"   [disabled]="quantityUomModel?.uomDisplay == null || quantityUomModel.uomDisplay.length == 0"></button>
    <!-- <button type="button" pButton icon="fa fa-save" (click)="update()" *ngIf="isEditable" label="Update"></button> -->
    <!--<button type="button" pButton icon="fa-remove" (click)="Delete()" [hidden]="!isEditable" label="Delete"></button>-->
    <!--This delete moved into grid column beside edit button.-->
    <button type="button" pButton icon="fa fa-close" (click)="cancel()" id="btnCreateUOMQuantityCancel" label="Cancel"></button>
  </p-footer>
</p-dialog>


