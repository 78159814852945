import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { ConfirmationService } from "primeng/api";
import { MessageService } from 'primeng/api';
import { PlannerItem, MaterialPlanningClient, UserModel, SearchFormulaModel, 
  ForecastStateModel, ForecastStateClient, UserClient, SearchCriteriaModel, 
  SearchClient, InventoryStateModel, ForecastItemDestinationModel, MaterialPlannerRequestModel,
  InventoryStateClient } from '../services/forecast-api-client.service';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DataViewModule, DataView } from 'primeng/dataview';
import { PanelModule } from 'primeng/panel';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Filter } from '../models/filter.model';
import { AppComponent } from '../app.component';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-submitted-forecasts-new',
  templateUrl: './submitted-forecasts-new.component.html',
  styleUrls: ['./submitted-forecasts-new.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class SubmittedForecastsNewComponent implements OnInit {
  openAccordion:boolean= true;
  public isMaterialPlanner: boolean;
  CurrentFilter:SearchFormulaModel;
  isAccordionOpen:boolean= false; 
  searchFilters:Array<SearchFormulaModel>;
  defaultFilter:SearchFormulaModel ;
  selectedFilter:SearchFormulaModel = new SearchFormulaModel(); 
  inventoryStates:Array<InventoryStateModel>;
  wipInventoryStates: Array<InventoryStateModel>;
  allInventoryStates: Array<InventoryStateModel>;
  IsDesc:boolean = true; 
  isSortOrderDesc :boolean = true;
  filteredCountries:any[];
  orderBy:any[] = [{name: 'Select', code: 0},{name: 'Date Range', code: 1} , {name: 'Material', code: 2}
,{name: 'Product Type', code: 3}, {name: 'Status', code: 4}, {name: 'Earliest Date Needed', code: 4}];
  countryModels:any;
filteredMaterialPlanners:any[];
DefaultFilterId:number = 2;
defaultState:InventoryStateModel;
newState:InventoryStateModel;
 materialPlanners:any[]=[];
 users:any[]=[];
 filteredUsers:any[];
  indexes = [];
  public plannerItems: Array<PlannerItem> = [];
  public plannerNonFilteredItems: Array<PlannerItem> = [];
  public assignPlanner: PlannerItem=new PlannerItem;
  public user: UserModel;
  filterForm: UntypedFormGroup;
  
  searchCriteriaList:SearchCriteriaModel[];
  filter: any = {
    Status: null,
    MaterialPlannerUserId: null,
    DateNeeded: null,
    Material: null,
    Name: null ,
    Country: null,
    RequesterId : null,
    ForecastNumber: 0,
    Id: 0,
    sortOrder : null
  };
  totalRecords: number;
  pageIndex: number = 1;
  pageSize: number = 20;
  first: number = 0;

  AllMaterials: SearchFormulaModel;
  MaterialsAssignedToMe: SearchFormulaModel;
  WIPAllMaterials: SearchFormulaModel;
  AllMaterialsAssignedToMe : SearchFormulaModel;
  @ViewChild('dv') dataView: DataView;
  constructor(
    public materialPlanningClient: MaterialPlanningClient,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute,
    public securityService: SecurityService,
    public confirmationService: ConfirmationService,
    public appComponent: AppComponent,
    public messageService: MessageService,
    public userClient: UserClient,
    public fb: UntypedFormBuilder,
    public searchClient: SearchClient,
    public inventoryStateClient: InventoryStateClient,
    public router: Router) {
    }

  ngOnInit() {
   // this.GetForm();
   this.appComponent.currentSelection = 'submitted-forecasts-new';
   //this.getMaterialPlannerUserlist();
    this.GetForm();
    this.activatedRoute.data
    .subscribe((allData) => {
      console.log(allData);
      this.countryModels = allData.masterData.CountryModel;
       //this.inventoryStates = allData.masterData.InventoryStates;
       //this.inventoryStates.splice(0,0,this.newState);
       this.materialPlanners = allData.masterData.MaterialPlanners;
       this.materialPlanners.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
       this.users = allData.masterData.Users;
       this.users.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
       this.user = this.securityService.getUser();
       this.searchFilters = allData.masterData.SearchFormulas;
       this.AllMaterials = new SearchFormulaModel();
          
       this.AllMaterials.name="All Materials";
       this.AllMaterials.searchCriteria = [];
      this.searchFilters.splice(0,0,this.AllMaterials);

      this.WIPAllMaterials = new SearchFormulaModel();
          
       this.WIPAllMaterials.name="WIP - Materials";
       this.WIPAllMaterials.searchCriteria = [];
      this.searchFilters.splice(0,0,this.WIPAllMaterials);

      this.MaterialsAssignedToMe = new SearchFormulaModel();
      this.MaterialsAssignedToMe.name="WIP - Materials Assigned To Me";
      this.MaterialsAssignedToMe.searchCriteria = [];
       this.searchFilters.splice(0,0,this.MaterialsAssignedToMe);

       this.AllMaterialsAssignedToMe = new SearchFormulaModel();
      this.AllMaterialsAssignedToMe.name="All Materials Assigned To Me";
      this.AllMaterialsAssignedToMe.searchCriteria = [];
       this.searchFilters.splice(0,0,this.AllMaterialsAssignedToMe);

       this.defaultFilter = this.WIPAllMaterials;
       this.selectedFilter = this.WIPAllMaterials;

        this.getMasterPlannings();
        this.getInventoryStateList();

      });

  }

  pageChanged(event: any) {
    this.pageIndex = (event.first / this.pageSize) + 1;
    this.getMasterPlannings(false);
  }

  getInventoryStateList() {
    this.newState = new ForecastStateModel();
    this.newState.stateName = "New";
    this.newState.id = -1;
    this.newState.description = "";
    this.inventoryStateClient.getInventoryStates()
     .subscribe(data => {
    //  data.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
      console.log(data);
      data.splice(0,0,this.newState);
      this.allInventoryStates = data;
      this.wipInventoryStates = data.filter(a => a.stateName != 'Cancelled' && a.stateName != 'Outsourced' && a.stateName != 'In Stock');
      this.inventoryStates = this.wipInventoryStates; 
    });
  }  

  getMasterPlannings(setCount: boolean = true) {
    this.GetFormData();
    var materialPlannerRequest = new MaterialPlannerRequestModel();
    if(this.selectedFilter.name ==  this.MaterialsAssignedToMe.name ||this.selectedFilter.name == this.WIPAllMaterials.name )
    {
      materialPlannerRequest.filterId = this.DefaultFilterId;
    }
    else
    {
      materialPlannerRequest.filterId =1;
    }
    
    materialPlannerRequest.pageIndex = setCount ? 1 : this.pageIndex;
    materialPlannerRequest.pageSize = this.pageSize;
    materialPlannerRequest.materialPlannerUserId = this.filter.MaterialPlannerUserId;
    materialPlannerRequest.materialName = this.filter.Material;
    materialPlannerRequest.country = this.filter.Country?.displayName;
    materialPlannerRequest.forecasterId = this.filter.Requester?.id;
    materialPlannerRequest.forecastNumber = this.filter.ForecastNumber;
    materialPlannerRequest.sortOrder = this.filter.sortOrder;
    materialPlannerRequest.inventoryState = this.filter.Status;
      materialPlannerRequest.isASC = !this.isSortOrderDesc;
    if(this.filter.DateNeeded != null)
    {
      if(this.filter.DateNeeded[0] != null)
      materialPlannerRequest.fromDate = this.filter.DateNeeded[0].toDateString();
      if(this.filter.DateNeeded[1] != null)
      {
        materialPlannerRequest.toDate = this.filter.DateNeeded[1].toDateString();
      }
    }
    
    this.materialPlanningClient.getMaterialPlanningGroups(materialPlannerRequest)
      .subscribe((data: any) => {
        console.log("plannerItem", data);
        this.isMaterialPlanner = this.user.roles.some(r => r.name === 'Material Planner');
        this.plannerItems = data.plannerItems;
        if (setCount ) {
          this.pageIndex = 1;
          this.totalRecords = data.totalCount;
          this.dataView.first = 0;
        }
        this.plannerNonFilteredItems = data.plannerItems;
        // this.ResetForm();
        // this.ApplySelectedFilter(null);
      },
        error => {
        }
      );
  }

  getMaterialPlannerUserlist()
  {
    this.userClient.getUsersInRoles(['Material Planner'])
     .subscribe(data => {
    //  data.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
    data = data.sort((a, b) => a.fullName > b.fullName ? 1 : -1);
    this.materialPlanners = data; 
     });
  }
  GetForm()
  {
    this.filterForm = this.fb.group({
      'id' :new UntypedFormControl(0),
        'Status': new UntypedFormControl(''),
        'Country': new UntypedFormControl(''),
      'MaterialPlannerUserId': new UntypedFormControl(''),
      'DateNeeded': new UntypedFormControl(''),
      'Material': new UntypedFormControl(''),
      'ForecastNumber' :new UntypedFormControl(''),
      'Requester': new UntypedFormControl(''),
      'OrderBy': new UntypedFormControl(''),
      'Name': new UntypedFormControl('',Validators.required)  
    });
  }
  ClearAll()
  {
   this.selectedFilter = this.defaultFilter;
  this.ApplySelectedFilter(null);
  }
  ResetForm()
  {
    
 
(<UntypedFormControl>this.filterForm.controls['id'])
.setValue(null , { onlySelf: true });
      (<UntypedFormControl>this.filterForm.controls['Material'])
      .setValue( null , { onlySelf: true });
      (<UntypedFormControl>this.filterForm.controls['MaterialPlannerUserId'])
      .setValue( null , { onlySelf: true });  
     (<UntypedFormControl>this.filterForm.controls['DateNeeded'])
        .setValue( null , { onlySelf: true });
        let value = this.inventoryStates.find(s => s.id == 0);
     (<UntypedFormControl>this.filterForm.controls['Status'])
        .setValue(value, { onlySelf: true });
     (<UntypedFormControl>this.filterForm.controls['Name'])
       .setValue(null, { onlySelf: true });  
       (<UntypedFormControl>this.filterForm.controls['Country'])
        .setValue( null , { onlySelf: true });
        (<UntypedFormControl>this.filterForm.controls['ForecastNumber'])
        .setValue( null , { onlySelf: true });
        (<UntypedFormControl>this.filterForm.controls['Requester'])
        .setValue( null , { onlySelf: true });
        var select = this.orderBy.find(x => x.code == 0);
        (<UntypedFormControl>this.filterForm.controls['OrderBy'])
        .setValue( select , { onlySelf: true })
       
  }
  filterMaterialPlanners(event) {
    let query = event.query;
    
        this.filteredMaterialPlanners =  [];
    for(let i = 0; i < this.materialPlanners.length ; i++) {
        let materialPlanner = this.materialPlanners[i];
        if (materialPlanner.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            this.filteredMaterialPlanners.push(materialPlanner);
        }
    }
     
  }
  filterUsers(event)
  {
    let query = event.query;
    
        this.filteredUsers =  [];
    for(let i = 0; i < this.users.length ; i++) {
        let user = this.users[i];
        if (user.secondaryEmail.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            this.filteredUsers.push(user);
        }
    }
  }
  filterCountry(event) {
    let query = event.query;
    
    this.filteredCountries =  [];
for(let i = 0; i < this.countryModels.length; i++) {
    let country = this.countryModels[i];
    if (country.displayName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        this.filteredCountries.push(country);
    }
}
     
  }
  StopDefaults()
  {
    this.openAccordion = false;
  }
  StopOpening(event)
  {
    event.stopPropagation();
    event.preventDefault();
  }
  AllowOnlyIntegers(e)
  {
    if(/^[0-9\s]*$/.test(e.key)) 
              {
                return true;
              }
            else
              {
                e.preventDefault();
                return false;
              }
  }
  setPlannerItemStatus(item){
    if(item.isOrderTabOpen){
      item.isOrderTabOpen = false;
    } else{
      item.isOrderTabOpen = true
      item.isProcurementTabOpen = true;
      item.isForecastTabOpen = false;
    }
  }
  
  myFunction(e, index){
    //alert("I need to expand/collpase");
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    const idx = this.indexes.findIndex(i => i === index);
    if (idx < 0) {
      this.isAccordionOpen = true;
      this.indexes.push(index);
    } else {
      this.isAccordionOpen = false;
      this.indexes.splice(idx, 1);
    }
    this.indexes = [ ...this.indexes ];
    
  }

  collapseAll() {
    this.plannerItems.forEach(a => {a['isOrderTabOpen'] = false; a['isProcurementTabOpen'] = false;});
  }
  expandAll() {
    this.plannerItems.forEach(a => {a['isOrderTabOpen'] = true; a['isProcurementTabOpen'] = true;});
  }
  GetFormData()
  {
    this.filter.Status =(this.filterForm.get('Status').value ==  null || this.filterForm.get('Status').value.length == 0)  ? null : this.filterForm.get('Status').value   ;
    this.filter.MaterialPlannerUserId = (this.filterForm.get('MaterialPlannerUserId').value == null || this.filterForm.get('MaterialPlannerUserId').value.length == 0) ? null : this.filterForm.get('MaterialPlannerUserId').value.id;
    this.filter.DateNeeded = (this.filterForm.get('DateNeeded').value == null || this.filterForm.get('DateNeeded').value.length == 0) ? null : this.filterForm.get('DateNeeded').value;
    this.filter.Material = (this.filterForm.get('Material').value == null || this.filterForm.get('Material').value.length == 0) ? null : this.filterForm.get('Material').value;
    this.filter.Name = (this.filterForm.get('Name').value == null || this.filterForm.get('Name').value.length == 0) ? null : this.filterForm.get('Name').value;
    this.filter.Id = (this.filterForm.get('id').value == null || this.filterForm.get('id').value == 0) ? 0 : this.filterForm.get('id').value;
    this.filter.Country= (this.filterForm.get('Country').value == null || this.filterForm.get('Country').value.length == 0) ? null : this.filterForm.get('Country').value;
    this.filter.ForecastNumber= (this.filterForm.get('ForecastNumber').value == null || this.filterForm.get('ForecastNumber').value == 0) ? null : this.filterForm.get('ForecastNumber').value;
    this.filter.Requester= (this.filterForm.get('Requester').value == null || this.filterForm.get('Requester').value.length == 0) ? null : this.filterForm.get('Requester').value;
    this.filter.sortOrder = (this.filterForm.get('OrderBy').value == null || this.filterForm.get('OrderBy').value.length == 0) ? null : this.filterForm.get('OrderBy').value.name;  
    if(this.filter.sortOrder =='Select')
    {
      this.filter.sortOrder = null;
    }
    if(this.selectedFilter.name ==  this.MaterialsAssignedToMe.name ||this.selectedFilter.name == this.AllMaterialsAssignedToMe.name )
    {
      this.filter.MaterialPlannerUserId =   this.user.id;
    }
  }

  SortPlanningItems(isDesc:boolean)
  {
    this.isSortOrderDesc = isDesc;
    this.getMasterPlannings();
  }

  updatePlannerItemStatus(higherStatus: string, plannerItem: PlannerItem) { 
    if(!plannerItem.materialPlannerUserId) {
      plannerItem.isNew = true;
    }
    plannerItem.higherStatus = higherStatus;
  }

  
  setMaterialPlanner(materialPlannerUserId: any, plannerItem : PlannerItem){
    if(plannerItem.materialPlannerUserId == null){
      plannerItem.materialPlannerUserId = materialPlannerUserId;
    }
    this.materialPlanningClient.assignMaterialGroup(plannerItem).subscribe(data => {
      //this.plannerItems.filter(x => x.seedForecastItemId == plannerItem.seedForecastItemId)[0].materialPlannerUserId = event.value ? event.value.id : null;
      this.messageService.add({severity: this.commonService.configurations.MessageServiceSeveritySuccess,
        summary:'Success', detail:'Material planner assigned successfully'});
        plannerItem.isNew = false;
      },
    error => {
      this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError,
        summary: 'Assign Error', detail: 'Error assigning material planner.' });
      });
  }

  ApplySelectedFilter($event) {
    if($event != null)
        $event.stopPropagation()
    if (this.selectedFilter.name.startsWith('WIP')) {
      this.inventoryStates = this.wipInventoryStates;
    }
    else {
      this.inventoryStates = this.allInventoryStates;
    }
    this.ResetForm();
    (<UntypedFormControl>this.filterForm.controls['Name'])
      .setValue(this.selectedFilter.name, { onlySelf: true });
     if (this.selectedFilter.name != this.AllMaterialsAssignedToMe.name && this.selectedFilter.name != this.MaterialsAssignedToMe.name &&  this.selectedFilter.name !=this.WIPAllMaterials.name  &&  this.selectedFilter.name !=this.AllMaterials.name )
    {
    (<UntypedFormControl>this.filterForm.controls['id'])
      .setValue(this.selectedFilter.id, { onlySelf: true });

    for (var i = 0; i < this.selectedFilter.searchCriteria.length; i++) {
      switch (this.selectedFilter.searchCriteria[i].criteriaName) {
        case "Status":
          var arr = this.selectedFilter.searchCriteria[i].criteriaValue.split(',');
          var selectedInventory = [];
          for(let i=0; i< arr.length; i++) {
            var value = this.inventoryStates.find(s => s.id == parseInt( arr[i]));
            selectedInventory.push(value);
          }
         
          (<UntypedFormControl>this.filterForm.controls['Status'])
            .setValue(selectedInventory, { onlySelf: true });
          break;
        case "MaterialPlannerUserId":
          var material = this.materialPlanners.find(r => r.id == parseInt( this.selectedFilter.searchCriteria[i].criteriaValue));
         
          (<UntypedFormControl>this.filterForm.controls['MaterialPlannerUserId'])
            .setValue(material, { onlySelf: true }); 
            break;
        case "DateNeeded":
          var dateNeeded = [];
          dateNeeded.push(null);
          dateNeeded.push(null);
          if(this.selectedFilter.searchCriteria[i].criteriaValue.indexOf('-') > 0)
          {
            dateNeeded[0] = new Date(this.selectedFilter.searchCriteria[i].criteriaValue.split('-')[0])
            dateNeeded[1] = new Date(this.selectedFilter.searchCriteria[i].criteriaValue.split('-')[1])
          }
          else
          {
            dateNeeded[0] = new Date(this.selectedFilter.searchCriteria[i].criteriaValue)
          }  
        (<UntypedFormControl>this.filterForm.controls['DateNeeded'])
            .setValue( dateNeeded, { onlySelf: true });
          break;
          case "Material":
          (<UntypedFormControl>this.filterForm.controls['Material'])
            .setValue(this.selectedFilter.searchCriteria[i].criteriaValue, { onlySelf: true });
          break;
          case "Country":
            var country = this.countryModels.find(r => r.displayName ==  this.selectedFilter.searchCriteria[i].criteriaValue);
          (<UntypedFormControl>this.filterForm.controls['Country'])
            .setValue(country, { onlySelf: true });
          break;
          case "Requester":
            var requester = this.users.find(r => r.id ==  this.selectedFilter.searchCriteria[i].criteriaValue);
          (<UntypedFormControl>this.filterForm.controls['Requester'])
            .setValue(requester, { onlySelf: true });
          break;
          case "ForecastNumber":
          (<UntypedFormControl>this.filterForm.controls['ForecastNumber'])
            .setValue(this.selectedFilter.searchCriteria[i].criteriaValue, { onlySelf: true });
          break;
          case "SortOrder":
            var sortFilter =this.selectedFilter.searchCriteria[i].criteriaValue.split('-');
             var sortOrder = this.orderBy.find(x => x.name == sortFilter[0]);
             if(sortFilter[1]== 'DESC')
             {
               this.isSortOrderDesc = true;
               this.IsDesc = true;
             }
             else{
              this.isSortOrderDesc = false;
               this.IsDesc = false;
             }
          (<UntypedFormControl>this.filterForm.controls['OrderBy'])
            .setValue(sortOrder, { onlySelf: true });
          break;
      
          
      }

    }
    console.log(this.selectedFilter)

    }
    if (this.CurrentFilter != null && (this.CurrentFilter.name == this.WIPAllMaterials.name || this.selectedFilter.name == this.WIPAllMaterials.name || this.CurrentFilter.name == this.MaterialsAssignedToMe.name || this.selectedFilter.name == this.AllMaterialsAssignedToMe.name)) {
    //this.getMasterPlannings();
    this.CurrentFilter = this.selectedFilter;
    this.FilterSubmittedForecast(false,true);
  }

    else {
      this.CurrentFilter = this.selectedFilter;
      this.getMasterPlannings();
      //this.FilterSubmittedForecast(false);
    }
  }

  FilterSubmittedForecast(isSave: boolean, serverSideFilter:boolean=false) {
    this.plannerItems = this.plannerNonFilteredItems;
    var searchFormula = new SearchFormulaModel();
    var searchCriteria = new SearchCriteriaModel();
    this.searchCriteriaList = [];
    this.GetFormData();
    if(this.selectedFilter.name ==  this.MaterialsAssignedToMe.name ||this.selectedFilter.name == this.AllMaterialsAssignedToMe.name )
    {
      this.filter.MaterialPlannerUserId =   this.user.id;
    }
     
    if(isSave){
      searchFormula.name=this.filterForm.get('Name').value ;
      searchFormula.id= this.filter.Id == undefined ? 0 : this.filter.Id;
      searchFormula.ownerId = this.user.id;
      searchFormula.enabled = true;
      searchFormula.isShared = false;
      searchFormula.dashboard ="SubmittedForecast";
      searchFormula.searchCriteria = [];
    }

    if(serverSideFilter){
      this.getMasterPlannings(true);
    }

    if (this.filter.Status != null && this.filter.Status.stateName != "Any") {
      // this.plannerItems = this.plannerItems
      //   .filter((element: PlannerItem) =>
      //     element.destinations.some((forecastDestination: ForecastItemDestinationModel) =>
      //       forecastDestination.inventoryOrderStateId == this.filter.Status.id));

      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Status";
      var statusArrary=[];
      this.filter.Status.forEach(function(value){
        statusArrary.push(value.id);
      });
      searchCriteria.criteriaValue = statusArrary.toString();
      this.searchCriteriaList.push(searchCriteria);
    }

    if (this.filter.MaterialPlannerUserId != null) {
      // this.plannerItems = this.plannerItems.filter(x => x.materialPlannerUserId == this.filter.MaterialPlanner.id)
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "MaterialPlannerUserId";
      searchCriteria.criteriaValue = this.filter.MaterialPlannerUserId.toString();
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.DateNeeded != null) {
      // this.plannerItems = this.plannerItems.filter(x =>
      //   new Date(x.dateFrom.setHours(0, 0, 0, 0)) <= new Date(this.filter.DateNeeded.setHours(0, 0, 0, 0))
      //   && new Date(x.dateTo.setHours(0, 0, 0, 0)) >= new Date(this.filter.DateNeeded.setHours(0, 0, 0, 0))
      // )
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "DateNeeded";
      
     
      if(this.filter.DateNeeded[0] != null)
      searchCriteria.criteriaValue  = this.filter.DateNeeded[0].toDateString();
      if(this.filter.DateNeeded[1] != null)
      {
        searchCriteria.criteriaValue  =searchCriteria.criteriaValue  + '-' + this.filter.DateNeeded[1].toDateString();
      }
    
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.Material != null) {
      // this.plannerItems = this.plannerItems.filter(x => x.materialName.toLowerCase().indexOf(this.filter.Material.toLowerCase()) >= 0)
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Material";
      searchCriteria.criteriaValue = this.filter.Material;
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.Country != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Country";
      searchCriteria.criteriaValue = this.filter.Country?.displayName;
      this.searchCriteriaList.push(searchCriteria);
    }

    if (this.filter.ForecastNumber != null) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "ForecastNumber";
      searchCriteria.criteriaValue = this.filter.ForecastNumber.toString();
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.Requester != null && this.filter.Requester != undefined) {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Requester";
      searchCriteria.criteriaValue = this.filter.Requester.id.toString();
      this.searchCriteriaList.push(searchCriteria);
    }
    if (this.filter.sortOrder != null) {
      let sortType = this.isSortOrderDesc ? "DESC" : "ASC";
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "SortOrder";
      searchCriteria.criteriaValue = this.filter.sortOrder.toString()+'-'+ sortType;
      this.searchCriteriaList.push(searchCriteria);
    }
    
    
    if (isSave) {
      if (this.searchCriteriaList.length <= 0) {
        this.messageService.add({ severity: 'error', summary: 'Confirmed', detail: 'Please select atleast one search criteria' });
        return;
      }
      searchFormula.searchCriteria = this.searchCriteriaList;
      if (searchFormula.id == 0) {
        this.searchClient.createSearchFormula(searchFormula).subscribe(data => {
          this.searchClient.getSubmittedForecastSearchFormulas(this.user.id).subscribe(data => {
            this.searchFilters = data;
            this.searchFilters.splice(0, 0, this.AllMaterials);
            this.searchFilters.splice(0, 0, this.MaterialsAssignedToMe);
            this.searchFilters.splice(0, 0, this.WIPAllMaterials);
            this.searchFilters.splice(0, 0, this.AllMaterialsAssignedToMe);
            searchFormula = data.filter(x => x.name == searchFormula.name)[0];
            this.selectedFilter = searchFormula;
          });
        });
      }
      else {
        searchFormula.searchCriteria.forEach(ele => ele.searchFormulaId = searchFormula.id)
        this.searchClient.updateSearchFormula(searchFormula.id, searchFormula).subscribe(data => {
          this.searchClient.getSubmittedForecastSearchFormulas(this.user.id).subscribe(data => {
            this.searchFilters = data;
            this.searchFilters.splice(0, 0, this.AllMaterials);
            this.searchFilters.splice(0, 0, this.MaterialsAssignedToMe);
            this.searchFilters.splice(0, 0, this.WIPAllMaterials);
            this.searchFilters.splice(0, 0, this.AllMaterialsAssignedToMe);
            searchFormula = data.filter(x => x.name == searchFormula.name)[0];
            this.selectedFilter = searchFormula;

          });

        });
      }
    }
    this.dataView.first = 0;

  }
  onPlannerItemDetail(plannerItem: PlannerItem,e) {
    this.router.navigate(['/submitted-forecast-detail-new/' + plannerItem.seedForecastItemId + '/'
      + plannerItem.formulationTypeId + '/0']);
      e.stopPropagation();
  }
  getSelectedmaterialPlanner(materialPlannerId:number)
  {
    if(materialPlannerId  == null)
    {
      return null;
    }
    else
    {
      return this.materialPlanners.filter(x => x.id == materialPlannerId)[0];
        }
  }
  setSelectedMaterialPlanner(event, plannerItem:PlannerItem)
  {
    this.assignPlanner.materialName=plannerItem.materialName;
    this.assignPlanner.seedForecastItemId = plannerItem.seedForecastItemId;
    this.assignPlanner.formulationTypeId=plannerItem.formulationTypeId;
    let message: string = '';
    let errorTitle: string = '';
    let errorMessage: string = '';
    if (event.value) {
      message = 'Material planner assigned successfully.';
      errorTitle = 'Assign error';
      errorMessage = 'Error assigning material planner.';
      this.assignPlanner.materialPlannerUserId=event.value.id;
    } else {
      message = 'Material planner removed successfully.';
      errorTitle = 'Remove error';
      errorMessage = 'Error removing material planner.';
      this.assignPlanner.materialPlannerUserId= null;
    }
    this.assignPlanner.formulationType = plannerItem.formulationType;
    console.log("Assigned Planner object");
    console.log(this.assignPlanner);
    this.materialPlanningClient.assignMaterialGroup(this.assignPlanner).subscribe(data => {
        this.plannerItems.filter(x => x.seedForecastItemId == plannerItem.seedForecastItemId)[0].materialPlannerUserId = event.value ? event.value.id : null;
        this.messageService.add({severity: this.commonService.configurations.MessageServiceSeveritySuccess,
          summary:'Success', detail:message});
          if (event.value) {
            plannerItem.isNew = false;
          } else {
            plannerItem.isNew = true;
          }
      },
      error => {
        this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError,
          summary: errorTitle, detail: errorMessage });
       }
      );
    
     
  }
}
