import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { ConcentrationUomModel } from '../models/concentrationuom';
import { ConfigurationSettings } from '../configuration-settings';

@Injectable()
export class ConcentrationUomService {
     url = ConfigurationSettings.REST_API_URL + '/api/concentrationuom';
        
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private _http: HttpClient) { }

    public getConcentrationUoms(): any {
         return this._http.get(this.url).pipe(
            tap(x => console.log('Fetched concentrationuom uoms')));
    }

    public updateConcentrationUom(ft: ConcentrationUomModel): any {
        let body = JSON.stringify(ft);
        return this._http.put(
            this.url,
            body, this.httpOptions).pipe(
                tap(x => console.log('Updated concentrationuom'))
            );
    }
    public createConcentrationUom(ft: ConcentrationUomModel): any {
        let body = JSON.stringify(ft);
        return this._http.post(
            this.url,
            body, this.httpOptions).pipe(
                tap(x => console.log('quantityuom created'))
            );
    }
    public checkDuplicate(ft: ConcentrationUomModel): any {
        let body = JSON.stringify(ft);
        return this._http.get(this.url +"/CheckDuplicate?UOMDisplay="+ft.uomDisplay).pipe(
            tap(x => console.log('Check duplicate uoms')));
    }

}
