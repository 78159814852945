<div class="grid">
    <div class="md:col-3"><h4 style="font-weight: bold;">My Forecasts</h4></div>
    <div class="md:col-9">
      <button pButton type="button" id="btnAddForecast" label="Add Forecast" style="float:right;" 
        icon="fa fa-plus" iconPos="right" (click)="onAddForecast()"></button>
    </div>
  </div>
  
  <div *ngIf="forecastData">
    <p-dataView #dv [value]="forecastData" id="forecastDataView"[paginator]="true" [rows]="pageSize" [lazy]="true"
    [totalRecords]="totalItems" pageLinkSize="3" (onPage)="pageChanged($event)" paginatorPosition="both" layout="grid"
    styleClass="hover">
      <ng-template pTemplate="header">
        <div style="width: 100%" style="background-color: #f4f4f4;border: 1px solid darkgray" class="clsDataViewHeaderVisiblePart">
              <div class="grid" style="width: 100%;">
                <div class="col-3 xl:col-3 lg:col-3 md:col-6 sm:col-12">
                  <div class="grid" style="margin-top: 1px;">
                    <div class="col-2">
                      <div (click)="myFunction($event, 0)" >
                        <i style="margin-left:10px" class="fa fa-filter fa-2x" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="col-8" >
                      <p-dropdown id="ddrFilterDropdown" [style]="{  'width':'100%'}"   optionLabel="name" [(ngModel)]="selectedFilter" (click)="StopDefaults()" [options]="searchFilters" >
                      </p-dropdown>
                    </div>
                    <div class="col-2"  >
                      <button type="button" style="margin-left: 1em" id="btnApplySelectedFilter" pButton (click)="ApplySelectedFilter($event)" label="Apply"></button>
                    </div>
                  </div>
              </div> 
              <div class="col-8 xl:col-8 lg:col-12 md:col-12 sm:col-12" >
                <div  class="grid" style="margin-left:3px;margin-top:1px;" *ngIf="false">
                  <div class="col-2 xl:col-2 lg:col-12 md:col-12 sm:col-12 clsFilterDiv" *ngFor="let searchCriteria of searchCriteriaList"> 
                     {{GetFilterValues(searchCriteria)}} 
                  </div>
                </div>
              </div>
            </div>   
          </div>
          <div *ngIf='isAccordionOpen' class='clsFilterFormDiv'>
            <form [formGroup]="filterForm" class='clsFilterForm' id="formFilterForm" style="padding-left: 15px;">
              <div class="grid" style="width: 100%;">
                <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Country</div>
                <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8"><p-autoComplete  id="autoCompleteFilterCountries"  [suggestions]="filteredCountries"
                  (completeMethod)="filterCountry($event)" 
                  field="displayName" [size]="20"  formControlName="country"  
                  (keyup.enter)="filterForecast(false)"
                   placeholder="Country" [minLength]="1"  >
                 </p-autoComplete> 
                <input type="hidden" id="hdnId" formControlName="id"> 
                </div>
                <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Status</div>
                <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                  <div class="grid" style="width: 100%">
                    <div class="col-9">
                      <p-multiSelect [options]="forecastState" formControlName="status" 
                      [style]="{'width': '186px'}" optionLabel="stateName" id="ddrForecastState"
                        appendTo="body" defaultLabel="Select" [showHeader]="false" [filter]="false">
                      </p-multiSelect>
                    </div>
                  </div>
                </div>
                <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Program</div>
                <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8"><input type="text" (keyup.enter)="filterForecast(false)" id="txtProgram" formControlName="Program"  pInputText class="p-inputtext"  ></div> 
              </div>
              <div class="grid" style="width: 100%">
                <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Material</div>
                <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8"><input type="text" id="txtMaterial" (keyup.enter)="filterForecast(false)" formControlName="Material" pInputText class="p-inputtext"  ></div>
                <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Reviewer</div>
                <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8"> 
                  <div class="grid" style="width: 100%">
                    <div class="col-9">
                      <p-autoComplete    [suggestions]="filteredReviewers" id="autoCompleteReviewer"
                        (completeMethod)="filterReviewers($event)" 
                        (keyup.enter)="filterForecast(false)"
                        field="secondaryEmail" [size]="20"  formControlName="Reviewer"  
                        placeholder="Reviewer" [minLength]="1"   >
                      </p-autoComplete> 
                    </div>
                  </div>
                </div>                 
                <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Forecaster</div>
                <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">  
                  <p-autoComplete    id="autoCompleteForecaster"  [suggestions]="filteredForecasters"
                  (completeMethod)="filterForecaster($event)" 
                  (keyup.enter)="filterForecast(false)"
                  field="secondaryEmail" [size]="20"  formControlName="Forecaster"  
                   placeholder="Forecaster" [minLength]="3"  >
                 </p-autoComplete> 
                 
              </div> 
              </div>
              <div class="grid" style="width: 100%">
                <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Forecast#</div>
                <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                  <input type="text" id="txtForecastNumber" (keyup.enter)="filterForecast(false)" (keypress)="AllowOnlyNumeric($event)"  formControlName="ForecastNumber" pInputText class="p-inputtext"  >
                </div>
                <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Sort Order</div>
                <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                  <p-dropdown id="ddrOrderBy" [options]="orderBy" formControlName="OrderBy"  optionLabel="name">
                  </p-dropdown>
                  <button type="button" style="margin-left:3px;" id="btnSort" pButton [disabled]="!filterForm.controls['OrderBy'].value"                      
                    [icon]="!IsDesc ? 'fa fa-arrow-down' : 'fa fa-arrow-up'" (click)="IsDesc = !IsDesc;filterForecast()">
                  </button>
                </div>
                <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Filter Name</div>
                <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                  <input type="text" id="txtFilterName"  pInputText class="p-inputtext" formControlName="Name">
                  <p-message severity="error" text="Name required" *ngIf="!filterForm.controls['Name'].valid
                  &&filterForm.controls['Name'].dirty"></p-message>
                </div> 
                <div class="col-3 xl:col-3 lg:col-6 md:col-6 sm:col-6">
                    <div class="grid" >
                       <div class="col-3"><button type="button" id="btnApplyFilter" style="min-width:80px;" pButton (click)="IsDesc = false;filterForecast(false);" label="Apply"></button> </div>
                      <div class="col-3"><button type="button" id="btnSaveFilter" style="min-width:80px;" pButton [disabled]="!filterForm.valid || filterForm.controls['Name'].value ==  defaultFilter.name || (selectedFilter  !=defaultFilter &&  selectedFilter.ownerId == null)"  (click)="filterForecast(true)"  label="Save"></button> </div>
                      <div class="col-3"><button type="button" id="btnClearFilter" style="width: 80px;" pButton (click)="clearFilterForecast()" label="Clear All"></button> </div>
                    </div>
                </div>
              </div>
            </form>
          </div>
      </ng-template>
        <ng-template let-forecastItem pTemplate="gridItem" >
            <div style="padding:.5em" class="col-12 md:col-6 lg:col-4 xl:col-3 resShadow">
                <p-panel [style]="{'text-align':'left', 'width':'100%'}" 
                  [styleClass]="getTitleStyle(forecastItem)" id="{{forecastItem.id}}">
                  <ng-template pTemplate="header" style="width: 100%;padding: unset !important;">
                    <div class="grid" style="margin-top: 0.1rem !important;width: 100%;margin-left:0px !important; ">
                      <div class="col-6">
                        <span class="form-panel-subheader"  >Forecast  {{forecastItem.id}} </span>
                      </div>
                        <div class="col-6">
                          <span aria-hidden="true" [class.disabled]="!forecastItem.isDeletable||forecastItem.stateName==='Deleted'||forecastItem.stateName==='Cancelled'||forecastItem.stateName==='In Process'" class="notification-counter p-button-icon-left p-clickable fa fa-trash clsTrash" [title]="'Delete'" (click)="DeleteForecast(forecastItem)" id="{{'Delete' + forecastItem.id}}"></span>
                          <span aria-hidden="true" class="p-button-icon-left p-clickable fa fa-pencil clsEdit" [title]="'Edit'" (click)="onEditForecast(forecastItem.id)" id="{{'Edit' + forecastItem.id}}"></span>
                          <span aria-hidden="true" class="p-button-icon-left p-clickable fa fa-clone clsClone" [title]="'Copy'" (click)="CopyForecast(forecastItem.id)" id="{{'Copy' + forecastItem.id}}"></span>
                          <span aria-hidden="true" *ngIf="forecastItem.bypassReservation" class="p-button-icon-left p-clickable fa fa-sign-out clsBypass" [title]="'Bypass'" id="{{'Bypass' + forecastItem.id}}"></span>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <div style="height: 220px;overflow-y: auto" (click)="onEditForecast(forecastItem.id)" >
                        <div class="forecastItem-detail"><span class="form-label-header">Material: </span><span
                                class="form-label-content">{{forecastItem.productName}}</span>
                              </div>
                              <div class="forecastItem-detail"><span class="form-label-header">Date Range: </span><span
                                class="form-label-content">{{GetDaterange(forecastItem)}}</span></div>
                        <div class="forecastItem-detail"><span class="form-label-header">Quantity: </span><span
                                class="form-label-content">{{GetAggregatedQuantity(forecastItem)}}</span></div>
                       
                       <div class="forecastItem-detail"><span class="form-label-header">Status: </span><span
                          class="form-label-content">{{forecastItem.stateName}}</span></div>
                        <div class="forecastItem-detail"><span class="form-label-header">Forecaster: </span><span
                          class="form-label-content">{{forecastItem.forecastingUserName}} </span></div>
                        <div class="forecastItem-detail"><span class="form-label-header">Reviewer: </span><span
                            class="form-label-content">{{forecastItem.reviewerUserName}} </span></div>
                        <div class="forecastItem-detail"><span class="form-label-header">Delegator: </span><span
                                class="form-label-content">{{forecastItem.delegatorUserName}} </span></div>                      
                        <div class="forecastItem-detail"><span class="form-label-header">Material Planner: </span><span
                          class="form-label-content"><a style="text-decoration: underline;" (click)="doNothing($event)" title="{{forecastItem.materialPlannerEmail}}" href="mailto:{{forecastItem.materialPlannerEmail}}">{{forecastItem.materialPlannerName}}</a></span></div>                      
                          <div class="forecastItem-detail"><span class="form-label-header">Approver: </span>
                            <span class="form-label-content" style="display:inline-flex !important;">
                              <span *ngIf="forecastItem.isApproved||(forecastItem.forecastingUserEmail===forecastItem.approverEmail)"><i style="color: green;" class="pi pi-check-circle" [title]="'Approved'" id="{{'IsApprovedYes' + forecastItem.id}}"></i>&nbsp;</span>
                              <span *ngIf="forecastItem.isApproved===false&&(forecastItem.forecastingUserEmail!=forecastItem.approverEmail)"><i style="color: red;" class="pi pi-times-circle" [title]="'Rejected'" id="{{'IsApprovedNo' + forecastItem.id}}"></i>&nbsp;</span>
                              <a style="text-decoration: underline;" href="mailto:{{forecastItem.approverEmail}}">{{forecastItem.approverName ?? forecastItem.approverEmail}}</a>
                          </span></div>                      
                        </div>
                    </ng-template>
                </p-panel>
            </div>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-state id="paginatorleft">
          <span class="p-paginator-first" style="padding-left:10px;"> Total Items : {{totalItems}}
              {{ dv.totalRecords == forecastData.length ? '' : '(Showing Items: '+ forecastData.length + ')'}} </span>
      </ng-template>
      <ng-template pTemplate="paginatorright" id="paginatorright" let-state>
          <span class="p-paginator-first"
              style="padding-right:10px;">{{ (dv.totalRecords > 0 && dv.totalRecords > (state.page  * state.rows)) ? (state.page  * state.rows) + 1 : dv.totalRecords === 0 ? 0 : 1 }} -
              {{ (state.rows * (state.page + 1)) > state.totalRecords ? state.totalRecords : (state.rows * (state.page + 1)) }}
              of {{dv.totalRecords}} Items</span>
      </ng-template>
    </p-dataView>
  </div>
  
  <p-toast></p-toast>
  <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
  