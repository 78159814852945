import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { ConfirmationService } from "primeng/api";
import { MessageService } from 'primeng/api';
import { ConcentrationUomService } from '../services/concentrationuom.service';
import { Validators, UntypedFormControl, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ForecastTypeModel, ForecastItemClient,
  ForecastItemModel,
  ForecastItemDestinationModel,
  ForecastItemActiveIngredientModel,
  DelegatesClient,
  UserModel,
  ForecastItemDelegatedUsers,
  UserClient,
  AltForecastItemActiveIngredientModel,
  ForecastItemCommentLogModel,
  InventoryStateClient,
  InventoryStateModel} from '../services/forecast-api-client.service';
import { ButtonRendererComponent } from '../custom/button-renderer/button-renderer.component';
import { AgGridAngular } from 'ag-grid-angular';
import { AutoComplete } from 'primeng/autocomplete';
import { CommonService } from '../services/common.service';
import { AppComponent } from '../app.component';
import { BioNexusClient, CompoundClient, FormulationClient, MaterialResult, RaceLabelsClient } from '../services/gsscommon-api-client.service';
import { DataViewModule, DataView } from 'primeng/dataview';
import { NullLogger } from '@microsoft/signalr';
import { StringUtility } from '../common-utility/string-utility';

@Component({
  selector: 'app-forecast-item',
  templateUrl: './forecast-item.component.html',
  styleUrls: ['./forecast-item.component.scss'],
  providers: [ConfirmationService, MessageService, ConcentrationUomService]
})
export class ForecastItemComponent implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  public forecastTitle: any;
  public forecastForm: UntypedFormGroup;
  public submitted: boolean;
  public totalCount: number;
  public pageIndex: number=1;
  public PAGE_SIZE: number = 25;
  public reviewerChangeDialog:boolean = false;
  public reviewerChangeAccepted:boolean = false;
  public forecastComments:Array<ForecastItemCommentLogModel> = [];
  public forecastItem: ForecastItemModel;
  public editForecastItem: ForecastItemModel;
  public forecastDestinations:Array<ForecastItemDestinationModel>;
  public totalForecast: any;
  public alternativeProduct: string;
  public reachApproved: any;
  public reviewers: any;
  public filteredReviewers:any;
  public delegators: any;
  public filteredDelegators:any;
  public forecastingSeasons: any;
  public forecastStates: any;
  public dataEntryStateId: any;
  public awaitReviewStateId: any;
  public submittedStateId:any ;
  public materialApprovalStateId: any;
  public isFScientist: any;
  public isBiologyLeader: any;
  public isMaterialPlanner: any;
  public showSearch: boolean = false;
  public backButtonText: string;
  public showBackButton: boolean;
  public isFormulationSpecialist: boolean;
  public disableMaterialSearch: boolean = false;
  public isCommercialUser: boolean;
  public isShow: false;
  public isShowAiWarning: boolean = true;
  public isSave: boolean;
  public alternativeProductPopup = false;
  public isEdit:boolean = false;
  public user: UserModel;
  public forecastTypes: ForecastTypeModel [] = [];
  public quantUoms: any;
  public ctrnUoms: any;
  public formulationTypes: any;
  public yesNo: any;
  public allData: any;
  public editAllData: any;
  public isShowAIDialog: boolean = false;
  public isShowMaterialAIDialog:boolean = false;
  public isShowDelegateDialog:boolean = false;
  public isModify: boolean = false;
  public showField: boolean = false;
  public aiform: UntypedFormGroup;
  public frameworkComponents: any;
  public enableSubmittButton:boolean = false;
  public altMaterialType:string = "Material";
  public columnDefs: any;
  public ais: ForecastItemActiveIngredientModel[] = [];
  public forecastItemId: any;
  public buttonName:string ="Submit for Procurement";
  public selectedSeasionDelegators:Array<UserModel>;
  public seasionDelegators:any;
  public SelectAll:boolean = false;
  public isReviewerChanged:boolean = false;
  public isSaveButton:boolean = false;
  public enableAccept:boolean= false;
  public defaultValue:UserModel =  null;
  public materialType:string = "Material"
  public isSearchingAlternative: boolean = false;
  public isForecastCopy:boolean = false;
  public UOM: any;
  public isMaterialInfo;
  public showMaterialDialog;
  @ViewChild('dv') dataView: DataView;
  public dialogAcceptCommentDialog = false;
  public commentText: string = '';
  public  inventoryStates: Array<InventoryStateModel>;
  public  toOrderState: InventoryStateModel;
  public  stringUtility: StringUtility;
  public isBypassInventory: boolean = false;
  public submitForProcurementDialog = false;
  public needDateRevisionDialog : boolean = false;
  public needDateRevisionCountries : string = '';
  public newforecastRevisionDate : Date;
  public newRevisionDatesAccepted : boolean = false;
  public disableonSubmit = false;
  public disableonComment = false;

  constructor(public fb: UntypedFormBuilder,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public securityService: SecurityService,
    public forecastItemClient: ForecastItemClient,
    public confirmationService: ConfirmationService,
    public delegatesClient: DelegatesClient,
    public inventoryStateClient: InventoryStateClient,
    public userClient:UserClient,
    public appComponent: AppComponent,
    public messageService: MessageService,
    public bioNexusClient: BioNexusClient,
    public raceLabelsClient : RaceLabelsClient,
    public formulationClient:FormulationClient,
    public compoundClient:CompoundClient,
    public commonService: CommonService) {
      this.user = this.securityService.getUser();

      this.yesNo = [{name: 'Yes', code: true}, {name: 'No', code: false}];
      this.frameworkComponents = {
        buttonRenderer: ButtonRendererComponent,
      }
      this.columnDefs = [
        {headerName: '', field: 'forecastItemName', sortable: true, filter: true, width: 475, tooltipField: 'forecastItemName'},
        {
          headerName: '', width:100,
          cellRenderer: 'buttonRenderer',
          cellRendererParams: {
            onClick: this.onBtnClick.bind(this),
            label: 'Delete'
          }
        }
      ];
      this.stringUtility = new StringUtility();

  }

  ngOnInit() {
    this.getInventoryStateList();
    this.appComponent.currentSelection = 'forecasts';
    this.isSearchingAlternative = false;
    this.showBackButton = true;
    this.isFScientist = this.checkFScientist();
    this.isBiologyLeader = this.checkBiologyLeader();
    this.isCommercialUser = this.checkCommercialUser();
    this.isMaterialPlanner = this.checkMaterialPlanner();
    this.isFormulationSpecialist = this.CheckFormulationSpecialist();
    if(!this.isBiologyLeader && this.isFScientist)
    {
      this.buttonName = "Send For Review";
    }
    this.activatedRoute.data
    .subscribe((allData) => {
      if (this.activatedRoute.snapshot.url[0].path === 'forecastitem') {
        this.allData = allData;
         this.isSave = true;
        this.processNewData();
        this.forecastTitle = "Add Forecast";
        this.showSearch = true;
        this.backButtonText = 'Back to forecast entry';
      } else if (this.activatedRoute.snapshot.url[0].path === 'forecastitemEdit') {
        this.editAllData = allData;
        this.isSave = false;
        this.processEditData();
        console.log( this.editAllData.editForecastData);
        if (this.isForecastItemForModify()) {
          this.forecastTitle = "Modify Forecast ("
            + this.editAllData.editForecastData.editForecastItem.id
            + ")";
          }
        else {
          this.forecastTitle = "View Forecast ("
            + this.editAllData.editForecastData.editForecastItem.id
            + ")";
          }
          this.showSearch = false;
          this.showBackButton = false;
          //this.backButtonText = 'Back to Material Search';
      } else if (this.activatedRoute.snapshot.url[0].path === 'forecastitemCopy') {
        this.editAllData = allData;
        this.isSave = true;
        this.isForecastCopy = true;
        this.removeAicFlagsOnCopyForecast();
        this.processEditData();
        this.forecastTitle = "Add Forecast";
        this.showSearch = this.forecastItem.materialId === null || this.forecastItem.materialId === undefined;
        this.backButtonText = this.showSearch ? 'Back to forecast entry' : 'Back to Material Search';
      }
      this.disableMaterialSearch = !(this.forecastItem.stateName === 'Data Entry' || this.forecastItem.stateName === 'Awaiting Review' || this.forecastItem.stateName === 'Delegate Review');
    },
    error => {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Error when loading forecast data. Check with Admin.' });
      console.log(error);
    });
  }

  public  getInventoryStateList() {
    this.inventoryStateClient.getInventoryStates()
     .subscribe(data => {
    //  data.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
    console.log(data);
      this.inventoryStates = data;
      this.toOrderState = this.inventoryStates.find(a => a.stateName === 'To Order');

     });
  }

  public  processNewData() {
    this.forecastItem = new ForecastItemModel();
    this.createForecastForm();
    this.forecastFormControlValueChanged();
    this.createAIForm();
    this.forecastDestinations =[];
     this.forecastTypes = this.allData.forecastData.forecastTypes;
    if(!this.user.isGlpeligible)
    {
      this.forecastTypes = this.forecastTypes.filter(({ forecastType1 }) => forecastType1 !== "GLP");
      let fttr = this.forecastTypes.find(ft => ft.forecastType1 == "Stock");
      (<UntypedFormControl>this.forecastForm.controls['forecastType'])
        .setValue(fttr, { onlySelf: true });
    }

    this.quantUoms = this.allData.forecastData.uoms;
    (<UntypedFormControl>this.forecastForm.controls['QuantityUom'])
        .setValue(this.quantUoms[0], { onlySelf: true });
    this.forecastItem.quantityUnitOfMeasureName = this.quantUoms[0].name;
    this.forecastItem.quantityUnitOfMeasureId = this.quantUoms[0].id;

    this.totalForecast = 0;
    this.formulationTypes = this.allData.forecastData.formulationTypes;

      this.reviewers = this.allData.forecastData.reviewers.filter(
        u => u.roles.find(r => r.name == "Biology Leader")
      );
      this.reviewers.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);


  this.delegators =  this.allData.forecastData.reviewers.filter(
    u => u.roles.find(r => r.name == "Biology Leader")
  );
  this.delegators.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
    let today = new Date();
    // this.forecastingSeasons = this.allData.forecastData.forecastingSeasons.filter(
    //   s => s.dueDate >= today && s.startDate <= today
    // );
    // this.forecastingSeasons.sort((n1, n2) => {
    //   return n1.startDate.valueOf() - n2.startDate.valueOf();
    // });
    //  (<UntypedFormControl>this.forecastForm.controls['forecastSeason'])
    //      .setValue(this.forecastingSeasons[0], { onlySelf: true });

    this.forecastStates = this.allData.forecastData.forecastStates;
    this.ctrnUoms = this.allData.forecastData.cuoms;
    let dataEntryState = this.forecastStates.find(fs => fs.stateName == 'Data Entry');
    let awaitReviewState = this.forecastStates.find(fs => fs.stateName == 'Awaiting Review');
    let submittedStateId = this.forecastStates.find(fs => fs.stateName == 'Submitted');
    let materialApprovalStateId = this.forecastStates.find(fs => fs.stateName == 'Material Approval');
    this.dataEntryStateId = dataEntryState.id;
    this.awaitReviewStateId = awaitReviewState.id;
    this.submittedStateId = submittedStateId.id;
    this.materialApprovalStateId = materialApprovalStateId.id;
    this.forecastItem.stateId = dataEntryState.id;
    this.forecastItem.stateName = dataEntryState.stateName;
    this.forecastItem.forecastingUserId = this.user.id;
    this.forecastItem.forecastingUserEmail = this.user.email;
    this.isModify = true;
  }
  public goSearch() {
    if (this.showSearch) {
      this.showSearch = false;
      this.isSearchingAlternative = false;
      this.showBackButton = false;
      this.backButtonText = 'Back to Material Search';
    } else {
      this.isSearchingAlternative = false;
      this.showSearch = true;
      this.showBackButton = true;
      this.backButtonText = 'Back to Forecast Entry';
    }
  }
  public goSearchAlternative() {
    this.isSearchingAlternative = true;
    this.showSearch = true;
    this.showBackButton = true;
    this.backButtonText = 'Back to Forecast Entry';
  }
  public markDuplications(items: MaterialResult[]) {
    if (!this.isSearchingAlternative) {
      if (this.forecastItem.altMaterialId !== undefined && this.forecastItem.altMaterialId !== null && this.forecastItem.altMaterialId !== ''
        && this.forecastItem.altMaterialRepoName !== undefined && this.forecastItem.altMaterialRepoName !== null && this.forecastItem.altMaterialRepoName !== '') {
          items.forEach(x => {
            if (x.materialIdInTargetSystem == this.forecastItem.altMaterialId && x.sourceSystem == this.forecastItem.altMaterialRepoName) {
              x["isAdded"] = true;
            }
          });
        }
    } else {
      if (this.forecastItem.materialId !== undefined && this.forecastItem.materialId !== null && this.forecastItem.materialId !== ''
        && this.forecastItem.repoName !== undefined && this.forecastItem.repoName !== null && this.forecastItem.repoName !== '') {
          items.forEach(x => {
            if (x.materialIdInTargetSystem == this.forecastItem.materialId && x.sourceSystem == this.forecastItem.repoName) {
              x["isAdded"] = true;
            }
          });
        }
    }
  }

  public  getProductName(item: MaterialResult): string {
    let res: string = "";
    switch (item.sourceSystem) {
      case 'COFORM':
        res = item.materialName;
        break;
      case 'CORE':
        res = item.commonName === null ? item.materialName : item.commonName + ' (' + item.materialName + ')';
        break;
      case 'FACTS':
        res = (item['preferredSynonym'] === null || item['preferredSynonym'] === undefined || item['preferredSynonym'] === '') ? 
        item.materialName : 
        item.materialName +' ('+ item['preferredSynonym'] + ')';
        break;
      default:
        res = item.materialName;
        break;
    }
    return res;
  }

  public addCartItem(item: MaterialResult) {

    if (!this.isSearchingAlternative) {
      this.forecastItem.materialId = item.materialIdInTargetSystem;
      this.forecastItem.forecastItemActiveIngredient = [];
      this.forecastItem.isGlp = item.isGLP;
      this.forecastItem.repoName = item.sourceSystem;
      //Race labels change on Material change
      if(this.forecastItem.productName != this.getProductName(item) && item.sourceSystem == 'FACTS'){
        if(this.forecastItem.forecastItemDestination && this.forecastItem.forecastItemDestination.length > 0){
          this.forecastItem.forecastItemDestination.forEach(element => {
            this.raceLabelsClient.retrieveLabelsForCompositionByCountry(
              item.materialName, 
              element.destinationCountryCode, 
              element.destinationCountryName).subscribe((response)=>{
                if(response.countryLabels &&  response.countryLabels.length != 0){
                  element.raceLabels = response.countryLabels[0].labels.join('||');
                }
                else{
                  element.raceLabels = "";
                }
            });
          });
        }
        else if(this.forecastDestinations && item.sourceSystem == 'FACTS'){
          this.forecastDestinations.forEach(element => {
            this.raceLabelsClient.retrieveLabelsForCompositionByCountry(
              item.materialName, 
              element.destinationCountryCode, 
              element.destinationCountryName).subscribe((response)=>{
                if(response.countryLabels &&  response.countryLabels.length != 0){
                  element.raceLabels = response.countryLabels[0].labels.join('||');
                }
                else{
                  element.raceLabels = "";
                }
            });
          });
        }
      }
      else{
        if(this.forecastItem.forecastItemDestination && this.forecastItem.forecastItemDestination.length > 0){
          this.forecastItem.forecastItemDestination.forEach(element => {
            element.raceLabels = '';
          });
        }
        if(this.forecastDestinations && this.forecastDestinations.length > 0){
          this.forecastDestinations.forEach(element => {
            element.raceLabels = '';
          });
        }
      }

      this.forecastItem.productName = this.getProductName(item);
      this.forecastItem.materialSearchType = item["materialSearchType"];
      this.forecastItem.lotId = item.lotId;
      this.forecastItem.lotNumber = item.lotNumber;
      this.forecastItem.approverEmail = item.approverEmail;
      this.forecastItem.approverName = item.approverFullName;
      this.forecastItem.isApproved = null;
      this.forecastItem.tradeName=item.tradeName;
      this.forecastItem.brandName = item.brandName;
      this.forecastItem.commonName=item.commonName;
      this.forecastItem.tsnNumber = item.tsn;
      this.forecastItem.recertDate = item.recertificationDate;
      this.forecastItem.stage = item.stage;
      this.forecastItem.glpStatus = item.glpStatus;
      this.forecastItem.isDas = !item.isThirdParty;
      this.forecastItem.formulationType = item.formulationTypeDesc;
      // this.forecastItem.materialAttributes = item.materialAttributes;
      // this.forecastItem.lotAttributes = item.lotAttributes;
      let fttr = this.forecastItem.isGlp ? this.forecastTypes.find(ft => ft.forecastType1 == "GLP") : this.forecastTypes.find(ft => ft.forecastType1 == "Stock");
      this.forecastItem.forecastTypeId = fttr.id;
      (<UntypedFormControl>this.forecastForm.controls['forecastType'])
        .setValue(fttr, { onlySelf: true });
      if (this.forecastItem.forecastItemDestination !== undefined && this.forecastItem.forecastItemDestination !== null && this.forecastItem.forecastItemDestination.length > 0) {
        this.forecastItem.forecastItemDestination.forEach(dest => {
          dest.lotNumber = item.lotNumber;
        });
      }

      this.forecastItem.synonyms =  (item.synonyms !== null && item.synonyms !== undefined && item.synonyms.length > 0) ? item.synonyms.join("||") : "";
      if (item.actives != null && item.actives !== undefined && item.actives.length > 0) {
        item.actives.forEach(a => {
          var aim = new ForecastItemActiveIngredientModel();
          aim.activeIngredient = a.ingredientName;
          aim.concentrationInPrimaryUom = a.concentrationPrimaryUom;
          aim.concentrationInSecondaryUom = a.concentrationSecondaryUom;
          this.forecastItem.forecastItemActiveIngredient.push(aim);
        });
      }
      (<UntypedFormControl>this.forecastForm.controls['materialName'])
          .setValue(this.forecastItem.productName, { onlySelf: true });
      (<UntypedFormControl>this.forecastForm.controls['materialNameSynonyms'])
        .setValue(this.forecastItem.synonyms === null ? '' : this.forecastItem.synonyms.length <= 20 ?
        this.forecastItem.synonyms.split('||').join('; ') : this.forecastItem.synonyms.split('||').join('; ').substring(0, 19)+' ...'  , { onlySelf: true });
      (<UntypedFormControl>this.forecastForm.controls['formulationType'])
        .setValue(this.forecastItem.formulationType, { onlySelf: true });
      this.determineMaterialNameLabel();
    }
    else {
      this.forecastItem.altMaterialId = item.materialIdInTargetSystem;
      this.forecastItem.altForecastItemActiveIngredient = [];
      this.forecastItem.altMaterialRepoName = item.sourceSystem;
      this.forecastItem.acceptableAlternativeProduct = this.getProductName(item);
      this.forecastItem.altMaterialSearchType = item["materialSearchType"];
      this.forecastItem.altLotId = item.lotId;
      this.forecastItem.altLotNumber = item.lotNumber;
      this.forecastItem.altMaterialApproverEmail = item.approverEmail;
      this.forecastItem.altMaterialTsnNumber = item.tsn;
      this.forecastItem.tradeName=item.tradeName;
      this.forecastItem.commonName=item.commonName;
      this.forecastItem.altMaterialRepoName = item.sourceSystem;
      this.forecastItem.altMaterialPurity = item.purity?.toString();
      this.forecastItem.altMaterialRecertDate = item.recertificationDate;
      this.forecastItem.altMaterialStage = item.stage;
      this.forecastItem.altMaterialGlpStatus = item.glpStatus;
      this.forecastItem.altMaterialFormulationType = item.formulationTypeDesc;
      // this.forecastItem.altMaterialAttributes = item.materialAttributes;
      // this.forecastItem.altLotAttributes = item.lotAttributes;

      this.forecastItem.altMaterialSynonyms =  (item.synonyms !== null && item.synonyms !== undefined && item.synonyms.length > 0) ? item.synonyms.join("||") : "";
      if (item.actives != null && item.actives !== undefined && item.actives.length > 0) {
        item.actives.forEach(a => {
          var aim = new AltForecastItemActiveIngredientModel();
          aim.activeIngredient = a.ingredientName;
          aim.concentrationInPrimaryUom = a.concentrationPrimaryUom;
          aim.concentrationInSecondaryUom = a.concentrationSecondaryUom;
          this.forecastItem.altForecastItemActiveIngredient.push(aim);
        });
      }
      (<UntypedFormControl>this.forecastForm.controls['alternativeProduct'])
           .setValue(this.forecastItem.acceptableAlternativeProduct, { onlySelf: true });
    }
    this.showSearch = false;
    this.showBackButton = false;
    this.backButtonText = 'Back to Material Search';
    this.determineAltMaterialNameLabel();
    if(item.approverEmail != null && item.approverEmail != undefined)
    {
      this.userClient.updateMaterialApprover(item.approverEmail).subscribe(x =>{});
    }
  }

  public ClearAlternativeProduct(event)
  {
    (<UntypedFormControl>this.forecastForm.controls['alternativeProduct'])
    .setValue('');
    this.forecastItem.altMaterialId = null;
    this.forecastItem.altForecastItemActiveIngredient = [];
    this.forecastItem.altMaterialRepoName = null;
    this.forecastItem.acceptableAlternativeProduct = null;
    this.forecastItem.altMaterialSearchType = null;
    this.forecastItem.altLotId = null;
    this.forecastItem.altLotNumber = null;
    this.forecastItem.altMaterialTsnNumber = null;
    this.forecastItem.altMaterialRepoName = null;
    this.forecastItem.altMaterialPurity = null;
    this.forecastItem.altMaterialRecertDate = null;
    this.forecastItem.altMaterialFormulationType = null;

    event.preventDefault();
  }

  public ShowMessage(msg: string)
  {
    this.messageService.add({ severity: 'error', summary: msg, detail: '' });
  }

  public OpenAlternativeProductPopup()
  {
    this.determineAltMaterialNameLabel();
    this.alternativeProductPopup = true;
  }
  public  determineMaterialNameLabel() {
    switch (this.forecastItem.repoName) {
      case 'COFORM':
        this.materialType = 'Coformulant/Field Inert';
        break;
      case 'CORE':
        if (this.forecastItem.lotNumber !== undefined && this.forecastItem.lotNumber !== null && this.forecastItem.lotNumber !== '') {
          this.materialType = 'Compound Lot';
        } else {
          this.materialType = 'Compound';
        }
        break;
      case 'FACTS':
        if (this.forecastItem.lotNumber !== undefined && this.forecastItem.lotNumber !== null && this.forecastItem.lotNumber !== '') {
          this.materialType = 'Formulation Lot';
        } else {
          this.materialType = 'Formulation';
        }
        break;
      default:
        this.materialType = 'Material';
        break;
    }
  }
  public  SetMaterialType() {
    switch (this.forecastItem.materialSearchType) {
      case 'FACTSFormulationName':
      case 'FACTSSynonym':
      case 'CortevaTradeName':
      case 'ThirdPartyTradeName':
        this.materialType = 'Formulation';
        break;
      case 'FACTSFormulationNameGlp' :
          this.materialType = 'Formulation Lot';
          break;
      case 'CORESynonym':
      case 'COREMaterialId':
          this.materialType = 'Compound';
        break;
      case 'COREMaterialLotGlp':
          this.materialType = 'Compound Lot';
        break;
      case 'Coformulant':
      case 'FieldInert':
        this.materialType = 'Coformulant/Field Inert';
        break;
      default:
        this.materialType = 'Material';
          break;
    }
  }
  public  SetAltMaterialType() {
    switch (this.forecastItem.altMaterialSearchType) {
      case 'FACTSFormulationName':
      case 'FACTSSynonym':
      case 'CortevaTradeName':
      case 'ThirdPartyTradeName':
        this.altMaterialType = 'Formulation';
        break;
      case 'FACTSFormulationNameGlp' :
          this.altMaterialType = 'Formulation Lot';
          break;
      case 'CORESynonym':
      case 'COREMaterialId':
          this.altMaterialType = 'Compound';
        break;
      case 'COREMaterialLotGlp':
          this.altMaterialType = 'Compound Lot';
        break;
      case 'Coformulant':
      case 'FieldInert':
        this.altMaterialType = 'Coformulant/Field Inert';
        break;
      default:
        this.altMaterialType = 'Material';
        break;
    }
  }
  public  determineAltMaterialNameLabel() {
    switch (this.forecastItem.altMaterialRepoName) {
      case 'COFORM':
        this.altMaterialType = 'Coformulant/Field Inert';
        break;
      case 'CORE':
        if (this.forecastItem.altLotNumber !== undefined && this.forecastItem.altLotNumber !== null && this.forecastItem.altLotNumber !== '') {
          this.altMaterialType = 'Compound Lot';
        } else {
          this.altMaterialType = 'Compound';
        }
        break;
      case 'FACTS':
        if (this.forecastItem.altLotNumber !== undefined && this.forecastItem.altLotNumber !== null && this.forecastItem.altLotNumber !== '') {
          this.altMaterialType = 'Formulation Lot';
        } else {
          this.altMaterialType = 'Formulation';
        }
        break;
      default:
        this.altMaterialType = 'Material';
        break;
    }
  }
  public  removeAicFlagsOnCopyForecast(){
    this.forecastDestinations = this.editAllData.editForecastData.editForecastItem.forecastItemDestination || [];
    for (let i = 0; i < this.forecastDestinations.length; i++) {
      this.forecastDestinations[i].autoInventoryReasonComment = null;
      this.forecastDestinations[i].hasBeenConsideredForAutoInventory = false;
      this.forecastDestinations[i].qualifiedForAutoInventory = false;
      this.forecastDestinations[i].reserved = false;
      this.forecastDestinations[i].id = 0;
      this.forecastDestinations[i].forecastItemId = 0;
    }
  }
  public  processEditData() {
    this.forecastItem = new ForecastItemModel();
    this.createForecastForm();
    this.forecastFormControlValueChanged();
    this.createAIForm();
    this.editForecastItem = this.editAllData.editForecastData.editForecastItem;
    this.forecastItem = this.editAllData.editForecastData.editForecastItem;
    if(this.forecastItem.materialSearchType == 'BioMaterialId'){
      this.bioNexusClient.retrieveBioNexusMaterialById(this.forecastItem.productName).subscribe((res:MaterialResult[]) => {
        if(res && res.length >0) {}
          // this.forecastItem.materialAttributes = res[0].materialAttributes;
      });
    }
    if(this.forecastItem.materialSearchType === 'BioMaterialLotId'){
      this.bioNexusClient.retrieveBioNexusLotByLotId(this.forecastItem.lotNumber).subscribe((res:MaterialResult[]) => {
        if(res && res.length >0){
          // this.forecastItem.materialAttributes = res[0].materialAttributes;
          // this.forecastItem.lotAttributes = res[0].lotAttributes;
        }
      });
    }
    if(this.forecastItem.altMaterialSearchType == 'BioMaterialId'){
      this.bioNexusClient.retrieveBioNexusMaterialById(this.forecastItem.acceptableAlternativeProduct).subscribe((res:MaterialResult[]) => {
        if(res && res.length >0) {}
          // this.forecastItem.altMaterialAttributes = res[0].materialAttributes;
      });
    }
    if(this.forecastItem.altMaterialSearchType === 'BioMaterialLotId'){
      this.bioNexusClient.retrieveBioNexusLotByLotId(this.forecastItem.altLotNumber).subscribe((res:MaterialResult[]) => {
        if(res && res.length >0){
          // this.forecastItem.altMaterialAttributes = res[0].materialAttributes;
          // this.forecastItem.altLotAttributes = res[0].lotAttributes;
        }
      });
    }
    if(!this.isForecastCopy){
      this.getData();
    }
    this.editForecastItem.forecastingSeasonId = null;
    this.editForecastItem.forecastingSeasonName = null;

    if(this.isBiologyLeader)
    {
      if(this.forecastItem.reviewerUserId != null && this.forecastItem.reviewerUserId != this.user.id)
      this.buttonName="Send For Review";
    }
    this.forecastDestinations = this.editAllData.editForecastData.editForecastItem.forecastItemDestination;
    this.AddTrackingIdForDestinations();
    this.forecastTypes = this.editAllData.editForecastData.forecastTypes;

    if(this.isForecastCopy){
      this.forecastDestinations.forEach(element => {
        element.dateNeeded = null;
        element.dateNeededLatest = null;
      });
      
      if(this.forecastItem.repoName === 'FACTS'){
        //this.forecastItem.materialId
        this.formulationClient.confidentialCompositionById(Number(this.forecastItem.materialId))
        .subscribe((response)=>{
          if(response && response.approver){
            if(this.forecastItem.approverEmail != response.approver.email){
              this.forecastItem.approverEmail = response.approver.email;
              this.forecastItem.approverName = response.approver.fullName;
            }
          }
        });
      }
      if(this.forecastItem.repoName === 'CORE'){
        this.compoundClient.retrieveCompoundByMaterialId(this.forecastItem.materialId)
        .subscribe((response)=>{
          if(response && response.length > 0){
            if(this.forecastItem.approverEmail != response[0].approverEmail){
              this.forecastItem.approverEmail = response[0].approverEmail;
              this.forecastItem.approverName = response[0].approverFullName;
            }
          }
        });
      }
      if(this.forecastItem.repoName === 'BIONEXUS'){
        this.bioNexusClient.retrieveBioNexusMaterialById(this.forecastItem.materialId)
        .subscribe((response)=>{
          if(response && response.length > 0){
            if(this.forecastItem.approverEmail != response[0].approverEmail){
              this.forecastItem.approverEmail = response[0].approverEmail;
              this.forecastItem.approverName = response[0].approverFullName;
            }
          }
        });
      }
    }
    

    let ftt = this.forecastTypes.find(ft => ft.id == this.editForecastItem.forecastTypeId);
    (<UntypedFormControl>this.forecastForm.controls['forecastType'])
        .setValue(ftt, { onlySelf: true });
    if(!this.user.isGlpeligible)
    {
      let fttr = this.forecastTypes.find(ft => ft.forecastType1 == "Stock");
      (<UntypedFormControl>this.forecastForm.controls['forecastType'])
        .setValue(fttr, { onlySelf: true });
    }
    let today = new Date();
    this.forecastingSeasons = this.editAllData.editForecastData.forecastingSeasons.filter(
      s => (s.dueDate >= today && s.startDate <= today) || s.id == this.editForecastItem.forecastingSeasonId
    );

    // let ftsn = this.forecastingSeasons.find(fs => fs.id == this.editForecastItem.forecastingSeasonId);
    // if(ftsn)
    //   (<UntypedFormControl>this.forecastForm.controls['forecastSeason'])
    //       .setValue(ftsn, { onlySelf: true });
    // else{
    //   (<UntypedFormControl>this.forecastForm.controls['forecastSeason'])
    //       .setValue(this.forecastingSeasons[0], { onlySelf: true });
    //}
    this.quantUoms = this.editAllData.editForecastData.uoms;
    // let uom = this.quantUoms.find(u => u.id == this.editForecastItem.quantityUnitOfMeasureId);
    // (<UntypedFormControl>this.forecastForm.controls['QuantityUom'])
    //     .setValue(uom, { onlySelf: true });
    // this.UOM = uom.uomDisplay;
    this.formulationTypes = this.editAllData.editForecastData.formulationTypes;
    let fmtt = this.formulationTypes.find(fmt => fmt.id == this.editForecastItem.formulationTypeId);
    (<UntypedFormControl>this.forecastForm.controls['formulationType'])
        .setValue(this.editForecastItem.formulationType, { onlySelf: true });
    this.reviewers = this.editAllData.editForecastData.reviewers;
    this.reviewers.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);

    this.delegators =  this.editAllData.editForecastData.reviewers;
    this.delegators.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);

    let dlt = this.delegators.find(d => d.id == this.editForecastItem.delegatorUserId);
    (<UntypedFormControl>this.forecastForm.controls['delegate'])
        .setValue(dlt, { onlySelf: true });

    let rev = this.reviewers.find(rv => rv.id == this.editForecastItem.reviewerUserId);
    (<UntypedFormControl>this.forecastForm.controls['reviewer'])
        .setValue(rev, { onlySelf: true });

    this.forecastStates = this.editAllData.editForecastData.forecastStates;
    this.isBypassInventory = this.editForecastItem.bypassReservation;
    this.bindDataToUIForm();
    let dataEntryState = this.forecastStates.find(fs => fs.stateName == 'Data Entry');
    let awaitReviewState = this.forecastStates.find(fs => fs.stateName == 'Awaiting Review');
    let submittedState = this.forecastStates.find(fs => fs.stateName == 'Submitted');
    let materialApprovaState = this.forecastStates.find(fs => fs.stateName == 'Material Approval');

    this.dataEntryStateId = dataEntryState.id;
    this.awaitReviewStateId = awaitReviewState.id;
    this.submittedStateId = submittedState.id
    this.materialApprovalStateId = materialApprovaState.id
    this.ctrnUoms = this.editAllData.editForecastData.cuoms;
    this.ais = this.editForecastItem.forecastItemActiveIngredient;
    this.checkShowAiWarning();
    this.CalculateTotalForecast();
    this.isModify = this.isForecastItemForModify();
    if (this.isSave) {
      this.forecastItem.stateId = this.dataEntryStateId;
      this.forecastItem.id = 0;
    }
    this.SetMaterialType();
    this.SetAltMaterialType();
  }

  public  bindDataToUIForm() {
    let ftst = this.forecastStates.find(fs => fs.id == this.editForecastItem.stateId);
    (<UntypedFormControl>this.forecastForm.controls['materialName'])
        .setValue(this.editForecastItem.productName, { onlySelf: true });
    (<UntypedFormControl>this.forecastForm.controls['materialNameSynonyms'])
        .setValue(this.editForecastItem.synonyms === null ? '' : this.editForecastItem.synonyms.length <= 20 ?
        this.editForecastItem.synonyms.split('||').join('; ') : this.editForecastItem.synonyms.split('||').join('; ').substring(0, 19)+'...'  , { onlySelf: true });
    (<UntypedFormControl>this.forecastForm.controls['forecaster'])
        .setValue(this.editForecastItem.forecastingUserName, { onlySelf: true });
    (<UntypedFormControl>this.forecastForm.controls['alternativeProduct'])
        .setValue(this.editForecastItem.acceptableAlternativeProduct, { onlySelf: true });
    (<UntypedFormControl>this.forecastForm.controls['isReachApproved'])
        .setValue(this.editForecastItem.isReachCompliant, { onlySelf: true });
    (<UntypedFormControl>this.forecastForm.controls['comment'])
        .setValue(this.editForecastItem.comments, { onlySelf: true });
    (<UntypedFormControl>this.forecastForm.controls['bypassInventory'])
    .setValue(this.editForecastItem.bypassReservation, { onlySelf: true });
  }

  public  createForecastForm(){
    this.forecastForm = this.fb.group({
      //'forecastSeason': new UntypedFormControl(''),
      'forecastType': new UntypedFormControl(''),
      'materialName': new UntypedFormControl('', Validators.required),
      'materialNameSynonyms': new UntypedFormControl(''),
      'formulationType': new UntypedFormControl(''),
      'QuantityUom': new UntypedFormControl(''),
      'alternativeProduct': new UntypedFormControl(''),
      'isReachApproved': new UntypedFormControl(false),
      'reviewer': new UntypedFormControl(''),
      'comment': new UntypedFormControl(''),
      'forecaster': new UntypedFormControl(''),
      'delegate': new UntypedFormControl(''),
      'bypassInventory': new UntypedFormControl(false)
    });
  }

  public  onSubmit($event) {
    if(!this.newRevisionDatesAccepted && this.isForecastDateRevisionNeeded()){
        this.needDateRevisionDialog=true;
        return;
    }
    if(this.isBypassInventory && this.buttonName === "Submit for Procurement"){
        this.submitForProcurementDialog = true;
    }
    else if(this.forecastItem.stateName == 'Awaiting Review' && this.isReviewerChanged)
    {
      this.reviewerChangeDialog = true;
    }
   else{
    this.processCreateForecastItem();
   }
  }

  public dateRevisionAccepted($event){
    this.newRevisionDatesAccepted = true;
    this.needDateRevisionDialog = false;
    const forecastDateRevisionDestinations = this.forecastItem.forecastItemDestination?.filter(x=>x.dateNeeded?.getTime() < this.newforecastRevisionDate.getTime());
    forecastDateRevisionDestinations.forEach(element => {
      element.dateNeeded = this.newforecastRevisionDate;
    });
    this.onSubmit($event);
  }

  public isForecastDateRevisionNeeded()
  {
    this.newforecastRevisionDate = this.addDays(46); // 45 days gap between current date to new revised date excluding today
    this.newforecastRevisionDate.setHours(0,0,0,0);
    const forecastDateRevisionDestinations = this.forecastItem.forecastItemDestination?.filter(x=>x.dateNeeded?.getTime()<this.newforecastRevisionDate.getTime()) || [];
    this.needDateRevisionCountries = [...new Set(forecastDateRevisionDestinations.map(x=> this.stringUtility.toPascalCase(x.destinationCountryName)))].join(', ');
    if(forecastDateRevisionDestinations.length > 0){
      return true;
    }
    return false;
  }

  public addDays(numOfDays: number, date = new Date()) {
    const dateCopy = new Date(date.getTime());

    dateCopy.setDate(dateCopy.getDate() + numOfDays);

    return dateCopy;
  }

  public  processCreateForecastItem(){
    if (this.showSearch) {
      return;
    }
    var status = 0;
    let potentialSubmittedStateId = this.submittedStateId;
    //Assigning  Material Approval State only if it is not bypass reservation.
    if (this.forecastItem.approverEmail !== null && this.forecastItem.approverEmail !== undefined  && this.forecastItem.approverEmail != ''
      && !this.forecastItem.bypassReservation && this.forecastItem.approverEmail !== this.forecastItem.forecastingUserEmail) {
      potentialSubmittedStateId = this.materialApprovalStateId;
    }
    if(this.isFormulationSpecialist || this.isCommercialUser)
    {
      status = potentialSubmittedStateId;
    }
   else if(!this.isBiologyLeader && this.isFScientist)
    {
      if(this.forecastForm.get('reviewer').value == null || this.forecastForm.get('reviewer').value.id == undefined)
      {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Reviewer is required' });
        return false;
      }
      status = this.awaitReviewStateId;
    }
    else  if(this.isBiologyLeader)
    {

      if (this.forecastForm.get('reviewer').value == null || this.forecastForm.get('reviewer').value.id == undefined)
        status = potentialSubmittedStateId;
      else if (this.forecastForm.get('reviewer').value.id != this.user.id)
        status = this.awaitReviewStateId;
      else
        status = potentialSubmittedStateId;
    }

    this.submitted = true;
    if((status === this.submittedStateId || status === this.materialApprovalStateId) && (this.forecastItem.dateSubmitted == undefined || this.forecastItem.dateSubmitted == null) )
    {
      this.forecastItem.dateSubmitted = new Date();
    }
    this.getForecastItem(status);
    this.forecastItem.forecastItemDestination = this.forecastDestinations;
    
    if(this.forecastComments) {
      this.forecastItem.forecastItemCommentLogModel = [];
      for(let forecast of this.forecastComments){
         let logcomment: ForecastItemCommentLogModel = new ForecastItemCommentLogModel();
         logcomment.comment = forecast.comment;
         logcomment.userId = forecast.user.id;
         logcomment.user = forecast.user;
         this.forecastItem.forecastItemCommentLogModel.push(logcomment);
      }
     }

    this.createForecastItem(this.forecastItem, this.isSave);
  }

  public openCommentDialog(){
    this.commentText = '';
    this.dialogAcceptCommentDialog=true;
  }

  public onComment()
  {
    if(this.forecastItem.id != undefined && !this.isForecastCopy)
    {
      this.forecastComments = [];
      this.forecastItem.updatedBy = this.user.id;
      this.forecastItem.comments = this.commentText;
      this.SaveComment(this.forecastItem);
    }
    else {
      let item : any = {
      comment: this.commentText,
      user: this.user,
      dateEntered: new Date()
      }
      this.forecastComments.push(item);
      this.totalCount = this.forecastComments.length;
      this.dialogAcceptCommentDialog = false;
    }
  }

  public pageChanged(event: any) {
    this.pageIndex = (event.first / this.PAGE_SIZE) + 1;
    this.getData(false);
  }

  public getData(setCount: boolean = true): void {
    if (this.user === null || this.user === undefined || this.user.id === undefined) {
      this.messageService.add({
        severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: 'Your session has expired. Please login again.' });
        this.router.navigate(['login']);
      return;
    }
     this.forecastItemClient.getForecastItemComments(this.forecastItem.id)
      .subscribe(data => {
        this.forecastComments = data.comments;
        if (setCount ) {
          this.pageIndex = 1;
          this.totalCount = data.totalCount;
          this.dataView.first = 0;
        }
        console.log(data);
     },
     error => {
       this.messageService.add({
         severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
         this.router.navigate(['login']);
     });
  }
  public  UpdateReviewer()
{
  if(this.isSaveButton)
  {
    this.reviewerChangeAccepted = true;
    this.onSave(null);
  }
  else{
    this.processCreateForecastItem();
  }
}
public  onSave($event) {
    if(this.forecastItem.stateName == 'Awaiting Review' && this.isReviewerChanged && !this.reviewerChangeAccepted)
    {
      this.reviewerChangeDialog = true;
      this.isSaveButton = true;
      return false;
    }
    this.submitted = true;
    this.getForecastItem(this.isSave ?  this.dataEntryStateId : this.editForecastItem.stateId);
    this.forecastItem.forecastItemDestination = this.forecastDestinations;
    this.forecastItem.updatedBy = this.user.id;
    if(this.forecastComments) {
     this.forecastItem.forecastItemCommentLogModel = [];
     for(let forecast of this.forecastComments){
        let logcomment: ForecastItemCommentLogModel = new ForecastItemCommentLogModel();
        logcomment.comment = forecast.comment;
        logcomment.userId = forecast.user.id;
        logcomment.user = forecast.user;
        this.forecastItem.forecastItemCommentLogModel.push(logcomment);
     }
    }
    this.createForecastItem(this.forecastItem, this.isSave);
  }
  public  onUomChange() {
    this.forecastItem.quantityUnitOfMeasureId = this.forecastForm.get('QuantityUom').value.id;
    this.UOM = this.forecastForm.get('QuantityUom').value.uomDisplay;
  }
  public  getForecastItem(stateId: number) {
//    this.forecastItem.isDas = this.forecastForm.get('isDas').value.code;
    this.forecastItem.isReachCompliant = this.forecastForm.get('isReachApproved').value;
//    this.forecastItem.productName = this.forecastForm.get('materialName').value;
    this.forecastItem.quantityUnitOfMeasureId = this.forecastForm.get('QuantityUom').value.id;
    if (this.forecastForm.get('delegate').value !== undefined)
      this.forecastItem.delegatorUserId = this.forecastForm.get('delegate').value.id;
    this.forecastItem.forecastTypeId = this.forecastForm.get('forecastType').value.id;
  //  if(this.isCommercialUser || this.isFormulationSpecialist || this.isBypassInventory)
  //  {
  //   this.forecastItem.forecastingSeasonId  = null;
  //  }
  //  else{
  //    var seas = this.forecastForm.get('forecastSeason').value;
  //   this.forecastItem.forecastingSeasonId = seas === undefined ? null : seas.id;
  //  }
    if(this.isSave)
    {
      if((this.isForecastCopy && !this.isMaterialPlanner ) || !this.isForecastCopy)
        this.forecastItem.forecastingUserId =  this.user.id;
        this.forecastItem.forecastingUserEmail = this.user.email;
    }
//    if (stateId == this.submittedStateId) {
      this.forecastItem.stateId = stateId;
//    }
//     this.forecastItem.formulationNumber = this.forecastForm.get('formulationNumber').value;
//    this.forecastItem.formulationTypeId = this.forecastForm.get('formulationType').value.id;
    if (this.forecastForm.get('reviewer').value !== undefined)
      this.forecastItem.reviewerUserId = this.forecastForm.get('reviewer').value != null ? this.forecastForm.get('reviewer').value.id : null;
//    this.forecastItem.acceptableAlternativeProduct = this.forecastForm.get('alternativeProduct').value;
    this.forecastItem.comments = this.forecastForm.get('comment').value;
    this.forecastItem.bypassReservation = this.forecastForm.get('bypassInventory').value;
//    this.forecastItem.forecastItemActiveIngredient = this.ais;
  }
  public reviewerSeleted(event)
  {
    if(this.forecastItem.stateName == "Awaiting Review")
    {
      this.isReviewerChanged = true;
    }
    if(this.isBiologyLeader)
    {
      if(event.id != this.user.id)
      this.buttonName="Send For Review";
    }
  }
  public reviewerRemoved()
  {
    if(this.isBiologyLeader)
    {
      this.buttonName="Submit for Procurement";
      this.enableSubmittButton = true;
    }
  }
  public filterReviewers(event) {
    let query = event.query;

        this.filteredReviewers =  [];
    for(let i = 0; i < this.reviewers.length ; i++) {
        let reviewer = this.reviewers[i];

        if (this.user.id != reviewer.id && reviewer.secondaryEmail.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            this.filteredReviewers.push(reviewer);
        }
    }
  }
  public  SaveComment(item: ForecastItemModel)
  {
    this.disableonComment = true;
    this.forecastItemClient.updateComments("MyForecast",item)
      .subscribe(data => {
        this.dialogAcceptCommentDialog = false;
        this.commentText = '';
        this.getData();
        this.messageService.add({severity:'info', summary:'Success', detail:'Comment added successfully'});
        this.disableonComment = false;
      },
      error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Comment not added. Please contact admin'});
        this.disableonComment = false;
      });
  }

  public  createForecastItem(item: ForecastItemModel, isSave: any) {
    this.disableonSubmit = true
    if (isSave) {
    if(item.forecastItemDestination?.length > 0 && item.bypassReservation && (this.forecastItem.stateId == this.submittedStateId)){
      item.forecastItemDestination.forEach(x => {
        x.inventoryOrderStateName = this.toOrderState.stateName;
        x.inventoryOrderStateId = this.toOrderState.id;
        x.inventoryOrderState = this.toOrderState;
      });
    }
      this.forecastItemClient.createForecastItem(item)
      .subscribe(data => {
        this.forecastItemId = data.id;
        console.log(data);
        this.messageService.add({severity: 'info', summary:'Success', detail:'Forecast Saved'});
        this.router.navigate(['forecastitemEdit/' + data.id]);
        this.disableonSubmit = false;
      },
      error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Forecast Item not Saved. Please contact admin'});
        this.disableonSubmit = false;
      });
    }
    else {
      this.forecastItemClient.updateForecastItem(item.id, item)
      .subscribe(data => {
        // this.forecastItemId = data.id;
        console.log(data);
        this.messageService.add({severity:'info', summary:'Success', detail:'Forecast Updated'});
        this.router.navigate(['forecasts'])
        // if(this.router.url.indexOf('reload') >= 0)
        //   this.router.navigate(['/forecastitem', this.forecastItem.id]);
        // else
        //   this.router.navigate(['/forecastitem/reload', this.forecastItem.id]);
      },
      error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Forecast Item not Updated. Please contact admin'});
        this.disableonSubmit = false;
      });
    }
  }
  public  onReset($event) {
    this.resetForecastForm();
    this.messageService.add({severity:'info', summary:'Success', detail:'Form Canceled'});
    this.router.navigate(['forecasts']);
  }
  public  resetForecastForm() {
    this.forecastForm = this.fb.group({
      //'forecastSeason': new UntypedFormControl(this.forecastingSeasons[0]),
      'materialName': new UntypedFormControl('', Validators.required),
      'formulationType': new UntypedFormControl(''),
      'QuantityUom': new UntypedFormControl(this.quantUoms[0]),
      'alternativeProduct': new UntypedFormControl(''),
      'isReachApproved': new UntypedFormControl(false),
      'reviewer': new UntypedFormControl(this.reviewers[0]),
      'comment': new UntypedFormControl(''),
      'forecaster': new UntypedFormControl(''),
      'delegate': new UntypedFormControl(''),
      'bypassInventory': new UntypedFormControl('')
    });
  }
  public  checkFScientist() {
    return this.user.roles.some(r => r.name == 'Field Scientist');
  }
  public  checkBiologyLeader() {
    return this.user.roles.some(r => r.name == 'Biology Leader');
  }
  public  checkMaterialPlanner() {
    return this.user.roles.some(r => r.name == 'Material Planner');
  }
  public getTitleStyle(forecastItemModel:any) {
    switch (forecastItemModel.stateName)
    {
      case  this.commonService.configurations.ForecastStatus.DataEntry :  return 'clsDataEntryPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.AwaitingReview :  return 'clsAwaitingReviewPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.Submitted :  return 'clsSubmittedPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.InProcess :  return 'clsInProcessPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.Completed : return 'clsCompletedPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.DelegateReview: return 'clsDelegateReviewPanelTitleBar' ; break ;
      case this.commonService.configurations.ForecastStatus.Procurement : return 'clsProcurementPanelTitleBar' ; break ;
      case this.commonService.configurations.ForecastStatus.Archived : return 'clsArchivedPanelTitleBar' ; break ;
      case this.commonService.configurations.ForecastStatus.Deleted : return 'clsDeletedPanelTitleBar' ; break ;
      case this.commonService.configurations.ForecastStatus.MaterialApproval : return 'clsMaterialApprovaPanelTitleBar' ; break ;
    }
    return '';
  }
  public CheckFormulationSpecialist(){
    return this.user.roles.some(r => r.name == "Formulation Specialist");
  }
  public checkCommercialUser() {
     return this.user.roles.some(r => r.name == "Commercial User");
  }
  public isUserForModify() {
    let result = false;
    let isForecastForModify = this.isForecastItemForModify();
    if (this.isMaterialPlanner()) {
      result = true;
    }
    else if ((this.user.id === this.editAllData.editForecastData.editForecastItem.forecastingUserId
      || this.user.id === this.editAllData.editForecastData.editForecastItem.reviewerUserId
      || this.user.id === this.editAllData.editForecastData.editForecastItem.delegatorUserId)
      && isForecastForModify ) {
        result = true;
    } else if (this.isSave) {
      result = true;
    }
    return result;
  }
  public isUserForDelete(){
    let result = false;
    let isForDelete = this.isForecastItemForDelete();
    if (this.isMaterialPlanner()) {
      result = true;
    }
    else if (this.user.id === this.editAllData.editForecastData.editForecastItem.forecastingUserId
      || this.user.id === this.editAllData.editForecastData.editForecastItem.reviewerUserId
      || this.user.id === this.editAllData.editForecastData.editForecastItem.delegatorUserId
      || isForDelete ) {
        result = true;
      }
      return result;
  }
  public isForecastItemForModify() {
    let states = ["Data Entry", "Delegate Review", "Awaiting Review", "Submitted"];
    return states.some(s => s === this.editAllData.editForecastData.editForecastItem.stateName);
  }
  public isForecastItemForDelete() {
    let states = ["Data Entry", "Delegate Review", "Awaiting Review"];
    return states.some(s => s === this.editAllData.editForecastData.editForecastItem.stateName);
  }

  public forecastFormControlValueChanged() {
  }
  public aiFormControlValueChanged() {

  }
  public AddDestination(forecastDetination:ForecastItemDestinationModel)
  {
    this.forecastDestinations.push(forecastDetination);
    this.forecastDestinations = [...this.forecastDestinations];
    this.CalculateTotalForecast();
  }
  public UpdateDestination(Data:any)
  {
    var index = this.forecastDestinations.indexOf(Data[0]);
    this.forecastDestinations[index] = Data[1];
    this.forecastDestinations = [...this.forecastDestinations];
    this.CalculateTotalForecast();
  }
  public RemoveDestination(forecastDetination:ForecastItemDestinationModel)
  {
    const index = this.forecastDestinations.indexOf(forecastDetination);
    if (index > -1) {
      this.forecastDestinations.splice(index, 1);
      this.forecastDestinations = [...this.forecastDestinations];
    }
    this.CalculateTotalForecast();
  }
  public AddTrackingIdForDestinations()
  {
    for(var i =0;i < this.forecastDestinations.length;i++)
    {
      this.forecastDestinations[i].trackingId = i + 1;
    }
  }
  CalculateTotalForecast() {
    this.totalForecast = this.GetAggregatedQuantity();
  }
  groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }
  public GetAggregatedQuantity(): string {
    let quantity = '0';
      let byUom = this.groupBy(this.forecastDestinations, 'quantityUomName');
      let uoms = Object.entries(byUom).map(m => [m[0], (m[1] as ForecastItemDestinationModel[]).reduce((ty, u) => ty + u.quantity, 0.000)]);
      if (uoms != null && uoms.length > 0) {
        quantity = '';
        uoms.forEach(a => {
          quantity = quantity + (quantity.length > 0 ? ' + ' : '') + parseFloat(a[1].toString()).toFixed(3) + ' ' + a[0];
        });
      }
    return quantity;
  }
public showAIDialog(isMaterialInfo?: boolean){

      this.isMaterialInfo = isMaterialInfo;
      //this.showMaterialDialog = true;
      this.determineMaterialNameLabel();
      this.isShowAIDialog=true;

  }
  public GetFormulationTypeName(formulationTypeName , formlulationTypeId )
  {
    if(formlulationTypeId == null)
    {
      return formulationTypeName
    }
    else
    {
      let formulationTypeName = this.formulationTypes.find(fmt => fmt.id == formlulationTypeId);
      return formulationTypeName.description + " (" + formulationTypeName.formulationTypeCode + ")";
    }
  }
  public GetTitle(){
    if(this.materialType == 'Formulation Lot' || this.materialType == 'Formulation'
    ||this.materialType == 'Compound Lot'|| this.materialType == 'Compound'
    ||this.materialType =='Coformulant/Field Inert'){
      return 'Click Here To View Details'
    }
    else
    {
      return  'Material Info';
    }
  }
  public GetDialogTitle(){
    if(this.materialType == 'Formulation Lot' ){
      return 'Formulation Lot ID Information'
    }
    else if(this.materialType == 'Formulation') {
      return  'Formulation Information';
    }
    else if (this.materialType =='Compound')
    {
      return 'Material ID Information';
    }
    else if (this.materialType =='Coformulant/Field Inert')
    {
      return 'CoFormulant Information';
    }
    else if(this.materialType =="Compound Lot")
    {
      return 'Material Lot ID Information';
    }
    else if(this.forecastItem.materialSearchType === 'BioMaterialLotId')
    {
      return 'BioNexus Lot Information';
    }
    else if(this.materialType === 'Material')
    {
      return 'BioNexus Material Information';
    }

    return "";
  }
  public GetAltDialogTitle(){
    if(this.altMaterialType == 'Formulation Lot' ){
      return 'Formulation Lot ID Information'
    }
    else if(this.altMaterialType == 'Formulation') {
      return  'Formulation Information';
    }
    else if (this.altMaterialType =='Compound')
    {
      return 'Material ID Information';
    }
    else if (this.altMaterialType =='Coformulant/Field Inert')
    {
      return 'CoFormulant Information';
    }
    else if(this.altMaterialType =="Compound Lot")
    {
      return 'Material Lot ID Information';
    }
    else if(this.forecastItem.altMaterialSearchType === 'BioMaterialLotId')
    {
      return 'BioNexus Lot Information';
    }
    else if(this.altMaterialType === 'Material')
    {
      return 'BioNexus Material Information';
    }
  }
  public createAIForm() {
    this.aiform = this.fb.group({
      'aiIngredient': new UntypedFormControl('', Validators.required),
      'concentration': new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{0,6}\\.?[0-9]{0,3}$'),]),
      'ctrnUom': new UntypedFormControl(''),
    });
  }
  public onAIFormShow(){
    this.aiform.reset();
    this.ais.forEach(ai =>
      ai.forecastItemName = ai.activeIngredient + " (" + ai.concentrationValue + " "
      + ai.concentrationUomName + ")");
    this.agGrid.api.setRowData( this.ais );
    (<UntypedFormControl>this.aiform.controls['ctrnUom'])
      .setValue(this.ctrnUoms[0], { onlySelf: true });
    (<UntypedFormControl>this.aiform.controls['aiIngredient'])
      .setValue("", { onlySelf: true });
    (<UntypedFormControl>this.aiform.controls['concentration'])
      .setValue("", { onlySelf: true });
  }
  public checkShowAiWarning() {
  }
  public onBtnClick(e) {
    this.confirmationService.confirm({
      message: 'You are about to remove '
      + e.rowData.forecastItemName
      + '. This action cannot be undone. Are you sure you want to do this?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        var ai = e.rowData;
        const idx = this.ais.findIndex(a => a.forecastItemName == ai.forecastItemName);
        this.ais.splice(idx, 1);
        this.agGrid.api.setRowData(this.ais);
        this.isShowAiWarning = this.ais.length < 1;
        if (this.isShowAiWarning) {
          (<UntypedFormControl>this.forecastForm.controls['isShowAiWarning'])
            .setValue(true, { onlySelf: true });
        }
        this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted' });
      }
    });
  }
  public onAddAi() {
    var newAi = new ForecastItemActiveIngredientModel();
    newAi.activeIngredient = this.aiform.controls['aiIngredient'].value;
    newAi.concentrationValue = this.aiform.controls['concentration'].value;
    newAi.concentrationUomId = this.aiform.controls['ctrnUom'].value.id;
    newAi.concentrationUomName = this.aiform.controls['ctrnUom'].value.uomDisplay;
    if (!this.isSave)
      newAi.forecastItemId = this.forecastItem.id;
    newAi.forecastItemName = newAi.activeIngredient + " (" + newAi.concentrationValue + " " + this.aiform.controls['ctrnUom'].value.uomDisplay + ")";
    let dupl = this.ais.filter((item) => {
      return item.activeIngredient == newAi.activeIngredient;
    });
    if (dupl.length > 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate active ingredient not allowed' });
    } else {
      this.ais.push(newAi);
    }
    this.agGrid.api.setRowData( this.ais );
    this.isShowAiWarning = this.ais.length < 1;
    if (!this.isShowAiWarning) {
      (<UntypedFormControl>this.forecastForm.controls['isShowAiWarning'])
        .setValue(false, { onlySelf: true });
    }
    this.onAIFormShow();
  }

  public getSeasonDelegators()
  {
    this.delegatesClient.getDelegations(this.user.id)
    .subscribe(data => {
            this.seasionDelegators = data[0].delegates;
            this.seasionDelegators.forEach(element => {
              element.selected = false;
            });
            this.SelectAll = false;
             this.isShowDelegateDialog = true;
    });

  }
  public createDelegateForecast()
  {
    var forecastItemid = 0;
    this.getForecastItem( this.dataEntryStateId);
    this.forecastItem.forecastItemDestination = this.forecastDestinations;
    forecastItemid = this.forecastItem.id;
    this.forecastItem.id = 0;
    this.forecastItem.delegatorUserId = this.user.id;
    if (this.forecastItem.reviewerUserId == null)
    {
      this.forecastItem.reviewerUserId  = this.user.id;
    }
    this.selectedSeasionDelegators = new Array<UserModel>();
    this.seasionDelegators.filter(x => x.selected == true).forEach(element => {
      this.selectedSeasionDelegators.push(element);
    });
    const fidu: ForecastItemDelegatedUsers = new ForecastItemDelegatedUsers();
    fidu.forecastItemModel = this.forecastItem;
    fidu.delegaterUsers = this.selectedSeasionDelegators;
    this.forecastItemClient.createDelegateForecastItem(fidu).subscribe(data => {
        this.forecastItemClient.deleteForecastItem(forecastItemid).subscribe(data => {
          this.router.navigate(['forecasts']);
        });
    });
  }

  public onCheck()
  {
    if( this.seasionDelegators.filter(x => x.selected == true).length > 0)
     this.enableAccept = true;
     else
      this.enableAccept = false;

      this.SelectAll = this.seasionDelegators.every((item:any) => {
        return item.selected == true;
      });
  }
  public selectAll()
  {
    if(this.SelectAll)
     this.enableAccept = true;
    else
     this.enableAccept = false;

     this.seasionDelegators.forEach(element => {
      element.selected = this.SelectAll;
    });
  }

  public setInventory(event: any) {
    this.isBypassInventory = event.checked;
  }

  public disableSaveBtn(isFormValid: boolean) {
    if (!this.isMaterialPlanner && 
        (!isFormValid || !this.isModify ||
          this.forecastDestinations.some(x => x.dateNeeded === null || x.dateNeeded === undefined ||
                                         x.dateNeededLatest === null || x.dateNeededLatest === undefined)
    )) {
      return true;
    }

    if(this.forecastDestinations.some(x => x.dateNeeded === null || x.dateNeeded === undefined ||
              x.dateNeededLatest === null || x.dateNeededLatest === undefined))
              return true;
    return false;
  }

  public disableSubmitBtn(isFormValid:boolean)
  {
    if(!this.isBypassInventory && !this.isFormulationSpecialist)
    {
      // var seas = this.forecastForm.get('forecastSeason').value;
      // if(seas === undefined || seas === " " || seas?.id === null)
      //     return true;
    }
    if(this.forecastItem.stateName == 'Awaiting Review' && (this.isReviewerChanged
     || this.enableSubmittButton || this.editForecastItem.reviewerUserId == null))
    {
      return false;
    }

    if (!isFormValid || !this.isModify  || this.forecastDestinations?.length == 0 || ((this.isCommercialUser || this.isFormulationSpecialist) && this.forecastDestinations.some(x => x.dateNeededLatest === null || x.dateNeededLatest === undefined) ))
    {
      return true;
    }
    else if ((!this.isBiologyLeader && this.isFScientist && this.forecastItem.stateName != 'Data Entry') || (this.isBiologyLeader && this.user.id != this.forecastItem.reviewerUserId && this.forecastItem.stateName == 'Awaiting Review'))
    {
      return true;
    }

    if(this.forecastDestinations.length != 0 && this.forecastDestinations.some(x => x.dateNeededLatest === null || x.dateNeededLatest === undefined))
    {
      return true;
    }

    return false;

  }
}
