import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { CountryClient, ForecastStateClient, InventoryStateClient, SearchClient, UserClient, UserModel } from '../forecast-api-client.service';
import { SecurityService } from '../security.service';

@Injectable()
export class SubmitForecastResolver implements Resolve<Observable<any>> {
    constructor(
        private inventoryStateClient: InventoryStateClient,
    private userClient: UserClient,
    private searchClient: SearchClient,
    private securityService: SecurityService,
    public countryClient: CountryClient
    ){

    }
    
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) : Observable<any> {
        var userInfo = this.securityService.getUser();
        return forkJoin(
            this.inventoryStateClient.getInventoryStates(),
            this.userClient.getUsersInRoles(['Material Planner']) ,
            this.searchClient.getSubmittedForecastSearchFormulas(userInfo.id) ,
            this.countryClient.getCountries(),
            this.userClient.getUsers()
        )
        .pipe(
            map(([InventoryStates, MaterialPlanners, SearchFormulas,CountryModel,Users]) => {   
                const defaultOrderBy = ['To Do', 'In Stock', 'To Order', 'Follow Up', 'Ordered', 'Cancelled'];
                InventoryStates = InventoryStates.sort((a, b) => {
                    return defaultOrderBy.indexOf(a.stateName) - defaultOrderBy.indexOf(b.stateName);
                });
                  
                MaterialPlanners.forEach(x => { x.secondaryEmail = x.firstName + ' ' + x.lastName; });
                MaterialPlanners = MaterialPlanners.sort((a,b) => a.fullName > b.fullName ? 1:-1);
                CountryModel = CountryModel.filter(x => x.isProhibited == false).sort((a, b) => {
                    if(a.displayName < b.displayName) { return -1; }
                    if(a.displayName > b.displayName) { return 1; }
                    return 0;
                  });
                return {InventoryStates, MaterialPlanners,SearchFormulas,CountryModel,Users}
            })
        )

    }

}