import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs/Observable';
import { map, tap } from 'rxjs/operators';
import { ConfirmationDialog } from './confirmation-dialog.component';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any> {
  dialogRef: DynamicDialogRef;
  constructor(private dialogService: DialogService) {}

  canDeactivate(component: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (component.canDeactivate && component.canDeactivate()) {
      const dialogRef = this.dialogService.open(ConfirmationDialog, {
        header: 'Save Changes?',
        width: '25%',
        contentStyle: { 'max-height': '500px', overflow: 'auto' },
        baseZIndex: 10000,
        dismissableMask: true
      });

      return dialogRef.onClose.pipe(
        tap((res) => {
          if (res.save) {
            component.saveData();
          }
        }),
        map((res) => {
          if (res.leave || res.save) {
            return true;
          }
          if (res.cancel) {
            return false;
          }
        })
      );
    }
    return true;
  }
}
