<div class="grid">
  <div class="col-12 lg:col-offset-1 xl:col-offset-1">
    <h4 style="font-weight: bold;">Manage Countries</h4>
  </div>
</div>
<div class="grid">
  <div class=" lg:col-offset-1  xl:col-offset-1">
    <ag-grid-angular
      style="width: 600px; height: 500px;"
      class="ag-theme-alpine"
      [rowData]="countryModels"
      [columnDefs]="columnDefs"
      [frameworkComponents]="frameworkComponents"
      [context]="context"
      [pagination]="true"
      [paginationPageSize]="25"
      ></ag-grid-angular>
  </div>
</div>
<p-dialog header="{{country.displayName}}" [(visible)]="displayDialog" [style]="{'min-width':'700px'}" [modal]="true"
    [dismissableMask]="true">
  <form>
    <div class="form-group row" hidden>
      <div class="col-md-3"><label for="Id">Id</label></div>
      <div class="col-md-6"><input type="text" id="txtCountryId" name="Id" placeholder="Id"
              [(ngModel)]="country.id" pInputText class="col-md-12"></div>
      <div class="col-md-3">
          <!--Validation Errors-->
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="Country">Country</label></div>
      <div class="col-md-6"><input type="text" id="txtCountryDisplayName" name="Country" placeholder="Country"
              [(ngModel)]="country.displayName" pInputText class="col-md-12"></div>
      <div class="col-md-3">
          <!--Validation Errors-->
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="Code">Code</label></div>
      <div class="col-md-6"><input type="text" id="txtCountryCode" name="Code" placeholder="Code"
                [(ngModel)]="country.countryCode" pInputText class="col-md-12"></div>
      <div class="col-md-3">
            <!--Validation Errors-->
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-3"><label for="IsProhibited">Is Prohibited</label></div>
      <div class="col-md-6"><p-checkbox inputId="{{country.id}}" name="CountryCheckboxGroup"
        [(ngModel)]="country.isProhibited" id="chkCountryIsProhibited" binary="true" value="{{country.countryCode}}"
        label="Is Prohibited"></p-checkbox></div>
      <div class="col-md-3">
          <!--Validation Errors-->
      </div>
    </div>
  </form>
  <p-footer>
    <button type="button" pButton icon="fa fa-check" id="btnCountrySave" (click)="save()" label="Save" *ngIf="!isEditable"></button>
    <!-- <button type="button" pButton icon="fa fa-save" (click)="update()" *ngIf="isEditable" label="Update"></button> -->
    <!--<button type="button" pButton icon="fa-remove" (click)="Delete()" [hidden]="!isEditable" label="Delete"></button>-->
    <!--This delete moved into grid column beside edit button.-->
    <button type="button" pButton icon="fa fa-close" id="btnCountryCancel"  (click)="cancel()" label="Cancel"></button>
  </p-footer>
</p-dialog>
<p-toast></p-toast>
