import { Component, Input, Output, OnInit, QueryList, ViewChildren, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForecastItemDestinationClient, ForecastTypeClient, ForecastTypeModel,
  ForecastItemDestinationModel, InventoryStateClient, MaterialPlanningClient,
  PlannerItem, SampleInventoryLocationClient, UserModel,
  ForecastItemCommentLogModel} from 'src/app/services/forecast-api-client.service';
import { ITCIntegrationClient, ProductSearch } from 'src/app/services/gsscommon-api-client.service';
import { MultiSelect } from 'primeng/multiselect';
import { CommonService } from 'src/app/services/common.service';
import { MessageService } from 'primeng/api';
import { SecurityService } from 'src/app/services/security.service';
import { ForecastItemClient, ForecastItemModel } from 'src/app/services/forecast-api-client.service';
import { ForecastlocationService } from 'src/app/services/forecastlocation.service';

@Component({
  selector: 'app-forecast-destinations',
  templateUrl: './forecast-destinations.component.html',
  styleUrls: ['./forecast-destinations.component.scss']
})
export class ForecastDestinationsComponent implements OnInit {
  @Input('destinations') destinations: any[];
  @Input('forecastStatus') forecastStatus: string;
  @Input('materialPlannerUserId') materialPlannerUserId: any;
  @Input('isBypassReservation') isBypassReservation: boolean;
  @Input('repoName') repoName : string;
  @Input('excludeGLPforSGS') ExcludeGLPforSGS : boolean;

  public dialogCancelConfirm = false;
  public dialogCancelError = false;
  public dialogShowComment = false;
  public dialogAcceptCommentDialog = false;
  public forecastLocationItemComment = '';
  public submitterEmail = '';
  public submittedBy = '';
  public commentText = '';
  public dateNeeded: any;
  public dialogOutsourceConfirm = false;
  @ViewChildren(MultiSelect) multiSelect: QueryList<MultiSelect>;
  public saveDest: ForecastItemDestinationModel = null;
  public sampleInventoryLocations: any = [];
  public user: UserModel;
  forecastTypes: ForecastTypeModel[] = [];
  itemByIndexValue = 0;
  showRequester: boolean = false;
  public cancelledSelected: any;
  public outsourcedSelected: any;
  public saveDestModel: ForecastItemDestinationModel = new ForecastItemDestinationModel();
  public productSearch: ProductSearch = new ProductSearch();
  public inventoryStates: any;
  public inventoryToDoState: any;
  public inventoryOrderedState: any;
  public inventoryToOrderState: any;
  public inventoryInStockState: any;
  public inventoryCancelledState: any;
  public inventoryOutsourcedState: any;
  cancelledQty:number=0;
  cancelPopupHeader:string;
  hasUsedQty:boolean;
  remainingQty:string;
  dialogCancelConfirmTitle:string;
  public lotNumberRegEx = /^[a-zA-Z0-9- ]{0,20}$/;
  @Output() destStatusUpdated = new EventEmitter<string>();
  @Output() setMaterialPlanner = new EventEmitter<any>();
  enableReserveBtn: boolean = false;
  enableId: number;
  forecastItem: ForecastItemModel;
  forecastItemId: number;
  forecastComments:Array<ForecastItemCommentLogModel> = [];
  totalCount: number;
  selectedForecastLocationId : number;
  destinationOrderedQuantity: number[] = [];

  constructor(private route: ActivatedRoute,
    private messageService: MessageService,
    private commonService: CommonService,
    private mpClient: MaterialPlanningClient,
    private inventoryStateClient: InventoryStateClient,
    private securityService: SecurityService,
    private itcClient: ITCIntegrationClient,
    private forecastItemClient: ForecastItemClient,
    private forecastItemDestinationClient:ForecastItemDestinationClient,
    private sampleInventoryLocation: SampleInventoryLocationClient,
    private router: Router,
    private forecastTypeClient: ForecastTypeClient
  ) { this.user = this.securityService.getUser(); }

  ngOnInit(): void {
    console.log("repo:", this.repoName);
    this.getInventoryStateList();
    this.getSampleInventoryLocations();
    this.getStorageLocations();
    console.log("destinations...", this.destinations);
    if (this.destinations) {
      this.destinations.forEach(x => {
        x['oldLotNumber'] = x.lotNumber;
        x['enableReserve'] = false;
        x['oldInvLocation'] = x.inventoryLocation;
        x['oldStrgLocation'] = x.forecastType;
        x['oldInventoryOrderState'] = x.inventoryOrderState;
        x['selectedInventoryOrderStates'] = [x.inventoryOrderState];
      });
    }

  }
  ngOnChanges() {
    if (this.destinations != null && this.inventoryStates != null) {
      console.log("destinations...", this.destinations);
      this.destinations.forEach(item => {
        if (item.inventoryOrderState.id == this.inventoryOrderedState.id) {
          item.inventoryOrderStateDropdown = this.inventoryStates;
        }
        else {
          item.inventoryOrderStateDropdown = this.inventoryStates.filter(x => x.stateName != 'Ordered');
        }

        item['forecastClassName'] = this.getTitleStyle(item);
      });
    }
  }

  public getStorageLocations(){
    this.forecastTypeClient.getForecastTypes().subscribe(x => {
      this.forecastTypes = x;
      console.log("forecastTypes...", this.forecastTypes);
    });
  }

  public OpenForecastItem(forecastItemId: number) {
    this.router.navigate(['/forecastitemEdit/' + forecastItemId]);

  }

  public getInventoryStateList() {
    this.inventoryStateClient.getInventoryStates()
      .subscribe(data => {
        //  data.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
        console.log(data);

        let test = this.destinations;
        this.inventoryStates = data;
        //To Do, Follow Up, To Order, In Stock, Cancelled, Outsourced
        const defaultOrderBy = ['To Do', 'Follow Up', 'To Order', 'Ordered', 'In Stock', 'Cancelled','Outsourced'];
        this.inventoryStates = this.inventoryStates.sort((a, b) => {
          return defaultOrderBy.indexOf(a.stateName) - defaultOrderBy.indexOf(b.stateName);
        });
        //this.inventoryStates = this.inventoryStates.filter(x => x.stateName != 'Ordered');
        this.inventoryToDoState = data.filter(x => x.stateName == 'To Do')[0];
        this.inventoryInStockState = data.filter(x => x.stateName == 'In Stock')[0];
        this.inventoryOrderedState = data.filter(x => x.stateName == 'Ordered')[0];
        this.inventoryToOrderState = data.filter(x => x.stateName == 'To Order')[0];
        this.inventoryCancelledState = data.filter(x => x.stateName == 'Cancelled')[0];
        this.inventoryOutsourcedState = data.filter(x => x.stateName == 'Outsourced')[0];
        this.destinations.forEach(item => {
          if (item.inventoryOrderState.id == this.inventoryOrderedState.id) {
            item.inventoryOrderStateDropdown = this.inventoryStates;
          }
          else {
            item.inventoryOrderStateDropdown = this.inventoryStates.filter(x => x.stateName != 'Ordered');
          }

          item['forecastClassName'] = this.getTitleStyle(item);
        });
      });
  }

  public ShowComment(id: any) {
    this.selectedForecastLocationId = id;
    this.destinations.find(x => x.id == id)['showComment'] = true;
    this.dialogShowComment = true;
    this.forecastLocationItemComment = this.destinations.find(x => x.id == id)['comment'];
    this.forecastComments = this.destinations.find(x => x.id == id)['forecastItemCommentLogModel'];
    this.totalCount = this.forecastComments.length;
    this.forecastItemId = this.destinations.find(x => x.id == id)['forecastItemId'];
  }

  public openCommentDialog(){
    this.dialogAcceptCommentDialog = true;
  }

  public onComment(){
    this.forecastItem = new ForecastItemModel();
    this.forecastItem.id = this.forecastItemId;
    this.forecastItem.updatedBy = this.user.id;
    this.forecastItem.comments = this.commentText;
    var commentsDestinations = this.destinations.find(x => x.forecastItemId == this.forecastItemId);
    var newItemCommentModel = new ForecastItemCommentLogModel();
    newItemCommentModel.comment =  this.commentText;
    newItemCommentModel.forecastItemId =  this.forecastItemId;
    newItemCommentModel.user =   this.user;
    newItemCommentModel.dateEntered = new Date();
    commentsDestinations.forecastItemCommentLogModel.unshift(newItemCommentModel);
    this.forecastComments = commentsDestinations.forecastItemCommentLogModel;
    commentsDestinations.comment = this.commentText;
    this.totalCount = this.forecastComments.length;
    let currentSelectedDestination = new ForecastItemDestinationModel();
    currentSelectedDestination.id = this.selectedForecastLocationId;
    this.forecastItem.forecastItemDestination = [];
    this.forecastItem.forecastItemDestination.push(currentSelectedDestination);
    this.SaveComment(this.forecastItem);
  }

  public updateAutoInventoryQualified(forecastDestinationId:number){
    let forecastDestination = this.destinations.find(x => x.id == forecastDestinationId);
    if(forecastDestination){
      forecastDestination['qualifiedForAutoInventory'] = false;
    }
  }

  public SaveComment(item: ForecastItemModel)
  {
    if(this.materialPlannerUserId == null || this.materialPlannerUserId == undefined){
      console.log(this.materialPlannerUserId + '-User');
      this.setMaterialPlanner.emit(this.user.id);
    }
    this.forecastItemClient.updateComments("SubmittedForecast",item)
      .subscribe(data => {
        // if(item.forecastItemDestination && item.forecastItemDestination[0]){
        //   this.updateAutoInventoryQualified(item.forecastItemDestination[0].id);
        // }
        this.dialogAcceptCommentDialog = false;
        this.commentText = '';
        this.messageService.add({severity:'info', summary:'Success', detail:'Comment added successfully'});
      },
      error => {
        this.messageService.add({severity:'error', summary:'Error', detail:'Comment not added. Please contact admin'});
      });
  }

  public ShowRequestor(id: any) {

    this.destinations.find(x => x.id == id)['showRequester'] = true;

  }

  public updateDestination(event, forecastItemDestinationModel: ForecastItemDestinationModel) {
    const itemByIndex = this.destinations.findIndex(x => x.id == forecastItemDestinationModel.id);
    forecastItemDestinationModel.oldInventoryOrderStateId = this.destinations[itemByIndex].inventoryOrderStateId;
    this.multiSelect.forEach(x => x.hide());
    if (event.value.length == 0) {
      this.messageService.add({
        severity: this.commonService.configurations.MessageServiceSeverityError,
        summary: 'Assign error', detail: "Please select status to proceed."
      });
      return false;
    }
    console.log('Event called')
    if (event.itemValue.stateName === 'Ordered' || (event.itemValue.stateName !== 'Cancelled' && event.itemValue.stateName !== 'Outsourced' && forecastItemDestinationModel.inventoryOrderState.id === this.inventoryInStockState.id)) {
      return;
    }
    if (event.itemValue.stateName === 'Cancelled') {
      this.cancelledQty = 0;
      this.saveDest = forecastItemDestinationModel;
      this.forecastItemDestinationClient.getAdvancedQuantity(this.saveDest.id).subscribe(advancedQuantity => {
        if(advancedQuantity > 0) {
          this.dialogCancelError = true;
        }
        else {
          this.forecastItemDestinationClient.getUsedQuantity(this.saveDest.id).subscribe(x => {
            this.itemByIndexValue = itemByIndex;
            this.cancelledSelected = event.itemValue;
            this.hasUsedQty = x > 0;
            this.remainingQty = (this.saveDest.quantity - x  ) + " " + this.saveDest.uomDisplayName;
            if(this.hasUsedQty && (this.saveDest.relinquishedQuantity === null 
                                  || this.saveDest.relinquishedQuantity === 0))
            {
             this.dialogCancelConfirmTitle = "Cancelled Reservation Notice";
              this.cancelledQty = this.saveDest.quantity - x;
            }
             else
             this.dialogCancelConfirmTitle = "Cancel Confirmation";
            this.dialogCancelConfirm = true;
          });
        }
      });
    }
    else if (event.itemValue.stateName === 'Outsourced') {
      if (this.destinations[itemByIndex].inventoryOrderStateName === 'To Do' || this.destinations[itemByIndex].inventoryOrderStateName === 'Follow Up') {
        this.itemByIndexValue = itemByIndex;
        this.outsourcedSelected = event.itemValue;
        this.dialogOutsourceConfirm = true;
      }
    }
     else if (event.itemValue.stateName === 'In Stock' || event.itemValue.stateName === 'To Order') {
      this.destinations[itemByIndex].inventoryOrderState = event.itemValue;
      this.destinations[itemByIndex].inventoryOrderStateId = event.itemValue.id;
      this.destinations[itemByIndex].inventoryOrderStateName = event.itemValue.stateName;
      this.destinations[itemByIndex].inventoryLocation = null;
      this.destinations[itemByIndex].inventoryLocationId = null;
      this.destinations[itemByIndex].enableReserve = true;

      forecastItemDestinationModel['selectedInventoryOrderStates'] = [event.itemValue];
      forecastItemDestinationModel.inventoryOrderState = event.itemValue;
      forecastItemDestinationModel.inventoryOrderStateId = event.itemValue.id;
      forecastItemDestinationModel.inventoryOrderStateName = event.itemValue.stateName;
      this.destinations[itemByIndex].forecastClassName = this.getTitleStyle(forecastItemDestinationModel);
      this.saveDest = forecastItemDestinationModel;
      this.itemByIndexValue = itemByIndex;
      // this.messageService.add({
      //   severity: this.commonService.configurations.MessageServiceSeveritySuccess,
      //   summary: 'Information', detail: 'Please select Sample Inventory Location to proceed'
      // }
      // );
      this.destStatusUpdated.emit(this.getHigherStatus(this.destinations));
    } else {
      this.destinations.filter(x => x.id == forecastItemDestinationModel.id)[0].inventoryOrderState = event.itemValue;
      this.destinations.filter(x => x.id == forecastItemDestinationModel.id)[0].inventoryOrderStateId = event.itemValue.id;
      this.destinations.filter(x => x.id == forecastItemDestinationModel.id)[0].inventoryOrderStateName = event.itemValue.stateName;
      forecastItemDestinationModel['selectedInventoryOrderStates'] = [event.itemValue];
      forecastItemDestinationModel.inventoryOrderState = event.itemValue;
      forecastItemDestinationModel.inventoryOrderStateId = event.itemValue.id;
      forecastItemDestinationModel.inventoryOrderStateName = event.itemValue.stateName;
      this.itemByIndexValue = itemByIndex;
      this.destinations[itemByIndex].forecastClassName = this.getTitleStyle(forecastItemDestinationModel);
      this.updateDestinationStatus(forecastItemDestinationModel);
      this.destStatusUpdated.emit(this.getHigherStatus(this.destinations));
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(''), ms)).then(() => console.log("fired"));
  }

  public getTitleStyle(forecastItemDestinationModel: ForecastItemDestinationModel) {
    switch (forecastItemDestinationModel.inventoryOrderStateName) {
      case this.commonService.configurations.InventoryOrderStatus.ToDo: return 'clsToDoPanelTitleBar'; break;
      case this.commonService.configurations.InventoryOrderStatus.InStock: return 'clsInStockPanelTitleBar'; break;
      case this.commonService.configurations.InventoryOrderStatus.ToOrder: return 'clsToOrderPanelTitleBar'; break;
      case this.commonService.configurations.InventoryOrderStatus.Ordered: return 'clsOrderedPanelTitleBar'; break;
      case this.commonService.configurations.InventoryOrderStatus.Cancelled: return 'clsCancelledPanelTitleBar'; break;
      case this.commonService.configurations.InventoryOrderStatus.Outsourced: return 'clsOutsourcedPanelTitleBar'; break;

    }
    return 'clsToDoPanelTitleBar';
  }

  public async updateDestinationStatus(saveDest: ForecastItemDestinationModel) {

    this.saveDestModel.id = saveDest.id;
    this.saveDestModel.forecastItemId = saveDest.forecastItemId;
    this.saveDestModel.inventoryOrderState = saveDest['selectedInventoryOrderStates'][0];
    this.saveDestModel.inventoryOrderStateId = saveDest.inventoryOrderState.id;
    this.saveDestModel.userId = this.user.id;
    this.saveDestModel.inventoryLocationId = saveDest.inventoryLocation?.id
    this.saveDestModel.lotNumber = saveDest.lotNumber;
    this.saveDestModel.oldInventoryOrderStateId = saveDest.oldInventoryOrderStateId;
    this.saveDestModel.forecastType = saveDest.forecastType;
    this.saveDestModel.forecastTypeId = saveDest.forecastTypeId;
    if(saveDest.inventoryOrderState?.stateName == 'Cancelled')
    {
    this.saveDestModel.unUsedQuantity = saveDest.unUsedQuantity;
    }
      this.mpClient.updateDestinationStatus(this.saveDestModel).subscribe(data => {
        console.log("Reserved", data)
        if ((saveDest.inventoryOrderState?.stateName == 'To Order' ||
          saveDest.inventoryOrderState?.stateName == 'In Stock') && !this.isBypassReservation) {
          this.SearchForProduct();
        }
        this.saveDest.reserved = data;
        this.messageService.add({
          severity: this.commonService.configurations.MessageServiceSeveritySuccess,
          summary: 'Success', detail: 'Destination status updated successfully.'
        }
        );
        saveDest.enableReserve = false;
        saveDest['oldInvLocation'] = saveDest.inventoryLocation;
        saveDest['oldLotNumber'] = saveDest.lotNumber;
        saveDest['oldStrgLocation'] = saveDest.forecastType;
        saveDest['oldInventoryOrderState'] = saveDest.inventoryOrderState;
        saveDest['hasBeenConsideredForAutoInventory'] = false;
        saveDest['qualifiedForAutoInventory'] = false;
        saveDest['autoInventoryReasonComment'] = null;
      },
        error => {
          this.messageService.add({
            severity: this.commonService.configurations.MessageServiceSeverityError,
            summary: 'Assign error', detail: "Error saving destination status."
          });
          saveDest.inventoryLocation = saveDest['oldInvLocation'];
          saveDest.lotNumber = saveDest['oldLotNumber'];
          saveDest.forecastType = saveDest['oldStrgLocation'];
          saveDest.inventoryOrderState = saveDest['oldInventoryOrderState'];
          saveDest.inventoryOrderStateName = saveDest.inventoryOrderState?.stateName;
          saveDest.forecastClassName = this.getTitleStyle(saveDest);

          }
      );
  }

  public SearchForProduct() {
    this.forecastItemClient.productSearch(this.saveDest.forecastItemId).subscribe(res => console.log(res),
      error => {
        //     this.messageService.add({ severity: 'error', summary: '', detail: 'ITC system was not available to initiate the product' });
      },
      () => {
        this.messageService.add({ severity: 'success', summary: '', detail: 'ITC system initiated the product successfully.' });
      })
  }

  public outsourceIt() {
    this.destinations[this.itemByIndexValue].inventoryOrderState = this.outsourcedSelected;
    this.destinations[this.itemByIndexValue].inventoryOrderStateId = this.outsourcedSelected.id;
    this.destinations[this.itemByIndexValue].inventoryOrderStateName = this.outsourcedSelected.stateName;
    this.destinations[this.itemByIndexValue]['forecastClassName'] = this.getTitleStyle(this.destinations[this.itemByIndexValue]);
    this.saveDest = this.destinations[this.itemByIndexValue];
    this.saveDest.enableReserve = false;
    this.dialogOutsourceConfirm = false;
    this.updateDestinationStatus(this.saveDest);
    this.destStatusUpdated.emit(this.getHigherStatus(this.destinations));
  }
  public cancelAndReset() {
    this.destinations[this.itemByIndexValue].inventoryOrderState = this.cancelledSelected;
    this.destinations[this.itemByIndexValue].inventoryOrderStateId = this.cancelledSelected.id;
    this.destinations[this.itemByIndexValue].inventoryOrderStateName = this.cancelledSelected.stateName;
    if(this.cancelledQty > 0)
    {
    this.destinations[this.itemByIndexValue].unUsedQuantity = this.cancelledQty;
    }
    this.destinations[this.itemByIndexValue]['forecastClassName'] = this.getTitleStyle(this.destinations[this.itemByIndexValue]);
    this.saveDest = this.destinations[this.itemByIndexValue];
    this.saveDest.enableReserve = false;
    this.dialogCancelConfirm = false;
    this.updateDestinationStatus(this.saveDest);
    this.destStatusUpdated.emit(this.getHigherStatus(this.destinations));
  }
  public onLotNumberPaste(e) {
    let clipboardData = e.clipboardData;
    let pastedText = clipboardData.getData('text');
    if (!(this.lotNumberRegEx.test(pastedText))) {
      e.preventDefault();
      return false;
    }
  }
  public onLotNumberUpdate(forecastLocationItem: ForecastItemDestinationModel) {
    this.enableId = forecastLocationItem.id;
    this.saveDestModel.id = forecastLocationItem.id;
    this.saveDestModel.forecastItemId = forecastLocationItem.forecastItemId;
    this.saveDestModel.inventoryOrderStateId = forecastLocationItem.inventoryOrderState.id;
    this.saveDestModel.userId = this.user.id;
    this.saveDestModel.manufacturer = forecastLocationItem.manufacturer;
    this.saveDestModel.isReachApproved = forecastLocationItem.isReachApproved;
    this.saveDestModel.comment = forecastLocationItem.comment;
    this.saveDestModel.inventoryLocationId = forecastLocationItem.inventoryLocation?.id
    this.saveDestModel.lotNumber = forecastLocationItem.lotNumber;
    if ((forecastLocationItem.inventoryOrderStateName === 'In Stock' || forecastLocationItem.inventoryOrderStateName === 'To Order')
      && (forecastLocationItem.inventoryLocation == null || forecastLocationItem.inventoryLocation == undefined)) {
      forecastLocationItem.lotNumber = forecastLocationItem.lotNumber;
    }
    else {
      this.destinations.forEach(x => {
        if(x.id == forecastLocationItem.id){
          x.enableReserve = (((forecastLocationItem.forecastType && forecastLocationItem.inventoryLocation) || (forecastLocationItem.inventoryLocation && this.repoName == "COFORM")) && (forecastLocationItem.inventoryOrderState.id!==this.inventoryOutsourcedState.id&&forecastLocationItem.inventoryOrderState.id!==this.inventoryCancelledState.id) && ((forecastLocationItem.inventoryLocation.locationName != x.oldInvLocation.locationName) || (forecastLocationItem.forecastType.forecastType1 != x.oldStrgLocation.forecastType1) || (forecastLocationItem.lotNumber != x.oldLotNumber))) ? true : false;
        }
      });
      //forecastLocationItem.enableReserve = ((forecastLocationItem.forecastType && forecastLocationItem.inventoryLocation)) ? true : false;

    }
  }

  public AllowAlphaNumericDash(e, forecastLocationItem: any) {
    if ((this.lotNumberRegEx.test(e.key)) &&
      (forecastLocationItem.lotNumber === undefined ||
        forecastLocationItem.lotNumber === null ||
        (forecastLocationItem.lotNumber.length < 20 && (this.lotNumberRegEx.test(forecastLocationItem.lotNumber))))) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  }
  public onInventoryLocChange(event, saveDest: ForecastItemDestinationModel) {
    this.enableId = saveDest.id;
    console.log('Event called')
    this.saveDest = saveDest;
    //this.enableReserveBtn = (saveDest.forecastType && saveDest.inventoryLocation) ? true : false;
    this.destinations.forEach(x => {
      if(x.id == saveDest.id){
        if(this.ExcludeGLPforSGS)
        if(x.inventoryLocation.locationName.toUpperCase() == 'SGS')
        {
          x.forecastTypes = [];
          x.forecastTypes = this.forecastTypes.filter(f => f.forecastType1.toUpperCase() != "GLP");
          x.forecastType = this.forecastTypes.filter(f => f.forecastType1.toUpperCase() == "STOCK")[0];
          x.forecastTypeId = this.forecastTypes.filter(f => f.forecastType1.toUpperCase() == "STOCK")[0].id;
        }
        else{
          x.forecastTypes = [];
          x.forecastTypes = this.forecastTypes;

        }
        x.enableReserve = (((saveDest.forecastType && saveDest.inventoryLocation) || (saveDest.inventoryLocation && this.repoName == "COFORM")) && (saveDest.inventoryOrderState.id!==this.inventoryOutsourcedState.id&&saveDest.inventoryOrderState.id!==this.inventoryCancelledState.id) && ((event.value.locationName != x.oldInvLocation) || (saveDest.forecastType.forecastType1 != x.oldStrgLocation) || (saveDest.lotNumber != x.oldLotNumber))) ? true : false;
      }
    });
    console.log("test", this.saveDest)
    //this.updateDestinationStatus(this.saveDest);
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  public saveStorageLoc(saveDest: ForecastItemDestinationModel) {
    this.saveDest = saveDest;
    this.enableId = saveDest.id;
    this.updateDestinationStatus(this.saveDest);
  }

  public onStorageLocChange(event, saveDest: ForecastItemDestinationModel) {
    console.log('Event called');
    saveDest.forecastTypeId = (saveDest.forecastType != null && saveDest.forecastType != undefined) ? saveDest.forecastType.id : 0;
    //this.enableReserveBtn = (saveDest.forecastType && saveDest.inventoryLocation) ? true : false;
    this.destinations.forEach(x => {
      if(x.id == saveDest.id){
        x.enableReserve = ((saveDest.forecastType && saveDest.inventoryLocation) && (saveDest.inventoryOrderState.id!==this.inventoryOutsourcedState.id&&saveDest.inventoryOrderState.id!==this.inventoryCancelledState.id)&& ((event.value.forecastType1 != x.oldStrgLocation.forecastType1) || (saveDest.inventoryLocation.locationName != x.oldInvLocation.locationName) || (saveDest.lotNumber != x.oldLotNumber))) ? true : false;
      }
    });
  }
  public getSampleInventoryLocations() {
    this.sampleInventoryLocation.getSampleInventoryLocations()
      .subscribe(data => {
        this.sampleInventoryLocations = data;
        console.log("SampleInventoryLocs....", this.sampleInventoryLocations);
      });
  }

  public getHigherStatus(destinations: any[]) {
    var toDo = destinations.filter(x => x.inventoryOrderState.stateName == "To Do")[0];
    if (toDo) {
      return toDo.inventoryOrderState.stateName;
    }

    var followUp = destinations.filter(x => x.inventoryOrderState.stateName == "Follow Up")[0];
    if (followUp) {
      return followUp.inventoryOrderState.stateName;
    }

    var toOrder = destinations.filter(x => x.inventoryOrderState.stateName == "To Order")[0];
    if (toOrder) {
      return toOrder.inventoryOrderState.stateName;
    }

    var ordered = destinations.filter(x => x.inventoryOrderState.stateName == "Ordered")[0];
    if (ordered) {
      return ordered.inventoryOrderState.stateName;
    }

    var inStock = destinations.filter(x => x.inventoryOrderState.stateName == "In Stock")[0];
    if (inStock) {
      return inStock.inventoryOrderState.stateName;
    }

    var cancelled = destinations.filter(x => x.inventoryOrderState.stateName == "Cancelled")[0];
    if (cancelled) {
      return cancelled.inventoryOrderState.stateName;
    }

    var outsourced = destinations.filter(x => x.inventoryOrderState.stateName == "Outsourced")[0];
    if (outsourced) {
      return outsourced.inventoryOrderState.stateName;
    }

    return "To Do";
  }

  onGetOrderedQuantity(destination: ForecastItemDestinationModel, index: number){
    this.forecastItemDestinationClient.getOrderedQuantity(destination.id).subscribe(qty => {
      this.destinationOrderedQuantity[index] = qty;
    })
  }

  isShowOrderedQtyHyperLink(forecastLocationItem: ForecastItemDestinationModel) {
    if (forecastLocationItem.inventoryOrderState !== undefined && forecastLocationItem.inventoryOrderState.stateName !== 'Cancelled' &&  
    forecastLocationItem.inventoryOrderState.stateName !== 'Outsourced' &&  forecastLocationItem.inventoryOrderState.stateName !== 'To Do' &&
    forecastLocationItem.inventoryOrderState.stateName !== 'Follow Up') {
        return true;
    } else  {
      return false;
    }
  }

}
