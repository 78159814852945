<div class="ui-g">
  <div class="ui-md-3">
    <h4 style="font-weight: bold;">Submitted Forecasts</h4>
  </div>
</div>
<div *ngIf="plannerItems">
  <p-dataView #dv [value]="plannerItems" id="plannerItemsView" [paginator]="true" [rows]="pageSize" [lazy]="true"
    [totalRecords]="totalRecords" pageLinkSize="3" (onPage)="pageChanged($event)" paginatorPosition="both" layout="grid"
    styleClass="hover">

    <ng-template pTemplate="header">
      <div style="width: 100%" style="background-color: #f4f4f4;border: 1px solid darkgray"
        class="clsDataViewHeaderVisiblePart">
        <div class="grid" style="width: 100%;">
          <div class="col-3 xl:col-3 lg:col-3 md:col-6 sm:col-12">
            <div class="grid" style="margin-top: 1px;">
              <div class="col-2">
                <div (click)="myFunction($event, 0)">              
                  <i style="margin-left:10px" class="fa fa-filter fa-2x" aria-hidden="true"></i>
                </div>
              </div>
              <div class="col-8">
                <p-dropdown id="ddrFilterDropdown" [style]="{  'width':'100%'}" optionLabel="name"
                  [(ngModel)]="selectedFilter" (click)="StopDefaults()" [options]="searchFilters">
                </p-dropdown>
              </div>
              <div class="col-2">
                <button type="button" style="margin-left: 1em" id="btnApplySelectedFilter" pButton
                  (click)="ApplySelectedFilter($event)" label="Apply"></button>
              </div>
            </div>
          </div>
          <div class="col-3 xl:col-8 lg:col-3 md:col-6 sm:col-12">
            <div class="grid" style="margin-top: -0.7rem">
            </div>
          </div>
        </div>
      </div>
      <div *ngIf='isAccordionOpen' class='clsFilterFormDiv'>
        <form [formGroup]="filterForm" class='clsFilterForm' id="formFilterForm" style="padding-left: 15px;">
          <div class="grid" style="width: 100%;">
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Country</div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
              <p-autoComplete id="autoCompleteFilterCountries" [suggestions]="filteredCountries"
                (completeMethod)="filterCountry($event)" field="displayName" [size]="20" formControlName="Country"
                (keyup.enter)="FilterSubmittedForecast(false,true)" placeholder="Country" [minLength]="1">
              </p-autoComplete>
            </div>
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Material Planner</div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
              <p-autoComplete [suggestions]="filteredMaterialPlanners" id="autoCompleteMaterialPlanners"
                (completeMethod)="filterMaterialPlanners($event)" field="fullName" dataKey="id" [size]="20"
                 formControlName="MaterialPlannerUserId"
                (keyup.enter)="FilterSubmittedForecast(false,true)" placeholder="Material Planner">
              </p-autoComplete>
            </div>
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Date Needed</div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
              <p-calendar id="calendarDateNeeded" selectionMode="range" name="DateNeeded" formControlName="DateNeeded"
                (keyup.enter)="FilterSubmittedForecast(false,true)" [showIcon]="true" dateFormat="dd-M-yy"></p-calendar>

            </div>
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 "> </div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8"></div>


          </div>
          <div class="grid" style="width: 100%">
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Material</div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8"><input type="text" id="txtMaterial"
                formControlName="Material" (keyup.enter)="FilterSubmittedForecast(false,true)" pInputText
                class="p-inputtext"></div>
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Forecaster</div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
              <p-autoComplete [suggestions]="filteredUsers" id="autoCompleteUsers"
                (completeMethod)="filterUsers($event)" field="secondaryEmail" [size]="20" formControlName="Requester"
                (keyup.enter)="FilterSubmittedForecast(false,true)" placeholder="Forecaster/Requester" [minLength]="1">
              </p-autoComplete>
            </div>
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Forecast#</div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8"><input type="text" id="txtForecastNumber"
                formControlName="ForecastNumber" (keyup.enter)="FilterSubmittedForecast(false,true)"
                (keypress)="AllowOnlyIntegers($event)" pInputText class="p-inputtext"></div>
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4"></div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8"></div>

          </div>
          <div class="grid" style="width: 100%">
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">
              Status
            </div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
              <p-multiSelect [options]="inventoryStates" formControlName="Status" 
              [style]="{'width': '186px'}" optionLabel="stateName"
                appendTo="body" defaultLabel="Select" [showHeader]="false" [filter]="false">
              </p-multiSelect>
            </div>
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4">Sort Order </div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
                <p-dropdown id="ddrOrderBy" [options]="orderBy" formControlName="OrderBy" optionLabel="name">
                </p-dropdown>
                <button type="button" style="margin-left:3px;" id="btnSort" pButton
                  [icon]="IsDesc ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"
                  (click)="IsDesc = !IsDesc;SortPlanningItems(!IsDesc);">
                </button>
            </div>
            <div class="col-1 xl:col-1 lg:col-4 md:col-4 sm:col-4 ">Filter Name</div>
            <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
              <input type="text" id="txtFilterName" formControlName="Name" pInputText class="p-inputtext">
              <p-message severity="error" text="Filter Name is Required" *ngIf="!filterForm.controls['Name'].valid
                &&filterForm.controls['Name'].dirty"></p-message>
            </div>

            <div class="col-3 xl:col-3 lg:col-6 md:col-6 sm:col-6">
              <div class="grid">
                <div class="col-3">
                  <button type="button" style="min-width:80px;"  id="btnApplyFilter" pButton (click)="FilterSubmittedForecast(false,true)"
                    label="Apply"></button>
                </div>
                <div class="col-3"><button type="button" id="btnSaveFilter" pButton style="min-width:80px;" 
                    [disabled]="!filterForm.valid || filterForm.controls['Name'].value == MaterialsAssignedToMe.name  || filterForm.controls['Name'].value == WIPAllMaterials.name || filterForm.controls['Name'].value == AllMaterials.name"
                    (click)="FilterSubmittedForecast(true,true)" label="Save"></button> </div>

                <div class="col-3"><button type="button" id="btnClearFilter"
                    style="width:80px;" pButton label="Clear All" (click)="ClearAll()"></button> </div>

              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="form-group grid mt-15" style="margin-top:15px;" *ngIf="plannerItems && plannerItems.length > 0">
        <div class="col-5"></div>
        <div class="col-7">
          <div style="float:right">
            <button pButton type="button" label="Expand All" (click)="expandAll()"></button>
            <button pButton type="button" label="Collapse All" (click)="collapseAll()"
              style="margin-left: 15px;"></button>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template let-plannerItem pTemplate="gridItem" let-i="rowIndex">
      <div class="order"
        style="width: 100%; border: 1px solid #dee2e6; margin:2px 0; display: flex; flex-direction: column ">
        <p-header (click)="setPlannerItemStatus(plannerItem)"
          [ngStyle]="{'cursor': 'pointer','width': '100%','background-color': (i%2==0 ? '#007ad9' : 'lightgray')}">
          <div class="row font-weight-bold">
            <div [ngClass]="plannerItem.isNew ?'col-md-3':'col-md-4'">
              <div [ngStyle]="{'line-height': '40px','padding-left': '10px','color':(i%2==0 ? 'white' : 'black')}"
                style="overflow-wrap: break-word;">
                <i [ngClass]="plannerItem.isOrderTabOpen ?'pi-caret-down':'pi-caret-right'" class="pi d-inline"></i>
                <span> {{plannerItem.materialName}}</span>
                <span *ngIf="plannerItem.brandName"> / {{plannerItem.brandName}}</span>
              </div>
            </div> 
            <div class="newStatus col-md-1" [ngStyle]="{'color':(i%2==0 ? 'white' : 'black')}" *ngIf="plannerItem.isNew">
              New!
            </div>
            <div class="col-md-2">
              
              <div class="status" [ngClass]="{
                  'to-do': plannerItem.higherStatus === 'To Do',
                  'follow-up': plannerItem.higherStatus === 'Follow Up',
                  'to-order': plannerItem.higherStatus === 'To Order',
                  'ordered': plannerItem.higherStatus === 'Ordered',
                  'in-stock': plannerItem.higherStatus === 'In Stock',
                  'cancelled': plannerItem.higherStatus === 'Cancelled',
                  'outsourced': plannerItem.higherStatus === 'Outsourced'
                }">
                <span>{{plannerItem.higherStatus}}</span>
              </div>
            </div>
            <div class="col-md-2">
              <div [ngStyle]="{'line-height': '40px','color':(i%2==0 ? 'white' : 'black')}">
                <span>Countries: </span><span>{{plannerItem.totalNumberOfDestinations}}</span>
              </div>
            </div>
            <div class="col-md-1" [ngStyle]="{'line-height': '40px','color':(i%2==0 ? 'white' : 'black')}">
              {{plannerItem.productType}}</div>
            <div class="col-md-1" [ngStyle]="{'line-height': '40px','color':(i%2==0 ? 'white' : 'black')}">
                {{plannerItem.dateFrom | date: 'dd-MMM-yyyy'}}</div>
            <div class="col-md-2" [ngStyle]="{'line-height': '40px','color':(i%2==0 ? 'white' : 'black')}">
              <p-dropdown [ngStyle]="{'color':(i%2==0 ? 'white' : 'black')}" [options]="materialPlanners"
                class="p-dropdown-materialplanner"
                [ngModel]="getSelectedmaterialPlanner(plannerItem.materialPlannerUserId)" optionLabel="fullName"
                id="ddrMaterialPlanner" [hidden]="false" [showClear]="true" *ngIf="isMaterialPlanner"
                placeholder="Material Planner" (click)="StopOpening($event)"
                (onChange)="setSelectedMaterialPlanner($event, plannerItem)"></p-dropdown>
            </div>
          </div>
        </p-header>
        <div class="form-group w-100 m-0 px-3 pt-3" *ngIf="plannerItem.isOrderTabOpen">
          <div>

            <div class="grid" style="margin-bottom:5px;border: 1px solid #ddd;">
              <div class="col-2 clsHeader">Formulation Type</div>
              <div class="col-3 clsHeader">Active Ingredients</div>
              <div class="col-1 clsHeader">Stage</div>
              <div class="col-2 clsHeader">Total Quantity </div>
              <div class="col-2 clsHeader">Date Range Needed </div>
              <div class="col-2 clsHeader">Forecast Type</div>
            </div>
            <div class="grid">
              <div class="col-2" style="text-align: center;">
                <div class="grid">

                  <div class="col-2">
                    <a href="" style="border: 1px solid;padding-left: 4px;padding-right: 4px;border-radius: 5px;"
                      [ngStyle]="{'border-color' :  !plannerItem.materialId ? 'lightgray' : '#007bff' , 'background-color': !plannerItem.materialId ? 'lightgray' : '#007bff','pointer-events': !plannerItem.materialId ? 'none' : null,'text-decoration':!plannerItem.materialId ? 'null' : 'underline'}"
                      [routerLink]="['/materialInventory/'+plannerItem.repoName + '/'+plannerItem.materialId]"
                      title="click the icon to view material inventory" target="_blank">
                       
                        <i class="fa fa-solid fa-list" style="color:white"></i>
                    </a>
                  </div>
                  <div class="col-10" style="text-align: left;">
                    {{plannerItem.formulationType}}
                  </div>
                </div>
              </div>
              <div class="col-3" style="text-align: center;">
                <div class="grid">
                  <div [ngClass]="(plannerItem.activeIngredient.length > 5)?'col-6':'col-12'"
                    style="text-align: left;" *ngFor="let ai of plannerItem.activeIngredient">
                    <span class="clsAI"> {{ai.activeIngredient}} ({{ai.concentrationInPrimaryUom}})</span>
                  </div>
                </div>
              </div>
              <div class="col-1" style="text-align: center;">{{plannerItem.stage}}</div>
              <div class="col-2" style="text-align: center;"> {{plannerItem.quantityWithUomBreakup}}</div>
              <div class="col-2" style="text-align: center;"> {{plannerItem.dateRange}} </div>
              <div class="col-2" style="text-align: center;">{{plannerItem.forecastType}}</div>
            </div>
          </div>
        </div>
        <div class="forecast-details" *ngIf="plannerItem.isOrderTabOpen">
          <div class="procurement type">
            <p-header (click)="plannerItem.isProcurementTabOpen =  !plannerItem.isProcurementTabOpen" class="header">
              <div class="row">
                <div class="col-md-12">
                  <div
                    style="line-height: 30px;padding-left:10px;font-weight: bold;font-size: inherit;margin-bottom: 5px;">
                    <i class="pi" [ngClass]="plannerItem.isProcurementTabOpen ?'pi-caret-down':'pi-caret-right'"></i>
                    Locations
                  </div>
                </div>
              </div>
            </p-header>
            <div *ngIf="plannerItem.isProcurementTabOpen && !plannerItem.bypassReservation">
                <app-forecast-destinations [repoName]="plannerItem.repoName" 
                  [excludeGLPforSGS]="plannerItem.isExcludeGLPforSGS" 
                  [isBypassReservation]="plannerItem.bypassReservation" 
                  [destinations]="plannerItem?.destinations" [forecastStatus]="plannerItem?.higherStatus" 
                  [materialPlannerUserId]="plannerItem?.materialPlannerUserId"
                  (setMaterialPlanner)="setMaterialPlanner($event, plannerItem)"
                    (destStatusUpdated)="updatePlannerItemStatus($event, plannerItem)">
                </app-forecast-destinations>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="paginatorleft" let-state id="paginatorleft2">
      <span class="ui-paginator-first" style="padding-left:10px;"> Total Items : {{totalRecords}}
        {{ totalRecords == plannerItems.length ? '' : '(Showing Items: '+ plannerItems.length + ')'}} </span>
    </ng-template>
    <ng-template pTemplate="paginatorright" id="paginatorright2" let-state>
      <span class="ui-paginator-first" style="padding-right:10px;">{{((pageIndex - 1) * pageSize) + 1}} -
        {{(plannerItems.length !== pageSize ? totalRecords : pageIndex * pageSize)}} of {{totalRecords}} Items</span>
    </ng-template>
  </p-dataView>
</div>
<div class="form-group row mt-15" *ngIf="plannerItems && plannerItems.length > 0">
  <div class="col-md-5"></div>
  <div class="col-md-7">
    <div style="float:right">
      <button pButton type="button" label="Expand All" (click)="expandAll()"></button>
      <button pButton type="button" label="Collapse All" (click)="collapseAll()" style="margin-left: 15px;"></button>
    </div>
  </div>
</div>
<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
