import { Injectable, ApplicationRef } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs';

@Injectable()
export class LoadingService {

    private messageSubject = new ReplaySubject<string>(1);
    private messageDelay = 300;

    private messageTimer: any;

    public clearMessage() {
        this.clearMessageTimerIfSet();
        this.messageSubject.next(null);
    }

    public setMessage(message: string) {

        this.clearMessageTimerIfSet();
        this.messageTimer = setTimeout(() => {
            this.messageSubject.next(message);
        }, this.messageDelay);
    }

    public onMessageUpdate(): Observable<string> {
        return this.messageSubject.asObservable();
    }

    public setMessageDelay(delay: number) {
        this.messageDelay = delay;
    }

    private clearMessageTimerIfSet() {
        if (this.messageTimer) {
            clearTimeout(this.messageTimer);
            this.messageTimer = null;
        }
    }
}
