import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { LoadingService } from './res-loading.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'res-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy, OnChanges {

    private loaderSubscription: Subscription;

    @Input() public imageSrc: string;
    @Input() public debounceTime = 300;
    public message  = '';

    constructor(private loader: LoadingService) { }

    ngOnInit(): void {

        this.loader.setMessageDelay(this.debounceTime);

        this.loaderSubscription = this.loader
            .onMessageUpdate()
            .subscribe(message => {
                this.message = message;
            });
    }

    ngOnDestroy(): void {
        if (this.loaderSubscription) {
            this.loaderSubscription.unsubscribe();
            this.loaderSubscription = null;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.debounceTime) {
            this.loader.setMessageDelay(changes.debounceTime.currentValue);
        }
    }
}
