<div class="row">
  <div class="col-md-12 text-center">
    <label style="font-size: 96px; color: red;">
      <span>
        <i class="pi pi-exclamation-circle" style="font-size: 6rem"></i>
      </span>
      Access Restricted
      <span>
        <i class="pi pi-exclamation-circle" style="font-size: 6rem"></i>
      </span>
    </label>
    <h4>You are not authorized to access this page</h4>
    <p></p>
    <p></p>
  </div>
</div>
