import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { ConfigurationSettings } from '../configuration-settings';
import { catchError, map, tap } from 'rxjs/operators';
import { UserModel } from './forecast-api-client.service';

@Injectable({
  providedIn: 'root'
})
export class ForecastItemService {
  url = ConfigurationSettings.REST_API_URL + '/api/ForecastItem/';
  httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private _http: HttpClient) { }

    public getMyForecast(userId:number): any {
       
        return this._http.get(this.url + "GetForecastItems/"+ userId ).pipe(
            tap(x => console.log('Fetched MyForecastItems')));
    }
    public getDashboardDate(userId:number): any {
       
      return this._http.get(this.url + "GetDashboardData/"+ userId ).pipe(
          tap(x => console.log('Fetched MyForecastItems')));
  }
  public deleteForecast(forecastId:number): any {
       
    return this._http.delete(this.url + forecastId ).pipe(
        tap(x => console.log('deleted MyForecastItem')));
}
public async GetForecaster(forecasterId:number): Promise<any> {       
  let url = ConfigurationSettings.REST_API_URL + "/api/User/" + forecasterId;
    return await this._http.get(url).pipe(
      tap(x => console.log("Fetched Forecaster"))).toPromise();
}
}
