
import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../loading/res-loading.service';

import {UserRoleModel} from './userrole.model';
import { UserRoleService } from '../services/userrole-service/userrole.service'

@Component({
    selector: 'app-userrole',
    templateUrl: './userrole.component.html',
    styleUrls: ['./userrole.component.css'],
    providers:[UserRoleService,ConfirmationService, MessageService]
})

export class UserRoleComponent implements OnInit {

    constructor(public userroleService: UserRoleService,public confirmationService: ConfirmationService, public messageService: MessageService) { }
    ngOnInit() {
    this.GetUserRoles();
    this.userroleTableHeaders=[
         { field: 'UserRoleId', header: ' User Role Id', display: 'none' },
         { field: 'RoleId', header: ' Role Id', display: 'none' },
         { field: 'UserId', header: ' User Id', display: 'none' },
            { field: 'CreatedDate', header: ' Created Date', display: 'table-cell' },
            { field: 'CreatedBy', header: ' Created By', display: 'table-cell' },
            { field: 'ModifiedDate', header: ' Modified Date', display: 'table-cell' },
            { field: 'ModifiedBy', header: ' Modified By', display: 'table-cell' },
    ]
    }

userroleTableHeaders: any[]

    userroles: UserRoleModel[];
    userrole= new UserRoleModel();
    selectedUserRole: UserRoleModel;
    serviceResponse: any;
    selectedUsers: any[];
    isEditable:boolean=false
    dialogHeaderText:any="Add new";

    //Property to display dialog
    displayDialog: boolean = false;

    //Method to display dialog
    AddnewItem(){
        this.userrole= {};
        this.displayDialog = true;
        this.isEditable=false;
         this.dialogHeaderText="Add new";
    }

    //Method to save dialog
    Save(){
        console.log(this.userrole);
        this.InsertUserRole(this.userrole)
         this.displayDialog = false;
        this.userrole= {};
    }

    Edit(data) {
        this.selectedUserRole=data;
        console.log(this.selectedUserRole);
        this.userrole = this.selectedUserRole;
        this.displayDialog = true;
        this.isEditable=true;
        this.dialogHeaderText="Edit";
    }

     Delete(){
        console.log(this.userrole);
        this.deleteUserRole(this.userrole.UserRoleId);
         this.displayDialog = false;
        this.userrole= {};
    }

        Update(){
        this.userrole=this.selectedUserRole;
        this.UpdateUserRole(this.userrole);
        this.displayDialog = false;
        this.userrole= {};
    }

    //Method to cancel dialog
    Cancel(){
        this.displayDialog = false;
        this.userrole= {};
    }

    public InsertUserRole(userrole) {
        this.userroleService.createUserRole(this.userrole).subscribe(res => this.serviceResponse = res,
            error => {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    public GetUserRoles() {
        this.userroleService.getUserRoles().subscribe(response => this.userroles = response,
            error => {
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })
            },
            () => { console.log(this.userroles) })
    }

    public UpdateUserRole(userrole) {
        this.userroleService.updateUserRole(this.userrole).subscribe(res => this.serviceResponse = res,
            error => {
                console.log(error);
                this.messageService.add({ severity: 'error', summary: '', detail: error.message })

            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

    public deleteUserRole(Id) {
        this.userroleService.deleteUserRole(this.userrole.UserRoleId).subscribe(res => this.serviceResponse = res,
            error => {
                 this.messageService.add({ severity: 'error', summary: '', detail: error.message })
            },
            () => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
                this.ngOnInit();
            });
    }

}

