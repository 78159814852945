import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuardService } from './services/role-gaurd-service';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { UserComponent } from './user/user.component';
import { UserRoleComponent } from './userrole/userrole.component';
import { QuantityUomComponent } from './quantityuom/quantity-uom.component';
import { ConcentrationUomComponent } from './concentrationuom/concentration-uom.component';
import { FormulationTypeComponent } from './formulationtype/formulation-type.component';
import { CountryComponent } from './country/country.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportingComponent } from './reporting/reporting.component';
import { UnauthorizedComponent } from './login/unauthorized.component';
import { ForecastItemComponent } from './forecast-item/forecast-item.component';
import { AddForecastResolver } from './services/resolvers/addForecast.resolver';

import { ProcurementDetailCountryResolver } from './services/resolvers/procurement-detail-country-resolver';
import { ProcurementDetailIdResolver } from './services/resolvers/procurement-detail-id-resolver';

import { QuantityUomService } from './services/quantityuom.service';
import { ConcentrationUomService } from './services/concentrationuom.service';
import { ModifyForecastResolver } from './services/resolvers/modifyForecast.resolver';
import { CloneForecastResolver } from './services/resolvers/cloneForecast.resolver';
import { DelegatesComponent } from './delegates/delegates.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { SubmittedForecastsNewComponent } from './submitted-forecast-new/submitted-forecasts-new.component';
import { ProcurementComponent } from './procurement/procurement.component';
import { PassThruGuardService } from './services/passthru-gaurd-service';

import { ProcurementDetailComponent } from './procurement-detail/procurement-detail.component';
import { ProcurementDetailsResolver } from './services/resolvers/procurement-details.resolver';
import { ProcurementDetailsAddResolver } from './services/resolvers/procurement-detailsAdd.resolver';
import { SubmitForecastResolver } from './services/resolvers/submitForecast.resolver';
import { CanDeactivateGuard } from './services/route-guards/can-deactivate-guard.service';
import { MaterialInventoryComponent } from './material-inventory/material-inventory.component';
import { ForecastsNewComponent } from './forecasts-new/forecasts-new.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AfterLoginComponent } from './after-login/after-login.component';
import { AfterLogoutComponent } from './after-logout/after-logout.component';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NavigateComponent } from './navigate/navigate.component';
import { MaintenanceMessageComponent } from './maintenance-message/maintenance-message.component';
import { FormulationRequestsComponent } from './formulation-requests/formulation-requests.component';
import { FormulationRawMaterialComponent } from './formulation-raw-material/formulation-raw-material.component';



const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'user', component: UserComponent, canActivate: [MsalGuard], data: {expectedRoles: ['Administrator']}  },
  { path: 'dashboard', component: DashboardComponent, canActivate: [MsalGuard]  },
  { path: 'unauthorized', component: AccessDeniedComponent },
  { path: 'forecasts', component: ForecastsNewComponent, canActivate: [MsalGuard]  },
  { path: 'approvals', component: ApprovalsComponent, canActivate: [MsalGuard]  },
  { path: 'submitted-forecasts-new', component: SubmittedForecastsNewComponent, 
      resolve: {masterData: SubmitForecastResolver},
      canActivate: [RoleGuardService],
      data: {expectedRoles: ['Material Planner']}  },
  { path: 'materialInventory/:repoName/:materialId', component: MaterialInventoryComponent },
  { path: 'procurement', component: ProcurementComponent, canActivate: [RoleGuardService],  canDeactivate: [CanDeactivateGuard],
      data: {expectedRoles: ['Material Planner','Order Coordinator','Receiving Team','IFS Leadership','Administrator']} },
  { path: 'procurementDetail/:id/:popupCountry', component: ProcurementDetailComponent, 
    resolve: {id: ProcurementDetailIdResolver, 
              popupCountry: ProcurementDetailCountryResolver,
              procurementDetails: ProcurementDetailsResolver
              },
    canActivate: [MsalGuard],  canDeactivate: [CanDeactivateGuard],
    data: {expectedRoles: ['Material Planner','Order Coordinator','Receiving Team','IFS Leadership','Administrator']},
    runGuardsAndResolvers: 'always' },
  { path: 'neworderrequest', component: ProcurementDetailComponent, 
    resolve: {procurementDetails: ProcurementDetailsAddResolver},
    canActivate: [MsalGuard],
    data: {expectedRoles: ['Material Planner','Order Coordinator','Receiving Team','IFS Leadership','Administrator']}
  },  
  { path: 'delegates', component: DelegatesComponent, canActivate: [RoleGuardService], data: {expectedRoles: ['Biology Leader']}  },
  { path: 'reporting', component: ReportingComponent, canActivate: [MsalGuard], runGuardsAndResolvers: 'always' },
  { path: 'userrole', component: UserRoleComponent },
  { path: 'quantityuom', component: QuantityUomComponent, canActivate: [RoleGuardService], data: {expectedRoles: ['Administrator']}  },
  { path: 'concentrationuom', component: ConcentrationUomComponent,
      canActivate: [RoleGuardService], data: {expectedRoles: ['Administrator']}},
  { path: 'formulationtype', component: FormulationTypeComponent,
      canActivate: [RoleGuardService], data: {expectedRoles: ['Administrator']}},
  { path: 'maintenancemessage', component: MaintenanceMessageComponent,
      canActivate: [RoleGuardService], data: {expectedRoles: ['Administrator']}},
  { path: 'country', component: CountryComponent, canActivate: [RoleGuardService], data: {expectedRoles: ['Administrator']}},
  { path: 'forecastitem', component: ForecastItemComponent,
    resolve: {forecastData: AddForecastResolver}, canActivate: [MsalGuard] },
  { path: 'forecastitemEdit/:id', component: ForecastItemComponent,
    resolve: {editForecastData: ModifyForecastResolver}, canActivate: [MsalGuard] },
  { path: 'forecastitemCopy/:id', component: ForecastItemComponent,
    resolve: {editForecastData: CloneForecastResolver}, canActivate: [MsalGuard] },
  { path: 'forecastitem/reload/:id', component: ForecastItemComponent,
    resolve: {editForecastData: ModifyForecastResolver}, canActivate: [MsalGuard] },
  { path: 'formulation-requests', component: FormulationRequestsComponent, 
    resolve: {masterData: SubmitForecastResolver},
    canActivate: [RoleGuardService],
    data: {expectedRoles: ['Formulation Specialist', 'Material Planner']}  },
  { path: 'formulation-raw-material/:id', component: FormulationRawMaterialComponent, canActivate: [RoleGuardService], data: {expectedRoles: ['Formulation Specialist']}  },
  //Below route is used to redirect the user on login page when given route in URL is empty.(http://localhost:4200/)
  { path: 'after-login', component: AfterLoginComponent }, // needed for MSAL exchange code for token(s); Don't add authguard - will cause errors;
  { path: 'after-logout', component: AfterLogoutComponent },
  { path: 'no-permissions', component: NoPermissionsComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: 'navigate', component: NavigateComponent },
  { path: 'login', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  //Below route is used to redirect the user on login page when given route in URL is not defined in application routes.(http://localhost:4200/**)
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true ,
    onSameUrlNavigation: 'reload',
    initialNavigation: isInIframe() ? 'disabled' : undefined
})],
  exports: [RouterModule],
  providers: [
    QuantityUomService,
    ConcentrationUomService,
    AddForecastResolver,
    ModifyForecastResolver,
    CloneForecastResolver,
    ProcurementDetailsResolver,
    ProcurementDetailIdResolver,
    ProcurementDetailCountryResolver,
    SubmitForecastResolver,
    ProcurementDetailsAddResolver,
    CanDeactivateGuard
  ]
})
export class AppRoutingModule { }
export function isInIframe() {
  return window !== window.parent && !window.opener;
}
