import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CompositeInventoryByLot, CompositeInventoryClient, CompositeInventoryResult } from '../services/gsscommon-api-client.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { QuantityUnitOfMeasureModel, QuantityUomClient, ReservationClient } from '../services/forecast-api-client.service';
import { RequestType } from '../request-type/request-type.enum';
import { ConfigurationSettings } from '../configuration-settings';
import { RoleGuardService } from '../services/role-gaurd-service';
import { CommonService } from '../services/common.service';
import { ReservationSearchInput, ReservationsClient, ReservationsSearchResults } from './../services/gsscommon-api-client.service';
@Component({
  selector: 'app-inventory-check',
  templateUrl: './inventory-check.component.html',
  styleUrls: ['./inventory-check.component.scss']
})
export class InventoryCheckComponent implements OnInit,OnChanges {
  @Input('isGlpEligible') isGlpEligible: boolean = false;
  @Input('materialType') materialType: string = "COREMaterialId";
  materialInfo: CompositeInventoryResult;
  materialNameHeader: any;
  displayDialog: boolean;
  selectedMaterialHeader: string = "";
  selectedMaterialRepoName: string = "";
  selectedMaterialId: string = "";
  uom: string = "";
  cols: any = [];
  inventoryUOM: string = '';
  @Input('requestTypeName') RequestTypeName: string = RequestType.InStock;
  uomOptions: QuantityUnitOfMeasureModel[];
  selectedUom: QuantityUnitOfMeasureModel;
  selectedRepo: string = "";
  euCenterFeature = ConfigurationSettings.CurrentEnvironment.featureFlags.euCenter;
  isAdministrator = false;
  isMaterialPlanner = false;
  isOrderCoordinator = false;
  selectedInventoryType = "STOCK";
  inventoryTotalAvailable = 0;
  inventoryList = [
    { name: "Stock", value: "STOCK" },
    { name: "GLP", value: "GLP" , disabled : false },
    { name: "Both", value: "BOTH", disabled : false },
  ];
  inventoryBreakDown = {
    STOCK: { INDY:{}, FRANCE: {}, SGS: {}, SUB: {} },
    GLP: { INDY: {}, FRANCE: {}, SGS: {}, SUB: {} },
  };
  reservations : ReservationsSearchResults[] = [];
  indyWarehouseName = "Indy";
  constructor(
    private compositeInventoryClient: CompositeInventoryClient,
    private quantityUomClient: QuantityUomClient,
    private http: HttpClient,
    private reservationClient:ReservationClient,
    private roleGuardService: RoleGuardService,
    private commonService: CommonService,
    private alchemistReservationsClient : ReservationsClient,
   ) {
  }

  ngOnInit(): void {

    this.isMaterialPlanner = this.roleGuardService.doesUserHaveRole('Material Planner');
    this.isAdministrator = this.roleGuardService.doesUserHaveRole('Administrator');
    this.isOrderCoordinator = this.roleGuardService.doesUserHaveRole("Order Coordinator");
    this.indyWarehouseName = this.euCenterFeature ? "Indianapolis" : "Indy";
    this.cols = [
      { field: 'lotId', header: 'Lot No', width: '142px' },
      { field: 'manufacturerLotNumber', header: 'Mfg Lot No', width: '142px' },
      { field: 'dateOfManufacture', header: 'Date of Mfg', width: '142px' },
      { field: 'inventoryType', header: 'Inventory Type', width: '142px' },
      { field: 'expirationDate', header: 'Exp Date', width: '142px' },
      { field: 'available', header: 'Available Qty', width: '142px' }
    ];
    this.getUomDetails();
  }

  ngOnChanges(){
    this.selectedInventoryType = this.RequestTypeName.toUpperCase();
    this.inventoryList.find(x=>x.value=="GLP").disabled =(this.materialType == this.commonService.configurations?.Coformulant || this.materialType == this.commonService.configurations?.FieldInert);
    this.inventoryList.find(x=>x.value=="BOTH").disabled =(this.materialType == this.commonService.configurations?.Coformulant || this.materialType == this.commonService.configurations?.FieldInert);
    this.inventoryList =[...this.inventoryList];
  }

  public reconcileAndRefresh(){
    this.reservationClient.reconcileReservations(this.selectedMaterialRepoName,this.selectedMaterialId).subscribe(response => {
    this.getMaterialDetails(this.selectedMaterialRepoName,this.selectedMaterialId, this.uom, this.selectedMaterialHeader);
      console.log(response);
    },
      error => {
        console.log(error.message);
  });
  }

  downloadReconcileReport(){
    let headers = new HttpHeaders();
    headers =  headers.set('Accept', 'application/pdf');
    this.http.get<Blob>(
      ConfigurationSettings.REST_API_URL + `/api/Reservation/ReconciliationReport/${this.selectedMaterialRepoName}/${this.selectedMaterialId}/${this.uom}/${this.materialNameHeader}`,
      { headers, responseType: 'blob' as 'json' }).subscribe(
        blob => {
            const url = window.URL.createObjectURL(blob);
            var fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.download = 'ReconciliationReport.xlsx';
            fileLink.click();
        });
  }

  isLotExist(lots:CompositeInventoryByLot[] = [], lotNumber, isGlp, warehouseName,reservationTypeId){
    let repos = ['FACTS', 'COFORM', 'BIONEXUS'];
   if(reservationTypeId){
    if(this.euCenterFeature){
      warehouseName = reservationTypeId == 2 ? "France" : this.indyWarehouseName;
    }else{
      warehouseName = reservationTypeId == 2 ? "SGS": this.indyWarehouseName;
    }
  }
    return lots.some(x=> ((this.selectedRepo =="CORE" && x.lotId == lotNumber) || (repos.includes(this.selectedRepo) && x.lotNumber == lotNumber))
                    && ((!isGlp && x.inventoryType.toLowerCase().includes('stock')) || (isGlp && x.inventoryType.toLowerCase().includes('glp')))
                    && (warehouseName == x.inventoryLocation));
  }

  breakDownInventory(materialInfo) {
    let indyStock = materialInfo.compositeInventoryByLot.filter(
      (x) => x.inventoryType.toLowerCase().includes('stock') && x.inventoryLocation == this.indyWarehouseName
    );
    let indyGLP = materialInfo.compositeInventoryByLot.filter(
      (x) => x.inventoryType.toLowerCase().includes('glp') && x.inventoryLocation == this.indyWarehouseName
    );

    let stockAvailable = 0;
    let glpAvailable = 0;
    let indyStockNoLotReservation = 0;
    let sgsStockNoLotReservation = 0;
    let franceStockNoLotReservation = 0;
    let indyGLPNoLotReservation = 0;
    let sgsGLPNoLotReservation = 0;
    let franceGLPNoLotReservation = 0;

    let indyStockPhysical = indyStock.reduce((f, x) => f + x.physical, 0);
    let indyStockReserved = indyStock.reduce((f, x) => f + x.reserved, 0);
    let indyStockAvailable = indyStock.reduce((f, x) => f + x.available, 0);

    let indyGLPPhysical = indyGLP.reduce((f, x) => f + x.physical, 0);
    let indyGLPReserved = indyGLP.reduce((f, x) => f + x.reserved, 0);
    let indyGLPAvailable = indyGLP.reduce((f, x) => f + x.available, 0);

    if (this.euCenterFeature) {
      let franceStock = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('stock') && x.inventoryLocation == "France"
      );
      let franceGLP = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('glp') && x.inventoryLocation == "France"
      );

      indyStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == this.indyWarehouseName).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      indyGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == this.indyWarehouseName).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      franceStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == 'France').reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      franceGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,x.warehouseName,undefined) && x.warehouseName == 'France').reduce((a,r)=> a+ r.reservationAmountConverted ,0);

      let franceStockPhysical = franceStock.reduce((f, x) => f + x.physical, 0);
      let franceStockReserved = franceStock.reduce((f, x) => f + x.reserved, 0);
      let franceStockAvailable = franceStock.reduce(
        (f, x) => f + x.available,
        0
      );

      let franceGLPPhysical = franceGLP.reduce((f, x) => f + x.physical, 0);
      let franceGLPReserved = franceGLP.reduce((f, x) => f + x.reserved, 0);
      let franceGLPAvailable = franceGLP.reduce((f, x) => f + x.available, 0);

      this.inventoryBreakDown.STOCK.INDY = {
        physical: indyStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation,
        available: indyStockAvailable - indyStockNoLotReservation,
      };

      this.inventoryBreakDown.GLP.INDY = {
        physical: indyGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation,
        available: indyGLPAvailable - indyGLPNoLotReservation,
      };

      this.inventoryBreakDown.STOCK.FRANCE = {
        physical: franceStockPhysical,
        reserved: franceStockReserved + franceStockNoLotReservation,
        available: franceStockAvailable - franceStockNoLotReservation,
      };
      this.inventoryBreakDown.GLP.FRANCE = {
        physical: franceGLPPhysical,
        reserved: franceGLPReserved + franceGLPNoLotReservation,
        available: franceGLPAvailable - franceGLPNoLotReservation,
      };

      stockAvailable = indyStockAvailable - indyStockNoLotReservation + franceStockAvailable - franceStockNoLotReservation;
      glpAvailable = indyGLPAvailable - indyGLPNoLotReservation +  franceGLPAvailable - franceGLPNoLotReservation;

      this.inventoryBreakDown.STOCK.SUB = {
        physical: indyStockPhysical + franceStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation + franceStockReserved + franceStockNoLotReservation,
        available: stockAvailable,
      };
      this.inventoryBreakDown.GLP.SUB = {
        physical: indyGLPPhysical + franceGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation + franceGLPReserved + franceGLPNoLotReservation,
        available: glpAvailable,
      };

    } else {
      let sgsStock = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('stock') && x.inventoryLocation == "SGS"
      );
      let sgsGLP = materialInfo.compositeInventoryByLot.filter(
        (x) => x.inventoryType.toLowerCase().includes('glp') && x.inventoryLocation == "SGS"
      );

      indyStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 1).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      indyGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 1).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      sgsStockNoLotReservation = this.reservations.filter(x=>!x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 2).reduce((a,r)=> a+ r.reservationAmountConverted ,0);
      sgsGLPNoLotReservation = this.reservations.filter(x=>x.isGLP && !this.isLotExist(materialInfo.compositeInventoryByLot,x.lotNumber,x.isGLP,undefined,x.reservationTypeID) && x.reservationTypeID == 2).reduce((a,r)=> a+ r.reservationAmountConverted ,0);

      let sgsStockPhysical = sgsStock.reduce((f, x) => f + x.physical, 0);
      let sgsStockReserved = sgsStock.reduce((f, x) => f + x.reserved, 0);
      let sgsStockAvailable = sgsStock.reduce((f, x) => f + x.available, 0);

      let sgsGLPPhysical = sgsGLP.reduce((f, x) => f + x.physical, 0);
      let sgsGLPReserved = sgsGLP.reduce((f, x) => f + x.reserved, 0);
      let sgsGLPAvailable = sgsGLP.reduce((f, x) => f + x.available, 0);

      this.inventoryBreakDown.STOCK.INDY = {
        physical: indyStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation,
        available: indyStockAvailable - indyStockNoLotReservation,
      };

      this.inventoryBreakDown.GLP.INDY = {
        physical: indyGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation,
        available: indyGLPAvailable - indyGLPNoLotReservation,
      };

      this.inventoryBreakDown.STOCK.SGS = {
        physical: sgsStockPhysical,
        reserved: sgsStockReserved + sgsStockNoLotReservation,
        available: sgsStockAvailable - sgsStockNoLotReservation,
      };
      this.inventoryBreakDown.GLP.SGS = {
        physical: sgsGLPPhysical,
        reserved: sgsGLPReserved + sgsGLPNoLotReservation,
        available: sgsGLPAvailable - sgsGLPNoLotReservation,
      };

      stockAvailable = indyStockAvailable - indyStockNoLotReservation + sgsStockAvailable - sgsStockNoLotReservation;
      glpAvailable = indyGLPAvailable - indyGLPNoLotReservation + sgsGLPAvailable - sgsGLPNoLotReservation;

      this.inventoryBreakDown.STOCK.SUB = {
        physical: indyStockPhysical + sgsStockPhysical,
        reserved: indyStockReserved + indyStockNoLotReservation + sgsStockReserved + sgsStockNoLotReservation,
        available: stockAvailable,
      };
      this.inventoryBreakDown.GLP.SUB = {
        physical: indyGLPPhysical + sgsGLPPhysical,
        reserved: indyGLPReserved + indyGLPNoLotReservation + sgsGLPReserved + sgsGLPNoLotReservation,
        available: glpAvailable,
      };
    }
      this.inventoryTotalAvailable = stockAvailable + glpAvailable;
    //console.log(this.inventoryBreakDown);
  }

  getMaterialDetails(repoName: string, materialId: string, uom: string, displayHeader = null) {
    this.selectedMaterialRepoName = repoName;
    this.selectedMaterialId = materialId;
    this.selectedMaterialHeader = displayHeader;
    this.uom = uom;
    this.compositeInventoryClient.retrieveCompositeInventoryWithStorageLoc(repoName, materialId, uom, true)
      .subscribe(materilInfo => {
        this.materialInfo = materilInfo[0];
        let input = new ReservationSearchInput();
        input.productSourceSystem = repoName;
        input.productIdentifier = materialId.toString();
        input.requestedUOM = uom;
        this.alchemistReservationsClient.search(input).subscribe(x=>{
          this.reservations = x;
          this.breakDownInventory(this.materialInfo);
       });
        const result = this.materialInfo.compositeInventoryByLot?.filter(d => d.available > 0 || d.physical > 0 || d.reserved > 0);
        this.materialInfo.compositeInventoryByLot = result;
        if (this.materialInfo.compositeInventoryByLot.length > 0) {
          if (repoName == 'CORE') {
            this.materialInfo.compositeInventoryByLot = this.materialInfo.compositeInventoryByLot.sort((a, b) => {
              return b.lotId - a.lotId;
            });
          }
          else {
            this.materialInfo.compositeInventoryByLot.sort((a, b) => (a.lotNumber > b.lotNumber) ? 1 : -1);
          }
        }
        this.materialNameHeader = displayHeader == null ? materialId : displayHeader;
        this.selectedUom = this.uomOptions.find(uom => uom.uomDisplay == this.uom);
        this.selectedRepo = repoName;
        this.inventoryUOM = uom;
        this.displayDialog = true;
      });
  }

  public showRow(rowData: any) : Boolean {
    let val = rowData['available'] != '0'&&((rowData['glpStatus'].toString().toLowerCase()==='stock')||this.isGlpEligible);
    let isShow = ((this.selectedInventoryType == 'STOCK' && rowData['inventoryType'].toLowerCase().includes('stock')) || (this.selectedInventoryType == 'GLP' && rowData['inventoryType'].toLowerCase().includes('glp')) || (this.selectedInventoryType == 'BOTH'));
    return val && isShow;
  }

  getUomDetails() {
    this.quantityUomClient.get3(true)
      .subscribe(uomResult => {
        this.uomOptions = uomResult;
      });
  }

  onUnitOfMeasureChange(obj: any) {
    this.uom = this.selectedUom.uomDisplay;
    this.getMaterialDetails(this.selectedMaterialRepoName, this.selectedMaterialId, this.uom, this.selectedMaterialHeader);
  }
}
