<div style="margin-left:10px;width: 985px; ">
    <div class="grid">
        <div class="col-7">
            <h4 style="font-weight: bold;">Users</h4>
        </div>
        <div class="col-5">
            <button pButton type="button" id="btnAddUser" label="Add New User" icon="fa fa-plus-square" iconPos="right" 
                (click)="showDialog()" style="float: right;"></button>
        </div>
    </div>
    <div class="grid">
        <div class="col-12">
            <ag-grid-angular
            style="width: 985px; height: 500px;"
            class="ag-theme-alpine"
            [rowData]="users"
            [columnDefs]="columnDefs"
            [frameworkComponents]="frameworkComponents"
            [pagination]="true"
            [paginationPageSize]="25"
            [context] = "context"
            (rowDoubleClicked) = "onRowDoubleClicked($event)"
            >
            </ag-grid-angular>
        </div>
    </div>
</div>
<p-dialog header="{{dialogHeaderText}} User" [draggable]="true" [(visible)]="displayDialog" 
    [style]="{'min-width':'700px'}" [modal]="true" class="add-user-dialog"
    [dismissableMask]="'true'" [contentStyle]="{minHeight: '400px'}">
    <div class="form-group row" hidden>
        <div class="col-md-3"><label for="Id">Id</label></div>
        <div class="col-md-6"><input type="text" id="Id" name="Id" placeholder="Id"
                [(ngModel)]="user.id" pInputText class="col-md-12"></div>
        <div class="col-md-3">
            <!--Validation Errors-->
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3"><label for="Email">Email</label></div>
        <div class="col-md-6"><input type="text" id="txtUserEmail" name="Email" placeholder="Email"
                [(ngModel)]="user.email" pInputText class="col-md-12"></div>
        <div class="col-md-3">
            <i class="fa fa-search fa-2x" style="color:green" aria-hidden="true"
            (click)="onEnterUserId()"></i>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3"><label for="FirstName">First Name</label></div>
        <div class="col-md-6"><input type="text" id="txtUserFirstName" name="FirstName" placeholder="First Name"
                [(ngModel)]="user.firstName" pInputText class="col-md-12" readonly></div>
        <div class="col-md-3">
            <!--Validation Errors-->
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3"><label for="MiddleInitial">Middle Initial</label></div>
        <div class="col-md-6"><input type="text" id="txtUserMiddleInitial" name="MiddleInitial" placeholder="Middle Initial"
                [(ngModel)]="user.middleInitial" pInputText class="col-md-12" readonly></div>
        <div class="col-md-3">
            <!--<p-message severity="error" text="Field is required" *ngIf="user.Username==null"></p-message>-->
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3"><label for="LastName">Last Name</label></div>
        <div class="col-md-6"><input type="text" id="txtUserLastName" name="LastName" placeholder="Last  Name"
                [(ngModel)]="user.lastName" pInputText class="col-md-12" readonly></div>
        <div class="col-md-3">
            <!--Validation Errors-->
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3"><label for="Designation">Title</label></div>
        <div class="col-md-6"><input type="text" id="txtUserDesignation" name="Designation" placeholder="Title"
                [(ngModel)]="user.designation" pInputText class="col-md-12" readonly></div>
        <div class="col-md-3">
            <!--Validation Errors-->
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3"><label for="EmailNotif">Email Notification</label></div>
        <div class="col-md-3"><p-checkbox inputId="EmailNotif" id="chkUserEmailNotification" name="EmailNotif" value="EmailNotif"
                [(ngModel)]="user.optInEmailNotification" binary="true"></p-checkbox></div>
        <div class="col-md-3"><label for="GLPEligible">GLP Eligible</label></div>
        <div class="col-md-3"><p-checkbox inputId="GLPEligible" id="chkGLPEligible" name="GLPEligible" value="GLPEligible"
                [(ngModel)]="user.isGlpeligible" binary="true"></p-checkbox></div>
    </div>
    <div class="form-group row">
        <div class="col-md-3"><label for="Enabled">Enabled</label></div>
        <div class="col-md-3">
            <p-checkbox inputId="Enabled" name="Enabled" [(ngModel)]="user.isActive" binary="true" id="chkUserIsActive">
            </p-checkbox>
        </div>
        <div class="col-md-3"><label for="Approver">Material Approver</label></div>
        <div class="col-md-3">
            <p-checkbox inputId="Approver" id="chkApprover" name="Approver" value="Approver"
                [(ngModel)]="user.isApprover" binary="true" [disabled]="true"></p-checkbox>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3"><label for="Function" style="font-weight: bold;">*Function</label></div>
        <div class="col-md-6">
            <p-dropdown [options]="jobFunctions" [(ngModel)]="selectedFunction" (onChange)="changeFunction()"></p-dropdown>
        </div>
        <div class="col-md-3">
            <!--Validation Errors-->
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-3"><label>User Roles</label></div>
        <div class="col-md-9">
            <div *ngFor="let row of rows">
                <div class="form-group row">
                    <div class="col-md-6" *ngFor="let role of row">
                        <p-checkbox [(ngModel)]="role.selected" id="{{'chkUserRoleSelected' + role.id}}" binary="true" label="{{role.name}}">
                        </p-checkbox>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <button type="button" pButton icon="fa fa-check" (click)="save()" label="Save" id="btnSaveAddUser" *ngIf="!isEditable" [disabled]="!isSaveEnabled"></button>
        <button type="button" pButton icon="fa fa-save" (click)="update()" id="btnUpdateAddUser" *ngIf="isEditable" label="Save" [disabled]="!isSaveEnabled"></button>
        <!--<button type="button" pButton icon="fa-remove" (click)="Delete()" [hidden]="!isEditable" label="Delete"></button>-->
        <!--This delete moved into grid column beside edit button.-->
        <button type="button" pButton icon="fa fa-close" (click)="cancel()" id="btnCancelAddUser" label="Cancel"></button>
    </p-footer>
</p-dialog>

<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>