import { CountryModel, ForecastStateModel, UserModel } from '../services/forecast-api-client.service';


export class Filter {
 country:CountryModel;
 status:ForecastStateModel;
 Program:string;
 Material:string;
 Reviewer:UserModel;
 Forecaster:UserModel;
 ForecastNumber:number;
 OrderBy:any;
 Name:string;
 id:number;
 Filter(){
     this.country = new CountryModel();
     this.status = new ForecastStateModel();
     this.Program ="";
     this.Material ="";
     this.Reviewer = new UserModel();
     this.Forecaster = new UserModel();
     this.Name = "";
     this.id = 0;
     this.ForecastNumber = 0;
     

 }


}
