import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { QuantityUomService } from '../quantityuom.service';
import { ConcentrationUomService } from '../concentrationuom.service';
import { ForecastItemClient, FormulationTypesClient,
         ForecastTypeClient, UserClient, ForecastingSeasonClient,
         ForecastStateClient, QuantityUomClient, ConcentrationUomClient, UserModel} from '../forecast-api-client.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AddForecastResolver implements Resolve<Observable<any>> {
    constructor(
        private uomService: QuantityUomClient,
        private cuonService: ConcentrationUomClient,
        private forecastTypeClient: ForecastTypeClient,
        private formulationTypeClient: FormulationTypesClient,
        private userClient: UserClient,
        private forecastingSeasonClient: ForecastingSeasonClient,
        private forecastStateClient: ForecastStateClient ){}
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) : Observable<any> {
        return forkJoin(
            this.uomService.get3(true),
            this.cuonService.get(true),
            this.forecastStateClient.getForecastStates(),
            this.forecastTypeClient.getForecastTypes(),
            this.formulationTypeClient.get2(true),
            this.userClient.getUsersInRoles(['Biology Leader']),
            this.forecastingSeasonClient.getForecastingSeasons()
        )
        .pipe(
            map(([uoms, cuoms, forecastStates, forecastTypes,
                  formulationTypes, reviewers, forecastingSeasons]) => {
                      formulationTypes.forEach(x => { x.formulationTypeLabelDesc = x.formulationTypeCode + ' - ' + x.description; });
                return {uoms, cuoms, forecastStates, forecastTypes, formulationTypes,
                reviewers, forecastingSeasons}
            })
        )
    }
}
