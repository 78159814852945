<res-loading></res-loading>
<res-layout>
	<res-header>
		<res-logo appName="FARM" [showAppName]="false">
			<img res-logo src="assets/primeng/images/FARM.png" style="width:110px; height:50px;" >
		</res-logo>
	<res-navigation>
		<res-navigation-item [routerLink]="['dashboard']" 
			[ngClass]="{'selected-menu-item': currentSelection === 'dashboard'}" resNavItem *ngIf="this.loggedIn===true&&(this.isBiologyLeader===true||this.isFieldScientist||this.isMaterialPlanner===true || this.isCommercialUser===true||this.isFormulationSpecialist===true||this.isReceivingTeam||isIFSLeadership||this.isOrderCoordinator===true||this.isManager===true)"
			title="My Dashboard">My Dashboard</res-navigation-item>
		<res-navigation-item [routerLink]="['forecasts']" 
			[ngClass]="{'selected-menu-item': currentSelection === 'forecasts'}" resNavItem
			*ngIf="this.isBiologyLeader===true||this.isFieldScientist||this.isMaterialPlanner===true || this.isCommercialUser === true || this.isFormulationSpecialist === true"
			title="My Forecasts">My Forecasts</res-navigation-item>
		<res-navigation-item [routerLink]="['submitted-forecasts-new']" 
			 [ngClass]="{'selected-menu-item': currentSelection === 'submitted-forecasts-new', 'break-word':true}" resNavItem
			 *ngIf="this.isMaterialPlanner===true" title="Submitted Forecasts">Submitted Forecasts</res-navigation-item>	
		<res-navigation-item [routerLink]="['formulation-requests']" 
			 [ngClass]="{'selected-menu-item': currentSelection === 'formulation-requests', 'break-word':true}" resNavItem
			 *ngIf="formulationProductionFeature&&this.isMaterialPlanner===true" title="Formulation Production">Formulation Production</res-navigation-item>
		<res-navigation-item [routerLink]="['procurement']" 
			[ngClass]="{'selected-menu-item': currentSelection === 'procurement'}" resNavItem
			*ngIf="this.isMaterialPlanner===true||this.isOrderCoordinator===true||this.isManager===true||this.isReceivingTeam||isIFSLeadership"
			title="Procurement">Procurement</res-navigation-item>
		<res-navigation-item [routerLink]="['reporting']"
			[ngClass]="{'selected-menu-item': currentSelection === 'reporting'}" resNavItem *ngIf="this.loggedIn===true&&(this.isBiologyLeader===true||this.isFieldScientist||this.isMaterialPlanner===true || this.isCommercialUser===true||this.isFormulationSpecialist===true||this.isReceivingTeam||isIFSLeadership||this.isOrderCoordinator===true||this.isManager===true)"
			title="Reporting">Reporting</res-navigation-item>
		<res-navigation-group name='Administration' *ngIf="this.loggedIn===true&&(this.isBiologyLeader===true||this.isApprover===true||this.isManager)"  title='Administration'
		[ngClass]="{'selected-menu-item': currentSelection==='approvals'||currentSelection==='delegates'||currentSelection==='forecastseason'||currentSelection==='country'||currentSelection==='user'||currentSelection==='formulationtype'||currentSelection==='quantityuom'||currentSelection==='concentrationuom'}">
			<res-navigation-item [routerLink]="['approvals']" 
				[ngClass]="{'selected-menu-item': currentSelection === 'approvals'}" resNavItem
				*ngIf="this.isApprover===true" title="Approvals">Approvals</res-navigation-item>	
		   <res-navigation-item [routerLink]="['delegates']" 
				[ngClass]="{'selected-menu-item': currentSelection === 'delegates'}" resNavItem
				*ngIf="this.isBiologyLeader===true" title="My Delegates">My Delegates</res-navigation-item>
			<res-navigation-item [hidden]="!this.isManager" [routerLink]="['maintenancemessage']" 
				[ngClass]="{'selected-menu-item': currentSelection === 'maintenancemessage'}" resNavItem
				title="Send a maintenance message">Maintenance Message</res-navigation-item>
			<res-navigation-item [hidden]="!this.isManager" [routerLink]="['country']"  resNavItem
				[ngClass]="{'selected-menu-item': currentSelection === 'country'}" title="View countries">Countries</res-navigation-item>
			<res-navigation-item [hidden]="!this.isManager" [routerLink]="['user']"  resNavItem
				[ngClass]="{'selected-menu-item': currentSelection === 'user'}"
				title="Add or modify Users or assign roles">Users</res-navigation-item>
			<res-navigation-item [hidden]="!this.isManager" [routerLink]="['formulationtype']" 
				[ngClass]="{'selected-menu-item': currentSelection === 'formulationtype'}" resNavItem
				title="Add or modify formulation types">Formulation Types</res-navigation-item>
			<res-navigation-item [hidden]="!this.isManager" [routerLink]="['quantityuom']"  resNavItem
				[ngClass]="{'selected-menu-item': currentSelection === 'quantityuom'}"
				title="Add or modify quantity units of measure">Quantity Units of Measure</res-navigation-item>
			<res-navigation-item [hidden]="!this.isManager" [routerLink]="['concentrationuom']" resNavItem
				[ngClass]="{'selected-menu-item': currentSelection === 'concentrationuom'}"
				title="Add or modify concentration units of measure">Concentration Units of Measure</res-navigation-item>
		</res-navigation-group>
	</res-navigation>
    <div res-app-settings>
	  </div>
  </res-header>
<router-outlet></router-outlet>
	<res-footer version="one hunnit">
		<div res-footer-content>
			<ul>
				<li>
					<a target="_blank" href="{{HelpUrl}}"><strong>Help</strong></a>
				</li>
			</ul>
		</div>
		<div res-footer-application-details>
			<div class="d-flex justify-content-end">
				<div>
					<div class="ml-3 align-self-center" style="opacity: 1" *ngIf="false">
					</div>
				</div>
			</div>
			<ul>
				<li>
					{{appVersion}}
				</li>
				<li>
					Developed by Team Katalyst
				</li>
				<li>
					Internal Use Only
				</li>
			</ul>
		</div>
	</res-footer>
</res-layout>
<p-dialog header="System-wide maintenance message" [(visible)]="displayMaintenanceMessage" class="maintenance-message-dialog"
[style]="{'min-width':'500px'}" [modal]="true" [dismissableMask]="true" [contentStyle]="{minHeight: '300px'}">
	<div class="grid">
		<div class="col-12">
			<p-editor [style]="{ height: '250px' }" [readonly]="true" [(ngModel)]="theMessage"></p-editor>
		</div>
	</div>
	<p-footer>
		<button type="button" pButton icon="fa fa-check" (click)="closeMaintenanceMessage()"
			label="Ok"></button>
	</p-footer>
</p-dialog>
<p-dialog header="Request Access" [(visible)]="displayRequestAccess" [style.overflow-x]="'hidden'" class="request-access-dialog"
	[style]="{'min-width':'500px'}" [modal]="true" [dismissableMask]="true" [contentStyle]="{minHeight: '300px'}">
	<div class="form-group row">
		<div class="col-md-12"><span>Hello {{user.firstName}}! Would you like to request access?</span></div>
	</div>
	<div class="form-group row">
		<div class="col-md-3"><label for="Function" style="font-weight: bold;">*Function</label></div>
		<div class="col-md-6">
			<p-dropdown [showClear]="true" [options]="jobFunctions" [(ngModel)]="selectedFunction"
				(onChange)="changeFunction()"></p-dropdown>
		</div>
		<div class="col-md-3">
			<!--Validation Errors-->
		</div>
	</div>
	<p-footer>
		<button type="button" pButton icon="fa fa-check" [disabled]='!isSaveEnabled || disableOnSave' (click)="saveAccessRequest()"
			label="Ok"></button>
		<button type="button" pButton icon="fa fa-close" (click)="cancelAccessRequest()" label="Cancel"></button>
	</p-footer>
</p-dialog>
<p-toast></p-toast>