<div class="grid">
    <div class="col-8">
        <h4 style="font-weight: bold;">My Delegates</h4>
    </div>
    <div class="col-3">
        <button pButton type="button" label="Request User Access" id="btnRequestUserAccess" style="float:right;" 
        icon="fa fa-plus" iconPos="right" (click)="onRequestUserAccess()"></button>
      </div>
</div>
<div>
    <div style="min-height: 250px;overflow-x:hidden" >
        <div class="form-group grid">
            <div class="md:col-11">
                <p-autoComplete #pauto [suggestions]="filteredUsersSingle" id="autoCompletefilteredUsersSingle1" [(ngModel)]="suggestion"
                (completeMethod)="filterUserSingle($event)" (onSelect)="setSuggestion($event)"
                field="fullName" [size]="25"  formControlName="enrolleduser"  
                    placeholder="Start typing a user's name" [minLength]="1" (onClear)="clearValue()"  >
                </p-autoComplete> 
                <button type="button" pButton style="margin-left:5px;" icon="fa fa-plus" (click)="addPickedUser()" label="Add" id="{{'btnAddPickedUser1'}}"></button>
            </div>
        </div>
        <div class="form-group grid">
            <div class="md:col-11">
                <ag-grid-angular
                    #agGridDelegates
                    style="width: 440px; height: 600px;"
                    class="ag-theme-alpine"
                    [rowData]="seasonalDelegations[0].delegates" 
                    [headerHeight]="0"
                    [columnDefs]="delegateGridColumnDefs"
                    [pagination]="true"
                    [paginationPageSize]="20"
                    [frameworkComponents]="frameworkComponents"
                    [overlayNoRowsTemplate]="noRowsTemplate"
                ></ag-grid-angular>
            </div>
        </div>
    </div>
  </div>
  <p-dialog header="Request User Access" [(visible)]="displayDialog" [style]="{'min-width':'700px'}" [modal]="true"
[dismissableMask]="true" [draggable]="true" [contentStyle]="{minHeight: '300px'}">
<div class="form-group row" hidden>
    <div class="col-md-3"><label for="Id">Id</label></div>
    <div class="col-md-6"><input type="text" id="Id" name="Id" placeholder="Id"  id="txtReuqestUserAccessUserId"
            [(ngModel)]="user.id" pInputText class="col-md-12"></div>
    <div class="col-md-3">
        <!--Validation Errors-->
    </div>
</div>
<div class="form-group row">
  <div class="col-md-3"><label for="Email">Email</label></div>
  <div class="col-md-6">
    <input type="text" pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$" size="35" id="Email" name="Email" placeholder="Email" (keyup.enter)="onEnterUserId()"
          [(ngModel)]="user.email" class="col-md-12" id="txtReuqestUserAccessUserEmail">
  </div>
  <div class="col-md-3">
      <i class="fa fa-search fa-2x" style="color:green;" aria-hidden="true" id="txtReuqestUserAccessEnterUserId"
      (click)="onEnterUserId()"></i>
  </div>
</div>
<div class="form-group row">
    <div class="col-md-3"><label for="FirstName">First Name</label></div>
    <div class="col-md-6"><input readonly type="text" id="FirstName" name="FirstName" placeholder="First Name"
            [(ngModel)]="user.firstName" pInputText class="col-md-12" id="txtReuqestUserAccessFirstName"></div>
    <div class="col-md-3">
        <!--Validation Errors-->
    </div>
</div>
<div class="form-group row">
    <div class="col-md-3"><label for="MiddleInitial">Middle Initial</label></div>
    <div class="col-md-6"><input readonly type="text" id="MiddleInitial" name="MiddleInitial" placeholder="Middle Initial"
            [(ngModel)]="user.middleInitial" pInputText class="col-md-12" id="txtReuqestUserAccessUserMiddlInitial"></div>
    <div class="col-md-3">
        <!--<p-message severity="error" text="Field is required" *ngIf="user.Username==null"></p-message>-->
    </div>
</div>
<div class="form-group row">
    <div class="col-md-3"><label for="LastName">Last Name</label></div>
    <div class="col-md-6"><input readonly type="text" id="LastName" name="LastName" placeholder="Last  Name"
            [(ngModel)]="user.lastName" pInputText class="col-md-12" id="txtReuqestUserAccessUserLastName"></div>
    <div class="col-md-3">
        <!--Validation Errors-->
    </div>
</div>
<div class="form-group row">
    <div class="col-md-3"><label for="Designation">Title</label></div>
    <div class="col-md-6"><input readonly type="text" id="Designation" name="Designation" placeholder="Title"
            [(ngModel)]="user.designation" pInputText class="col-md-12" id="txtReuqestUserAccessUserDesignation"></div>
    <div class="col-md-3">
        <!--Validation Errors-->
    </div>
</div>
<p-footer>
    <button type="button" pButton icon="fa fa-check" (click)="save()" label="Save" *ngIf="!isEditable" id="btnReuqestUserAccessSave"></button>
    <!--<button type="button" pButton icon="fa-remove" (click)="Delete()" [hidden]="!isEditable" label="Delete"></button>-->
    <!--This delete moved into grid column beside edit button.-->
    <button type="button" pButton icon="fa fa-close" (click)="cancel()" label="Cancel" id="btnReuqestUserAccessCancel"></button>
</p-footer>
</p-dialog>

<p-toast></p-toast>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>