import { Component, OnInit } from '@angular/core';
import { ConfigurationSettings } from '../configuration-settings';
import { HttpClient, HttpResponse, HttpHeaders, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../loading/res-loading.service';
import { CommonService } from '../services/common.service';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'rxjs/operators';
import { RoleGuardService } from '../services/role-gaurd-service';
import { ToastModule } from 'primeng/toast';
import { ForecastingSeasonClient, UserModel } from '../services/forecast-api-client.service';
import { SecurityService } from '../services/security.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
  providers: [MessageService, HttpClient]
})
export class ReportingComponent implements OnInit {
  isForecastSeason:boolean = false;
  isAdministrator = false;
  isBiologyLeader = false;
  isMaterialPlanner = false;
  isIFSLeadership = false;
  forecastingSeasons =[];
  selectedDate:any ;
  displayForecastSummaryDialog = false;
  isFScientist: any;
  isFormulationSpecialist :boolean
  isCommercialUser: boolean;
  isOrderCoordinator:boolean = false;
  isReceivingTeam: boolean = false;
  userInfo:UserModel ;
  isOrderSummary:boolean = false;
  constructor(private http: HttpClient,
    private messageService: MessageService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private forecastingSeasonClient: ForecastingSeasonClient,
    private appComponent: AppComponent,
    private securityService:SecurityService,
    private roleGuardService: RoleGuardService) { }

  ngOnInit() {
    this.appComponent.currentSelection = 'reporting';
    this.isBiologyLeader = this.roleGuardService.doesUserHaveRole('Biology Leader');
    this.isMaterialPlanner = this.roleGuardService.doesUserHaveRole('Material Planner');
    this.isAdministrator = this.roleGuardService.doesUserHaveRole('Administrator');
    this.isFScientist = this.roleGuardService.doesUserHaveRole('Field Scientist');
    this.isFormulationSpecialist = this.roleGuardService.doesUserHaveRole("Formulation Specialist");
    this.isCommercialUser = this.roleGuardService.doesUserHaveRole("Commercial User");
    this.isIFSLeadership = this.roleGuardService.doesUserHaveRole("IFS Leadership");
    this.isReceivingTeam = this.roleGuardService.doesUserHaveRole("Receiving Team");
    this.isOrderCoordinator = this.roleGuardService.doesUserHaveRole("Order Coordinator");
    this.userInfo = this.securityService.getUser();
    if(this.userInfo ===  null || this.userInfo === undefined)
    {
      this.securityService.validateUser(localStorage.getItem("loggedInUsername"))
          .subscribe(data => {
            this.userInfo = data; 
          });
    }
  }
  countriesReportClick(e) {
    this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeveritySuccess,
      summary: '', detail: this.commonService.configurations.PleaseWaitForReports });
    let headers = new HttpHeaders();
    headers =  headers.set('Accept', 'application/pdf');
    this.http.get<Blob>(
      ConfigurationSettings.REST_API_URL + '/api/country/report',
      { headers, responseType: 'blob' as 'json' }).subscribe(
        blob => {
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        });
        e.stopPropagation();
        e.preventDefault();
      }
  countriesByRegionReportClick(e) {
    this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeveritySuccess,
      summary: '', detail: this.commonService.configurations.PleaseWaitForReports });
    let headers = new HttpHeaders();
    headers =  headers.set('Accept', 'application/pdf');
    this.http.get<Blob>(
      ConfigurationSettings.REST_API_URL + '/api/country/reportByRegion',
      { headers, responseType: 'blob' as 'json' }).subscribe(
        blob => {
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        });
        e.stopPropagation();
        e.preventDefault();
  }
  procurementReportClick(e) {
    this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeveritySuccess,
      summary: '', detail: this.commonService.configurations.PleaseWaitForReports });
    let headers = new HttpHeaders();
    headers =  headers.set('Accept', 'application/pdf');
    this.http.get<Blob>(
      ConfigurationSettings.REST_API_URL + '/api/procurement/GetProcurementReport',
      { headers, responseType: 'blob' as 'json' }).subscribe(
        blob => {
          let date_ob = new Date();
          let date = ("0" + date_ob.getDate()).slice(-2);
          let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
          let year = date_ob.getFullYear();
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = month +'/'+date +'/'+year + ' procurement.xlsx';
            anchor.href = url;
            anchor.click();
        });
        e.stopPropagation();
        e.preventDefault();
  }

  ReportPopup(e,isOrderSummary = false)
{
  this.isOrderSummary = isOrderSummary;
if(isOrderSummary || (this.isOrderCoordinator || this.isFormulationSpecialist || this.isAdministrator || this.isMaterialPlanner || this.isFScientist || this.isBiologyLeader || this.userInfo.functionName == 'Actives & Product Technology'))
{
//    this.forecastingSeasonClient.getForecastingSeasons().subscribe(data => {
//     data = data.sort((a, b) => {
//       return b.startDate.getTime() - a.startDate.getTime()
//     });
//      this.forecastingSeasons = data;
//      this.isForecastSeason = true;
//      this.selectedDate = data[0];
//      this.displayForecastSummaryDialog = true;
//    });
// }
//    else
// {
    this.forecastingSeasons =[];
     for(var i= (new Date()).getFullYear() ; i >=  2020 ; i--)
     {       
       this.forecastingSeasons.push({'name' : i , 'id' :i })
     }
     this.isForecastSeason = false;
     this.selectedDate = this.forecastingSeasons[0];
     this.displayForecastSummaryDialog = true;
  }
  e.stopPropagation();
  e.preventDefault();
}

  ForecastSummaryReport(e) {
    let isLeadership = (this.isBiologyLeader || this.isIFSLeadership || this.isMaterialPlanner || this.isOrderCoordinator || this.isAdministrator)
    
    this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeveritySuccess,
      summary: '', detail: this.commonService.configurations.PleaseWaitForReports });
    let headers = new HttpHeaders();
    headers =  headers.set('Accept', 'application/pdf');
    this.http.get<Blob>(
      ConfigurationSettings.REST_API_URL + '/api/ForecastItem/GetForecastSummaryReport?input=' + this.selectedDate.name + '&isForecastSeason='+ this.isForecastSeason + '&isLeadership='+ isLeadership + '&userId=' + this.userInfo.id,
      { headers, responseType: 'blob' as 'json' }).subscribe(
        blob => {
          let date_ob = new Date();

          // adjust 0 before single digit date
          let date = ("0" + date_ob.getDate()).slice(-2);
          // current month
          let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

          // current year
          let year = date_ob.getFullYear();
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = month +'/'+date +'/'+year + ' Forecast Summary.xlsx';
            anchor.href = url;
            anchor.click();
          this.displayForecastSummaryDialog = false;
        });
        e.stopPropagation();
        e.preventDefault();
}
  OrderSummaryReport(e) {
     
    this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeveritySuccess,
      summary: '', detail: this.commonService.configurations.PleaseWaitForReports });
    let headers = new HttpHeaders();
    headers =  headers.set('Accept', 'application/pdf');
    this.http.get<Blob>(
      ConfigurationSettings.REST_API_URL + '/api/ForecastItem/GetOrderSummaryReport/'+this.selectedDate.name+'/'+this.userInfo.id,
      { headers, responseType: 'blob' as 'json' }).subscribe(
        blob => {
          let date_ob = new Date();

          // adjust 0 before single digit date
          let date = ("0" + date_ob.getDate()).slice(-2);
          // current month
          let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

          // current year
          let year = date_ob.getFullYear();
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = month +'/'+date +'/'+year + ' Order Summary Report.xlsx';
            anchor.href = url;
            anchor.click();
          this.displayForecastSummaryDialog = false;
        });
        e.stopPropagation();
        e.preventDefault();
}
  notImplementedClick(e) {
    this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError,
      summary: '', detail: 'This report is currently in development.' });
      e.stopPropagation();
      e.preventDefault();
}

}
