<div class="grid">
  <div class="md:col-3">
    <h4 id="farm-submission-title" class="farm-submission-title">FARM Submission</h4>
  </div>
  <div class="md:col-9">
  </div>
</div>

<p-card id="formulationRawMaterialRow1">
  <ng-template pTemplate="header">
      <div class="card-header">
          <span class="header-text">{{ formulationRequestInfo.factsId | uppercase }}</span>
      </div>
  </ng-template>
</p-card>

<p-table id="formulationRawMaterialRow2" [value]="[formulationRequestInfo]" [tableStyle]="{ 'min-width': '50rem' }" class="p-datatable-gridlines">
  <ng-template pTemplate="header">
      <tr id="row2Header">
          <th id="programNumberHeader" style="border-width: 1px 0px 0px 1px; text-align: center">Program #</th>
          <th id="productionOwnerHeader" style="border-width: 1px 0px 0px 0px; text-align: center">Production Owner</th>
          <th id="productionLocationHeader" style="border-width: 1px 0px 0px 0px; text-align: center">Production Location</th>
          <th id="searchDelegateHeader" style="border-width: 1px 0px 0px 0px; text-align: center">Delegate</th>
          <th id="reachHeader" style="border-width: 1px 0px 0px 0px; text-align: center">EU Comp.</th>
          <th id="dateNeededHeader" style="border-width: 1px 1px 0px 0px; text-align: center">Date Needed</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body">
      <tr id="row2Body">
          <td id="programNumber" style="border-width: 0px 0px 1px 1px; width: 20%; text-align: center">{{ formulationRequestInfo.programNumber }}</td>
          <td id="productionOwner" style="border-width: 0px 0px 1px 0px; width: 20%; text-align: center">{{ formulationRequestInfo.productionOwner }}</td>
          <td id="productionLocation" style="border-width: 0px 0px 1px 0px; width: 20%; text-align: center">{{ formulationRequestInfo.productionLocation }}</td>
          <td id="searchDelegate" style="border-width: 0px 0px 1px 0px; padding: unset; text-align: center">
              <p-autoComplete [(ngModel)]="selectedDelegate" [suggestions]="filteredUsers" id="autoCompleteDelegates"
                  (completeMethod)="filterUsers($event)" field="display" (onSelect)="onSelectDelegate($event)"
                  placeholder="Delegate" [minLength]="1" scrollHeight="300px">
              </p-autoComplete>
          </td>
          <td id="reachHolder" style="border-width: 0px 1px 1px 0px; text-align: center">{{ formulationRequestInfo.reach ? 'Yes' : 'No' }}</td>
          <td id="dateNeeded" style="border-width: 0px 1px 1px 0px; text-align: center">{{ formulationRequestInfo.dateNeeded | date:'dd-MMM-yyyy' }}</td>
      </tr>
  </ng-template>
</p-table>

<p-table id="formulationRawMaterialRow3" [value]="[formulationRequestInfo]" [tableStyle]="{ 'width': '80%' }" 
class="p-datatable-gridlines">
  <ng-template pTemplate="header">
      <tr id="row3Header">
          <th id="quantityForecastedHeader" style="border-width: 1px 0px 0px 1px; width: 25%; text-align: center">Quantity Forecasted (kg)</th>
          <th id="quantityAdjustmentHeader" style="border-width: 1px 0px 0px 0px; width: 25%; text-align: center">Quantity Adjustment</th>
          <th id="totalQuantityHeader" style="border-width: 1px 0px 0px 0px; width: 25%; text-align: center">Total Quantity</th>
          <th id="totalQuantityHeader" style="border-width: 1px 0px 0px 0px; width: 25%; text-align: center">Forecast Type</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body">
      <tr id="row3Body">
          <td id="quantityForecasted" style="border-width: 0px 0px 1px 1px; text-align: center">{{ formulationRequestInfo.quantityForecasted | number: '1.3-3'  }} kg</td>
          <td id="quantityAdjustment" style="border-width: 0px 0px 1px 0px; padding: unset; text-align: center">
              <input id="quantityAdjustmentInputBox" type="number" step='0.01' value='0.000' placeholder='0.000' style="text-align: center"  [(ngModel)]="quantityAdjustment" (input)="calculateQuantity()" />
              <span id="quantityAdjustmentLabel"> kg</span>
          </td>
          <td id="totalQuantity" style="border-width: 0px 0px 1px 0px; text-align: center">{{ totalQuantity | number: '1.3-3' }} kg</td>
          <td id="totalQuantity" style="border-width: 0px 0px 1px 0px; text-align: center">{{ forecastType}}</td>
      </tr>
  </ng-template>
</p-table>

<p-toast position="top-right"></p-toast>

<p-accordion id="add-shipping-address" [activeIndex]="0" >
  <p-accordionTab header="Add Shipping Address">
    <div *ngIf="!isShippingAddressSaved">
      <div class="grid">
        <div class="col-1 font-weight-bold"><span>*Recipient</span></div>
        <div class="col-9">
          <span class="ui-fluid">
            <p-autoComplete [(ngModel)]="selectedRecipient" [suggestions]="filteredUsers" id="autoCompleteRecipient"
            (completeMethod)="filterUsers($event)" field="display" (onSelect)="recipientOnSelect($event)"
            placeholder="Search by name" [minLength]="1" scrollHeight="300px">
            </p-autoComplete> 
          </span>
        </div>
      </div>
      <div class="col-12" *ngIf="addressList.length > 0">
        <hr>
      </div>

      <div class="col-12 spacing">
        <div class="col-12 header-bar">
          <span> Shipping Address </span>
        </div>
        <div class="grid">
          <div class="col-3"> <span *ngIf="addressList.length > 0 " class="selectedAddressLabel">Selected Address</span>
          </div>
          <div class="col-7"> <span *ngIf="addressList.length > 0 " class="availableAddressLabel">Available
              Addresses</span>
          </div>
        </div>

        <div class="grid" *ngIf="addressList.length > 0">
          <div class="col-3 ui-widget">
            <div class="col-3" class="padding-5-px">
              <div class="selected-address" [@selectedAddressAnimation]=AddressSelectionState (@selectedAddressAnimation.done)="handleTransitionDone($event)">
                <div class="address-content">
                  <span class="font-weight-bold">{{selectedShippingAddress.PersonFullName}}</span>
                  <div>{{selectedShippingAddress.AddressLine1}}</div>
                  <div>{{selectedShippingAddress.AddressLine2}}</div>
                  <div>{{selectedShippingAddress.AddressLine3}}</div>
                  <div *ngIf="selectedShippingAddress.AddressId > 0">{{selectedShippingAddress.City}}, {{selectedShippingAddress.State}} -
                    {{selectedShippingAddress.PostalCode}}</div>
                  <div>{{selectedShippingAddress.Country}}</div>
                  <div *ngIf="selectedShippingAddress.AddressId > 0"><span>Phone Number : </span> {{selectedShippingAddress.Mobile}}</div>
                  <div>{{selectedShippingAddress.OrgName}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel col-9">
            <p-carousel #addressCarousel [value]="addressList" [numVisible]="3" [numScroll]="3">
              <ng-template let-address pTemplate="item">
                <div class="md:col-3" class="padding-5-px">
                  <div [ngStyle]="address.IsDefaultAddress ? {'background-color':'aliceblue'}:''" class="available-address"
                    (click)="shipmentAddressOnClick(address)">
                    <p id="bg-text" *ngIf="address.IsDefaultAddress">Default</p>
                    <div class="address-content">
                      <span class="font-weight-bold">{{address.PersonFullName}}</span>
                      <div>{{address.AddressLine1}}</div>
                      <div>{{address.AddressLine2}}</div>
                      <div>{{address.AddressLine3}}</div>
                      <div>{{address.City}}, {{address.State}} - {{address.PostalCode}}</div>
                      <div>{{address.Country}}</div>
                      <div><span>Phone Number : </span> {{address.Mobile}}</div>
                      <div>{{address.OrgName}}</div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </div>

          <div class="col-12" style="text-align:right" *ngIf="!isShippingAddressSaved">
            <button id="shippingAddressSaveButton" pButton type="button" label="Save" (click)="saveShippingAddress()"></button>
          </div>
        </div>  
      </div>
    </div>

    <div *ngIf="isShippingAddressSaved">
      <div class="grid">
        <div class="col-3 font-weight-bold"><span> Shipping Address Details </span></div>
      </div>
    
      <div class="col-12 spacing">
        <div class="grid mt-2">
          <div class="col-3 ui-widget resShadow" style="margin-right: 8px;">
            <div class="grid">
              <div class="col-12 header-bar"><span> Recipient Details </span></div>
              <div class="col-3">Full Name: </div><div class="col-9">{{selectedShippingAddress.PersonFullName}}</div>
              <div class="col-3">Email: </div><div class="col-9">{{selectedRecipient.email}}</div>
              <div class="col-3">Phone Number: </div><div class="col-9">{{selectedShippingAddress.Mobile}}</div>
            </div>
          </div>
          <div class="col-4 ui-widget resShadow">
            <div class="grid">
              <div class="col-12 header-bar"><span> Shipping Address </span></div>
              <div class="col-2">Address 1: </div><div class="col-10">{{selectedShippingAddress.AddressLine1}}</div>
              <div class="col-2">Address 2: </div><div class="col-10">{{selectedShippingAddress.AddressLine2}}</div>
              <div class="col-2">Address 3: </div><div class="col-10">{{selectedShippingAddress.AddressLine3}}</div>
              <div class="col-2">City: </div><div class="col-10">{{selectedShippingAddress.City}}</div>
              <div class="col-2">State: </div><div class="col-10">{{selectedShippingAddress.State}}</div>
              <div class="col-2">Postal Code: </div><div class="col-10">{{selectedShippingAddress.PostalCode}}</div>
              <div class="col-2">Country: </div><div class="col-10">{{selectedShippingAddress.Country}}</div>
            </div>
          </div>
    
          <div class="col-12" style="text-align:right" *ngIf="isShippingAddressSaved">
            <button id="shippingAddressEditButton" pButton type="button" label="Edit" (click)="editShippingAddress()"></button>
          </div>  
        </div>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
<div class="spacer" style="height: 20px;"></div>

<p-table id="formulationRawMaterialRow4" *ngIf="isDisplayformulationRawMaterialRow4" [value]="rawMaterials" class="p-datatable-gridlines" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10, 25, 50]" [(selection)]="selectedRawMaterials" 
(onHeaderCheckboxToggle)="onRawMaterialCheckboxClicked($event)" (onRowSelect)="onRawMaterialCheckboxClicked($event)"(onRowUnselect)="onRawMaterialCheckboxClicked($event)">
  <ng-template id="formulationRawMaterialRow4Header" pTemplate="header">
    <tr id="row4Header">
      <th id="formulationRawMaterialRow4CheckboxSelectAllHeader" style="border-width: 1px 0px 0px 1px; text-align: center">
        <p-tableHeaderCheckbox id="formulationRawMaterialRow4CheckboxSelectAll" style="position: relative"></p-tableHeaderCheckbox>
        <span id="formulationRawMaterialRow4CheckboxSelectAllLabel" style="position: absolute; margin-left: 8px;">Select All</span>
      </th>
      <th id="viewMaterialInventoryHeader" style="border-width: 1px 0px 0px 0px; text-align: center"></th>
      <th id="materialNameHeader" style="border-width: 1px 0px 0px 0px; text-align: center">Material Name</th>
      <th id="isGLPHeader" style="border-width: 1px 0px 0px 0px; text-align: center">GLP</th>
      <th id="quantityHeader" style="border-width: 1px 0px 0px 0px; text-align: center">Quantity(kg)</th>
      <th id="expiredAcceptableHeader" style="border-width: 1px 0px 0px 0px; text-align: center">Expired Acceptable</th>
      <th id="reachApprovedHeader" style="border-width: 1px 1px 0px 0px; text-align: center">EU Comp.</th>
    </tr>
  </ng-template>
  <ng-template id="formulationRawMaterialRow4Body" pTemplate="body" let-rawMaterial let-index="rowIndex">
    <tr id="row4Body">
      <td id="formulationRawMaterialRow4Checkbox_{{index}}" style="border-width: 0px 0px 1px 1px; width: 5%; text-align: center">
        <p-tableCheckbox [value]="rawMaterial"></p-tableCheckbox>
      </td>
      <td id="viewMaterialInventoryButton_{{index}}" style="border-width: 0px 0px 1px 0px; width: 5%; text-align: center">
        <button pButton type="button" icon="pi pi-list" title="Click icon to view inventory" (click)="handleInventoryCheckClick(rawMaterial)" class="p-button-label"></button>
      </td>
      <td id="materialNameValue_{{index}}" style="border-width: 0px 0px 1px 0px; width: 16%; text-align: center">
        <ng-container *ngIf="!rawMaterial.alternates || rawMaterial.alternates.length === 0">
          {{ rawMaterial.materialName }}
        </ng-container>
        <ng-container *ngIf="rawMaterial.alternates && rawMaterial.alternates.length > 0">
          <p-dropdown class="materialName-dropdown" [options]="rawMaterial.alternates" [(ngModel)]="rawMaterial.selectedRawMaterial" optionLabel="label"></p-dropdown>
        </ng-container>
      </td>
      <td id="isGLPValue_{{index}}" style="border-width: 0px 0px 1px 0px; width: 16%; text-align: center">
				<p-selectButton [options]="isGLPOptions" [(ngModel)]="rawMaterial.isGLP" optionLabel="label" (click)="isGLPOnClick(rawMaterial)" [disabled]="!rawMaterial.isGLPColumnEnabled"></p-selectButton>
      </td>
      <td id="quantityValue_{{index}}" style="border-width: 0px 0px 1px 0px; width: 16%; text-align: center">
        <input type="number" style="text-align: center" step="any"  [(ngModel)]="rawMaterial.quantity" />                
      </td>
      <td id="expiredAcceptableValue_{{index}}" style="border-width: 0px 0px 1px 0px; width: 16%; text-align: center">
        <p-selectButton [options]="expiredAcceptableOptions" [(ngModel)]="rawMaterial.expiredAcceptable" optionLabel="label"></p-selectButton>
      </td>
      <td id="reachApprovedValue_{{index}}" style="border-width: 0px 1px 1px 0px; width: 16%; text-align: center">
        <p-selectButton [options]="reachApprovedOptions" [(ngModel)]="rawMaterial.reachApproved" optionLabel="label"></p-selectButton>
      </td>
    </tr>
  </ng-template>
</p-table>

<div id="rawMaterialAddItemButton" style="float:left">
  <button pButton type="button" label="Add Item" (click)="addItem()" style="margin-left: 15px; margin-top: 15px;"></button>
</div>

<div id="rawMaterialPageSubmitButton" style="float:right">
	<button pButton type="button" label="Submit" (click)="submit()" style="margin-left: 15px; margin-top: 15px;" [disabled]="isSubmitDisabled()"></button>
</div>


<p-dialog id="activeIngredientDialog" [header]="activeIngredientName" [(visible)]="isCOREMaterialLotSearchVisible" (onHide)="activeIngredientDialogOnHide()" [style]="{ width: '50vw', height: '300px' }" [modal]="true" [dismissableMask]="false">
  <div class='col-12' style="display: flex; justify-content: center;">
    <span style='font-weight:bold'>Enter CORE Material Lot ID</span>
  </div>
  <div class='col-12' style='display: flex; justify-content: center;'>
    <div class="grid">
      <div class='col-9'>
        <input #txtSearch [(ngModel)]="searchText" type="text" class="searchTextBox" pInputText tabindex="0" (keyup.enter)="validateActiveIngredient()">
      </div>
      <div class='col-3' style="text-align: end;">
        <i aria-hidden="true" title="Validate" class="validate-icon fa fa-solid fa-check fa-2x" style="margin-top:5px;color:blue;cursor:pointer" (click)="validateActiveIngredient()"></i>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="Close" (click)="isCOREMaterialLotSearchVisible = false"></button>
  </ng-template>
</p-dialog>

<p-dialog id="formulationDialog" [header]="formulationName" [(visible)]="isFormulationMaterialLotSearchVisible" (onHide)="formulationDialogOnHide()" [style]="{ width: '50vw', height: '300px' }" [modal]="true" [dismissableMask]="false">
  <div class='col-12' style='display: flex; justify-content: center;'>
    <div class="grid" style='margin-left:100px;'>
      <div class='col-2'>
        <div class='col-12' style='display: flex; justify-content: center;' >
          <span><h6>AiC*</h6></span>
        </div>
        <div class='col-12'>
          <input #txtAiC   [(ngModel)]="formulationValidationAiC" maxlength="10" type="text" class="searchTextBox2" pInputText tabindex="0" (keyup.enter)="validateFormulation()">
        </div>
      </div>
      <div class='col-2' style="margin-left:15px;">
        <div class='col-12' style='display: flex; justify-content: center;' >
          <span><h6>Concept*</h6></span>
        </div>
        <div class='col-12'>
          <input #txtConcept [(ngModel)]="formulationValidationConcept" type="text" class="searchTextBox2" pInputText tabindex="0" (keyup.enter)="validateFormulation()">
        </div>
      </div>
      <div class='col-2' style="margin-left:15px;">
        <div class='col-12' style='display: flex; justify-content: center;' >
          <span><h6>Composition*</h6></span>
        </div>
        <div class='col-12'>
          <input #txtComposition [(ngModel)]="formulationValidationComposition" type="text" class="searchTextBox2" pInputText tabindex="0" (keyup.enter)="validateFormulation()">
        </div>
      </div>
      <div class='col-2' style="margin-left:15px;">
        <div class='col-12' style='display: flex; justify-content: center;' >
          <span><h6>Lot*</h6></span>
        </div>
        <div class='col-12'>
          <input #txtLot [(ngModel)]="formulationValidationLotNumber" type="text" class="searchTextBox2" pInputText tabindex="0" (keyup.enter)="validateFormulation()">
        </div>
      </div>
      <div class='col-3'>
        <div class='col-12' style='display: flex; justify-content: center;' ><span></span></div>
        <div class='col-12'>
          <i aria-hidden="true" title="Search" class="validate-icon fa fa-solid fa-check fa-2x" style="color: blue;margin-top: 45px;margin-left:5px;cursor:pointer" (click)="validateFormulation()"></i>
        </div>
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="Close" (click)="isFormulationMaterialLotSearchVisible = false"></button>
  </ng-template>
</p-dialog>

<p-dialog id="addItemDialog" [(visible)]="isAddItemDialogVisible" (onHide)="addItemDialogOnHide()" [style]="{ width: '55vw', height: '500px' }" [modal]="true" [dismissableMask]="false">
  <div class="grid" style="margin-top: 20px; margin-bottom: 30px;">
    <div class="col-3">
      <div class="col-12">
        <span style='font-weight:600'>Co-Formulants</span>
      </div>
      <div class="col-12">
        <p-radioButton label='Co-Formulant' name="materialTypeId" value="1" [(ngModel)]="materialTypeId" (click)="onNewItemTypeChange();"></p-radioButton>
      </div>
    </div>

    <div class="col-3">
      <div class="col-12">
        <span style='font-weight:600'>Formulations</span>
      </div>
      <div class="col-12">
        <p-radioButton label='FACTS Formulation ID' name="materialTypeId" value="2" [(ngModel)]="materialTypeId" (click)="onNewItemTypeChange();"></p-radioButton>
      </div>
    </div>

    <div class="col-3">
      <div class="col-12">
        <span style='font-weight:600'>Compounds/Technicals</span>
      </div>
      <div class="col-12">
        <p-radioButton label='CORE Material ID' name="materialTypeId" value="3" [(ngModel)]="materialTypeId" (click)="onNewItemTypeChange();"></p-radioButton>
      </div>
    </div>

    <div class="col-3">
      <div class="col-12">
        <span style='font-weight:600'>Bionexus Search</span>
      </div>
      <div class="col-12">
        <p-radioButton label='BIO Material ID' name="materialTypeId" value="4" [(ngModel)]="materialTypeId" (click)="onNewItemTypeChange();"></p-radioButton>
      </div>
    </div>
  </div>

  <div class="grid" *ngIf="!isAddItemFormulation">
    <div class='col-3'></div>
    <div class='col-6'>
      <input [(ngModel)]="addItemSearchText" type="text" class="addItemInputBox" pInputText (keyup.enter)="searchMaterial()">
    </div>
    <div class='col-3' style="text-align: left;">
      <i aria-hidden="true" title="Validate" class="fa fa-search fa-2x" style="margin-top:5px;color:blue;cursor:pointer" (click)="searchMaterial()"></i>
    </div>
  </div>

  <div class="grid" *ngIf="isAddItemFormulation">
    <div class='col-3'></div>
    <div class='col-2'>
      <div class='col-12'>
        <input [(ngModel)]="addFormulationValidationAiC" maxlength="10" type="text" class="addFormulationInputBox" pInputText (keyup.enter)="validateFormulation()">
      </div>
    </div>
    <div class='col-2'>
      <div class='col-12'>
        <input [(ngModel)]="addFormulationValidationConcept" type="text" class="addFormulationInputBox" pInputText (keyup.enter)="searchMaterial()">
      </div>
    </div>
    <div class='col-2'>
      <div class='col-12'>
        <input [(ngModel)]="addFormulationValidationComposition" type="text" class="addFormulationInputBox" pInputText (keyup.enter)="searchMaterial()">
      </div>
    </div>
    <div class='col-2'>
      <div class='col-12' style="text-align: left;">
        <i aria-hidden="true" title="Search" class="fa fa-search fa-2x" style="margin-top:5px;color:blue;cursor:pointer" (click)="searchMaterial()"></i>
      </div>
    </div>
    <div class='col-1'></div>
  </div>

  <div class='col-12' *ngIf="isSearchItemResultVisible">
    <p-dataView #dv layout="list" [paginator]="true" [rows]="5" [value]="searchItemResult" paginatorPosition="both" pageLinks="3" [alwaysShowPaginator]="true">
      <ng-template let-result pTemplate="listItem">
        <div class="col-12 ui-dataview-content ui-widget-content">
          <div class="col-12">
            <div class="grid resShadow">
              <div class="col-10">
                <span>{{result.materialName}}</span>
              </div>
              <div class="col-2">
                <button pButton type="button" icon="fa fa-plus fa-3x" (click)="addIndividualItem(result)" class="p-button-success float-right" *ngIf="!result.isAdded">
                </button>
                <button pButton type="button" icon="fa fa-minus fa-3x" (click)="removeIndividualItem(result)" class="removeIndividualItem p-button-danger float-right" *ngIf="result.isAdded">
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="paginatorleft" let-state>
        <span class="ui-paginator-first padding-left-10px"> Total Items : {{searchItemResult.length}} {{ dv.totalRecords == searchItemResult.length ? '' : '(Showing Items: '+ dv.totalRecords + ')'}} </span>
      </ng-template>
    </p-dataView>
  </div>

  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="Close" (click)="addItemDialogOnHide()"></button>
  </ng-template>
</p-dialog>



<app-inventory-check #inventoryCheck></app-inventory-check>