import { Injectable, InjectionToken, Inject } from '@angular/core';

export const COMPOSITE_SEARCH_SETTINGS: string = 'COMPOSITE_SEARCH_SETTINGS';
@Injectable({
    providedIn: 'root',
  })
export class MaterialSearchConfig {
    factsUrl: string;
    coreUrl: string;
    raceUrl: string;
    hostAppApiUrl: string;
    tokenEndPoint: string;
    gssCommonUrl: string;
    pageLength: number;
    showCartButton: boolean;
    showInfoButton: boolean;
    useShowAllHideAll: boolean;
}
