import { EnvironmentService } from "./services/environment-service/environment.service";
import { WebConfigKey } from './services/web-config-service/web-config-key';
/*
    This class will contain all configuration related keys.
*/
export class ConfigurationSettings {

    // environmentService is the object of the EnvironmentDefinition interface type. 
    // It will provide you the the current environment related key and its value
    private static environmentService = new EnvironmentService();

    /* Getting RestApiUrl */
    public static REST_API_URL: string = ConfigurationSettings.environmentService.currentEnvironment.ShellAPIPath;
    public static CURRENT_ENVIRONMENT_NAME: string = ConfigurationSettings.environmentService.currentEnvironment.name;
    public static APP_UI_URL: string = ConfigurationSettings.environmentService.currentEnvironment.ShellUIPath;
    public static CLIENT_ID: string = ConfigurationSettings.environmentService.currentEnvironment.clientID;
    public static AUTHORITY: string = ConfigurationSettings.environmentService.currentEnvironment.authority;
    public static CPSS_API_URL: string = ConfigurationSettings.environmentService.currentEnvironment.cpssApiPath;
    public static APP_INSIGHTS_KEY: string = ConfigurationSettings.environmentService.currentEnvironment.appInsightsKey;
    public static FAQ_URL: string = ConfigurationSettings.environmentService.currentEnvironment.faqUrl;
    public static HELP_URL: string = ConfigurationSettings.environmentService.currentEnvironment.helpUrl;
    public static APP_VERSION: string = ConfigurationSettings.environmentService.currentEnvironment.appVersion;
    public static COMPOSITE_SEARCH_SETTINGS = ConfigurationSettings.environmentService.currentEnvironment.COMPOSITE_SEARCH_SETTINGS;
  
    public static CurrentEnvironment = ConfigurationSettings.environmentService.currentEnvironment;
    // /* the default page after the table component loading */
    // public static PAGE: number = 1;

    // /* number of the displaying items (rows) on a page */
    // public static ITEMS_PER_PAGE: number = 10;

    // /* number of the displaying pages before ... */
    // public static MAX_SIZE: number = 5;

    // /* total number of the pages */
    // public static NUMBER_PAGES: number = 1;

    // /* 
    //     configuration key which will allow to check first char from user entered source identifiers with provided prefix list.
    //     if configuration key is true then only it will perform prefix check 
    //   */
    // public static PERFORM_EVENT_PREFIX_CHECK: string =  WebConfigKey.getValue("PerformEventPrefixCheck");

    // /* component id prefix RegExp */
    // public static COMPONENT_ID_PREFIX_LIST: RegExp = new RegExp(WebConfigKey.getValue("ComponentIdPrefixList"));

    public static CURRENT_LOGGED_IN_USERNAME = "gss"; //sessionStorage.getItem("loggedInUsername");

    public static PLEASE_WAIT_MESSAGE = "Please wait...";

    // public static DECISION_TYPE_SELECTED_ADVANCEMENT = "Advancement";

    // public static DECISION_TYPE_SELECTED_RECOMMENDATION = "Recommendatioy
    // public static MAX_TRAIT_CONCEPT_LENGTH = 50;

    // public static ENABLE_SECURITY = "false";

    // public static FILE_IS_TO_BIG = "Invalid file size - ";

    // public static MAX_FILE_SIZE = "Maximum upload size is 10MB";
}