import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 

@Injectable()
export class CommonService {

  public configurations:any = {
    "DataSaved":"Data saved successfully",
    "MessageServiceSeveritySuccess": "success",
    "MessageServiceSeverityError": "error",
    "ConcentrationUOMAddPopupHeader" :"Add Concentration unit of Measure",
    "ConcentrationUOMEditPopupHeader" : "Edit Concentration unit of Measure",
    "QuantityUOMAddPopupHeader" : "Add Quantity unit of Measure",
    "QuantityUOMEditPopupHeader":  "Edit Quantity unit of Measure",
    "DuplicateAbbreviation" :  "Cannot create measure with same Abbreviation",
    "PleaseWaitForReports": "Your report will be ready momentarily!",
    "ForecastStatus" : {
                        "DataEntry" :"Data Entry",
                        "AwaitingReview" :"Awaiting Review",
                        "Submitted" :"Submitted",
                        "InProcess" :"In Process",
                        "Completed" :"Completed",
                        "DelegateReview" :"Delegate Review",
                        "Procurement": "Procurement",
                        "Archived" :"Archived",
                        "Deleted" :"Deleted",
                        "MaterialApproval": "Material Approval"
                        },
    "InventoryOrderStatus": {
        "ToDo": "To Do",
        "InStock": "In Stock",
        "ToOrder": "To Order",
        "Ordered": "Ordered",
        "Cancelled": "Cancelled",
        "FollowUp": "Follow Up",
        "Outsourced": "Outsourced"
    },
    "OrderStatus": {
        "New": "New",
        "Ordered": "Ordered",
        "FollowUp": "Follow-up",
        "Received": "Received",
        "InInventory": "In Inventory",
        "PartialReceipt": "Partial Receipt"
    },
    "CompositeSearchTimeoutError":"Please provide appropriate and narrower criteria.",
    "SearchCriteriaCannotBeEmptyWithLimit":"Search criteria cannot be empty and should not exceed {0} digits",
    "SearchCriteriaCannotBeEmpty":"Search criteria cannot be empty",
    "EnterTradeName" :"Enter Trade Name or Common Name",
    "EnterFormulation" : "Enter Formulation Composition",
    "EnterSynonym" :"Enter a Synonym to search",
    "EnterCortevaTradeName" :"Enter Corteva Trade Name",
    "EnterPartyTradeName":"Enter 3rd Party Trade Name",
    "EnterFormulationLot":"Enter Formulation Lot",
    "EnterCoreMaterialId" :"Enter CORE Material ID",
    "EnterCoreMaterialLotId":"Enter CORE Material Lot ID",
    "EnterAIC":"Please enter AIC",
    "EnterConcept":"Please enter concept",
    "EnterComposition":"Please enter composition",
    "AICAlphaNumeric":"AIC should contain only alpha numeric values and should not exceed 10 characters",
    "EnterLot":"Please enter lot",
    "Coformulant":"Coformulant",
    "FieldInert" :"FieldInert",
    "CortevaTradeName":"CortevaTradeName",
    "ThirdPartyTradeName": "ThirdPartyTradeName" ,
    "FACTSFormulationName" :"FACTSFormulationName",
    "FACTSSynonym" :"FACTSSynonym",
    "FACTSFormulationNameGlp" :"FACTSFormulationNameGlp",
    "COREMaterialId" :"COREMaterialId",
    "CORESynonym" :"CORESynonym",
    "COREMaterialLotGlp" :"COREMaterialLotGlp",
    "EarliestDateNeededCaption": "This should be the earliest date a sample of this forecasted material will be needed/used.",
    "LatestDateNeededCaption": "This should be the latest date a sample of this forecasted material could be needed/used. Please note that the inventory reservation for this forecast will expire 30 days after the date selected."
};

constructor(private _http: HttpClient) {}

  public convertUTCToLocalTime(dateString: string | Date) {
    let date = new Date(dateString);
    const milliseconds = Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    );
    return new Date(milliseconds);
  }
}
