import { SecurityService} from './security.service';
import { MsalService } from "@azure/msal-angular";
import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot
} from '@angular/router';
@Injectable()
export class PassThruGuardService implements CanActivate {
    private userInfo: any = '';
    constructor(public router: Router, private securityService: SecurityService, private auth: MsalService) { }
    canActivate(route: ActivatedRouteSnapshot): boolean {
        var ret = localStorage.getItem("msal.client.info") !== null;
        return ret;
    }
}
