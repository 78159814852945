import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 

@Injectable()
export class TupleService {

  constructor(private _http: HttpClient) {
  }
   
  public configurations:any = {"CompositeSearchTimeoutError":"No exact matches found, please try a different search term.",
  "SearchCriteriaCannotBeEmptyWithLimit":"Search criteria cannot be empty and should not exceed {0} digits",
  "SearchCriteriaCannotBeEmpty":"Search criteria cannot be empty",
  "EnterTradeName" :"Enter Trade Name or Common Name",
  "EnterFormulation" : "Enter Formulation Composition",
  "EnterSynonym" :"Enter a Synonym to search",
  "EnterCortevaTradeName" :"Enter Corteva Trade Name",
  "EnterPartyTradeName":"Enter 3rd Party Trade Name",
  "EnterFormulationLot":"Enter Formulation Lot",
  "EnterCoreMaterialId" :"Enter CORE Material ID",
  "EnterCoreMaterialLotId":"Enter CORE Material Lot ID",
  "EnterAIC":"Please enter AIC",
  "EnterConcept":"Please enter concept",
  "EnterComposition":"Please enter composition",
  "AICAlphaNumeric":"AIC should contain only alpha numeric values and should not exceed 10 characters",
  "EnterLot":"Please enter lot",
  "EnterTsn":"Enter TSN",
  "Coformulant":"Coformulant",
  "FieldInert" :"FieldInert",
  "CortevaTradeName":"CortevaTradeName",
  "ThirdPartyTradeName": "ThirdPartyTradeName" ,
  "FACTSFormulationName" :"FACTSFormulationName",
  "FACTSSynonym" :"FACTSSynonym",
  "FACTSFormulationNameGlp" :"FACTSFormulationNameGlp",
  "COREMaterialId" :"COREMaterialId",
  "CORESynonym" :"CORESynonym",
  "COREMaterialLotGlp" :"COREMaterialLotGlp",
  "FACTTSNGlp" :"FACTTSNGlp",
  "EnterTSNLot":"Enter TSN",
  "TsnGlp":"TsnGlp",
  "BioMaterialId":"BioMaterialId",
  "EnterBioMaterialId": "Enter Bionexus Material ID",
  "BionexusSynonymGlp": "BionexusSynonymGlp",
  "BionexusSynonymStock": "BionexusSynonymStock",
  "BioMaterialLotId": "BioMaterialLotId",
  "EnterBioMaterialLotId": "Enter Bionexus Material Lot ID",
  };

}
