import { Component, OnInit } from '@angular/core';
import { UserClient, UserModel, RoleClient, RoleModel, JobFunction } from '../services/forecast-api-client.service';
import { ConfirmationService, SelectItem } from "primeng/api";
import { MessageService, Message } from 'primeng/api';
import { concatMap } from 'rxjs/operators';
import { LoadingService } from '../loading/res-loading.service';
import { AngularFrameworkComponentWrapper } from 'ag-grid-angular/lib/angularFrameworkComponentWrapper';
import { CheckboxCellRendererComponent } from '../custom/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    providers: [ConfirmationService, MessageService]
})

export class UserComponent implements OnInit {
    columnDefs = [
        // {headerName: 'User ID', field: 'userId', sortable: true, filter: true, width: 100},
        {headerName: 'Email', field: 'email', sortable: true, filter: true, width: 210, tooltipField: 'email'},
        {headerName: 'First Name', field: 'firstName', sortable: true, filter: true, width: 140},
        {headerName: 'MI', field: 'middleInitial', sortable: true, filter: true, width: 70},
        {headerName: 'Last Name', field: 'lastName', sortable: true, filter: true, width: 150},
        {headerName: 'Enabled', field: 'isActive', cellRenderer: 'checkboxCellRenderer', width: 110, sortable: true, filter: true},
        {headerName: 'Awaiting Approval', field: 'awaitingApproval', cellRenderer: 'checkboxCellRenderer',
            width: 165, sortable: true, filter: true},
        {headerName: 'Material Approver', field: 'isApprover', cellRenderer: 'checkboxCellRenderer', 
        width: 165, sortable: true, filter: true},
        {headerName: 'Function', field: 'functionName', sortable: true, filter: true, width: 150},

    ];
    frameworkComponents = {
        checkboxCellRenderer: CheckboxCellRendererComponent
    };
    public context;
    constructor(public userClient: UserClient,
        public roleClient: RoleClient,
        public appComponent: AppComponent,
        public confirmationService: ConfirmationService,
        public messageService: MessageService) { 
            this.context = { componentParent: this, disableCheckBox: true };
        }

    ngOnInit() {
        this.appComponent.currentSelection = 'user';
        this.loadDataUsers();
        this.loadDataRoles();
        this.loadJobFunctions();
    }

    // growlMsgs: GrowlModule[] = [];
    users: UserModel[];
    roles: RoleModel[];
    rows: any[] = [];
    user = new UserModel();
    isEditable: boolean = false;
    displayDialog: boolean = false;
    isDuplicateUser: boolean = false;
    dialogHeaderText: any = "Add New";
    isSaveEnabled: boolean = false;
    jobFunctions: SelectItem[] = [];
    selectedFunction: number;
    public loadDataUsers() {
        this.userClient.getUsers()
        .subscribe(data => {
            this.users = data;
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Error when loading users. Check with Admin.' });
            console.log(error);
          });
    }
    public loadDataRoles() {
        this.roleClient.getRoles()
        .subscribe(data => {
            this.roles = data;
            this.rows = this.convert1DTo2D(this.roles, 2);
        });
    }
    public loadJobFunctions() {
        if (this.jobFunctions.length === 0) {
        this.jobFunctions.push({label: 'Select Function', value: 0});
        this.userClient.getFunctionsAll()
        .subscribe(data => {
            const fxs = data.map(x => ({label: x.functionName, value: x.id}));
            fxs.forEach(x => this.jobFunctions.push(x));
            });
        }
    }

    public showDialog() {
        this.dialogHeaderText = 'Add New';
        this.user = new UserModel();
        this.user.optInEmailNotification = true;
        this.user.isActive = true;
        this.selectedFunction = 0;
        this.user.functionId = 0;
        this.isEditable = false;
        this.roles.forEach(role => role.selected = false);
        this.displayDialog = true;
        this.isSaveEnabled = false;
    }
    public onRowDoubleClicked($event) {
        this.isEditable = true;
        this.user = $event.data;
        this.selectedFunction = (this.user.functionId === undefined || this.user.functionId === 0) ? 0 : this.jobFunctions.find(a => a.value === this.user.functionId).value;
        this.isSaveEnabled = (this.selectedFunction !== 0);
        this.roles.forEach(role => role.selected = false);
        this.user.roles.forEach(ur => {
            var tr = this.roles.find(x => x.id === ur.id);
            tr.selected = true;
        });
        this.dialogHeaderText = "Modify"
        this.displayDialog = true;
    }
    public onEnterUserId() {
        this.messageService.add({ severity: 'info', summary: '', detail: 'Search user information. Wait ...' });
        this.userClient.getADUser(this.user.email) 
        .subscribe(data => {
            if (data === null) {
                this.messageService.add({ severity: 'warn', summary: '', detail: 'User information not found. Check spelling.' });
            } else {
                this.user = data;
                this.user.optInEmailNotification = true;
                this.user.isActive = true;
                // this.isSaveEnabled = true;
                this.messageService.add({ severity: 'success', summary: '', detail: 'User information successfully retrieved.' });
            }
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Error when retrieving user information. Check with admin.' });
            console.log(error);
        });
    }
    public save() {
        var selectedroles = this.roles.filter(role => role.selected === true);
        this.user.functionId = this.selectedFunction;
        this.user.roles = selectedroles;
        this.createToDb(this.user);
    }
    public update() {
        var selectedroles = this.roles.filter(role => role.selected === true);
        this.user.functionId = this.selectedFunction;
        this.user.roles = selectedroles;
        this.updateToDb(this.user);
    }
    public changeFunction() {
        this.isSaveEnabled = this.selectedFunction !== 0;
        const func = this.jobFunctions.find(a => a.value === this.selectedFunction);
        if (func.label === 'Commercial' || func.label === 'IFS') {
            this.user.isGlpeligible = false;
        }
        if (func.label === 'P&PT' || func.label === 'Regulatory') {
            this.user.isGlpeligible = true;
        }
    }
    public updateToDb(userToUpdate) {
        const updateUsers = this.userClient.putUser(userToUpdate.id, this.user)
        .pipe(
            concatMap(result => {
                return this.userClient.getUsers();
            })
        );
        updateUsers.subscribe((data) => {
            this.users = data;
            this.messageService.add({ severity: 'success', summary: '', detail: 'User successfully updated' });
            this.displayDialog = false;
            this.isSaveEnabled = false;
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Error when updating user. Check with Admin.' });
            console.log(error);
        });
    }
    public createToDb(newUser) {
        const createUser = this.userClient.postUser(newUser) 
        .pipe (
            concatMap(result => {
                return this.userClient.getUsers();
            })
        );
        createUser.subscribe((data) => {
            this.users = data;
            this.messageService.add({ severity: 'success', summary: '', detail: 'User successfully saved' });
            this.displayDialog = false;
            this.isSaveEnabled = false;
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Error when saving user. Duplicated user email.' });
            console.log(error);
        });
    }
    cancel() {
        this.displayDialog = false;
        this.isSaveEnabled = false;
        this.isDuplicateUser = false;
        this.isEditable = false;
        this.selectedFunction = 0;
        this.user = new UserModel();
    }
    public convert1DTo2D (array: RoleModel[], size: number) {
        var tmp = [], i, k;
        for (i = 0, k = -1; i < array.length; i++) {
            if (i % size === 0) {
                k++;
                tmp[k] = [];
            }
            tmp[k].push(array[i]);
        }
        return tmp;
    }
}