export class StringUtility {

    /* Method to remove duplicate items in provided array */
    public removeDuplicates(arr): Array<string> {
        let obj = {};
        for (let i = 0; i < arr.length; i++) {
            obj[arr[i]] = true;
        }
        arr = [];
        for (let key in obj) {
            arr.push(key);
        }
        return arr;
    }

    /* Method will check that the first char of the provided string is matched with the any one char with the RegExp
       if matched then return true else false*/
    public isFirstCharOfStringMatched(reg:RegExp, str: string): boolean
    {
        return str.charAt(0).match(reg) == null ? false : true;
    }

    /* Method to check that value is null or empty */
    public isNullOrEmpty(value: string): boolean {
        let isNullOrEmpty: boolean = (value == null || value.trim() == "") ? true : false;
        return isNullOrEmpty;
    }

    /* Method to check that value is null */
    public isNull(value: any): boolean {
        let isNullValue: boolean = (value == null) ? true : false;
        return isNullValue;
    }

    /* Method to check that the oldValue and newValue are changed or not */
    public isValueChanged(oldValue: string, newValue: string): boolean {
        let isValueChanged: boolean = false;
        if(!this.isNullOrEmpty(oldValue) && !this.isNullOrEmpty(newValue))
         {
             isValueChanged = (oldValue.toLowerCase() == newValue.toLowerCase()) ? false: true;
         }
         return isValueChanged;
     }

      /* Method to convert strings to PascalCase from lowercase, uppercase, and mixed */
    public toPascalCase(str : string){
      let output = str;
      if(output)
        output = (output || '').replace(/\w+/g, function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();});

      return output;
  }
}
