import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  OrderItemModel, OrderItemForecastDestinationModel, OrderStatusModel,
  QuantityUnitOfMeasureModel, OrderItemCommentModel, UserModel, OrderItemDestinationModel,
  SampleInventoryLocationModel, ForecastItemActiveIngredientModel, OrderTypeModel, MaterialPurposeModel,
  OrderItemActiveIngredientModel, ProcurementClient, CountryClient, QuantityUomClient, UserClient,
  MaterialPurposeClient, OrderTypeClient, ShipmentDefectClient, ShipmentDefectModel, ReceiptStatusModel, OrderItemShipmentModel, ForecastItemModel,
  ForecastItemCommentLogModel,
  ForecastItemDestinationModel,
  ForecastItemDestinationClient
} from '../services/forecast-api-client.service';
import { CommonService } from '../services/common.service';
import { SecurityService } from '../services/security.service';
import { AppComponent } from '../app.component';
import { UntypedFormControl, UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';
import { FileAlreadyExistException } from '@angular-devkit/schematics';
import { Observable } from 'rxjs';
import { CanComponentDeactivate } from '../services/route-guards/can-deactivate-guard.service';
import { BioNexusClient, MaterialResult } from '../services/gsscommon-api-client.service';
import { ForecastItemClient } from '../services/forecast-api-client.service';

@Component({
  selector: 'app-procurement-detail',
  templateUrl: './procurement-detail.component.html',
  styleUrls: ['./procurement-detail.component.scss'],
})
export class ProcurementDetailComponent implements OnInit, CanComponentDeactivate {
  actualProductName:string;
  procurementDetails: OrderItemModel;
  oldprocurementDetails: OrderItemModel;
  allData: any;
  destinations: OrderItemForecastDestinationModel[];
  forecastItem: ForecastItemModel;
  forecastItemCommentLogModel: ForecastItemCommentLogModel[];
  olddestinations: OrderItemForecastDestinationModel[];
  newOrderStatus: OrderStatusModel;
  inventoryOrderStatus: OrderStatusModel;
  cancelledOrderStatus: OrderStatusModel;
  outsourcedOrderStatus: OrderStatusModel;
  orderStatus: OrderStatusModel[];
  destOrderStatuses: OrderStatusModel[];
  orderQuantityUOM: QuantityUnitOfMeasureModel;
  stockQuantity: string;
  glpQuantity: string;
  isDirty = false;
  displayCountryDialog = false;
  dialogCancelCountryConfirm = false;
  dialogAcceptCommentDialog = false;
  dialogCommentGrid = false;
  dialogForecastCommentGrid = false;
  dialogDeleteShipmentDialog = false;
  dialogCancelConfirm = false;
  dialogAcceptForecastComment = false;
  showDialogDirtyCheck = false;
  currentState:number;
  currentDestId:number;
  leavePage = false;
  openCountryPopup = false;
  destinationForm: UntypedFormGroup;
  countryModels: any;
  filteredCountriesSingle: any[];
  filteredUsersSingle: UserModel[];
  suggestion: UserModel | any;
  userSuggestion: UserModel | any;
  users: Array<UserModel>;
  loggedInUser: UserModel | any;
  minDate = new Date();
  minReqDate = new Date();
  minDateNeeded = new Date();
  quantUoms: any;
  materialPurposes: any;
  orderTypes: any;
  commentText: string = '';
  user: UserModel;
  sampleInventoryLocation: SampleInventoryLocationModel;
  isShowAIDialog: boolean = false;
  isShowPNDialog: boolean = false;
  aiform: UntypedFormGroup;
  ctrnUoms: any;
  isNewOrder: boolean = false;
  shipmentDefects: ShipmentDefectModel[];
  receiptStatuses: ReceiptStatusModel[];
  orderShipment: OrderItemShipmentModel = new OrderItemShipmentModel();
  orderItemShipmentForm: UntypedFormGroup;
  shipmentDeleteIndex = -1;
  shipmentflag: boolean = false;
  shipmentUomflag: boolean = false;
  shipmentmainflag: boolean = false;
  isSelfRedirect: boolean = false;
  isQuantityOrderedInvalid: boolean = false;
  showSearch: boolean = false;
  disableMaterialSearch: boolean = false;
  backButtonText: string;
  latestComment: string;
  materialPlanners: UserModel[];
  createdBy: string;
  materialPlanner: any;
  sampleFullname: string;
  public assignPlanner: OrderItemModel = new OrderItemModel;
  enableMP: boolean = true;
  showActiveIngradientsPanel: boolean = false;
  materialType:string;
  commentTitle: string;
  commentForecastItmId: any;
  commentForecastDestinationId: any;
  isProductNameChanged: boolean = false;
  recertDate: Date = null;
  remainingQty:string="";
  currentDestinationId:number;
  currentRemainingQty:number;
  cancelledQuantities = new Map<number, number>();
  materialDetails:MaterialResult;
  destinationOrderedQuantity: number[] = [];
  constructor(public router: Router,
    public fb: UntypedFormBuilder,
    public activatedRoute: ActivatedRoute,
    public commonService: CommonService,
    public securityService: SecurityService,
    public messageService: MessageService,
    public procurementClient: ProcurementClient,
    public countryClient: CountryClient,
    public quantityUomClient: QuantityUomClient,
    public mpClient: MaterialPurposeClient,
    public otClient: OrderTypeClient,
    public appComponent: AppComponent,
    public userClient: UserClient,
    public forecastItemClient: ForecastItemClient,
    public shipmentDefectClient: ShipmentDefectClient,
    public forecastItemDestinationClient:ForecastItemDestinationClient,
    public bioNexusClient:BioNexusClient
  ) { }

  ngOnInit(): void {
    this.appComponent.currentSelection = 'procurement';
    this.isSelfRedirect = false;
    //this.isDirty = false;
    window.scrollTo(0, 0); // reset scroll position to top
    this.getProspectiveUsers();
    this.user = this.securityService.getUser();
    console.log('user', this.user)
    this.countryClient.getCountries()
      .subscribe(data => {
        this.countryModels = data.filter(x => !x.isProhibited);
      });
    this.quantityUomClient.get3(true)
      .subscribe(data => {
        this.quantUoms = data.filter(x => x.isEnabled);
        (<UntypedFormControl>this.destinationForm.controls['quantityUom'])
          .setValue(this.quantUoms[0], { onlySelf: true });
      });
    this.mpClient.getMaterialPurpose()
      .subscribe(data => {
        this.materialPurposes = data;
        let mtt = this.materialPurposes.find(ft => ft.materialPurpose1 === 'R&D');
        (<UntypedFormControl>this.destinationForm.controls['materialPurpose'])
          .setValue(mtt, { onlySelf: true });
      });
    this.otClient.getOrderType()
      .subscribe(data => {
        this.orderTypes = data;
        if (!this.user.isGlpeligible) {
          this.orderTypes = this.orderTypes.filter(({ orderType1 }) => orderType1 !== "GLP");
        }
        let ott = this.orderTypes.find(ft => ft.orderType1 === 'Stock');
        (<UntypedFormControl>this.destinationForm.controls['orderType'])
          .setValue(ott, { onlySelf: true });
      });

    this.shipmentDefectClient.getShipmentDefects()
      .subscribe(data => {
        console.log(data);
        this.shipmentDefects = data;
      });
    this.procurementClient.getReceiptStatuses()
      .subscribe(data => {
        console.log(data);
        this.receiptStatuses = data;
      });
    this.createAIForm();
    this.createCountryForm();
    this.activatedRoute.data
      .subscribe((allData) => {

        console.log(allData);
        this.backButtonText = 'Back to Material Search';
        this.isSelfRedirect = false
        this.openCountryPopup = allData.procurementDetails.openCountryPopup;
        if (this.activatedRoute.snapshot.url[0].path === 'procurementDetail') {
          // Edit Form
          this.isNewOrder = false;
          this.procurementDetails = allData.procurementDetails.procurementDetails;
          let id = this.procurementDetails.id;
          this.procurementClient.getOrderItem(id)
            .subscribe((oldData) => {
              {
                this.oldprocurementDetails = oldData;
              }
            });

          console.log(this.procurementDetails)

          this.allData = allData.procurementDetails;
          this.allData.formulationTypes.forEach(element => {
            element.description = element.formulationTypeCode + '-' + element.description;
            element.label = element.description
            element.value = element.description
          });
          this.actualProductName = allData.procurementDetails.procurementDetails.productName
          this.orderStatus = allData.procurementDetails.orderStatus;
          this.minDateNeeded = this.procurementDetails.createdDate;
          this.destOrderStatuses = this.orderStatus.filter(x => x.status != 'Partial Receipt');
          this.newOrderStatus = this.orderStatus.find(x => x.status === 'New');
          this.cancelledOrderStatus = this.orderStatus.find(x => x.status === 'Cancelled');
          this.inventoryOrderStatus = this.orderStatus.find(x => x.status === 'In Inventory');
          this.outsourcedOrderStatus = this.orderStatus.find(x => x.status === 'Outsourced');

          this.createdBy = this.procurementDetails.createdByUserName;
          this.aggregateDestinationObjects();
          if (this.procurementDetails.sampleInventoryLocation == null) {
            if (this.procurementDetails.sampleInventoryLocationId == null) {
              this.procurementDetails.sampleInventoryLocation = this.allData.sampleInventoryLocations.find(u => u.locationName == 'Indy');
            }
            else {
              this.procurementDetails.sampleInventoryLocation = this.allData.sampleInventoryLocations.find(u => u.id == this.procurementDetails.sampleInventoryLocationId);
            }
          }
          if (this.procurementDetails.orderQuantityUom == null)
            this.procurementDetails.orderQuantityUom = this.allData.sourcingUnitOfMeasure.find(u => u.uomDisplay.toLowerCase() == 'kg');

          this.GetStockQuantity();
          this.GetGLPQuantity();

          if([1,3].includes(this.procurementDetails.orderStatusId)){
            this.disableMaterialSearch = false;
          }
          else{
            this.disableMaterialSearch = true;
          }
          this.enableMP = this.orderStatus.filter(x => x.id == this.procurementDetails.orderStatusId)[0].status == "In Inventory"  ? false : true;
        } else {
          // New Order
          this.isNewOrder = true;
          this.allData = allData.procurementDetails
          this.procurementDetails = new OrderItemModel();
          this.procurementDetails.orderQuantityUom = this.allData.sourcingUnitOfMeasure.find(u => u.uomDisplay.toLowerCase() == 'kg');
          this.procurementDetails.orderItemActiveIngredient = new Array<OrderItemActiveIngredientModel>();
          this.procurementDetails.orderItemComment = new Array<OrderItemCommentModel>();;
          this.procurementDetails.orderItemDestination = new Array<OrderItemDestinationModel>();;
          this.allData.formulationTypes.forEach(element => {
            element.description = element.formulationTypeCode + '-' + element.description;
            element.label = element.description
            element.value = element.description
          });
          this.destinations = [];
          this.orderStatus = allData.procurementDetails.orderStatus;
          this.procurementDetails.sampleInventoryLocation = this.allData.sampleInventoryLocations.find(u => u.locationName == 'Indy');
          this.procurementDetails.orderStatus = this.orderStatus.find(x => x.status === 'New');
          this.newOrderStatus = this.orderStatus.find(x => x.status === 'New');
          this.procurementDetails.createdByUserId = this.user.id;
          this.procurementDetails.createdByUserName = this.user.firstName + ' ' + this.user.lastName;
          this.createdBy = this.procurementDetails.createdByUserName;
          this.procurementDetails.createdDate = new Date();
          this.minDateNeeded = new Date();
          this.procurementDetails.modifiedByUserId = this.user.id;
          this.procurementDetails.orderItemShipment = [];

        }
        this.initOrderItemShipmentForm();
      });


    if (this.openCountryPopup) {
      this.showCountryDialog();
    }

    this.filterstatusonstart();
    this.getMaterialPlannerUserlist();
    this.getMaterialDetails();
  }
public setSuggestion($event) {

}
public clearValue() {
  
}
  getMaterialPlannerUserlist() {
    this.userClient.getUsersInRoles(['Material Planner'])
      .subscribe(data => {
       // data.forEach(u => this.sampleFullname = u.fullName.toLowerCase());
        this.materialPlanners = data;
        this.materialPlanner = this.materialPlanners.filter(n => n.fullName.toLowerCase() == this.procurementDetails.materialPlannerUserName.toLowerCase())[0];
      });
  }

  getMaterialDetails(){
    if(this.procurementDetails?.materialSearchType == 'BioMaterialId'){
      this.bioNexusClient.retrieveBioNexusMaterialById(this.procurementDetails?.productName).subscribe((res:MaterialResult[]) => {
        if(res && res.length >0)
          this.materialDetails = res[0];
      });
    }
    if(this.procurementDetails?.materialSearchType === 'BioMaterialLotId'){
      this.bioNexusClient.retrieveBioNexusLotByLotId(this.procurementDetails?.lotNumber).subscribe((res:MaterialResult[]) => {
        if(res && res.length >0){
          this.materialDetails = res[0];
        }
      });
    }
  }

  OpenCommentDialog(country: any, forecastItmId: number, forecastItemDestinationId : number) {
    if (forecastItmId && forecastItmId !== null && forecastItmId !== undefined) {
      this.dialogForecastCommentGrid=true;
      this.commentTitle = country;
      this.commentForecastItmId = forecastItmId;
      this.commentForecastDestinationId = forecastItemDestinationId;
      this.forecastItemCommentLogModel = this.procurementDetails.forecastItemCommentLog.filter(n => n.forecastItemId == forecastItmId)
    }
  }

  GetTitle(){
    if(this.materialType == 'Formulation Lot' || this.materialType == 'Formulation'
    ||this.materialType == 'Compound Lot'|| this.materialType == 'Compound'
    ||this.materialType =='Coformulant/Field Inert'){
      return 'Click Here To View Details'
    }
    else
    {
      return  'Material Info';
    }
  }
  GetDialogTitle(){
    if(this.materialType == 'Formulation Lot' ){
      return 'Formulation Lot ID Information'
    }
    else if(this.materialType == 'Formulation') {
      return  'Formulation Information';
    }
    else if (this.materialType =='Compound')
    {
      return 'Material ID Information';
    }
    else if (this.materialType =='Coformulant/Field Inert')
    {
      return 'CoFormulant Information';
    }
    else if(this.materialType =="Compound Lot")
    {
      return 'Material Lot ID Information';
    }
    else if(this.materialType === 'Bionexus Lot')
    {
      return 'BioNexus Lot Information';
    }
    else if(this.materialType === 'Bionexus')
    {
      return 'BioNexus Material Information';
    }
  }

  public SetMaterialType() {
    this.materialType = '';
    if (this.procurementDetails.materialSearchType !== undefined) {
      switch (this.procurementDetails.materialSearchType) {
        case 'FACTSFormulationName':
        case 'FACTSSynonym':
        case 'CortevaTradeName':
        case 'ThirdPartyTradeName':
          this.materialType = 'Formulation';
          break;
        case 'FACTSFormulationNameGlp' :
        case 'FACTTSNGlp':
            this.materialType = 'Formulation Lot';
            break;
        case 'CORESynonym':
        case 'COREMaterialId':
            this.materialType = 'Compound';
          break;
        case 'COREMaterialLotGlp':
        case 'TsnGlp':
            this.materialType = 'Compound Lot';
          break;
        case 'Coformulant':
        case 'FieldInert':
          this.materialType = 'Coformulant/Field Inert';
          break;
        case 'BioMaterialId':
        case 'BionexusSynonymGlp':
        case 'BionexusSynonymStock':
          this.materialType = 'Bionexus'
          break;
        case 'BioMaterialLotId':
          this.materialType = 'Bionexus Lot'
          break;
        default:
          this.materialType = '';
            break;
      }
    }
  }

  StopOpening(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  onCheckAddtoFP(event, procurementDetails :any){
    if(event.checked){
      procurementDetails.isManufactured = true;
      this.procurementClient.updateIsManufactured(procurementDetails).subscribe(response =>{
        this.messageService.add({
          severity: this.commonService.configurations.MessageServiceSeveritySuccess,
          summary: 'Success', detail: "Add to FP Updated successfully"
        });
      });
    }
  }

  setSelectedMaterialPlanner(event) {
    if(this.isNewOrder){
      this.procurementDetails.materialPlannerUserId = event.value.id;
    }
    else{
    this.assignPlanner.productName = this.procurementDetails.productName;
    this.assignPlanner.id = this.procurementDetails.id;
    this.assignPlanner.formulationTypeId = this.procurementDetails.formulationTypeId;
    let message: string = '';
    let errorTitle: string = '';
    let errorMessage: string = '';
    if (event.value) {
      message = 'Material planner assigned successfully.';
      errorTitle = 'Assign error';
      errorMessage = 'Error assigning material planner.';
      this.assignPlanner.materialPlannerUserId = event.value.id;
    } else {
      message = 'Material planner removed successfully.';
      errorTitle = 'Remove error';
      errorMessage = 'Error removing material planner.';
      //this.assignPlanner.materialPlannerUserId= null;
    }
    this.assignPlanner.formulationType = this.procurementDetails.formulationType;
    this.procurementClient.assignMaterialGroup2(this.assignPlanner).subscribe(data => {
    this.procurementDetails.materialPlannerUserId = event.value ? event.value.id : null;
    this.messageService.add({
      severity: this.commonService.configurations.MessageServiceSeveritySuccess,
      summary: 'Success', detail: message
    });
      this.materialPlanner = this.materialPlanner = this.materialPlanners.filter(n => n.fullName.toLowerCase() == event.value.fullName.toLowerCase())[0];
    },
      error => {
        this.messageService.add({
          severity: this.commonService.configurations.MessageServiceSeverityError,
          summary: errorTitle, detail: errorMessage
        });
      }
    );
    }

  }

  setMaterialPlanner(materialPlannerUserId: any){
    if(this.procurementDetails.materialPlannerUserId == null){
      this.procurementDetails.materialPlannerUserId = materialPlannerUserId;
    }
    this.procurementClient.assignMaterialGroup2(this.procurementDetails).subscribe(data => {
      //this.plannerItems.filter(x => x.seedForecastItemId == plannerItem.seedForecastItemId)[0].materialPlannerUserId = event.value ? event.value.id : null;
      this.materialPlanner = this.materialPlanners.filter(n => n.id == materialPlannerUserId)[0];
      this.messageService.add({severity: this.commonService.configurations.MessageServiceSeveritySuccess,
        summary:'Success', detail:'Material planner assigned successfully'});
      },
    error => {
      this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError,
        summary: 'Assign Error', detail: 'Error assigning material planner.' });
      });
  }

  setDefaultsForDestination() {

    (<UntypedFormControl>this.destinationForm.controls['quantityUom'])
      .setValue(this.quantUoms[0], { onlySelf: true });
    let mtt = this.materialPurposes.find(ft => ft.materialPurpose1 === 'R&D');
    (<UntypedFormControl>this.destinationForm.controls['materialPurpose'])
      .setValue(mtt, { onlySelf: true });
    let ott = this.orderTypes.find(ft => ft.orderType1 === 'Stock');
    (<UntypedFormControl>this.destinationForm.controls['orderType'])
      .setValue(ott, { onlySelf: true });
  }

  public ShowMessage(msg: string) {
    this.messageService.add({ severity: 'error', summary: msg, detail: '' });
  }

  public initOrderItemShipmentForm() {
    this.orderItemShipmentForm = this.fb.group({
      shipments: this.fb.array([])
    })
    if (this.procurementDetails && this.procurementDetails.orderItemShipment && this.procurementDetails.orderItemShipment.length) {

      this.procurementDetails.orderItemShipment.forEach(shipment => {
        this.addShipmentDetaisToFormGroup(shipment);
      });
    }
  }

  public addShipmentDetaisToFormGroup(shipment) {
    const formGroup = this.getShipmentGroup(shipment);
    (this.orderItemShipmentForm.get('shipments') as UntypedFormArray).push(formGroup);
    this.checkReceivingStatus(formGroup);
  }

  public checkReceivingStatus(formGroup) {
    const defectControl = formGroup.get("defect");
    const defectCreatedControl = formGroup.get("defectCreatedDate");
    formGroup
      .get("receiptStatus")
      .valueChanges.pipe(
        tap((val: any) => {
          if (val.statusText === "Received Defect") {
            defectControl.setValidators([Validators.required]);
            defectCreatedControl.setValidators([Validators.required]);
            defectControl.updateValueAndValidity();
            defectCreatedControl.updateValueAndValidity();
          } else {
            defectControl.setValidators([]);
            defectCreatedControl.setValidators([]);
            defectControl.updateValueAndValidity();
            defectCreatedControl.updateValueAndValidity();
          }
        })
      )
      .subscribe();
  }

  public getShipmentGroup(shipment): UntypedFormGroup {
    if (shipment.receiptStatus == null && this.receiptStatuses) {
      shipment.receiptStatus = this.receiptStatuses[0];
    }

    console.log('12345', shipment.id)
    return this.fb.group({
      'dateReceived': [shipment.dateReceived, [Validators.required]],
      'totalQuantityReceived': [shipment.totalQuantityReceived, [Validators.required]],
      'lotNumber': [shipment.lotNumber, [Validators.required]],
      'defect': [shipment.defect, [defectValidator(shipment.receiptStatus.statusText)]],
      'certificateOfAnalysisFlag': [shipment.certificateOfAnalysisFlag],
      'defectReasonText': [shipment.defectReasonText],
      'showDefect': [shipment.defect && shipment.defect.defectName == 'Other' ? true : false],
      'defectCreatedDate': [shipment.defectCreatedDate, [defectValidator(shipment.receiptStatus.statusText)]],
      'defectResolvedDate': [shipment.defectResolvedDate],
      'tsnNumber': [shipment.tsnNumber],
      'receiptStatus': [shipment.receiptStatus],
      'orderQuantityUom': [shipment.receivedQuantityUom],
      'procurementTime': [this.getDays(this.procurementDetails.createdDate, shipment.dateReceived)],
      'defectDuration': [this.getDays(shipment.defectCreatedDate, shipment.defectResolvedDate)],
      'receiptCreated': [shipment.id == 0 ? shipment.createdByUser?.fullName : shipment.createdByUser?.fullName]
    });
  }

  filterstatusonstart() //KAT285
  {
    if (this.procurementDetails.orderStatus.status == "Follow-up") {
      this.allData.orderStatus = this.allData.orderStatus.filter(({ status }) => status !== "New");
    }
    else if (this.procurementDetails.orderStatus.status !== "New") {
      this.allData.orderStatus = this.allData.orderStatus.filter(({ status }) => status !== "New" && status !== "Follow-up");
    }
  }
  filterUserSingle(event) {
    this.filteredUsersSingle = this.filterUser(event.query, this.users);
  }
  filterUser(query, users: UserModel[]): UserModel[] {
    let filtered: any[] = [];
    for (let i = 0; i < users.length; i++) {
      let user = users[i];
      if (user.fullName.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
        filtered.push(user);
      }
    }
    return filtered;
  }
  getProspectiveUsers() {
    this.userClient.getUsersInRoles(['Biology Leader', 'Field Scientist', 'Material Planner', 'Order Coordinator'])
      .subscribe(data => {
        this.users = data;
        console.log(data);
      },
        error => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Users list could not be retrieved' });
        });
  }
  SetData() {
  }
  GetData() {
    const destinationData = new OrderItemForecastDestinationModel();
    destinationData.isNonForecast = true;
    destinationData.quantity = Number(this.destinationForm.get('quantity').value);
    destinationData.quantityUomId = this.destinationForm.get('quantityUom').value.id;
    destinationData.uomDisplay = this.destinationForm.get('quantityUom').value.uomDisplay;
    destinationData.dateNeeded = new Date(this.destinationForm.get('dateNeeded').value.setHours(0, 0, 0, 0));
    destinationData.alternativeProduct = this.destinationForm.get('alternateFormulation').value;
    destinationData.approvedAlternative = this.destinationForm.get('approvedAlternate').value;
    destinationData.requestSubmissionDate = new Date(this.destinationForm.get('dateRequested').value.setHours(0, 0, 0, 0));
    destinationData.rdsimsRequestNumber = this.destinationForm.get('rDSimsRequestNumber').value;
    destinationData.materialPurposeId = this.destinationForm.get('materialPurpose').value.id;
    destinationData.orderTypeId = this.destinationForm.get('orderType').value.id;
    destinationData.orderType = this.destinationForm.get('orderType').value;
    destinationData.materialPurpose = this.destinationForm.get('materialPurpose').value;
    destinationData.orderStatus = this.newOrderStatus;
    destinationData.orderStatusId = this.newOrderStatus.id;
    const selectedUser = this.destinationForm.get('sampleRequesterName').value;
    if (typeof selectedUser === 'string' || selectedUser instanceof String) {
      destinationData.requesterName = selectedUser.toString();
    } else {
      destinationData.requesterUser = selectedUser;
      destinationData.requesterUserId = selectedUser.id;
      destinationData.requesterName = selectedUser.fullName;
    }
    const countryId = this.destinationForm.get('country').value.id;
    // destinationData.destinationCountryId = this.destinationForm.get('country').value.id;
    destinationData.country = this.destinationForm.get('country').value.displayName;

    let oidm = this.procurementDetails.orderItemDestination.find(a => a.destinationCountryId === countryId);
    if (oidm !== null && oidm !== undefined) {
      oidm.orderItemForecastDestination.push(destinationData);
    } else {
      oidm = new OrderItemDestinationModel();
      oidm.destinationCountryId = countryId;
      oidm.orderItemId = this.procurementDetails.id;
      oidm.orderItemForecastDestination = [];
      oidm.orderItemForecastDestination.push(destinationData);
      this.procurementDetails.orderItemDestination.push(oidm);
    }

  }

  SaveDestination(formItems: any) {
    this.GetData();
    this.displayCountryDialog = false;
    this.aggregateDestinationObjects();
    return false;
  }
  CancelDestination() {
    this.displayCountryDialog = false;
  }
  showCountryDialog() {
    this.displayCountryDialog = true;
    this.onDestinationFormShow();
  }
  createCountryForm() {
    this.minDate.setDate(new Date().getDate() + 46);
    this.minReqDate.setDate(new Date().getDate());
    this.destinationForm = this.fb.group({
      'country': new UntypedFormControl('', Validators.required),
      'quantity': new UntypedFormControl('', [Validators.required, Validators.min(.0001), Validators.pattern('^[0-9]{0,6}\\.?[0-9]{0,3}$')]),
      'quantityUom': new UntypedFormControl(''),
      'sampleRequesterName': new UntypedFormControl([], Validators.required),
      'materialPurpose': new UntypedFormControl('', Validators.required),
      'orderType': new UntypedFormControl('', Validators.required),
      'dateNeeded': new UntypedFormControl('', Validators.required),
      'rDSimsRequestNumber': new UntypedFormControl('', Validators.pattern('^[0-9]{1,16}$')),
      'dateRequested': new UntypedFormControl('', Validators.required),
      'approvedAlternate': new UntypedFormControl(''),
      'alternateFormulation': new UntypedFormControl('')
    });
    this.createCountryFormChanged();
  }

  public createCountryFormChanged() {
    this.destinationForm.get('dateRequested').valueChanges.subscribe(value => {
      if (value !== undefined && value !== null) {
        var dateNeeded = new Date(value.getTime());
        dateNeeded.setDate(dateNeeded.getDate() + 46);
        this.minDate = new Date(dateNeeded);
        this.destinationForm.get('dateNeeded').setValue(dateNeeded);
      }
    });
  }

  filterCountrySingle(event) {
    let query = event.query;
    this.filteredCountriesSingle = this.filterCountry(query, this.countryModels);
  }
  filterCountry(query, countries: any[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < countries.length; i++) {
      let country = countries[i];
      if (country.displayName.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(country);
      }
    }
    return filtered;
  }
  public aggregateDestinationObjects() {
    if (this.procurementDetails === undefined || this.procurementDetails.orderItemDestination === undefined) {
      this.destinations = [];
    }
    else {
      this.destinations = this.procurementDetails.orderItemDestination.reduce((pn, u) => [...pn, ...u.orderItemForecastDestination], []);
      if(this.destinations && this.destinations.length > 0){
        this.forecastItem = this.destinations[0].forecastItem;
      }
    }
    if(!this.procurementDetails?.materialId && !this.procurementDetails?.repoName)
    {
      this.showActiveIngradientsPanel = true;
    }
    this.GetStockQuantity();
    this.SetMaterialType();
  }
  public getTitleStyle(orderItemForecastDestinationModel: OrderItemForecastDestinationModel) {
    if (orderItemForecastDestinationModel.orderStatus !== undefined)
    {
      switch (orderItemForecastDestinationModel.orderStatus?.status) {
        case this.commonService.configurations.OrderStatus.New: return 'clsNewPanelTitleBar'; break;
        case this.commonService.configurations.OrderStatus.InInventory: return 'clsInInventoryPanelTitleBar'; break;
        case this.commonService.configurations.OrderStatus.Ordered: return 'clsOrderedPanelTitleBar'; break;
        case this.commonService.configurations.OrderStatus.Received: return 'clsReceivedPanelTitleBar'; break;
        case this.commonService.configurations.OrderStatus.FollowUp: return 'clsFollowUpPanelTitleBar'; break;
        case this.commonService.configurations.OrderStatus.PartialReceipt: return 'clsPartialReceiptPanelTitleBar'; break;
      }
    }
    return 'clsNewPanelTitleBar';
  }

  groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }

  GetStockQuantity() {
    // Accepts the array and key
    this.stockQuantity = 'n/a';
    let stockDs: OrderItemForecastDestinationModel[] = this.destinations.filter(a => a.orderTypeId === 1);
    console.log(stockDs);
    let stockDsByUom = this.groupBy(stockDs.filter(x => x.orderStatus.status !== 'Cancelled'), 'uomDisplay');
    console.log(stockDsByUom);
    let stks = Object.entries(stockDsByUom).map(m => [m[0], (m[1] as OrderItemForecastDestinationModel[]).reduce((ty, u) =>
      ty + u.quantity, 0.000)]);
    console.log(stks);

    if (stks != null && stks.length > 0) {
      this.stockQuantity = '';
      stks.forEach(a => {
        this.stockQuantity = this.stockQuantity + (this.stockQuantity.length > 0 ? ' + ' : '') + parseFloat(a[1].toString()).toFixed(3) + ' ' + a[0];
      });
    }
  }
  GetGLPQuantity() {
    this.glpQuantity = 'n/a';
    let stockDs: OrderItemForecastDestinationModel[] = this.destinations.filter(a => a.orderTypeId === 2);
    console.log(stockDs);
    let stockDsByUom = this.groupBy(stockDs.filter(x => x.orderStatus.status !== 'Cancelled'), 'uomDisplay');
    console.log(stockDsByUom);
    let glps = Object.entries(stockDsByUom).map(m => [m[0], (m[1] as OrderItemForecastDestinationModel[]).reduce((ty, u) => ty + u.quantity, 0.000)]);
    console.log(glps);
    if (glps != null && glps.length > 0) {
      this.glpQuantity = '';
      glps.forEach(a => {
        this.glpQuantity = this.glpQuantity + (this.glpQuantity.length > 0 ? ' + ' : '') + parseFloat(a[1].toString()).toFixed(3) + ' ' + a[0];
      });
    }
  }
  DeleteActiveIngredient(activeIngredient, index) {
    this.procurementDetails.orderItemActiveIngredient.splice(index, 1);
  }
  GetEarliestDate(orderItemDestinationModel: OrderItemDestinationModel[]) {
    if (orderItemDestinationModel == undefined) {
      return '';
    }
    var minDate;
    var lastMinDate;
    var minDates = [];
    orderItemDestinationModel.forEach((orderItemDestination: OrderItemDestinationModel) => {
      orderItemDestination.orderItemForecastDestination.forEach((orderItemForecastDestinationModel: OrderItemForecastDestinationModel) => {
        if (minDate == undefined) {
          if (orderItemForecastDestinationModel.dateNeeded != undefined) {
            minDate = new Date(orderItemForecastDestinationModel.dateNeeded)
          }
        }
        else if (new Date(orderItemForecastDestinationModel.dateNeeded) < minDate) {
          if (orderItemForecastDestinationModel.dateNeeded != undefined) {
            minDate = new Date(orderItemForecastDestinationModel.dateNeeded)
          }
        }
      });
      if (minDate != undefined) {
        minDates.push(minDate);
      }
    });
    if (minDates != null) {
      if (minDates.length == 1) {
        return minDates[0];
      }
      else {
        minDates.forEach((date) => {
          if (lastMinDate == undefined) {
            if (date != undefined) {
              lastMinDate = new Date(date)
            }
          }
          else if (new Date(date) < lastMinDate) {
            lastMinDate = new Date(date)
          }
        });
      }
     // console.log("Create Date:", this.procurementDetails.createdDate);
      if (this.procurementDetails && this.procurementDetails.createdDate && lastMinDate < new Date(this.procurementDetails.createdDate)) {
        lastMinDate = this.procurementDetails.createdDate;
      }
    }
    else {
      return '';
    }
    return lastMinDate;
  }

  public SaveComment() {
    const nc = new OrderItemCommentModel();
    nc.comment = this.commentText;
    nc.enteredByUserId = this.user.id;
    nc.enteredByUserName = this.user.fullName;
    nc.orderItemId = this.procurementDetails.id;
    nc.enteredAt = new Date();
    this.procurementDetails.orderItemComment.unshift(nc);
    this.isDirty = true;
    this.commentText = '';
    this.dialogAcceptCommentDialog = false;
  }

  public CloseForecastComment() {
    this.dialogAcceptForecastComment=false;
    this.commentText = "";
  }

  public LaunchForecastComment() {
    this.dialogAcceptForecastComment=true;
    this.commentText = "";
  }

  public GetDateNeededLatest(destination : OrderItemForecastDestinationModel){

    if(destination.forecastItemDestination && destination.forecastItemDestination.dateNeededLatest){
      return destination.forecastItemDestination.dateNeededLatest;
    }
    else if(destination.forecastItem && destination.forecastItem.forecastSeason){
      return destination.forecastItem.forecastSeason.reservationExpiryDate;
    }

    return null;
  }

  public SaveForecastComment() {
      const itemComment = new ForecastItemCommentLogModel();
    itemComment.comment = this.commentText;
    itemComment.user = this.user;
    itemComment.forecastItemId = this.commentForecastItmId;
    itemComment.dateEntered = new Date();
    this.procurementDetails.forecastItemCommentLog.unshift(itemComment);
    this.forecastItemCommentLogModel = this.procurementDetails.forecastItemCommentLog.filter(n => n.forecastItemId == this.commentForecastItmId)
    const nc = new ForecastItemModel();
    nc.comments = this.commentText;
    nc.updatedBy = this.user.id;
    nc.id = this.commentForecastItmId;
    let currentSelectedDestination = new ForecastItemDestinationModel();
    currentSelectedDestination.id = this.commentForecastDestinationId;
    nc.forecastItemDestination = [];
    nc.forecastItemDestination.push(currentSelectedDestination);

    this.forecastItemClient.updateComments("Procurement",nc)
    .subscribe(data => {
      this.dialogAcceptForecastComment = false;
      this.commentText = '';
      this.isDirty = true;
      this.messageService.add({severity:'info', summary:'Success', detail:'Comment added successfully'});
    },
    error => {
      this.messageService.add({severity:'error', summary:'Error', detail:'Comment not added. Please contact admin'});
    });
    if(this.procurementDetails.materialPlannerUserId == null){
      this.setMaterialPlanner(this.user.id);
    }
  }

  public GetLatestComment(forecastItemId: number) {
    if (this.procurementDetails.forecastItemCommentLog !== undefined) {
      var result = this.procurementDetails.forecastItemCommentLog.filter(n => n.forecastItemId == forecastItemId && n.comment != '')
      if(result && result.length > 1){
        return result[0].comment?.substring(0, 35) + '...';
      }
      if(result && result.length == 1){
        return result[0].comment?.substring(0, 35);
      }
      else{
        return 'No Records Found';
      }
    }
    else {
      return 'No Records Found';
    }
  }

  public onAIFormShow() {
    this.aiform.reset();
    (<UntypedFormControl>this.aiform.controls['ctrnUom'])
      .setValue(null, { onlySelf: true });
    (<UntypedFormControl>this.aiform.controls['aiIngredient'])
      .setValue("", { onlySelf: true });
    (<UntypedFormControl>this.aiform.controls['concentration'])
      .setValue("", { onlySelf: true });
  }
  public onDestinationFormShow() {
    this.destinationForm.reset();
    this.setDefaultsForDestination();
  }
  public createAIForm() {
    this.aiform = this.fb.group({
      'aiIngredient': new UntypedFormControl('', Validators.required),
      'concentration': new UntypedFormControl('', [Validators.required, Validators.pattern('^[0-9]{0,6}\\.?[0-9]{0,3}$'),]),
      'ctrnUom': new UntypedFormControl('', Validators.required),
    });
  }
  public showAIDialog(show: boolean) {
    this.isShowAIDialog = show;
    this.isProductNameChanged = false;
  }

  public showPNDialog(show: boolean) {
    console.log(this.materialType);
    if(this.materialType && this.materialType!='') {
      this.isShowPNDialog = show;
    }
    if(this.materialDetails?.materialAttributes == null || this.materialDetails?.lotAttributes == null
      || this.materialDetails?.materialAttributes == undefined || this.materialDetails?.lotAttributes == undefined){
        this.getMaterialDetails();
    }
  }

  public enableDisableToggle() {
    if (this.isNewOrder) {
      return true;
    }
    if (this.shipmentflag === true) {
      return true;
    }
    if(this.isProductNameChanged){
      return true;
    }

    if (this.oldprocurementDetails !== undefined && this.procurementDetails !== undefined) {
      if (this.oldprocurementDetails.orderQuantityUom === null || this.oldprocurementDetails.orderQuantityUom === undefined) {
        this.oldprocurementDetails.orderQuantityUom = this.allData.sourcingUnitOfMeasure.find(u => u.uomDisplay.toLowerCase() == 'kg');
      }
      if ((this.oldprocurementDetails.orderQuantityUom?.id !== null) && (this.procurementDetails.orderQuantityUom?.id !== null)) {
        if (this.oldprocurementDetails.orderQuantityUom?.id !== this.procurementDetails.orderQuantityUom?.id) {
          return true;
        }
      }

      if (this.oldprocurementDetails.sampleInventoryLocation === null || this.oldprocurementDetails.sampleInventoryLocation === undefined) {
        this.oldprocurementDetails.sampleInventoryLocation = this.allData.sampleInventoryLocations.find(u => u.locationName == 'Indy');
      }


      if ((this.oldprocurementDetails.sampleInventoryLocation?.id !== undefined) && (this.procurementDetails.sampleInventoryLocation?.id !== undefined)) {
        if (this.oldprocurementDetails.sampleInventoryLocation?.id !== this.procurementDetails.sampleInventoryLocation?.id) {
          return true;
        }
      }
      if (this.oldprocurementDetails.formulationType !== this.procurementDetails.formulationType) {
        return true;
      }
      if (this.procurementDetails.orderStatusId !== this.procurementDetails.orderStatus?.id) {
        return true;
      }
      if (this.checkstring(this.oldprocurementDetails.gmidDcodeItemNumber) !== this.checkstring(this.procurementDetails.gmidDcodeItemNumber)) {
        return true;
      }
      if (this.checkstring(this.oldprocurementDetails.orderConfirmationNumber) !== this.checkstring(this.procurementDetails.orderConfirmationNumber)) {
        return true;
      }
      if (this.checkstring(this.oldprocurementDetails.supplierPlantCode) !== this.checkstring(this.procurementDetails.supplierPlantCode)) {
        return true;
      }
      if (this.checkstring(this.oldprocurementDetails.sgSinventoryProfile) !== this.checkstring(this.procurementDetails.sgSinventoryProfile)) {
        return true;
      }
      if (this.checkstring(this.oldprocurementDetails.approver) !== this.checkstring(this.procurementDetails.approver)) {
        return true;
      }
      if (this.oldprocurementDetails.quantityOrdered !== this.procurementDetails.quantityOrdered) {
        return true;
      }
      if (this.oldprocurementDetails.isReachCompliant !== this.procurementDetails.isReachCompliant) {
        return true;
      }
      if (this.checkstring(this.oldprocurementDetails.formulationNumber) !== this.checkstring(this.procurementDetails.formulationNumber)) {
        return true;
      }
      if ((this.oldprocurementDetails.formulationTypeId) !== (this.procurementDetails.formulationTypeId)) {
        return true;
      }
      if (this.oldprocurementDetails.orderSubmissionDate == undefined) {
        this.oldprocurementDetails.orderSubmissionDate = new Date();
      }
      if (this.procurementDetails.orderSubmissionDate !== undefined) {
        if (this.procurementDetails.orderSubmissionDate === null || (this.oldprocurementDetails.orderSubmissionDate.getDate() != (this.procurementDetails.orderSubmissionDate.getDate()))) {
          return true;
        }
      }
      if ((this.oldprocurementDetails.orderItemDestination.length != (this.procurementDetails.orderItemDestination.length))) {
        return true;
      }

      if (this.procurementDetails.orderItemDestination.some(a => a.orderItemForecastDestination.some(b => b.id === null || b.id === 0 || b.id === undefined))) {
        return true;
      }

      if ((this.oldprocurementDetails.orderItemComment.length != (this.procurementDetails.orderItemComment.length))) {
        return true;
      }
      if ((this.oldprocurementDetails.orderItemActiveIngredient.length != (this.procurementDetails.orderItemActiveIngredient.length))) {
        return true;
      }
      if ((this.oldprocurementDetails.orderItemShipment.length != (this.procurementDetails.orderItemShipment.length))) {
        return true;
      }
      this.olddestinations = this.oldprocurementDetails.orderItemDestination.reduce((op, i) => [...op, ...i.orderItemForecastDestination], []);
      var flag = this.destinations.some(y => this.olddestinations.some(z => z.id === y.id && z.orderStatus.status !== y.orderStatus.status));
      var invLocFlag = this.destinations.some(y => this.olddestinations.some(z => z.id === y.id && z.inventoryLocation !== null && y.inventoryLocation !== null && z.inventoryLocation !== undefined && y.inventoryLocation !== undefined && z.inventoryLocation.id !== y.inventoryLocation.id));

      if (this.destinations.some(y => this.olddestinations.some(z => z.id === y.id && !y.isNonForecast &&
        (z.inventoryLocation === null || z.inventoryLocation === undefined)
        && y.inventoryLocation !== null && y.inventoryLocation !== undefined))) {
        return true;
      }

      if (flag === true || invLocFlag == true) {
        return true;
      }

      if (this.orderItemShipmentForm !== null && this.orderItemShipmentForm !== undefined && (this.orderItemShipmentForm.valid || this.orderItemShipmentForm.touched)) {
        let index = 0;
        let shipmentsArray = this.orderItemShipmentForm.get("shipments") as UntypedFormArray;
        for (let shipment of shipmentsArray.controls) {
          const shipmentDetails = this.oldprocurementDetails.orderItemShipment[index];
          shipmentDetails.dateReceived = shipment.value.dateReceived ? new Date(shipment.value.dateReceived.setHours(0, 0, 0, 0)) : null;
          shipmentDetails.totalQuantityReceived = shipment.value.totalQuantityReceived;
          shipmentDetails.receivedQuantityUom = shipment.value.orderQuantityUom;
          shipmentDetails.lotNumber = shipment.value.lotNumber;
          shipmentDetails.defect = shipment.value.defect;
          shipmentDetails.certificateOfAnalysisFlag = shipment.value.certificateOfAnalysisFlag;
          shipmentDetails.defectReasonText = shipment.value.defectReasonText;
          shipmentDetails.defectCreatedDate = shipment.value.defectCreatedDate ? new Date(shipment.value.defectCreatedDate.setHours(0, 0, 0, 0)) : null;
          shipmentDetails.defectResolvedDate = shipment.value.defectResolvedDate ? new Date(shipment.value.defectResolvedDate.setHours(0, 0, 0, 0)) : null;
          shipmentDetails.tsnNumber = shipment.value.tsnNumber;
          shipmentDetails.receiptStatus = shipment.value.receiptStatus;
          // if(shipment.value.orderQuantityUom.id != this.oldprocurementDetails.orderQuantityUomId)
          // {
          //   return true;
          // }


          index++;
        }
        this.shipmentmainflag = false;
        for (let p of this.procurementDetails.orderItemShipment) {
          for (let q of this.oldprocurementDetails.orderItemShipment) {
            if (p.id == q.id) {
              if (p.dateReceived.getDate() !== q.dateReceived.getDate()) {
                return true;
              }
              if (p.totalQuantityReceived.toString() !== q.totalQuantityReceived.toString()) {
                return true;
              }
              if (p.receivedQuantityUom.id !== q.receivedQuantityUom.id) {
                return true;
              }
              if (p.lotNumber !== q.lotNumber) {
                return true;
              }
              if (this.checkBoolean(p.certificateOfAnalysisFlag) != this.checkBoolean(q.certificateOfAnalysisFlag)) {
                return true;
              }
              if (this.checkstring(p.tsnNumber) !== this.checkstring(q.tsnNumber)) {
                return true;
              }
              if (p.defect != undefined || q.defect != null) {
                if (p.defect.defectName !== q.defect.defectName) {
                  return true;
                }
              }
              if (p.defectReasonText !== q.defectReasonText) {
                return true;
              }
              if (p.defectCreatedDate != undefined || q.defectCreatedDate != null) {
                if (p.defectCreatedDate.getDate() !== q.defectCreatedDate.getDate()) {
                  return true;
                }
              }
              if (p.defectResolvedDate != undefined || q.defectResolvedDate != null) {
                if (p.defectResolvedDate?.getDate() !== q.defectResolvedDate.getDate()) {
                  return true;
                }
              }
            }
          }
        }
        //var ordershipmentflag = this.procurementDetails.orderItemShipment.some(y => this.oldprocurementDetails.orderItemShipment.some(z => z.id === y.id && (z.dateReceived.getDate() !== y.dateReceived.getDate() || z.totalQuantityReceived != y.totalQuantityReceived || z.lotNumber !== y.lotNumber || this.checkBoolean( z.certificateOfAnalysisFlag) != this.checkBoolean( y.certificateOfAnalysisFlag)|| this.checkstring(z.tsnNumber) !== this.checkstring(y.tsnNumber) ||this.checkstring(z.defect?.defectName) !== this.checkstring(y.defect?.defectName) || this.checkstring(z.defectReasonText) !== this.checkstring(y.defectReasonText) || z.defectCreatedDate.getDate() !== y.defectCreatedDate.getDate() || z.defectResolvedDate.getDate() !== y.defectResolvedDate.getDate())))

      }
    }
  }
  public checkBoolean(obj: boolean) {
    if (obj === null) {
      obj = false;
      return obj;
    }
    else {
      return obj;
    }
  }

  public checkstring(str: string) {
    if (str === null) {
      str = "";
      return str;
    }
    else {
      return str;
    }

  }

  public onAddAi() {
    this.isProductNameChanged = true;
    var newAi = new OrderItemActiveIngredientModel();
    newAi.activeIngredient = this.aiform.controls['aiIngredient'].value;
    newAi.concentrationValue = this.aiform.controls['concentration'].value;
    newAi.concentrationUomId = this.aiform.controls['ctrnUom'].value.id;
    newAi.uomDisplayName = this.aiform.controls['ctrnUom'].value.uomDisplay;
    newAi.concentrationInPrimaryUom = newAi.concentrationValue + ' ' + newAi.uomDisplayName;
    newAi.orderItemId = this.procurementDetails.id;

    let duplicate = this.procurementDetails.orderItemActiveIngredient.filter((item) => {
      return item.activeIngredient == newAi.activeIngredient;
    });
    if (duplicate.length > 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Duplicate active ingredient not allowed' });
    } else {
      this.procurementDetails.orderItemActiveIngredient.push(newAi);
      this.onAIFormShow();
      this.isShowAIDialog = false;
    }

  }

  public goSearch() {
    if (this.showSearch) {
      this.showSearch = false;
      this.backButtonText = 'Back to Material Search';
    } else {
      this.showSearch = true;
      this.backButtonText = 'Back to Procurement Detail Page';
    }
  }

  public getProductName(item: MaterialResult): string {
    let res: string = "";
    switch (item.sourceSystem) {
      case 'COFORM':
        res = item.materialName;
        break;
      case 'CORE':
        res = item.commonName === null ? item.materialName : item.commonName + ' (' + item.materialName + ')';
        break;
      case 'FACTS':
        return item['preferredSynonym'] === null ? item.materialName : item.materialName + ' (' + item['preferredSynonym'] + ')';
        break;
      default:
        res = item.materialName;
        break;
    }
    return res;
  }

  public addCartItem(item: MaterialResult) {
    // console.log(item);
    this.showSearch = false;
    this.isProductNameChanged = true;
    this.backButtonText = 'Back to Material Search';
    this.procurementDetails.materialSearchType = item.materialSearchType;
    this.procurementDetails.materialId = item.materialIdInTargetSystem;
    this.procurementDetails.productName = this.getProductName(item);
    this.actualProductName = this.procurementDetails.productName;
    this.procurementDetails.formulationType = item.formulationTypeDesc;
    this.procurementDetails.repoName = item.sourceSystem;
    this.procurementDetails.synonyms = item.synonyms?.toString();
    this.procurementDetails.lotId = item.lotId;
    this.procurementDetails.lotNumber = item.lotNumber;
    this.procurementDetails.recertDate = item.recertificationDate;
    this.procurementDetails.orderItemActiveIngredient = [];
    if(item.actives && item.actives.length > 0){
    item.actives.forEach((act)=>{
      var newAi = new OrderItemActiveIngredientModel();
      newAi.activeIngredient = act.ingredientName;
      newAi.concentrationInPrimaryUom = act.concentrationPrimaryUom;
      newAi.concentrationInSecondaryUom = act.concentrationSecondaryUom;
      this.procurementDetails.orderItemActiveIngredient.push(newAi);
    });
   }
    this.procurementDetails.stage = item.stage;
    if (item.approverEmail != null && item.approverEmail != undefined) {
      this.userClient.updateMaterialApprover(item.approverEmail).subscribe(x => { });
    }
    this.SetMaterialType();
    // console.log(this.materialType);
    this.showActiveIngradientsPanel = false;
  }

  public ClearProduct(event) {
    this.procurementDetails.materialId = null;
    this.procurementDetails.productName = null;
    this.procurementDetails.formulationType = null;
    this.procurementDetails.repoName = null;
    //this.showActiveIngradientsPanel = false;
    event.preventDefault();
  }

  public saveOrder(fromDeactivate?: boolean) {
    //this.showActiveIngradientsPanel = false;
    if (this.isNewOrder) {
      if (this.procurementDetails.productName.trim().length == 0) {
        if (!fromDeactivate) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please enter product name' });
        }
        return false;
      }
      if ((this.procurementDetails.formulationType == null || this.procurementDetails.formulationType == undefined)
       && this.procurementDetails.repoName == 'FACTS'
      ) {
        if (!fromDeactivate) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select formulation type' });
        }
        return false;
      }
    }
    if (!this.isNewOrder && this.procurementDetails && this.procurementDetails.orderItemShipment && this.procurementDetails.orderItemShipment.length) {
      this.orderItemShipmentForm.markAllAsTouched();
      if (!this.orderItemShipmentForm.valid) {
        if (!fromDeactivate) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'One or more receipts have invalid information. Form cannot be saved.' });
        }
        return false;
      } else {
        let index = 0;
        let shipmentsArray = this.orderItemShipmentForm.get("shipments") as UntypedFormArray;
        for (let shipment of shipmentsArray.controls) {
          const shipmentDetails = this.procurementDetails.orderItemShipment[index];
          shipmentDetails.dateReceived = shipment.value.dateReceived ? new Date(shipment.value.dateReceived.setHours(0, 0, 0, 0)) : null;
          shipmentDetails.totalQuantityReceived = shipment.value.totalQuantityReceived;
          shipmentDetails.receivedQuantityUom = shipment.value.orderQuantityUom;
          shipmentDetails.lotNumber = shipment.value.lotNumber;
          shipmentDetails.defect = shipment.value.defect;
          shipmentDetails.certificateOfAnalysisFlag = shipment.value.certificateOfAnalysisFlag;
          shipmentDetails.defectReasonText = shipment.value.defectReasonText;
          shipmentDetails.defectCreatedDate = shipment.value.defectCreatedDate ? new Date(shipment.value.defectCreatedDate.setHours(0, 0, 0, 0)) : null;
          shipmentDetails.defectResolvedDate = shipment.value.defectResolvedDate ? new Date(shipment.value.defectResolvedDate.setHours(0, 0, 0, 0)) : null;
          shipmentDetails.tsnNumber = shipment.value.tsnNumber;
          shipmentDetails.receiptStatus = shipment.value.receiptStatus;
          // if(shipmentDetails.id && shipmentDetails.id==0)
          //  shipmentDetails.createdByUserId = this.user.Id;
          index++;
        }
      }
    }
    this.procurementDetails.orderQuantityUomId = this.procurementDetails.orderQuantityUom?.id;
    this.procurementDetails.sampleInventoryLocationId = this.procurementDetails.sampleInventoryLocation?.id;
    this.procurementDetails.orderStatusId = this.procurementDetails.orderStatus?.id;
    if(this.cancelledQuantities != null)
    {
      this.procurementDetails.orderItemDestination.forEach(itemDest => {
        itemDest.orderItemForecastDestination.forEach(forecastDest => {
          if(this.cancelledQuantities.get(forecastDest.id) != null)
          {
            forecastDest.unUsedQuantity = this.cancelledQuantities.get(forecastDest.id);
          }
        });
      });

    }
    if (this.procurementDetails.orderSubmissionDate != null && this.procurementDetails.orderSubmissionDate != undefined) {
      this.procurementDetails.orderSubmissionDate = new Date(this.procurementDetails.orderSubmissionDate.setHours(0, 0, 0, 0));
    }
    console.log("save Order");
    console.log(this.procurementDetails.orderItemShipment);
    this.saveOrUpdateOrder().subscribe((data) => {
      if (!fromDeactivate) {
        if (this.isNewOrder) {
          this.isSelfRedirect = true;
          this.router.navigate(['/procurementDetail', data.id, 'false']);
          this.messageService.add({
            severity: this.commonService.configurations.MessageServiceSeveritySuccess,
            summary: 'Success', detail: "Order created successfully."
          }
          );
        } else {
          this.messageService.add({
            severity: this.commonService.configurations.MessageServiceSeveritySuccess,
            summary: 'Success', detail: "Order details are successfully updated."
          }
          );
          this.isSelfRedirect = true;
          this.router.navigate(['/procurementDetail', this.procurementDetails.id, 'false']);
        }
        this.shipmentflag = false;
        this.isProductNameChanged = false;
      }
    }, error => {
      if (!fromDeactivate) {
        if (this.isNewOrder) {
          this.messageService.add({
            severity: this.commonService.configurations.MessageServiceSeverityError,
            summary: 'Order details error', detail: "Error while creating Order."
          });
        } else {
          this.messageService.add({
            severity: this.commonService.configurations.MessageServiceSeverityError,
            summary: 'Order details error', detail: "Error while updating Order details."
          });
        }
      }
    });
  }

  public saveOrUpdateOrder(): Observable<any> {
    if(this.isProductNameChanged){
      if(!this.materialType || this.materialType == ''){
        this.procurementDetails.materialSearchType = null;
      }
    }
    if (this.isNewOrder) {
      return this.procurementClient.createOrder(this.procurementDetails);
    }
    else {
      this.procurementDetails.modifiedByUserId = this.user.id;
      return this.procurementClient.updateOrderDetails(this.procurementDetails);
    }
  }

  public cancelOrder() {
    this.router.navigated = false;
    // this.loadOrderDetails();
    this.router.navigate([this.router.url]);

  }
  public cancelAndReset() {
    this.dialogCancelConfirm = false;
    this.orderItemShipmentForm = null;
    this.shipmentflag = false;
    this.isProductNameChanged = false;
    this.shipmentUomflag = false;
    this.shipmentmainflag = false;
    this.router.navigated = false;
    this.isSelfRedirect = true;
    this.router.navigate([this.router.url]);
  }

  public filterstatus() {
    if (this.procurementDetails.orderStatus.status == "Follow-up") {
      this.allData.orderStatus = this.allData.orderStatus.filter(({ status }) => status !== "New");
    }
    else if (this.procurementDetails.orderStatus.status !== "New") {
      this.allData.orderStatus = this.allData.orderStatus.filter(({ status }) => status !== "New" && status !== "Follow-up");
    }

    if (this.procurementDetails.orderStatus.status == "New" ||
      this.procurementDetails.orderStatus.status == "Follow-up") {
      this.disableMaterialSearch = false;
    }
    else {
      this.disableMaterialSearch = true;
    }
    this.enableMP = this.procurementDetails.orderStatus.status == "In Inventory" ? false : true;

  }
  public addReceiving() {

    //this.orderShipment.totalQuantityReceived = 0;
    this.orderShipment = new OrderItemShipmentModel();
    this.orderShipment.id = 0;
    this.orderShipment.lotNumber = "";
    this.orderShipment.receivedQuantityUom = this.procurementDetails.orderQuantityUom;
    this.orderShipment.createdByUser = this.user;
    this.procurementDetails.orderItemShipment.splice(0, 0, this.orderShipment);
    console.log("shipment array", this.procurementDetails.orderItemShipment);
    (this.orderItemShipmentForm.get('shipments') as UntypedFormArray).insert(0, this.getShipmentGroup(this.orderShipment))
  }

  public deleteShipmentDialog(index: any) {
    this.shipmentDeleteIndex = index;
    this.dialogDeleteShipmentDialog = true;
  }
  public deleteReceving() {
    this.dialogDeleteShipmentDialog = false;
    if (this.shipmentDeleteIndex != -1) {
      this.procurementDetails.orderItemShipment.splice(this.shipmentDeleteIndex, 1);
      (this.orderItemShipmentForm.get('shipments') as UntypedFormArray).removeAt(this.shipmentDeleteIndex);
    }

  }

  public calProcurementTime(formGroup: any) {
    var pdays = this.getDays(this.procurementDetails.createdDate, formGroup.get('dateReceived').value)
    formGroup.controls['procurementTime'].setValue(pdays);
  }

  public calDefectDuration(shipmentGroup: any) {
    var pdays = this.getDays(shipmentGroup.get('defectResolvedDate').value, shipmentGroup.get('defectCreatedDate').value)
    shipmentGroup.controls['defectDuration'].setValue(pdays);
  }

  decimalFilter(event: any) {
    this.isQuantityOrderedInvalid = false;
    let input = this.procurementDetails.quantityOrdered.toString();
    const reg = /^[0-9]{0,6}\.?[0-9]{0,3}$/;

    if (!reg.test(input)) {
      this.isQuantityOrderedInvalid = true;
    }
  }

  public getDays(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      var diff = Math.abs(endDate.getTime() - startDate.getTime());
      return Math.ceil(diff / (1000 * 3600 * 24));
    }
    else {
      return 0;
    }
  }

  public parseToLocalDate(date: Date) {

    date.setHours(date.getHours() - (date.getTimezoneOffset() / 60))
    return date;

  }


  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (this.canDeactivate() && !this.isSelfRedirect) {
      event.preventDefault();
      event.returnValue = true;
    }
  }

  canDeactivate(): boolean {
    if (this.enableDisableToggle() && !this.isSelfRedirect) {
      return true;
    }
    else {
      return false;
    }

  }

  saveData() {
    this.saveOrder(true);
  }

  onProductNameChange(event: any) {
    if((this.actualProductName == undefined && this.procurementDetails.productName) || this.actualProductName.trim() !=  this.procurementDetails.productName)
    {
      this.isProductNameChanged = true;
      this.materialType = '';
      this.procurementDetails.orderItemActiveIngredient = [];
      this.showActiveIngradientsPanel = false;
      if(this.procurementDetails.productName){
          this.showActiveIngradientsPanel = true;
      }
    }
  }

  onRecevingStatusChange(shipmentGroup: UntypedFormGroup, event: any) {
    this.shipmentflag = true;
    //this.enableDisableToggle();
    if (event.value?.statusText == "In Inventory") {
      shipmentGroup.get("defect").setValue(null)
      shipmentGroup.get("defectReasonText").setValue('')
      shipmentGroup.get("defect").setValidators(null)
      shipmentGroup.get("defectCreatedDate").setValidators(null)
    }
    else {

      shipmentGroup.get("defect").setValidators([Validators.required])
      shipmentGroup.get("defectCreatedDate").setValidators([Validators.required])
    }
    shipmentGroup.get("defect").updateValueAndValidity();
    shipmentGroup.get("defectCreatedDate").updateValueAndValidity();
  }

  cancelAndResetDestination()
  {
     this.destinations.filter(x => x.id == this.currentDestId)[0].orderStatusId = this.currentState;
     this.destinations.filter(x => x.id == this.currentDestId)[0].orderStatus = this.destOrderStatuses.filter(x => x.id == this.currentState)[0];
     this.dialogCancelCountryConfirm = false;
  }
  CheckRemainingQty(event, orderItemForecastDestinationModel: OrderItemForecastDestinationModel)
  {
    this.currentState = orderItemForecastDestinationModel.orderStatusId;
    this.currentDestId = orderItemForecastDestinationModel.id;
    this.currentDestinationId = null;
    this.currentRemainingQty = null;
    if (event.value.status === 'Cancelled') {
      if(orderItemForecastDestinationModel.forecastItemDestinationId == null)
      {
        return true;
      }
      this.forecastItemDestinationClient.getUsedQuantity(orderItemForecastDestinationModel.forecastItemDestinationId).subscribe(x => {

        if(x > 0)
        {
        this.currentDestinationId=  orderItemForecastDestinationModel.id ;
        this.currentRemainingQty = orderItemForecastDestinationModel.quantity - x;
        this.remainingQty = (orderItemForecastDestinationModel.quantity - x  ) + " " + orderItemForecastDestinationModel.uomDisplay;

        this.dialogCancelCountryConfirm = true;
        }
        else{
          return true;
        }
      });
    }
  }
  recordCancellation()
  {
    if(this.currentDestinationId != null)
    {
    this.cancelledQuantities.set(this.currentDestinationId,this.currentRemainingQty);
    }
    this.currentDestinationId = null;
    this.currentRemainingQty = null;
    this.dialogCancelCountryConfirm = false;
  }

  isShowOrderedQtyHyperLink(forecastLocationItem: OrderItemForecastDestinationModel) {
    if (forecastLocationItem?.forecastItemId !== null && forecastLocationItem?.forecastItemId > 0 &&  forecastLocationItem.orderStatus !== undefined
      && forecastLocationItem.orderStatus.status !== 'Cancelled' &&  forecastLocationItem.orderStatus.status !== 'Outsourced') {
        return true;
    } else  {
      return false;
    }
  }

  onGetOrderedQuantity(destination: OrderItemForecastDestinationModel, index: number){
    this.forecastItemDestinationClient.getOrderedQuantity(destination.forecastItemDestinationId).subscribe(qty => {
      this.destinationOrderedQuantity[index] = qty;
    })
  }

}
interface ValidationResult {
  [key: string]: boolean;
}
export function defectValidator(avalue: string) {

  return (control: UntypedFormControl): ValidationResult => {
    if (avalue && avalue == "In Inventory") {
      console.log('value', avalue)
      return null;
    }
    else if (control.value) {
      return null;
    }

    return { valid: true };
  };
}
