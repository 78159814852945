<div class="grid">
  <div class="md:col-8">
    <h4 class="clsDataEntryPanelTitleBar">{{(isNewOrder && !showSearch) ? "Create New - " : ""}}Procurement Order</h4>
  </div>
  <div class="md:col-4" *ngIf="showSearch">
    <a (click)="goSearch()" [ngClass]="{'anchorDisabled': disableMaterialSearch}" style="float:right;">
      <span [title]="backButtonText" style="text-decoration: underline; cursor: pointer; float: right;">
        <i class="fa fas fa-arrow-left" aria-hidden="true"></i>
        {{backButtonText}}
      </span></a>
  </div>
</div>
<div *ngIf="showSearch" >
  <app-composite-search [isGlpEligible]="user.isGlpeligible" (AddCartItem)="addCartItem($event)" (ShowMessage)="ShowMessage($event)"></app-composite-search>
</div>

<div *ngIf="!showSearch" class="grid">
  <div class="md:col-12">
    <div style="float:right">
      <!-- Save [Disabled] has two conditions, first for new procurement until formulation type check , second for existing -->
      <button pButton type="button" id="btnSaveTop"
        [disabled]="(isNewOrder
         && (procurementDetails.productName  == undefined ||   procurementDetails.repoName == undefined  ||
         (procurementDetails.formulationType == undefined && procurementDetails.repoName == 'FACTS'))
         && procurementDetails.orderItemActiveIngredient.length == 0)
         || !(enableDisableToggle()) || procurementDetails.orderItemDestination.length === 0"
        label="Save" (click)="saveOrder()" style="margin-top: 10px;"></button>
      <button pButton type="button" id="btnCancelTopBottom" label="Cancel" [disabled]=" !(enableDisableToggle())"
        (click)="dialogCancelConfirm=true" style="margin-top: 10px;margin-left: 10px;"></button>
    </div>
    <!-- <div class="md:col-2" >
        <a (click)="goSearch()" [ngClass]="{'anchorDisabled': disableMaterialSearch}" style="float:right;">
          <span [title]="backButtonText" style="text-decoration: underline; cursor: pointer; float: right;"><i class="fa fas fa-arrow-left" aria-hidden="true" ></i>   {{backButtonText}}</span></a>
      </div> -->
  </div>
</div>

<div *ngIf="!showSearch" class="mt-15">
  <p-panel>
    <p-header style="width: 100%;background-color: #007ad9;height: 115%;">
      <div class="grid align-items-center d-flex " style="width: 100%;padding-top: 4px;padding-left: 11px;">
        <div class="col-4 xl:col-2 lg:col-4 md:col-4 sm:col-4" style="width: 15%;" [ngClass]="{'clsBold': isNewOrder}">
          <label style="color: white; padding-right: 3px" [ngClass]="{'clsBold': isNewOrder}">
            <div *ngIf="isNewOrder">*</div>Product Name
          </label>
         
          <a href="" style="border: 1px solid;padding-left: 4px;padding-right: 4px;border-radius: 5px;"
          [ngStyle]="{'border-color' :  (!materialType || materialType =='') ? 'lightgray' : '#007bff' , 'background-color': (!materialType || materialType =='') ? 'lightgray' : '#007bff','pointer-events': (!materialType || materialType =='') ? 'none' : null,'text-decoration':(!materialType || materialType =='') ? 'null' : 'underline'}"
          [routerLink]="['/materialInventory/'+procurementDetails.repoName+'/'+procurementDetails.materialId]"
          title="click the icon to view material inventory" target="_blank">

            <i class="fa fa-solid fa-list" style="color:white"></i> 
        </a>
        </div>
        <div class="col-4 xl:col-2 lg:col-2 md:col-4 sm:col-4 position-relative d-flex align-items-center" style="width: 40%;">
          <input type="text" id="txtProductName" [(ngModel)]="procurementDetails.productName" pInputText
            class="ui-inputtext" style="width: 100%;" [disabled]="disableMaterialSearch" (keyup)="onProductNameChange($event)">
          <span [ngClass]="{'anchorDisabled': disableMaterialSearch}"
            style="position: absolute;top: 0.5em;right: 2.85em;color: #8b9094;cursor: pointer;font-size: 25px;font-weight: bold;"
            (click)="ClearProduct($event)">&times;</span>
          <a (click)="goSearch()" [ngClass]="{'anchorDisabled': disableMaterialSearch}">
            <span><i class="fa fa-search fa-2x" style="color:white;padding-left:3px;cursor: pointer;"
                aria-hidden="true"></i></span>
          </a>
          <i class="fa fa-info-circle fa-2x"  [ngClass]="(!materialType || materialType =='') ? 'clsHide':''" title="{{GetTitle()}}" style="padding-left:3px;" (click)="showPNDialog(true)"></i>
        </div>
        <div class="col-4 lg:col-2 md:col-4 sm:col-4" style="width: 40%;padding-left: 150px;">
          <!-- md:col-7 -->
          <label style="color: white;padding-left: 45px; padding-right: 13px;">Status</label>
          <p-dropdown id="ddrOrderStatus" [disabled]="isNewOrder" optionLabel="status" [options]="allData.orderStatus"
            [(ngModel)]="procurementDetails.orderStatus" (onClick)="filterstatus()">
          </p-dropdown>
        </div>
        <div class="col-4 lg:col-2 md:col-4 sm:col-4" (click)="$event.stopPropagation();" style="color: white; text-align: right;">
          <p-checkbox id="chkIsManufactured" inputId="{{procurementDetails.id}}"
                style="padding-right: 5px;" label="Add to FP" 
                  [(ngModel)]="procurementDetails.isManufactured" binary="true"
                  (onChange)="onCheckAddtoFP($event, procurementDetails)" [disabled]="procurementDetails.isManufactured">
          </p-checkbox>
          <!-- <label for="chkIsManufactured">Add to FP</label> -->
        </div>
      </div>
    </p-header>
    <div class="grid" style="width: 100%">
      <div class="col-1 xl:col-2 lg:col-4 md:col-4 sm:col-4">Stock Quantity</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        {{stockQuantity}}
      </div>
      <div class="col-3 xl:col-2 lg:col-3 md:col-4 sm:col-4" ><span *ngIf="!isNewOrder">GLP Quantity</span><span class="clsBold" *ngIf="isNewOrder">*Unit of Measure</span></div>
      <div class="col-6 xl:col-8 md:col-6 lg:col-8 sm:col-8">
        <div *ngIf="isNewOrder">
          <p-selectButton id="selectProcurementDetailsUOM" [options]="allData.sourcingUnitOfMeasure"
            [(ngModel)]="procurementDetails.orderQuantityUom" optionLabel="uomDisplay"></p-selectButton>
        </div>
        <div *ngIf="!isNewOrder">{{glpQuantity}}
        </div>
      </div>
    </div>
    <div class="grid" style="width: 100%">
      <div class="col-1 xl:col-2 lg:col-4 md:col-4 sm:col-4">Created By</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        {{procurementDetails.createdByUserName}}
      </div>

      <div class="col-3 xl:col-2 lg:col-3 md:col-4 sm:col-4">Earliest Date</div>
      <div class="col-2 md:col-6 lg:col-8 md:col-8 sm:col-8">
        {{GetEarliestDate(procurementDetails.orderItemDestination) | date: 'dd-MMM-yyyy'}}
      </div>
    </div>
    <div class="grid" style="width: 100%">
      <div class="col-1 xl:col-2 lg:col-4 md:col-4 sm:col-4">Created Date</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        {{procurementDetails.createdDate | date: 'dd-MMM-yyyy'}}
      </div>
    </div>
    <div class="grid" style="width: 100%">
      <div class="col-1 xl:col-2 lg:col-4 md:col-4 sm:col-4">Material Planner</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8" style="width: auto;">
        <p-dropdown  [options]="materialPlanners" style="float: right;"
        class="r"
        optionLabel="fullName"
        id="ddrMaterialPlanner" [hidden]="false" [showClear]="true"
        placeholder="Material Planner" (click)="StopOpening($event)"
        [ngModel]="materialPlanner"
        (onChange)="setSelectedMaterialPlanner($event)"
        [disabled]="!enableMP"></p-dropdown>
      </div>
    </div>
  </p-panel>
  <!-- *ngIf="procurementDetails.materialId && showActiveIngradientsPanel" -->
  <p-panel style="margin-top: 15px;" *ngIf="showActiveIngradientsPanel || (!materialType || materialType =='')">
    <p-header style="width: 100%;background-color: #007ad9;height: 108%;">
      <div class="grid">
        <div class="col-10 md:col-10">

          <div style="line-height: 40px;padding-left: 10px;color:white;">
            <span>Active Ingredients</span>
          </div>
        </div>
        <div class="col-2 md:col-2" style="text-align: right;"><button pButton type="button"
            style="float:right;width:165px !important;margin-left:-5px;padding: 0 0.75rem;margin-top:5px !important;border:none;" class="w-full"
            label="Add Active Ingredients" icon="fa fa-plus"
            *ngIf="procurementDetails.orderStatus?.status == 'New' || procurementDetails.orderStatus?.status == 'Follow-up'"
            iconPos="right" (click)="showAIDialog(true)"></button></div>
      </div>

    </p-header>

    <div class="md:col-12" style="display: flex;">
      <div class="clsFilterDiv"
        *ngFor="let orderItemActiveIngredient of procurementDetails.orderItemActiveIngredient; let i = index">
        {{ orderItemActiveIngredient.activeIngredient +'(' + orderItemActiveIngredient.concentrationInPrimaryUom + ')'
        }}
        <span aria-hidden="true" [title]="'Delete'" class="p-button-icon-left p-clickable fa fa-trash clsTrash"
          (click)="DeleteActiveIngredient(orderItemActiveIngredient,i)" style="margin-left: 3px;"></span>
      </div>
    </div>

  </p-panel>
  <p-panel>
    <p-header style="width: 100%;background-color: #007ad9;height: 108%;">
      <div class="grid">
        <div class="col-11 md:col-11">
          <div style="line-height: 40px;padding-left: 10px;color:white;">
            <span>Country Information</span>
          </div>
        </div>
        <div class="col-1 md:col-1" style="text-align: right;">
          <button *ngIf="procurementDetails.orderStatus?.status == 'New' || procurementDetails.orderStatus?.status == 'Follow-up'" (click)='showCountryDialog()' pButton type="button"
            style="float:right;width: 120px !important;margin-left:-5px;margin-top:5px !important;border:none;" class="w-full"
            label="Add Country" icon="fa fa-plus" iconPos="right"></button></div>
      </div>

    </p-header>
    <div>
      <p-dataView #dv id="ForecastDestinations" [paginator]="true" [rows]="8" paginatorPosition="both"
        [value]="destinations" layout="grid" styleClass="hover">
        <ng-template let-destination let-rowIndexValue="rowIndex" pTemplate="gridItem" id="templateForecastDestinations" *ngIf="destinations">
          <div style="padding:.5em" class="col-12 sm:col-12 md:col-6 lg:col-4 xl:col-3 resShadow">
            <p-panel  [styleClass]="getTitleStyle(destination)" id="{{destination.id}}" [style]="{'text-align':'left'}">
              <ng-template pTemplate="header" style="width: 100%;padding: unset !important;">
                <div class="grid" style="margin-top: 0.1rem !important;width: 100%;margin-left:0px !important; ">
                  <div class="col-10">
                    <span class="form-panel-subheader">{{destination.country}}</span>
                  </div>
                  <div class="col-2">
                    <span aria-hidden="true" *ngIf="!destination.isNonForecast&&destination.forecastItem!==null&&destination.forecastItem.bypassReservation"
                      class="p-button-icon-left p-clickable fa fa-sign-out fa-2x clsBypass" [title]="'Bypass'"
                      id="{{'Bypass' + destination.id}}"></span>
                  </div>
                </div>
              </ng-template>
              <div style="min-height: 200px;" id="{{'forecastItem' + destination.id}}">
                <div class="forecastItem-detail"><span class="form-label-header">Status: </span>
                  <p-dropdown id="{{'ddrorderState' + destination.id}}" [options]="destOrderStatuses"
                    optionLabel="status" [(ngModel)]="destination.orderStatus"
                    [style]="{'minWidth':'50px', 'width': '120px'}"
                    (onChange)="CheckRemainingQty($event,destination)"
                    [disabled]="destination.orderStatusId==outsourcedOrderStatus?.id || destination.orderStatusId==cancelledOrderStatus?.id || destination.orderStatusId==inventoryOrderStatus?.id">
                  </p-dropdown>
                </div>
                <div class="forecastItem-detail"><span class="form-label-header">Qty: </span>
                  <span class="form-label-content" id="{{'quantity' + destination.id}}">
                    <!-- {{destination.quantity}}   {{destination.uomDisplay}} -->
                    <ng-container *ngIf="isShowOrderedQtyHyperLink(destination); else qty">
                      <a style="text-decoration: underline;"  (click)="onGetOrderedQuantity(destination, rowIndexValue)" class="orderedQtyLink">
                        {{destination.quantity}} {{destination.uomDisplay}}
                        <span *ngIf="destinationOrderedQuantity[rowIndexValue] !== undefined">&nbsp;(</span> 
                        {{destinationOrderedQuantity[rowIndexValue] !== undefined ? destinationOrderedQuantity[rowIndexValue] : ''}} {{destinationOrderedQuantity[rowIndexValue] !== undefined ? destination.uomDisplay : ''}}
                        <span *ngIf="destinationOrderedQuantity[rowIndexValue] !== undefined">&nbsp;Ordered in CPSS)</span> 
                      </a>
                  </ng-container>
                  <ng-template #qty>
                    {{destination.quantity}} {{destination.uomDisplay}}
                  </ng-template>
                  </span>
                    
                </div>
                <div class="forecastItem-detail" style="padding-top: 5px;">
                  <span class="form-label-header">Reservation Location:</span>
                  <p-dropdown id="selectSampleInventoryLocation" [options]="allData.sampleInventoryLocations"
                    class="location-select" [(ngModel)]="destination.inventoryLocation" optionLabel="locationName"
                    [disabled]="destination.isNonForecast||destination.orderStatusId==cancelledOrderStatus?.id||destination.orderStatusId==outsourcedOrderStatus?.id||destination.orderStatusId==inventoryOrderStatus?.id||(!destination.isNonForecast&&destination.forecastItem!==null&&destination.forecastItem.bypassReservation)"></p-dropdown>
                </div>
                <div class="forecastItem-detail"><span class="form-label-header">Requester's Name: </span><span
                    class="form-label-content"
                    id="{{'requester' + destination.id}}">{{destination.requesterName}}</span>
                </div>
                <div class="forecastItem-detail"><span class="form-label-header">Material Purpose: </span><span
                    class="form-label-content"
                    id="{{'materialPurpose' + destination.id}}">{{destination.materialPurpose.materialPurpose1}}</span>
                </div>
                <div class="forecastItem-detail"><span class="form-label-header">Order Type: </span><span
                    class="form-label-content"
                    id="{{'orderType' + destination.id}}">{{destination.orderType.orderType1}}</span>
                </div>
                <div class="forecastItem-detail"><span class="form-label-header">Date Needed: </span>
                  <span
                    class="form-label-content" id="{{'dateNeeded' + destination.id}}">{{destination.dateNeeded | date:
                    'dd-MMM-yyyy'}}
                <span
                      id="{{'dateNeededLatest' + destination.id}}" *ngIf="GetDateNeededLatest(destination)">
                    - {{ GetDateNeededLatest(destination) | date:'dd-MMM-yyyy'}}
                </span>
                  </span>
                </div>
                <div class="forecastItem-detail"><span class="form-label-header">Date Submitted: </span><span
                    class="form-label-content"
                    id="{{'requestSubmissionDate' + destination.id}}">{{destination.requestSubmissionDate | date:
                    'dd-MMM-yyyy'}}</span>
                </div>
                <div class="forecastItem-detail"><span class="form-label-header">Forecast#: </span>
                  <span class="form-label-content"
                    id="{{'rDSims_RequestNumber' + destination.id}}"><a style="text-decoration: underline;" 
                    target="_blank" [title]="destination.forecastItemId" [routerLink]="['/forecastitemEdit',destination.forecastItemId]">{{destination.forecastItemId}}</a></span>
                </div>
                <div class="forecastItem-detail"><span class="form-label-header">Request #: </span><span
                    class="form-label-content"
                    id="{{'rDSims_RequestNumber' + destination.id}}">{{destination.rdsimsRequestNumber}}</span>
                </div>
                <!-- <div class="forecastItem-detail"><span class="form-label-header">Labels : </span><span
                  class="form-label-content"
                  id="{{'rDSims_raceLabels' + destination.id}}">{{destination.forecastItemDestination.raceLabels}}</span>
              </div> -->
              <div class="forecastItem-detail"><span class="form-label-header">Labels: </span><span
                class="form-label-content" id="{{'rDSims_raceLabels' + destination.id}}"  [pTooltip]="destination.forecastItemDestination?.raceLabels?.replaceAll('||','\r\n')">
                {{(destination.forecastItemDestination?.raceLabels && destination.forecastItemDestination?.raceLabels.length > 20) ? destination.forecastItemDestination?.raceLabels.substring(0,20)?.replaceAll('||',', ')+'...': destination.forecastItemDestination?.raceLabels}} 
              </span></div>
                <div class="forecastItem-detail"><span class="form-label-header" style="width: 55%;">Alternative
                    Product: </span><span class="form-label-content" style="width: 45%;"
                    id="{{'alternativeProduct' + destination.id}}">{{destination.alternativeProduct}}</span>
                </div>
                <div class="forecastItem-detail" style="padding-bottom:8px;"><span class="form-label-header">Comment:
                </span>
                <span class="form-label-content" id="{{'comment' + destination.id}}">
                  <a (click)="OpenCommentDialog(destination.country, destination.forecastItemId, destination.forecastItemDestinationId);">{{GetLatestComment(destination.forecastItemId)}}</a>
                </span>
              </div>
              </div>
            </p-panel>
          </div>
        </ng-template>
        <ng-template pTemplate="paginatorleft" let-state id="paginatorleft">
          <span class="ui-paginator-first" style="padding-left:10px;"> Total Items : {{destinations.length}}
            {{ dv.totalRecords == destinations.length ? '' : '(Showing Items: '+ dv.totalRecords + ')'}} </span>
        </ng-template>
        <ng-template pTemplate="paginatorright" id="paginatorright" let-state>
          <span class="ui-paginator-first" style="padding-right:10px;">{{ dv.totalRecords > 0 ? (state.page *
            state.rows) + 1 : 0 }} -
            {{ (state.rows * (state.page + 1)) > state.totalRecords ? state.totalRecords : (state.rows * (state.page +
            1)) }}
            of {{dv.totalRecords}} Items</span>
        </ng-template>
      </p-dataView>
    </div>
  </p-panel>
  <p-panel>
    <p-header style="width: 100%;background-color: #007ad9;height: 108%;">
      <div class="grid">
        <div class="md:col-10">

          <div style="line-height: 40px;padding-left: 10px;color:white;">
            <span>Procurement Information</span>
          </div>
        </div>
      </div>
    </p-header>
    <div class="grid" style="width: 100%">
      <div class="col-2 xl:col-2 lg:col-4 md:col-4 sm:col-4 ">GMID/D-Code/Item No</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <input type="text" id="txtItemNo" pInputText class="ui-inputtext"
          [(ngModel)]="procurementDetails.gmidDcodeItemNumber">
      </div>
      <div class="col-1 xl:col-1 lg:col-2 md:col-2 sm:col-2 ">Date Ordered</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <p-calendar id="calendarDateordered" [showIcon]="true" dateFormat="dd-M-yy"
          [(ngModel)]="procurementDetails.orderSubmissionDate"></p-calendar>
      </div>
      <div class="col-2 xl:col-2 lg:col-4 md:col-4 sm:col-4 ">Inventory Location</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <p-dropdown id="selectSampleInventoryLocatino" [(ngModel)]="procurementDetails.sampleInventoryLocation"
          [options]="allData.sampleInventoryLocations" optionLabel="locationName"></p-dropdown>
      </div>
    </div>
    <div class="grid" style="width: 100%">
      <div class="col-2 xl:col-2 lg:col-4 md:col-4 sm:col-4">Supplier/Plant Code</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <input type="text" id="txtPlantCode" pInputText class="ui-inputtext"
          [(ngModel)]="procurementDetails.supplierPlantCode">
      </div>
      <div class="col-1 xl:col-1 lg:col-2 md:col-2 sm:col-2">Order #/Conf #</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <input type="text" id="txtConf" pInputText class="ui-inputtext"
          [(ngModel)]="procurementDetails.orderConfirmationNumber">
      </div>
      <div class="col-2 xl:col-2 lg:col-4 md:col-4 sm:col-4">Inventory Profile</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <input type="text" id="txtSGS" pInputText class="ui-inputtext"
          [(ngModel)]="procurementDetails.sgSinventoryProfile">
      </div>
    </div>
    <div class="grid" style="width: 100%">
      <div class="col-2 xl:col-2 lg:col-4 md:col-4 sm:col-4">Approver</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <input type="text" id="txtApprover" pInputText class="ui-inputtext" [(ngModel)]="procurementDetails.approver">
      </div>
      <div class="col-1 xl:col-1 lg:col-1 md:col-2 sm:col-2">Quantity</div>
      <div class="col-7 xl:col-7 lg:col-7 md:col-7 sm:col-7" style="display: inherit;padding-left: 7px!important;">
        <div>
          <input type="number" id="txtQty" pInputText class="ui-inputtext" (keyup)="decimalFilter($event)"
            [(ngModel)]="procurementDetails.quantityOrdered">
          <p-message _ngcontent-bob-c218="" severity="error" text="Quantity is invalid" *ngIf="isQuantityOrderedInvalid"
            ng-reflect-severity="error" ng-reflect-text="Quantity is invalid" class="ng-star-inserted">
            <div aria-live="polite" class="p-inline-message p-component p-inline-message-error ng-star-inserted"
              ng-reflect-ng-class="[object Object]"><span class="p-inline-message-icon pi pi-times-circle"
                ng-reflect-ng-class="pi pi-times-circle"></span><span
                class="p-inline-message-text ng-star-inserted">Quantity is
                invalid</span>
            </div>
          </p-message>
        </div>
        <div style="padding-left: 5px;">
          <p-selectButton id="selectProcurementDetailsUOM" [options]="allData.sourcingUnitOfMeasure"
            [(ngModel)]="procurementDetails.orderQuantityUom" optionLabel="uomDisplay"></p-selectButton>
        </div>
      </div>
    </div>
    <div class="grid" style="width: 100%">
      <div class="col-2 xl:col-2 lg:col-2 md:col-4 sm:col-4">Reach Approved</div>
      <div class="col-2 xl:col-2 lg:col-8 md:col-8 sm:col-8">
        <p-inputSwitch [(ngModel)]="procurementDetails.isReachCompliant"></p-inputSwitch>
      </div>
    </div>
  </p-panel>
  <p-panel class="clsCommentPanel">
    <p-header style="width: 100%;background-color: #007ad9;height: 108%;">
      <div class="grid">
        <div class="col-10 md:col-10">
          <div style="line-height: 40px;padding-left: 10px;color:white;">
            <span>Comments</span>
          </div>
        </div>
        <div class="col-2 md:col-2" style="text-align: right;"><button (click)="dialogAcceptCommentDialog=true;" pButton
            type="button" style="float:right;width:135px !important;margin-left:-5px;padding: 0 0.75rem;margin-top:5px !important;border:none;" class="w-full"
            label="Add Comments" icon="fa fa-plus" iconPos="right"></button>
        </div>
      </div>
    </p-header>
    <div class="grid" style="width: 100%;font-weight: bold !important;">
      <div class="col-3 xl:col-3 lg:col-3 md:col-3 sm:col-3 ">Date</div>
      <div class="col-3 xl:col-3 lg:col-3 md:col-3 sm:col-3">Name</div>
      <div class="col-6 xl:col-6 lg:col-6 md:col-6 sm:col-6">Comment</div>
    </div>
    <div class="grid" style="width: 100%" *ngFor="let item of procurementDetails.orderItemComment">
      <div class="col-3 xl:col-3 lg:col-3 md:col-3 sm:col-3 ">{{commonService.convertUTCToLocalTime(item.enteredAt) | date:'dd-MMM-yyyy'}}</div>
      <div class="col-3 xl:col-3 lg:col-3 md:col-3 sm:col-3">{{item.enteredByUserName}}</div>
      <div class="col-6 md:col-6 lg:col-6 md:col-6 sm:col-6">{{item.comment}}</div>
    </div>
  </p-panel>
  <p-panel *ngIf="!isNewOrder">
    <p-header style="width: 100%;background-color: #007ad9;height: 108%;">
      <div class="grid">
        <div class="col-11 md:col-11">

          <div style="line-height: 40px;padding-left: 10px;color:white;">
            <span>Receiving</span>
          </div>
        </div>
        <div class="col-1 md:col-1" style="text-align: right;">
          <button pButton type="button" style="float:right;width:115px !important;margin-left:-5px;padding: 0 0.75rem;margin-top:5px !important;border:none;"
            (click)="addReceiving()" class="w-full" label="Add Receipt" icon="fa fa-plus" iconPos="right"></button>
        </div>
      </div>
    </p-header>

    <div *ngIf="orderItemShipmentForm" style="margin-top: 10px; max-height:830px; overflow-y: auto">

      <form [formGroup]="orderItemShipmentForm">
        <div formArrayName="shipments"
          *ngFor="let shipment of orderItemShipmentForm.get('shipments').controls; let i=index">
          <div formGroupName="{{i}}">
            <p-panel class="shipment-panel" style="min-height: 700px !important;">
              <div class="grid" style="width: 100%">
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2 font-weight-bold">*Received Date</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <div>
                    <p-calendar id="calendarReceivedDate{{i}}" formControlName="dateReceived" [showIcon]="true"
                      (onSelect)="calProcurementTime(shipment)" [minDate]="procurementDetails.createdDate"
                      dateFormat="dd-M-yy">
                    </p-calendar>
                    <p-message severity="error" text="Received Date is required"
                      *ngIf="shipment.get('dateReceived').invalid && shipment.get('dateReceived').dirty">
                    </p-message>
                  </div>
                </div>
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2 font-weight-bold">*Receiving Status</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <p-dropdown id="ddlReceivingStatus{{i}}" optionLabel="statusText" [options]="receiptStatuses"
                    formControlName="receiptStatus" (onChange)="onRecevingStatusChange(shipment,$event)">
                  </p-dropdown>
                </div>
              </div>
              <div class="grid" style="width: 100%">
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2 font-weight-bold">*Received Quantity</div>
                <div class="col-10 md:col-10 lg:col-10 md:col-10 sm:col-10"
                  style="display: inherit;padding-left: 7px!important;">
                  <div>
                    <input type="number" pInputText class="ui-inputtext" formControlName="totalQuantityReceived">
                    <p-message severity="error" text="Received Quantity is required"
                      *ngIf="shipment.get('totalQuantityReceived').invalid && shipment.get('totalQuantityReceived').dirty">
                    </p-message>
                  </div>
                  <div style="padding-left: 5px;">
                    <p-selectButton id="selectOrderReceivedUOM{{i}}" [options]="allData.sourcingUnitOfMeasure"
                      formControlName="orderQuantityUom" optionLabel="uomDisplay"></p-selectButton>
                  </div>
                </div>
              </div>
              <div class="grid" style="width: 100%">
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2 font-weight-bold">*Manufacture Lot#</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <input type="text" pInputText class="ui-inputtext" formControlName="lotNumber" />
                  <p-message severity="error" text="Manufacture lot# is required"
                    *ngIf="shipment.get('lotNumber').invalid && shipment.get('lotNumber').dirty"></p-message>
                </div>
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2"
                  *ngIf="shipment.get('receiptStatus') && shipment.get('receiptStatus').value?.statusText=='In Inventory'">
                  Receiving Defect</div>
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2 font-weight-bold"
                  *ngIf="shipment.get('receiptStatus') && shipment.get('receiptStatus').value?.statusText!='In Inventory'">
                  *Receiving Defect</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <p-dropdown placeholder="Select a defect" id="ddrReceivingDefects{{i}}" optionLabel="defectName"
                    [options]="shipmentDefects" formControlName="defect"
                    [disabled]="shipment.get('receiptStatus') && shipment.get('receiptStatus').value?.statusText=='In Inventory'">
                  </p-dropdown>
                  <p-message severity="error" text="Receiving Defect is required"
                    *ngIf="shipment.get('defect').invalid && shipment.get('defect').dirty">
                  </p-message>
                </div>
              </div>
              <div class="grid" style="width: 100%">
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2 font-weight-bold">*Certificate Of Analysis</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <p-inputSwitch formControlName="certificateOfAnalysisFlag"></p-inputSwitch>
                </div>
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2"
                  *ngIf="shipment.get('defect') && shipment.get('defect').value?.defectName=='Other'">Other Reason</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4"
                  *ngIf="shipment.get('defect') && shipment.get('defect').value?.defectName=='Other'">
                  <input type="text" id="txtOtherReason{{i}}" pInputText class="ui-inputtext"
                    formControlName="defectReasonText"
                    [disabled]="shipment.get('receiptStatus') && shipment.get('receiptStatus').value?.statusText=='In Inventory'">
                </div>
              </div>
              <div class="grid" style="width: 100%">
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2">Created By</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <span>{{ shipment.get('receiptCreated').value }}</span>
                </div>
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2"
                  *ngIf="shipment.get('receiptStatus') && shipment.get('receiptStatus').value?.statusText=='In Inventory'">
                  Defect Created</div>
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2 font-weight-bold"
                  *ngIf="shipment.get('receiptStatus') && shipment.get('receiptStatus').value?.statusText!='In Inventory'">
                  *Defect Created</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <p-calendar id="calendarDefectCreated{{i}}" [showIcon]="true" dateFormat="dd-M-yy"
                    formControlName="defectCreatedDate" (onSelect)="calDefectDuration(shipment)"
                    [disabled]="shipment.get('receiptStatus') && shipment.get('receiptStatus').value?.statusText=='In Inventory'">
                  </p-calendar>
                  <p-message severity="error" text="Received Date is required"
                    *ngIf="shipment.get('defectCreatedDate').invalid && shipment.get('defectCreatedDate').dirty">
                  </p-message>
                </div>
              </div>
              <div class="grid" style="width: 100%">
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2">Procurement Time</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <span>{{shipment.get('procurementTime').value}}</span>
                </div>
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2">Defect Resolved</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <p-calendar id="calendarDefectResolved{{i}}" [showIcon]="true" dateFormat="dd-M-yy"
                    formControlName="defectResolvedDate" (onSelect)="calDefectDuration(shipment)"
                    [minDate]="shipment.get('defectCreatedDate').value"
                    [disabled]="shipment.get('receiptStatus') && shipment.get('receiptStatus').value?.statusText=='In Inventory'">
                  </p-calendar>
                </div>
              </div>
              <div class="grid" style="width: 100%">
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2">TSN #</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <input type="text" id="txtTsn{{i}}" pInputText class="ui-inputtext" formControlName="tsnNumber">
                </div>
                <div class="col-2 xl:col-2 lg:col-2 md:col-2 sm:col-2">Defect Duration</div>
                <div class="col-4 xl:col-4 lg:col-4 md:col-4 sm:col-4">
                  <span>{{shipment.get('defectDuration') && shipment.get('defectDuration').value}}</span>
                </div>
              </div>
              <div class="grid" style="width: 100%;text-align: right;">
                <div class="md:col-11">
                  <button pButton type="button" label="Delete" (click)="deleteShipmentDialog(i)"
                    style="margin-top: 10px;text-align:right">
                  </button>
                </div>
                <div class="grid" style="width: 100%;min-height:100px !important;">
                </div>
              </div>
            </p-panel>
          </div>
        </div>
      </form>
    </div>
  </p-panel>
</div>
<div *ngIf="!showSearch" class="grid">
  <div class="md:col-2"> Last Modified By</div>
  <div class="md:col-2"> {{ procurementDetails.modifiedByUserName}} </div>
  <div class="md:col-2"> Last Modified On</div>
  <div class="md:col-2"><span *ngIf="procurementDetails.modifiedDate != undefined"> {{parseToLocalDate(procurementDetails.modifiedDate)  | date: 'd-MMM-y h:mm:ss a'}}</span></div>
  <div class="md:col-4" style="padding: 0.5rem !important;">
    <div style="float:right">  <!-- Save [Disabled] has two conditions, first for new procurement until formulation type check , second for existing -->
      <!-- Save [Disabled] has two conditions, first for new procurement until formulation type check , second for existing -->
      <button pButton type="button" id="btnSaveBottom"
      [disabled]="(isNewOrder
      && (procurementDetails.productName  == undefined ||   procurementDetails.repoName == undefined  ||
      (procurementDetails.formulationType == undefined && procurementDetails.repoName == 'FACTS'))
      && procurementDetails.orderItemActiveIngredient.length == 0)
      || !(enableDisableToggle()) || procurementDetails.orderItemDestination.length === 0"
        label="Save" (click)="saveOrder()" style="margin-top: 10px;"></button>
      <button pButton type="button" id="btnCancelTopBottom" label="Cancel" [disabled]="!(enableDisableToggle())" (click)="dialogCancelConfirm=true" style="margin-top: 10px;margin-left: 10px;"></button>
    </div>
  </div>
</div>
<p-dialog id="dialogCommentGrid" header='{{commentTitle}}' [(visible)]="dialogCommentGrid"
[modal]="true"
[style.z-index]="1000" (onShow)="onAIFormShow()" [styleClass]="'clsDialogActiveIngredientStyle'"
[style.minWidth]="'800px'" [style.minHeight]="'475px'"   [style.maxWidth]="'800px'" [style.maxHeight]="'725px'"
[draggable]="true" [resizable]="false">
  <p-panel class="clsCommentPanel">
    <p-header style="width: 100%;background-color: #007ad9;height: 108%;">
      <div class="grid">
        <div class="md:col-8">
          <div style="line-height: 40px;padding-left: 10px;color:white;">
            <span>Comments</span>
          </div>
        </div>
        <div class="md:col-4" style="text-align: right;"><button (click)="dialogAcceptCommentDialog=true;" pButton
            type="button" style="padding: 0 0.75rem;margin-top:5px !important;border:none;" class="w-full"
            label="Add Comments" icon="fa fa-plus" iconPos="right"></button>
        </div>
      </div>
    </p-header>
    <div class="grid" style="width: 100%;font-weight: bold !important;">
      <div class="col-2 md:col-2">Date</div>
      <div class="col-4 md:col-4">Name</div>
      <div class="col-6 md:col-6" style="padding-left: 3px !important;">Comment</div>
    </div>
    <div class="grid" style="width: 100%" *ngFor="let item of procurementDetails.orderItemComment">
      <div class="col-2 md:col-2">{{commonService.convertUTCToLocalTime(item.enteredAt) | date:'dd-MMM-yyyy'}}</div>
      <div class="col-4 md:col-4">{{item.enteredByUserName}}</div>
      <div class="col-6 md:col-6">{{item.comment}}</div>
    </div>
  </p-panel>
  <p-footer>
    <div class="grid" styleClass="disable-scroll">
      <div class="md:col-12">
        <button  type="button" pButton id="btnNoCancel" pButton icon="fa fa-close" (click)="dialogCommentGrid=false;" label="Cancel"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog id="dialogForecastCommentGrid" header='{{commentTitle}}' [(visible)]="dialogForecastCommentGrid"
[modal]="true"
[style.z-index]="1000" (onShow)="onAIFormShow()" [styleClass]="'clsDialogActiveIngredientStyle'"
[style.minWidth]="'800px'" [style.minHeight]="'475px'"   [style.maxWidth]="'800px'" [style.maxHeight]="'725px'"
[draggable]="true" [resizable]="false">
  <p-panel class="clsCommentPanel">
    <p-header style="width: 100%;background-color: #007ad9;height: 108%;">
      <div class="grid">
        <div class="md:col-8">
          <div style="line-height: 40px;padding-left: 10px;color:white;">
            <span>Comments</span>
          </div>
        </div>
        <div class="md:col-4" style="text-align: right;"><button (click)="LaunchForecastComment();" pButton
            type="button" style="padding: 0 0.75rem;margin-top:5px !important;border:none;" class="w-full"
            label="Add Comments" icon="fa fa-plus" iconPos="right"></button>
        </div>
      </div>
    </p-header>
    <div class="grid" style="width: 100%;font-weight: bold !important;">
      <div class="col-2 md:col-2">Date</div>
      <div class="col-4 md:col-4">Name</div>
      <div class="col-6 md:col-6" style="padding-left: 3px !important;">Comment</div>
    </div>
    <div class="grid" style="width: 100%" *ngFor="let item of forecastItemCommentLogModel">
      <div class="col-2 md:col-2">{{item.dateEntered | date:'dd-MMM-yyyy'}}</div>
      <div class="col-4 md:col-4">
        <a href="mailto:{{item.user.email}}">{{item.user.firstName}} {{item.user.lastName}}</a>
      </div>
      <div class="col-6 md:col-6">{{item.comment}}</div>
    </div>
  </p-panel>
  <p-footer>
    <div class="grid" styleClass="disable-scroll">
      <div class="md:col-12">
        <button  type="button" pButton id="btnNoCancel" pButton icon="fa fa-check" (click)="dialogForecastCommentGrid=false;" label="Ok"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
<p-dialog id="dialogAcceptComment" header='New Comment' [(visible)]="dialogAcceptCommentDialog"
[styleClass]="'clsDialog'"
 [modal]="true"
  [dismissableMask]="true">
  <div class="grid">
    <div class="col-12">
      <textarea name="commentText" [value]="commentText" (input)="commentText = $event.target.value;" style="height: 200px;overflow-y: scroll;border-width: 1px;;" formControlName="commentText" id="txtComment" [rows]="10" [cols]="50"></textarea>
    </div>
  </div>
  <div class="grid">
    <div class="md:col-12">
      <button type="button" pButton id="btnSaveComment" [disabled]="!commentText"  (click)="SaveComment()" label="Save"   ></button>
      <button style="margin-left: 10px;" type="button" pButton id="btnCancel" (click)="dialogAcceptCommentDialog=false;" label="Cancel"   ></button>
    </div>
  </div>
</p-dialog>
<p-dialog id="dialogAcceptForecastComment" header='New Comment' [(visible)]="dialogAcceptForecastComment"
[styleClass]="'clsDialog'"
 [modal]="true"
  [dismissableMask]="true">
  <div class="grid">
    <div class="col-12">
      <textarea name="commentText" [value]="commentText" (input)="commentText = $event.target.value;" style="height: 200px;overflow-y: scroll;border-width: 1px;;" formControlName="commentText" id="txtComment" [rows]="10" [cols]="50"></textarea>
    </div>
  </div>
  <div class="grid">
    <div class="md:col-12">
      <button type="button" pButton id="btnSaveComment" [disabled]="!commentText"  (click)="SaveForecastComment()" label="Save" ></button>
      <button style="margin-left: 10px;" type="button" pButton id="btnCancel" (click)="CloseForecastComment();" label="Cancel"   ></button>
    </div>
  </div>
</p-dialog>
<p-dialog header="Active Ingredients" [(visible)]="isShowAIDialog" id="dialogOrderItemActiveIngredients" [modal]="true"
[style.z-index]="1000" (onShow)="onAIFormShow()" [styleClass]="'clsDialogActiveIngredientStyle'"
[style.minWidth]="'725px'" [style.minHeight]="'475px'"   [style.maxWidth]="'725px'" [style.maxHeight]="'725px'"
[draggable]="true" [resizable]="false">
<form [formGroup]="aiform" (ngSubmit)="aiform.value" id="formOrderItemAIForm">
  <div class="grid">
    <div class="md:col-4"><label style="font-weight: bold;">*Active Ingredient</label></div>
    <div class="md:col-8">
      <input type="text" formControlName="aiIngredient" id="txtOrderItemaiIngredient" pInputText placeholder="AI Ingredient">
      <p-message severity="error" text="Active ingredient is required" *ngIf="!aiform.controls['aiIngredient'].valid&&aiform.controls['aiIngredient'].dirty"></p-message>
    </div>
  </div>
  <div class="grid">
    <div class="md:col-4"><label style="font-weight: bold;">*Concentration</label></div>
    <div class="md:col-8">
      <input type="text" formControlName="concentration" id="txtOrderItemconcentration" pInputText placeholder="0">
      <p-message severity="error" [text]="aiform.controls['concentration'].errors['required'] ? 'Concentration is required' : aiform.controls['concentration'].errors['pattern'] ? 'Concentration value is invalid' : ''"
      *ngIf="!aiform.controls['concentration'].valid&&aiform.controls['concentration'].dirty"></p-message>
    </div>
  </div>
  <div class="grid">
    <div class="md:col-4"><label style="font-weight: bold;">*Unit of Measure</label></div>
    <div class="md:col-8">
      <p-selectButton formControlName="ctrnUom" [options]="allData.cUom"
      optionLabel="uomDisplay" id="selectButtonOrderItemctrnUOM"></p-selectButton>
    </div>

  </div>
  <div class="grid">
      <div class="md:col-4"></div>
      <div class="md:col-1">
          <button pButton type="button" id="btnSaveAI" label="Add" style="margin-top: 10px"
          type="submit" (click)="onAddAi()"   [disabled]="!aiform.valid" title="Click Here to Add Active Ingredient" ></button>


  </div>
  <div class="md:col-2">
      <button pButton type="button" id="btnCancelAI" label="Cancel" (click)="showAIDialog(false)" style="margin-top: 10px;margin-left: 10px;"
             ></button>
  </div>
  </div>
</form>
</p-dialog>
<p-dialog id="dialogAddCountry" header="Add Country" [(visible)]="displayCountryDialog"
[styleClass]="'clsDialogAddLocationStyle'"
[style.minWidth]="'650px'" [style.minHeight]="'670px'" [modal]="true"
  [dismissableMask]="true">
  <form [style.minHeight]="'665px'" [style.overflow-x]="'hidden'" [formGroup]="destinationForm" id="formForecastDestination" (ngSubmit)="SaveDestination(destinationForm.value)">
    <div class="grid">
      <div class="col-3 md:col-3"><label for="country" style="font-weight: bold;">*Country</label></div>
      <div class="col-9 md:col-9">
        <p-autoComplete  id="autoCompleteDestinationCountry" [suggestions]="filteredCountriesSingle"
         (completeMethod)="filterCountrySingle($event)"
         field="displayName" [size]="30"  formControlName="country"
          placeholder="Destination Country" [minLength]="1">
        </p-autoComplete>
      </div>
    </div>
    <div class="grid">
      <div class="md:col-3"><label for="quantity" style="font-weight: bold;">*Quantity</label></div>
      <div class="col-9 md:col-9">
        <div class="grid">
          <div class="col-2">
            <input style="width:80px;" type="text" id="numberDestinationQuantity" min="1" required formControlName="quantity" pInputText class="ui-inputtext"  >
          </div>
          <div class="col-10" >
            <p-selectButton formControlName="quantityUom" id="selectQuantityUOM" [options]="quantUoms"
            optionLabel="uomDisplay"></p-selectButton>
          </div>
        </div>
       <div *ngIf="!destinationForm.controls['quantity'].valid&&destinationForm.controls['quantity'].dirty">
        <p-message severity="error" *ngIf="destinationForm.get('quantity').hasError('required')" text="Quantity is required" ></p-message>
        <p-message severity="error" *ngIf="destinationForm.get('quantity').errors.min"  text="Quantity should be greater than 0.0001" ></p-message>
        <p-message severity="error" *ngIf="destinationForm.controls['quantity'].errors?.pattern"  text="Quantity is invalid" ></p-message>
       </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-3 md:col-3"><label for="SampleRequester" style="font-weight: bold;">*Requester's Name</label></div>
      <div class="col-9 md:col-9">
        <p-autoComplete #pauto [suggestions]="filteredUsersSingle" id='txtDestinationSampleRequesterName' [(ngModel)]="suggestion"
        (completeMethod)="filterUserSingle($event)" (onSelect)="setSuggestion($event)"
        field="fullName" [size]="25"  formControlName="sampleRequesterName"
         placeholder="Start typing a user's name" [minLength]="1" (onClear)="clearValue()"  >
       </p-autoComplete>
      </div>
    </div>
    <div class="grid">
      <div class="col-3 md:col-3"><label for="materialPurpose" style="font-weight: bold;">*Material Purpose</label></div>
      <div class="col-9 md:col-9">
            <p-selectButton formControlName="materialPurpose" id="materialPurpose" [options]="materialPurposes"
            optionLabel="materialPurpose1"></p-selectButton>
       </div>
    </div>
    <div class="grid">
      <div class="col-3 md:col-3"><label for="orderType" style="font-weight: bold;">*Order Type</label></div>
      <div class="col-9 md:col-9">
            <p-selectButton formControlName="orderType" id="orderType" [options]="orderTypes"
            optionLabel="orderType1"></p-selectButton>
       </div>
    </div>
    <div class="grid">
      <div class="col-3 md:col-3"><label for="DateRequested" style="font-weight: bold;">*Request Date</label></div>
      <div class="col-9 md:col-9"> <p-calendar id="calendarDateRequested"  name="DateRequested" formControlName="dateRequested"
        [showIcon]="true" [(minDate)]="minReqDate" dateFormat="dd-M-yy"
       ></p-calendar>
      </div>
    </div>
    <div class="grid">
        <div class="col-3 md:col-3"><label for="DateNeeded" style="font-weight: bold;">*Date Needed</label></div>
        <div class="col-9 md:col-9"> <p-calendar id="calendarDestinationDateNeeded"  name="DateNeeded" formControlName="dateNeeded"
          [showIcon]="true" [(minDate)]="minDateNeeded" dateFormat="dd-M-yy"
        ></p-calendar>
        <p-message severity="error" text="Needed Date is required" *ngIf="!destinationForm.controls['dateNeeded'].valid&&destinationForm.controls['dateNeeded'].dirty"></p-message>
        </div>
    </div>
    <div class="grid">
      <div class="col-3 md:col-3"><label for="RDSimsRequestNumber"> Request#</label></div>
      <div class="col-9 md:col-9">
        <input type="text" formControlName="rDSimsRequestNumber"
        pInputText class="ui-inputtext" id="RDSimsRequestNumber" >
        <p-message severity="error" text="Only numeric values up to 16 digits accepted" *ngIf="!destinationForm.controls['rDSimsRequestNumber'].valid&&destinationForm.controls['rDSimsRequestNumber'].dirty"></p-message>
      </div>
    </div>
    <div class="grid">
      <div class="col-3 md:col-3"><label for="AlternateFormulation"> Alternative Product</label></div>
      <div class="col-9 md:col-9">
        <input type="text" id="AlternateFormulation" formControlName="alternateFormulation"
        pInputText class="ui-inputtext">
        </div>
    </div>
  </form>
  <p-footer>
    <button type="button" pButton icon="fa fa-check" id="btnAddPopupForecastLocation" [disabled]="!destinationForm.valid" (click)="SaveDestination(destinationForm.value);" label="Add"></button>
    <button type="button" pButton icon="fa fa-close" id="btnCancelPopupForecastLocation" (click)="CancelDestination()" label="Cancel"></button>
  </p-footer>
</p-dialog>
<p-dialog id="dialogConfirmation" header='Cancel Confirmation' [(visible)]="dialogCancelConfirm"
[styleClass]="'clsDialog'"
 [modal]="true"
  [dismissableMask]="true"
  [contentStyle]="{'overflow-y': 'hidden', 'overflow-x': 'hidden',
  'max-height': '80vh','min-height':'50px'}"
   [resizable]="false">
  <div class="grid" >
    <div class="col-12">
      <div><label class="fa fa-info-circle" > <span style="margin-left: 14px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">Changes made in this page will not be saved. Are you sure you want to cancel?</span> </label></div>
    </div>
  </div>
  <p-footer style="height:5px;">
  <div class="grid" styleClass="disable-scroll">
    <div class="md:col-12">
      <button style="margin-left:340px;" type="button" pButton id="btnYesCancel" pButton icon="fa fa-check"   (click)="cancelAndReset()" label="Yes, Cancel"   ></button>
      <button style="margin-left:2px" type="button" pButton id="btnNoCancel" pButton icon="fa fa-close" (click)="dialogCancelConfirm=false;" label="No"   ></button>
    </div>
  </div>
</p-footer>
</p-dialog>
<p-dialog id="dialogDeleteshipment" header='Delete Receiving Record' [(visible)]="dialogDeleteShipmentDialog"
[styleClass]="'clsDialog'"
 [modal]="true"
  [dismissableMask]="true"
  [contentStyle]="{'overflow-y': 'hidden', 'overflow-x': 'hidden',
  'max-height': '80vh','min-height':'50px'}"
   [resizable]="false">
  <div class="grid" >
    <div class="col-12">
      <div><label class="fa fa-info-circle" >
         <span style="margin-left: 14px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
        Are you sure you want to delete this record? After selecting Yes, you must save the changes. </span> </label></div>
    </div>
  </div>
  <p-footer style="height:5px;">
  <div class="grid" styleClass="disable-scroll">
    <div class="md:col-12">
      <button style="margin-left:340px;" type="button" pButton id="btnYesCancel" pButton icon="fa fa-check"   (click)="deleteReceving();" label="Yes, delete"></button>
      <button style="margin-left:2px" type="button" pButton id="btnNoCancel" pButton icon="fa fa-close" (click)="dialogDeleteShipmentDialog=false;" label="Cancel"   ></button>
    </div>
  </div>
</p-footer>
</p-dialog>

<p-dialog header="{{GetDialogTitle()}}" [(visible)]="isShowPNDialog" id="dialogForemulationActiveIngredients" [modal]="true"
  [style.z-index]="1000"   [styleClass]="'clsDialogFormulationActiveIngredientStyle'"
  [style.minWidth]="'725px'" [style.minHeight]="'475px'"   [style.maxWidth]="'725px'"
  [draggable]="false" [resizable]="false">

  <div class="grid leftMargin"  *ngIf="materialType == 'Coformulant/Field Inert'">

    <div class='col-12'>
      <span style="font-weight: bold;">Trade Name:</span>
    </div>

    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="procurementDetails?.productName != null && procurementDetails?.productName.length >0">
      {{procurementDetails?.productName}}
    </li>
    <div class='col-12'>
      <span style="font-weight: bold;">Common Name:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="procurementDetails?.synonyms != null && procurementDetails?.synonyms.length >0">
       {{procurementDetails?.synonyms.split('||').join('; ')}}
    </li>
</div>
  <div   class="grid" *ngIf="materialType == 'Formulation Lot' || materialType == 'Formulation'" >
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Material Name:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;">
      {{procurementDetails.productName}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Formulation Type:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;">
      {{procurementDetails.formulationType}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="materialType == 'Formulation Lot' && showField">
      <span style="font-weight: bold;">TSN:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="materialType == 'Formulation Lot' && forecastItem?.tsnNumber != null && showField">
      {{forecastItem.tsnNumber}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;"  *ngIf="materialType == 'Formulation'">
      <span style="font-weight: bold;">Actives:</span>
    </div>
    <div style="width: 100%;" *ngIf="materialType == 'Formulation'">
    <div class="col-12" style="padding-bottom: 0px;" *ngFor="let active of procurementDetails.orderItemActiveIngredient">

      <div class="grid"  >
        <div class="col-8 clsSpaceActives">
          <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" >
            {{active.activeIngredient}}
          </li>
         </div>
        <div class="col-2 clsSpaceActives">{{active.concentrationInPrimaryUom}}</div>
        <div class="col-2 clsSpaceActives">{{active.concentrationInSecondaryUom}}</div>

      </div>

    </div>
  </div>

    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;"  >
      <span style="font-weight: bold;">Synonyms:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="procurementDetails?.synonyms != null && procurementDetails?.synonyms.length >0">
      {{procurementDetails?.synonyms.split('||').join('; ')}}
   </li>
  </div>
  <div class="grid" *ngIf="materialType == 'Compound'">
    <div class='col-12' style="padding-bottom: 0rem !important ;">
      <span style="font-weight: bold;">Core Material ID:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      {{procurementDetails?.materialId}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Synonyms:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="procurementDetails?.synonyms != null && procurementDetails?.synonyms.length >0">
      {{procurementDetails?.synonyms.split('||').join('; ')}}
   </li>
</div>
<div class="grid" *ngIf="materialType === 'Bionexus' || materialType === 'Bionexus Lot'" >
  <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
    <span style="font-weight: bold;">Material Name:</span>
  </div>
  <li class='col-12 clsSpacing' style="padding-top: 0rem !important;">
    {{procurementDetails?.productName}}
  </li>
  <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="procurementDetails?.lotNumber==null">
    <span style="font-weight: bold;">Material Id:</span>
  </div>
  <li class='col-12 clsSpacing' style="padding-top: 0rem !important;"  *ngIf="procurementDetails?.lotNumber==null">
    {{procurementDetails?.materialId }}
  </li>
  <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="procurementDetails?.lotNumber">
    <span style="font-weight: bold;">Lot Id:</span>
  </div>
  <li class='col-12 clsSpacing' style="padding-top: 0rem !important;"  *ngIf="procurementDetails?.lotNumber">
    {{procurementDetails?.lotNumber }}
  </li>
  <div class="col-12">
    
  <p-tabView style="border: 0px !important;padding: 0 !important;">
    <p-tabPanel header="Material Attributes" headerStyleClass="tabpanelClass" style="display: block; max-height: 175px;overflow-y: auto;" >
      <div style="min-height: 175px;">
      <p-table [columns]="cols" [value]="materialDetails?.materialAttributes"  >
        <!-- <ng-template pTemplate="header" let-columns> 
          <tr>
            <th>Name</th>
            <th>Value</th>
        </tr>
        </ng-template> -->
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td style="padding: 0px;">
              <span>{{ rowData['name'] }}</span>
            </td>
            <td style="padding: 0px;">
              <span>{{ rowData['values'].join('; ') }}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    </p-tabPanel>
    <p-tabPanel header="Lot Attributes"  style="display: block; max-height: 175px;overflow-y: auto;" *ngIf="materialDetails?.lotAttributes">
      <div style="min-height: 175px;">
      <p-table [columns]="cols" [value]="materialDetails?.lotAttributes" >
        
        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td style="padding: 0px;">
              <span>{{ rowData['name'] }}</span>
            </td>
            <td style="padding: 0px;">
              <span>{{ rowData['values'].join(';') }}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    </p-tabPanel>
      <p-tabPanel header="Synonyms" headerStyleClass="tabpanelClass" style="display: block; max-height: 175px;overflow-y: auto;">
        <div style="min-height: 175px;">
          <p-table [value]="procurementDetails?.synonyms?.split('||')">
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td style="padding: 0px;">
                  <span>{{ rowData }}</span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-tabPanel>
  </p-tabView>
  </div>
</div>
  <div class="grid" *ngIf="materialType == 'Compound Lot'">
    <div class='col-12' style="padding-bottom: 0rem !important ;">
      <span style="font-weight: bold;">Core Material ID:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      {{procurementDetails?.materialId}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="showField">
      <span style="font-weight: bold;">TSN:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem?.tsnNumber != null && showField">
      {{forecastItem.tsnNumber}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="showField">
      <span style="font-weight: bold;">Purity:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem; padding-bottom: 0rem !important;" *ngIf="forecastItem?.Purity != null && showField">
      {{forecastItem.Purity}}
    </li>
    <div class='col-12' style="padding-top: 0rem; padding-bottom: 0rem !important;">
      <span style="font-weight: bold;">Synonyms:</span>
    </div>
    <li class='col-12 clsSpacing' style="padding-top: 0rem !important;" *ngIf="procurementDetails?.synonyms != null && procurementDetails?.synonyms.length >0">
      {{procurementDetails?.synonyms.split('||').join('; ')}}
   </li>
</div>
<div class="col-12">
    <button pButton type="button" label="Done" id="btnForecastItemForemulationAIDone" style="float: right;"
      (click)="isShowPNDialog=false;"></button>
  </div>
</p-dialog>
<p-dialog id="dialogConfirmation" header='Cancelled Reservation Notice' [(visible)]="dialogCancelCountryConfirm"
  [styleClass]="'clsDialog'" [modal]="true" [dismissableMask]="true" [contentStyle]="{'overflow-y': 'hidden', 'overflow-x': 'hidden',
        'max-height': '80vh','min-height':'50px'}" [resizable]="false">

      <div class="grid">
      <div class="col-1" style="text-align: center;font-size: 33px;color: #007ad9;">
        <i class="fa fa-exclamation-triangle"></i>
      </div>
      <div class="col-11">
     <p>Are you sure you would like to cancel it?</p>
     <p>You are about to cancel a forecast that has an associated CPSS request against it.</p>
       <p>The unused quantity of <b style="font-weight:bold ;">  {{remainingQty}}</b> will be cancelled from this forecast</p>

      </div>
    </div>

  <p-footer style="height:3px;">
    <div class="grid" styleClass="disable-scroll" style="margin-bottom:0px">
      <div class="md:col-12">
        <button style="margin-left:340px;" type="button" pButton id="btnYesCancel" pButton icon="fa fa-check"
        (click)="recordCancellation();"  label="Yes, Cancel"></button>
        <button style="margin-left:2px" type="button" pButton id="btnNoCancel" pButton icon="fa fa-close"
        (click)="cancelAndResetDestination()"  label="No"></button>
      </div>
    </div>
  </p-footer>
</p-dialog>
