import { Component, OnInit,  Renderer2} from '@angular/core';
import { CheckboxCellRendererComponent } from '../custom/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { ConcentrationUomService } from '../services/concentrationuom.service';
import { ConcentrationUomModel } from '../models/concentrationuom';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../loading/res-loading.service';
import { CommonService } from '../services/common.service';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-concentration-uom',
  templateUrl: './concentration-uom.component.html',
  styleUrls: ['./concentration-uom.component.scss'],
  providers: [ConcentrationUomService, MessageService]
})
export class ConcentrationUomComponent implements OnInit {
  public context;
  public appMessages = ''
  public gridApi;
  public gridColumnApi;
  public concentrationUomModel:ConcentrationUomModel= new ConcentrationUomModel();
  public displayDialog:boolean=false; // Responsible for opening/Closing popup
  public IsEdit:boolean = false; // To differenciate API Calls
  public IsCheckboxClicked:boolean=false; // to differenciate row click and checkbox click event
  public headerText:string= ""; // Popup header
  public columnDefs = [
    {headerName: 'Abbreviation', field: 'uomDisplay', sortable: true, filter: true, width: 150},
    {headerName: 'Description', field: 'description', sortable: true, filter: true, width: 260},
    { headerName: 'Is Enabled', field: 'isEnabled', sortable: true,  filter: true,  cellRenderer: 'checkboxCellRenderer',  width: 130} // cellRenderer:checkboxCellRenderer is to load checkbox with event binded
  ];
  public frameworkComponents = {
    checkboxCellRenderer: CheckboxCellRendererComponent
  };

  public rowData: Array<ConcentrationUomModel>;

constructor(private cuoms: ConcentrationUomService, 
  private appComponent: AppComponent,
  private messageService: MessageService,private renderer:Renderer2,private commonService:CommonService) {
              this.context = { componentParent: this };
  }

  ngOnInit() {
    //Get list of Concentration UoMs
    this.appComponent.currentSelection = 'concentrationuom';
    this.cuoms.getConcentrationUoms().subscribe(response => this.rowData = response,
      error => {
          this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
      },
      () => { console.log(this.rowData); } );
}
/// <summary>
/// popup close event
/// </summary>
public  cancel() {
  this.displayDialog = false;
}

/// <summary>
/// event to open popup to add concentration QoM
/// </summary>
public  CreateConcentrationUOM() {
  this.IsEdit = false;
  this.concentrationUomModel = new ConcentrationUomModel();
  this.concentrationUomModel.isEnabled= false; // defaul IsEnabled is set to false else null value is passed to API 
  this.headerText =this.commonService.configurations.ConcentrationUOMAddPopupHeader ;
  
  this.displayDialog = true;
  setTimeout(() => {
    this.renderer.selectRootElement("#UomDisplay").focus();
  }, 20);
}

/// <summary>
/// grid row click event
/// </summary>
public EditConcentrationUOM(event: any) { 
  if(!this.IsCheckboxClicked){ // check if the rowclick event trigger from checkbox click
  this.IsEdit = true; // set to true to call Edit API 
  this.concentrationUomModel.id = event.data.Id;
  this.concentrationUomModel.description = event.data.Description;
  this.concentrationUomModel.uomDisplay = event.data.UomDisplay;
  this.concentrationUomModel.isEnabled = event.data.IsEnabled;
  this.headerText =this.commonService.configurations.ConcentrationUOMEditPopupHeader;
  
  this.displayDialog = true;
  setTimeout(() => {
    this.renderer.selectRootElement("#UomDisplay").focus();
  }, 20);

  }
  this.IsCheckboxClicked = false; // is set to false not to effect next click events
 }
/// <summary>
/// Add or update Concentration QoM
/// </summary>
public  save()
{
  if(!this.IsEdit){
    this.cuoms.checkDuplicate(this.concentrationUomModel).subscribe(res => {
        if(res == false){
            this.cuoms.createConcentrationUom(this.concentrationUomModel).subscribe(res => console.log(res),
              error => {
                  console.log(error);
                  this.messageService.add({ severity:  this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message })
                  },
              () => {
                  this.messageService.add({ severity:  this.commonService.configurations.MessageServiceSeveritySuccess, summary: '', detail: this.commonService.configurations.DataSaved })
                  this.ngOnInit();
                  this.displayDialog = false;
              });
          }
      else
      {
        this.messageService.add({ severity:  this.commonService.configurations.MessageServiceSeverityError, summary: '', detail:  this.commonService.configurations.DuplicateAbbreviation })
      }
    });
  }
  else
  {
    this.updateConcentrationUoM(this.concentrationUomModel);
  }
}
/// <summary>
/// Is Enabled Checkbox click event
/// </summary>
public checkBoxUpdated(concentrationUomModel: ConcentrationUomModel) {
  this.IsCheckboxClicked = true; // to avoid opening popup when only checkbox in clicked on grid
  this.updateConcentrationUoM(concentrationUomModel); 

  }
/// <summary>
/// Update Concentration QoM API calls
/// </summary>
  public updateConcentrationUoM(concentrationUomModel:ConcentrationUomModel)
  {
    this.cuoms.updateConcentrationUom(concentrationUomModel).subscribe(res => console.log(res),
    error => {
        console.log(error);
        this.messageService.add({ severity:  this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message })
    },
    () => {
        this.messageService.add({ severity:  this.commonService.configurations.MessageServiceSeveritySuccess, summary: '', detail: this.commonService.configurations.DataSaved })
        this.ngOnInit();
        this.displayDialog = false; // closes the popup then the UoM is edited from popup 
    });
}
public   onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
