<div class="row">
  <div class="col-md-12 text-center">
    <label style="font-size: 96px; color: red;">
      <span>
        <i class="pi pi-exclamation-circle" style="font-size: 6rem"></i>
      </span>
      No Permissions
      <span>
        <i class="pi pi-exclamation-circle" style="font-size: 6rem"></i>
      </span>
    </label>
    <h4>You do not have permissions to access anything in this application. Please contact support.</h4>
    <p></p>
    <p></p>
  </div>
</div>

