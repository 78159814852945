import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ForecastItemService } from '../services/forecastItem.service';
import { MessageService,ConfirmationService } from 'primeng/api';
import { CommonService } from '../services/common.service';
import { DatePipe } from '@angular/common';

import { SecurityService } from '../services/security.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CountryClient, UserClient, ForecastStateModel, ForecastStateClient, ForecastItemClient,
  SearchFormulaModel, SearchCriteriaModel, SearchClient, ForecastItemModel, ForecastItemDestinationModel, 
  UserModel, ForecastItemDTO }
    from '../services/forecast-api-client.service';
import { Filter } from '../models/filter.model';
import { UntypedFormControl, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { AppComponent } from '../app.component';
import { DataView } from 'primeng/dataview';
import { MsalService } from "@azure/msal-angular";

@Component({
  selector: 'app-forecasts-new',
  templateUrl: './forecasts-new.component.html',
  styleUrls: ['./forecasts-new.component.scss'],
  providers: [ForecastItemService, MessageService,ConfirmationService]
})
 
export class ForecastsNewComponent implements OnInit {
  isAccordionOpen:boolean= false;
  StatusSortAsc = [] ;
  indexes = [];
  IsDesc:boolean = false; 
  openAccordion:boolean= true;
forecastData:Array<ForecastItemModel>;
forecastNonFilterData:Array<ForecastItemModel>;
filteredCountries:any[];
filteredForecasters:any[] = [];
filteredReviewers:any[];
filteredForeaster:UserModel= null;
searchFilters:Array<SearchFormulaModel>;
defaultFilter:SearchFormulaModel ;
selectedFilter:SearchFormulaModel = new SearchFormulaModel(); 
searchCriteriaList:SearchCriteriaModel[];
orderBy:any[] = [{name: 'Select', code: 0},{name: 'Date Range', code: 1} ,{name: 'Delegator', code: 2},{name: 'Material', code: 3}
,{name: 'Reviewer', code: 5}, {name: 'Status', code: 6}];
countryModels:any;
reviewers:any[]=[];
forecasters:any[]=[];
filter:Filter;
userModel:UserModel = new UserModel();
userInfo:UserModel ;
forecastState:Array<ForecastStateModel>;
  @ViewChild('dv') dataView: DataView;
filterForm: UntypedFormGroup;

pageIndex: number = 1;
pageSize: number = 20;
totalItems: number = 0;
customFilterObj: any;

  constructor(public datepipe: DatePipe,
    private forecastItemService: ForecastItemService,
    private confirmationService:ConfirmationService ,
    private messageService: MessageService,
    private commonService:CommonService,
    private securityService:SecurityService,
    private authService:MsalService,
    private router: Router,
    private userClient: UserClient,
    private forecastStateClient:ForecastStateClient,
    private forecastItemClient: ForecastItemClient,
    private appComponent: AppComponent,
    private searchClient: SearchClient,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,public countryClient: CountryClient) { 
      
    }

  ngOnInit() {
    this.appComponent.currentSelection = 'forecasts';
    this.defaultFilter = new SearchFormulaModel();
    this.defaultFilter.name="Materials Assigned to me";
    this.defaultFilter.searchCriteria = [];
    this.Getform();
     this.filter = new Filter();
    this.userInfo = this.securityService.getUser();
    if(this.userInfo === undefined || this.userInfo === null)
    {
      var loggedInUsername = localStorage.getItem("loggedInUsername");
      if (loggedInUsername !== undefined && loggedInUsername !== null) {
        this.securityService.validateUser(loggedInUsername)
            .subscribe(data => {
              this.userInfo = data; 
              this.getData();
        },
        error => {
          this.messageService.add({ severity: 'info', summary: 'User not logged in', detail: 'Your session has expired.' });
//          this.authService.loginRedirect();
        });
      } else {
        this.messageService.add({ severity: 'info', summary: 'User not logged in', detail: 'Your session has expired.' });
//        this.authService.loginRedirect();
      }
    } else {
      this.getData();
    }
  }
  doNothing(e, index) {
    if (e) {
      e.stopPropagation();
    }
  }
  myFunction(e, index){
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    const idx = this.indexes.findIndex(i => i === index);
    if (idx < 0) {
      this.isAccordionOpen = true;
      this.indexes.push(index);
    } else {
      this.isAccordionOpen = false;
      this.indexes.splice(idx, 1);
    }
    this.indexes = [ ...this.indexes ];
    
  }
  Getform()
  {
    this.filterForm = this.fb.group({
      'id' :new UntypedFormControl(0),
      'country': new UntypedFormControl(''),
      'status':new UntypedFormControl(''),
      'Program': new UntypedFormControl(''),
      'Material': new UntypedFormControl(''),
      'Forecaster': new UntypedFormControl(''),
      'Reviewer': new UntypedFormControl(''),
      'OrderBy': new UntypedFormControl(''),
      'ForecastNumber': new UntypedFormControl(''),      
      'Name': new UntypedFormControl('',Validators.required)  
    });
  }
   
  StopDefaults()
  {
    this.openAccordion = false;
  }
  getData() {
    if (this.userInfo === null || this.userInfo === undefined || this.userInfo.id === undefined) {
      this.messageService.add({
        severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: 'Your session has expired. Please login again.' });
        this.router.navigate(['login']);
      return;
    }
     this.searchClient.getSearchFormulas(this.userInfo.id).subscribe(data =>{
       this.searchFilters = data;
       this.searchFilters.splice(0,0,this.defaultFilter);
       this.selectedFilter = this.defaultFilter;
     },
     error => {
       this.messageService.add({
         severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
         this.router.navigate(['login']);
     });

     this.forecastStateClient.getForecastStates()
    .subscribe(data => {
      this.forecastState = data
      data.forEach(ele => {
        this.StatusSortAsc.push(ele.stateName);
      });
//      console.log(data);
    });

     this.userClient.getUsersInRoles(['Biology Leader'])
    .subscribe(data => {
      console.log(data);
      data.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
      this.reviewers = data;  
      console.log(this.reviewers);
    });
     
     this.countryClient.getCountries()
    .subscribe(data => { 
      this.countryModels = data.filter(x => x.isProhibited == false).sort((a, b) => {
        if(a.displayName < b.displayName) { return -1; }
        if(a.displayName > b.displayName) { return 1; }
        return 0;
      });
    });
    this.getForecastsItemsCommonMethod(1, 20);
  }
  filterCountry(event) {
    let query = event.query;
    
        this.filteredCountries =  [];
    for(let i = 0; i < this.countryModels.length; i++) {
        let country = this.countryModels[i];
        if (country.displayName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            this.filteredCountries.push(country);
        }
    }
     
  }
  async filterForecaster(event) {
    let query = event.query;
     
    console.log("Started");
    this.forecastItemClient.getAllForecaster(query).subscribe(data => {
      data.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
      console.log(data);
      this.filteredForecasters = data;
    });
    //var forecasters =await this.GetForecasters(query); 
    console.log("Ended");
    }
    GetForecaster(input:number): Promise<any>
    {
      var responseDate = [];
      return this.forecastItemService.GetForecaster(input).then(data => {
        data.secondaryEmail = data.firstName + " " + data.lastName;
        this.filteredForeaster = data;
      })
        .catch(error => {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Error occurred while fetching data. Contact the Administrator' })
        });
    }
  filterReviewers(event) {
    let query = event.query;
    
        this.filteredReviewers =  [];
    for(let i = 0; i < this.reviewers.length ; i++) {
        let reviewer = this.reviewers[i];
        if (reviewer.secondaryEmail.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            this.filteredReviewers.push(reviewer);
        }
    }
    console.log(this.filteredReviewers);
     
  }
  //
  async ApplySelectedFilter($event)
  {
    console.log(this.selectedFilter);
    $event.stopPropagation()
    this.openAccordion = false;
      var selectedFilter = this.selectedFilter; 
      this.ResetForm();
      if(selectedFilter == this.defaultFilter)
      {
        
        (<UntypedFormControl>this.filterForm.controls['Name'])
      .setValue(this.selectedFilter.name , { onlySelf: true });

        this.filterForecast(false);
        return;
      }
      let value;

      (<UntypedFormControl>this.filterForm.controls['Name'])
      .setValue(this.selectedFilter.name , { onlySelf: true });

      (<UntypedFormControl>this.filterForm.controls['id'])
      .setValue(this.selectedFilter.id , { onlySelf: true });
      
      for(var i = 0 ;i < this.selectedFilter.searchCriteria.length ;i++ )
      {
        switch( this.selectedFilter.searchCriteria[i].criteriaName)
        {
          case "Material":  
            (<UntypedFormControl>this.filterForm.controls['Material'])
            .setValue( this.selectedFilter.searchCriteria[i].criteriaValue , { onlySelf: true });
          break;
          case "ForecastNumber":  
            (<UntypedFormControl>this.filterForm.controls['ForecastNumber'])
            .setValue( this.selectedFilter.searchCriteria[i].criteriaValue , { onlySelf: true });
          break;
          case "Program":
            (<UntypedFormControl>this.filterForm.controls['Program'])
            .setValue( this.selectedFilter.searchCriteria[i].criteriaValue , { onlySelf: true }); break;
          case "OrderBy": 
          value = this.orderBy.filter(ob => ob.name == this.selectedFilter.searchCriteria[i].criteriaValue)[0];
              (<UntypedFormControl>this.filterForm.controls['OrderBy'])
              .setValue( value , { onlySelf: true });
          break;
          case "status": 
              var arr = this.selectedFilter.searchCriteria[i].criteriaValue.split(',');
              var selectedStatusList = [];
              for(let i=0; i< arr.length; i++) {
                var val = this.forecastState.find(s => s.id == parseInt( arr[i]));
                selectedStatusList.push(val);
              }
              console.log(arr);
              console.log(selectedStatusList);
              (<UntypedFormControl>this.filterForm.controls['status'])
              .setValue(selectedStatusList, { onlySelf: true });
          break;
          case "country":  
            value  =  this.countryModels.find(ft => ft.id == parseInt( this.selectedFilter.searchCriteria[i].criteriaValue));
            (<UntypedFormControl>this.filterForm.controls['country'])
            .setValue(value, { onlySelf: true });
          break;
          case "Reviewer": 
              value = this.reviewers.find(r => r.id == parseInt( this.selectedFilter.searchCriteria[i].criteriaValue));
              (<UntypedFormControl>this.filterForm.controls['Reviewer'])
              .setValue(value, { onlySelf: true });
          break;
          case "Forecaster": 
          this.filteredForeaster = null;
              var forecasters = await this.GetForecaster(parseInt(this.selectedFilter.searchCriteria[i].criteriaValue));  
              value = this.filteredForeaster;
              (<UntypedFormControl>this.filterForm.controls['Forecaster'])
              .setValue(value, { onlySelf: true });
          break;
        }
      } 
      this.filterForecast(false);
  }

  ResetForm()
  {
    
this.filteredReviewers =[];
this.filteredForecasters =[];
(<UntypedFormControl>this.filterForm.controls['id'])
.setValue(null , { onlySelf: true });
      (<UntypedFormControl>this.filterForm.controls['Material'])
      .setValue( null , { onlySelf: true });
      (<UntypedFormControl>this.filterForm.controls['Program'])
      .setValue( null , { onlySelf: true });  
     (<UntypedFormControl>this.filterForm.controls['OrderBy'])
        .setValue( null , { onlySelf: true });
     (<UntypedFormControl>this.filterForm.controls['status'])
        .setValue(null, { onlySelf: true });
     (<UntypedFormControl>this.filterForm.controls['country'])
       .setValue(null, { onlySelf: true });
    (<UntypedFormControl>this.filterForm.controls['Reviewer'])
        .setValue(null, { onlySelf: true });
     
      (<UntypedFormControl>this.filterForm.controls['Forecaster'])
        .setValue(null, { onlySelf: true });
        (<UntypedFormControl>this.filterForm.controls['ForecastNumber'])
        .setValue(null, { onlySelf: true });
        
  }
  GetFormData()
  {
    var filter = new Filter() ;
    filter.Material =  (this.filterForm.get('Material').value ==  null || this.filterForm.get('Material').value.length == 0)  ? null : this.filterForm.get('Material').value  ;
    filter.Program = (this.filterForm.get('Program').value ==  null || this.filterForm.get('Program').value.length == 0)  ? null : this.filterForm.get('Program').value  ;
    filter.OrderBy = (this.filterForm.get('OrderBy').value ==  null || this.filterForm.get('OrderBy').value.length == 0 ) ? this.orderBy[0] : this.filterForm.get('OrderBy').value  ;
    filter.status = (this.filterForm.get('status').value ==  null || this.filterForm.get('status').value.length == 0)  ? null : this.filterForm.get('status').value   ;
    filter.country = (this.filterForm.get('country').value ==  null || this.filterForm.get('country').value.length == 0)  ? null : this.filterForm.get('country').value  ;
    filter.Reviewer = (this.filterForm.get('Reviewer').value ==  null || this.filterForm.get('Reviewer').value.length == 0)  ? null : this.filterForm.get('Reviewer').value  ;
    filter.Forecaster =(this.filterForm.get('Forecaster').value ==  null || this.filterForm.get('Forecaster').value.length == 0)  ? null : this.filterForm.get('Forecaster').value  ;
    filter.ForecastNumber =(this.filterForm.get('ForecastNumber').value ==  null || this.filterForm.get('ForecastNumber').value.length == 0)  ? null : this.filterForm.get('ForecastNumber').value  ;
    filter.id = (this.filterForm.get('id').value == null || this.filterForm.get('id').value == 0)  ? 0 : this.filterForm.get('id').value  ;
    return filter;
  }
 
  filterForecast(isSave:boolean)
  {
    //code for resolving country,Reviewer and Forecaster when proper value is entered but not selected from suggestion list.code starts here
    try 
    { // using try catch to avoid any exception in below code will not effect other execution
        var countryName = (document.getElementById("autoCompleteFilterCountries") as HTMLInputElement).getElementsByTagName("input")[0].value
        var reviewerName = (document.getElementById("autoCompleteReviewer") as HTMLInputElement).getElementsByTagName("input")[0].value
        var ForecasterName = (document.getElementById("autoCompleteForecaster") as HTMLInputElement).getElementsByTagName("input")[0].value
        var value  = null;
        if(countryName != undefined && countryName != null && countryName.length > 0)
        {
            value  =  this.countryModels.find(ft => ft.displayName.toLowerCase() == countryName.toLowerCase());
            if(value != undefined && value != null)
            (<UntypedFormControl>this.filterForm.controls['country']).setValue(value, { onlySelf: true });
        }
        
       if(reviewerName != undefined && reviewerName != null && reviewerName.length > 0)
        {
              value = this.reviewers.find(r => r.secondaryEmail.toLowerCase() == reviewerName.toLowerCase());
              if(value != undefined && value != null)
              (<UntypedFormControl>this.filterForm.controls['Reviewer']).setValue(value, { onlySelf: true });
        }
        
        if(ForecasterName != undefined && ForecasterName != null && reviewerName.length > 0)
        {
              value = this.forecasters.find(d => d.secondaryEmail.toLowerCase() ==ForecasterName.toLowerCase());
              if(value != undefined && value != null)
              (<UntypedFormControl>this.filterForm.controls['Forecaster']).setValue(value, { onlySelf: true });
        }

    }
    catch(error)
    {
      console.log(error)
    }
    //code end starts here
    var searchFormula = new SearchFormulaModel();
  var searchCriteria = new SearchCriteriaModel();
  this.searchCriteriaList = [];
    this.forecastData = this.forecastNonFilterData;
  this.filter = this.GetFormData();
  if (!isSave) {
    const statusList: any = this.filter?.status;
    var ids;
      if(statusList !== null && statusList.length > 0) {
        ids = statusList.map(status => {
          return status.id;
        })
      }
    
    this.customFilterObj = {
      forecastNumber: this.filter?.ForecastNumber,
      country: this.filter.country?.id,
      materialName: this.filter?.Material,
      statusList: ids,
      reviewerId: this.filter.Reviewer?.id,
      orderBy: this.filter.OrderBy?.name,
      program: this.filter?.Program,
      forecasterId: this.filter.Forecaster?.id,
      isDesc: this.IsDesc,
    }
    this.getFilteredForecastsItemsPaginated(this.customFilterObj, 1, this.pageSize);
  }

  if(isSave){
    searchFormula.name=this.filterForm.get('Name').value ;
    searchFormula.id= this.filter.id;
    searchFormula.ownerId = this.userInfo.id;
    searchFormula.enabled = true;
    searchFormula.isShared = false;
    searchFormula.dashboard ="dashboard";
    searchFormula.searchCriteria = [];
  }

    if(this.filter.Material != null)
    {
        searchCriteria = new SearchCriteriaModel();
        searchCriteria.criteriaName = "Material";
        searchCriteria.criteriaValue = this.filter.Material;
        this.searchCriteriaList.push(searchCriteria);
    }
    if(this.filter.Program != null)
    {
        searchCriteria = new SearchCriteriaModel();
        searchCriteria.criteriaName = "Program";
        searchCriteria.criteriaValue = this.filter.Program;
        this.searchCriteriaList.push(searchCriteria);
      
    }

    if(this.filter.Reviewer != null)
    {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Reviewer";
      searchCriteria.criteriaValue = this.filter.Reviewer.id.toString();
      this.searchCriteriaList.push(searchCriteria);
     
    }

    if(this.filter.Forecaster != null)
    {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "Forecaster";
      searchCriteria.criteriaValue = this.filter.Forecaster.id.toString();
      this.searchCriteriaList.push(searchCriteria);
     
    }
    if(this.filter.ForecastNumber != null)
    {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "ForecastNumber";
      searchCriteria.criteriaValue = this.filter.ForecastNumber.toString();
      this.searchCriteriaList.push(searchCriteria);
     
    }
    if(this.filter.status != null)
    {
      var statusList: any = this.filter.status;
      var criteriaValue = '';
      statusList.forEach((status, i) => {
        if (i === 0) {
          criteriaValue = status.id;
        } else {
          criteriaValue = criteriaValue + ',' + status.id;
        }
      });  
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "status";
      searchCriteria.criteriaValue = criteriaValue;
      this.searchCriteriaList.push(searchCriteria);    
    }
    if(this.filter.country != null)
    {
      searchCriteria = new SearchCriteriaModel();
      searchCriteria.criteriaName = "country";
      searchCriteria.criteriaValue = this.filter.country.id.toString();
      this.searchCriteriaList.push(searchCriteria);
    
    }
    if(this.filter.OrderBy != null)
    {
      
        // this.SortForecastItems();
        if(this.orderBy[0].name != this.filter.OrderBy.name) 
        {
          searchCriteria = new SearchCriteriaModel();
          searchCriteria.criteriaName = "OrderBy";
          searchCriteria.criteriaValue = this.filter.OrderBy.name.toString();
          this.searchCriteriaList.push(searchCriteria);
        }
      
    }

    if(isSave)
    {
      searchFormula.searchCriteria = this.searchCriteriaList;
        if(searchFormula.id == 0)
        {
          this.searchClient.createSearchFormula(searchFormula).subscribe(data => {
            this.searchClient.getSearchFormulas(this.userInfo.id).subscribe(data => {
              this.searchFilters = data
              this.searchFilters.splice(0,0,this.defaultFilter);
              searchFormula = data.filter(x => x.name== searchFormula.name)[0];
              this.selectedFilter = searchFormula;
              
            });

          });
        }
        else
        {
          searchFormula.searchCriteria.forEach(ele => ele.searchFormulaId = searchFormula.id)
          this.searchClient.updateSearchFormula(searchFormula.id , searchFormula).subscribe(data => {
            this.searchClient.getSearchFormulas(this.userInfo.id).subscribe(data => {
              this.searchFilters = data
              this.searchFilters.splice(0,0,this.defaultFilter);
              searchFormula = data.filter(x => x.name== searchFormula.name)[0];
              this.selectedFilter = searchFormula;
               
            });

          });
        }
    }
//    this.dataView.first = 0;
  }

  SortForecastItems(IsDesc:boolean = false)
  {
    let obj = this;
    let sortOrder = this.StatusSortAsc;
     this.filter.OrderBy =   (this.filterForm.get('OrderBy').value ==  null || this.filterForm.get('OrderBy').value.length == 0 ) ? this.orderBy[0] : this.filterForm.get('OrderBy').value  ;
 
  switch(this.filter.OrderBy.name)
  {
     case 'Select' : 
            this.forecastData = this.forecastData.sort((a, b) => {
              if(IsDesc)
              return sortOrder.indexOf(b.stateName) - sortOrder.indexOf(a.stateName);
              else
                return sortOrder.indexOf(a.stateName) - sortOrder.indexOf(b.stateName);
            });
     
     break;
     case  'Status' : 
        this.forecastData = this.forecastData.sort((a, b) => {
          if(IsDesc)
            return sortOrder.indexOf(b.stateName) - sortOrder.indexOf(a.stateName);
          else
            return sortOrder.indexOf(a.stateName) - sortOrder.indexOf(b.stateName);
          
      });
     break;
     case 'Material' :
      this.forecastData = this.forecastData
      .sort((a, b) => {
        if(IsDesc){
          if(a.productName.toLowerCase() > b.productName.toLowerCase()) { return -1; }
          if(a.productName.toLowerCase() < b.productName.toLowerCase()) { return 1; }
          return 0;
        }
        else
        {
          if(a.productName.toLowerCase() < b.productName.toLowerCase()) { return -1; }
          if(a.productName.toLowerCase() > b.productName.toLowerCase()) { return 1; }
          return 0;
        }
    });
     break;
     case 'Date Range' :
     
      this.forecastData = this.forecastData.sort((a, b) => {
        if(IsDesc)
        return  obj.GetMinDate(b.forecastItemDestination,IsDesc)- obj.GetMinDate(a.forecastItemDestination,IsDesc) ;
        else
        return  obj.GetMinDate(a.forecastItemDestination,IsDesc)- obj.GetMinDate(b.forecastItemDestination,IsDesc) ;
    });
     break;
     case 'Quantity' :
      this.forecastData = this.forecastData.sort((a, b) => {
        if(IsDesc)
          return  obj.getSumOfQuantity(b.forecastItemDestination) - obj.getSumOfQuantity(a.forecastItemDestination) ;
        else
          return  obj.getSumOfQuantity(a.forecastItemDestination) - obj.getSumOfQuantity(b.forecastItemDestination) ;
      });
     break;
     case 'Reviewer' :
       
      this.forecastData = this.forecastData.sort((a, b) => {
        if(IsDesc){
          if (a.reviewerLastName === null) {  return 1;  }
          if (b.reviewerLastName === null) {  return -1; }
          if( a.reviewerLastName.toLowerCase() > b.reviewerLastName.toLowerCase()) { return -1; }
          if(a.reviewerLastName.toLowerCase() < b.reviewerLastName.toLowerCase()) { return 1; }
          return 0;
        }
        else {
        if (a.reviewerLastName === null) {  return 1;  }
        if (b.reviewerLastName === null) {  return -1; }
        if( a.reviewerLastName.toLowerCase() < b.reviewerLastName.toLowerCase()) { return -1; }
        if(a.reviewerLastName.toLowerCase() > b.reviewerLastName.toLowerCase()) { return 1; }
        return 0;
      }
    });
     break;
     case 'Delegator' :
      this.forecastData = this.forecastData.sort((a, b) => {
        if(IsDesc){
          if (a.delegatorLastName === null) {  return 1;  }
          if (b.delegatorLastName === null) {  return -1; }
          if( a.delegatorLastName.toLowerCase() > b.delegatorLastName.toLowerCase()) { return -1; }
          if(a.delegatorLastName.toLowerCase() < b.delegatorLastName.toLowerCase()) { return 1; }
          return 0;
        }
        else {
          if (a.delegatorLastName === null) {  return 1;  }
          if (b.delegatorLastName === null) {  return -1; }
        if( a.delegatorLastName.toLowerCase() < b.delegatorLastName.toLowerCase()) { return -1; }
        if(a.delegatorLastName.toLowerCase() > b.delegatorLastName.toLowerCase()) { return 1; }
        return 0;
      }
    });
     break;
    default :
    this.forecastData = this.forecastData.sort((a, b) => {
      if(IsDesc)
      return sortOrder.indexOf(b.stateName) - sortOrder.indexOf(a.stateName);
      else
        return sortOrder.indexOf(a.stateName) - sortOrder.indexOf(b.stateName);
    });
    break;
    

  }
  }

  getSumOfQuantity(obj):number
  {
    var total = 0
    for ( var i = 0, _len = obj.length; i < _len; i++ ) {
        total += obj[i].quantity;
    }
    return total
  }
  GetMinDate(forecastItemDestination,IsDesc):number
  {
    
    var minDate;
    forecastItemDestination.forEach((itemDest: ForecastItemDestinationModel) => {
        
        if(minDate == undefined)
        {
          minDate = new Date(itemDest.dateNeeded)
        }
        else if(new Date(itemDest.dateNeeded) < minDate)
        {
          minDate = new Date(itemDest.dateNeeded)
        }
                
      }); 
      if(IsDesc)
      {
        if(minDate == undefined) return 0;
      }
        return minDate == undefined ?  Number.MAX_SAFE_INTEGER : minDate.getTime() ; 
     
  }

  public getTitleStyle(forecastItemModel:any) {
    switch (forecastItemModel.stateName)
    {
      case  this.commonService.configurations.ForecastStatus.DataEntry :  return 'clsDataEntryPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.AwaitingReview :  return 'clsAwaitingReviewPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.Submitted :  return 'clsSubmittedPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.InProcess :  return 'clsInProcessPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.Completed : return 'clsCompletedPanelTitleBar' ; break ;
      case  this.commonService.configurations.ForecastStatus.DelegateReview: return 'clsDelegateReviewPanelTitleBar' ; break ;
      case this.commonService.configurations.ForecastStatus.Procurement : return 'clsProcurementPanelTitleBar' ; break ;
      case this.commonService.configurations.ForecastStatus.Archived : return 'clsArchivedPanelTitleBar' ; break ;
      case this.commonService.configurations.ForecastStatus.MaterialApproval : return 'clsMaterialApprovaPanelTitleBar' ; break ;
    }
    return '';
  }

  GetDaterange(forecastItemModel: ForecastItemModel):string
  {
    var maxDate; 
    var minDate;
    this.forecastData.filter(x => x.id === forecastItemModel.id).forEach((elem) => {
      elem.forecastItemDestination.forEach((itemDest: ForecastItemDestinationModel) => {
        if(maxDate == undefined)
        {
          maxDate = new Date(itemDest.dateNeeded)
        }
        else if (new Date(itemDest.dateNeeded) > maxDate)
        {
          maxDate = new Date(itemDest.dateNeeded)
        }

        if(minDate == undefined)
        {
          minDate = new Date(itemDest.dateNeeded)
        }
        else if(new Date(itemDest.dateNeeded) < minDate)
        {
          minDate = new Date(itemDest.dateNeeded)
        }
      });
    });
    
    if(minDate == undefined && maxDate == undefined)
       return "";
    if(this.datepipe.transform(minDate , 'dd-MMM-yyyy') == this.datepipe.transform(maxDate , 'dd-MMM-yyyy'))
      return this.datepipe.transform(maxDate , 'dd-MMM-yyyy');
      
    return this.datepipe.transform(minDate , 'dd-MMM-yyyy') + " - " + this.datepipe.transform(maxDate , 'dd-MMM-yyyy');
  }
  groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }
  GetAggregatedQuantity(forecastItemModel: ForecastItemModel): string {
    let quantity = '0';
    let fm = this.forecastData.find(x => x.id === forecastItemModel.id);
    if (fm != null) {
      let byUom = this.groupBy(fm.forecastItemDestination, 'quantityUomName');
      let uoms = Object.entries(byUom).map(m => [m[0], (m[1] as ForecastItemDestinationModel[]).reduce((ty, u) => ty + u.quantity, 0.000)]);
      if (uoms != null && uoms.length > 0) {
        quantity = '';
        uoms.forEach(a => {
          quantity = quantity + (quantity.length > 0 ? ' + ' : '') + parseFloat(a[1].toString()).toFixed(3) + ' ' + a[0];
        });
      }
  
    }
    return quantity;
  }
  clearFilterForecast(){
    this.ResetForm();
    this.selectedFilter = this.defaultFilter;
    (<UntypedFormControl>this.filterForm.controls['Name'])
     .setValue(this.selectedFilter.name , { onlySelf: true });
    this.filterForecast(false);
    this.customFilterObj = undefined;
 }
  DeleteForecast(forecast: ForecastItemModel) {
    const countries = forecast.forecastItemDestination.length;
    const ingredients =   forecast.forecastItemActiveIngredient.length;
    this.confirmationService.confirm({
      message: 'You are about to delete the forecast for ' + forecast.productName +
        '  which has ' + countries +' countries in '+ ingredients +
        ' active ingredients tied to it.  This action cannot be undone.  Are you sure you would like to remove it?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        const isMaterialPlanner = this.userInfo.roles.some(r => r.name === 'Material Planner');
        const presubmissionStates = ['Data Entry', 'Delegate Review', 'Awaiting Review'];

        if (presubmissionStates.some(s => s === forecast.stateName)) {
          this.forecastItemService.deleteForecast(forecast.id).subscribe(response => {
            this.ngOnInit();
            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted' });
          },
          error => {
            this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError,
              summary: '', detail: error.message });
          });
        } else {
          forecast.stateId = this.forecastState.find(fs => fs.stateName === 'Deleted').id;
          this.forecastItemClient.updateForecastItem(forecast.id, forecast).subscribe(data => {
            this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Forecast marked to Deleted state.' });
            forecast.stateName = 'Deleted';
            forecast.isDeletable = false;
          },
          error => {
            this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError,
              summary: '', detail: error.message });
        });
      }
      },
      reject: () => {
      }
    });
  }
  onAddForecast() {
    this.router.navigate(['forecastitem']);
  }
  onEditForecast(id: any) {
    this.router.navigate(['/forecastitemEdit', id]);
  }
  CopyForecast(id: any) {
    this.router.navigate(['/forecastitemCopy', id]);
  }
  onBypassForecast(id: any) {
    this.router.navigate(['/forecastitemEdit', id]);
  }
  GetFilterValues(searchCriteriaModel:SearchCriteriaModel)
  {
    var filterValue="";
    var filterText="";
    switch(searchCriteriaModel.criteriaName)
    {
      case "Material":
        filterValue = searchCriteriaModel.criteriaValue;
        filterText = "Material";
        break;
      case "Program":
        filterValue = searchCriteriaModel.criteriaValue;
        filterText = "Program";
        break;
        case "ForecastNumber":
          filterValue = searchCriteriaModel.criteriaValue;
          filterText = "Forecast Number";
          break;
      case "OrderBy":
        filterValue = searchCriteriaModel.criteriaValue;
        filterText = "Order By";
        break;
      case "Reviewer":
        filterValue = this.reviewers.find(r => r.id == parseInt(searchCriteriaModel.criteriaValue)).secondaryEmail;
        filterText = "Reviewer";
        break;
      case "Forecaster":
        filterValue = this.filteredForeaster.secondaryEmail;
        filterText = "Forecaster";
        break;
      case "status":
        filterValue = this.forecastState.find(s => s.id == parseInt(searchCriteriaModel.criteriaValue)).stateName;
        filterText = "Status";
        break;  
      case "country":
        filterValue = this.countryModels.find(ft => ft.id == parseInt(searchCriteriaModel.criteriaValue)).displayName;
        filterText = "Country";
        break;      
    }
    return filterText + " : " + filterValue;
  }
  AllowOnlyNumeric(e)
  {
    if (/^[0-9\s]*$/.test(e.key)) {
      return true;
      } else {
          e.preventDefault();
          return false;
      }
     
  }

  pageChanged(event: any) {
    this.pageIndex = (event.first / this.pageSize) + 1;
    console.log(this.pageIndex);
    if (this.customFilterObj !== undefined) {
      this.getFilteredForecastsItemsPaginated(this.customFilterObj, this.pageIndex, this.pageSize);
    } else {
      this.getForecastsItemsCommonMethod(this.pageIndex, this.pageSize);  
    }
  }

  getForecastsItemsCommonMethod(pageIndex: number, pageSize: number) {
    this.forecastItemClient.getPaginatedForecastItems(pageIndex, pageSize, this.userInfo.id)
    .subscribe((response: ForecastItemDTO) => {
            response.forecastItems = response.forecastItems.sort((a, b) => {
                return this.StatusSortAsc.indexOf(a.stateName) - this.StatusSortAsc.indexOf(b.stateName);
            });
            this.forecastData = response.forecastItems;
            this.forecastNonFilterData = response.forecastItems;
            this.totalItems =response.totalForecastItems;
            this.forecastData.forEach(element => {
              const isMaterialPlanner = this.userInfo.roles.some(r => r.name === 'Material Planner');
              const states = ['Data Entry', 'Delegate Review', 'Awaiting Review'];
              const presubmissionStates = ['Data Entry', 'Delegate Review', 'Awaiting Review'];
              const nonDeletableStates = ['Deleted', 'Archived', 'Expired', 'Completed','In Process','Cancelled'];
      //        console.log(element);
              element.isDeletable = !nonDeletableStates.some(s => s === element.stateName) && (isMaterialPlanner ||
                ((element.forecastItemDestination != null && !element.forecastItemDestination.some(b => b.inventoryOrderState !== null && b.inventoryOrderState.stateName !== 'To Do') &&
                (element.forecastingUserId === this.userInfo.id ||
                  (element.delegatorUserId !== null && element.delegatorUserId !== undefined && element.delegatorUserId === this.userInfo.id) ||
                  (element.reviewerUserId !== null && element.reviewerUserId !== undefined && element.reviewerUserId === this.userInfo.id)))));
      //        console.log(element);
            });
            if (response.currentPage === 1) {
              this.dataView.first = 1;
            }
          },
          error => {
            this.messageService.add({
              severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
              this.router.navigate(['login']);
          });
  }

  getFilteredForecastsItemsPaginated(searchObj: any, pageIndex: number, pageSize: number) {
    this.forecastItemClient.filterForecatItems(this.userInfo.id, pageIndex, pageSize, searchObj)
    .subscribe((response: ForecastItemDTO) => {
            this.forecastData = response.forecastItems;
            this.forecastNonFilterData = response.forecastItems;
            this.totalItems =response.totalForecastItems;
            this.forecastData.forEach(element => {
              const isMaterialPlanner = this.userInfo.roles.some(r => r.name === 'Material Planner');
              const states = ['Data Entry', 'Delegate Review', 'Awaiting Review'];
              const presubmissionStates = ['Data Entry', 'Delegate Review', 'Awaiting Review'];
              const nonDeletableStates = ['Deleted', 'Archived', 'Expired', 'Completed','In Process','Cancelled'];
      //        console.log(element);
              element.isDeletable = !nonDeletableStates.some(s => s === element.stateName) && (isMaterialPlanner ||
                ((element.forecastItemDestination != null && !element.forecastItemDestination.some(b => b.inventoryOrderState !== null && b.inventoryOrderState.stateName !== 'To Do') &&
                (element.forecastingUserId === this.userInfo.id ||
                  (element.delegatorUserId !== null && element.delegatorUserId !== undefined && element.delegatorUserId === this.userInfo.id) ||
                  (element.reviewerUserId !== null && element.reviewerUserId !== undefined && element.reviewerUserId === this.userInfo.id)))));
      //        console.log(element);
            });
            if (response.currentPage === 1) {
              this.dataView.first = 1;
            }
          },
          error => {
            this.messageService.add({
              severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
              this.router.navigate(['login']);
          });
  }
}
