import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryClient, FormulationTypesClient, OrderStatusClient, ProcurementClient, QuantityUomClient, UnitOfMeasureClient,SampleInventoryLocationClient, ConcentrationUomClient } from '../forecast-api-client.service';
@Injectable()
export class ProcurementDetailsAddResolver implements Resolve<Observable<any>> {
    constructor(
        private orderStatusClient: OrderStatusClient,
        private quantityUomClient: QuantityUomClient, 
        private formulationTypeClient: FormulationTypesClient,
        private countryClient: CountryClient,
        private procurementClient: ProcurementClient, 
        private sampleInventoryLocation:SampleInventoryLocationClient,
        private cuomService: ConcentrationUomClient,
        private sourcingUnitOfMeasure:UnitOfMeasureClient ){}

    public resolve() : Observable<any> {
         
        return forkJoin(
            this.orderStatusClient.getOrderStatus(), 
            this.formulationTypeClient.get2(true),
            this.quantityUomClient.get3(true),
            this.countryClient.getCountries(),
            this.sourcingUnitOfMeasure.getUnitOfMeasures(true),
            this.sampleInventoryLocation.getSampleInventoryLocations(),
            this.cuomService.get(true) 
        ).pipe(
            map(([orderStatus,formulationTypes,uom,countries,sourcingUnitOfMeasure,sampleInventoryLocations,cUom]) =>
            {
                const defaultOrderBy = ['New', 'Follow-up', 'Ordered', 'Received', 'In Inventory', 'Partial Receipt'];
                orderStatus = orderStatus.sort((a, b) => {
                    return defaultOrderBy.indexOf(a.status) - defaultOrderBy.indexOf(b.status);
                });
                return {orderStatus, formulationTypes, uom, countries,sourcingUnitOfMeasure, sampleInventoryLocations,cUom};
            }
            )
        )
    }
}
