import { Component, OnInit } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { SecurityService } from '../services/security.service';
import { ForecastItemService } from '../services/forecastItem.service';
import { MessageService } from 'primeng/api';
import { CommonService } from '../services/common.service';
import { AppComponent } from '../app.component';
import { UserClient, UserModel} from '../services/forecast-api-client.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ SecurityService,MessageService,ForecastItemService]
})
export class DashboardComponent implements OnInit {
  userName:string;
  userRoles:string;
  chartData: any;
  chartBackgroundColors:any = [
    "#0072ce",
    "#fc4c02",
    "#46237a",
     "#d33f49",
    "#00bf67",
     "#0a2342",
     "#f3b700",
     "#1be7ff"
   ];
   
  userInfo:UserModel ;
  datLoaded:boolean = false;
  options:any = {     
                  legend: {
                    display: false
                  }
                };
  forecastState:any;     


  constructor(private forecastItemService: ForecastItemService,private messageService: MessageService,private commonService:CommonService, 
    private appComponent: AppComponent,
    private securityService:SecurityService) {
        this.chartData = {
            labels: ['Data Entry', 'Awaiting Review', 'Submitted', 'In Process', 'Completed', 'Delegate Review', 'Procurement', 'Archived'],
            datasets: [
                {
                    data: [0,0,0,0,0,0,0,0],
                    backgroundColor: this.chartBackgroundColors,
                    hoverBackgroundColor: this.chartBackgroundColors
                }]    
            };
  }

  ngOnInit() {
    this.GetuserInfo();
    this.appComponent.currentSelection = 'dashboard';
  }
GetuserInfo()
{
  this.userInfo = this.securityService.getUser();
  if(this.userInfo === null)
  {
    this.securityService.validateUser(localStorage.getItem("loggedInUsername"))
        .subscribe(data => {this.userInfo = data;
          this.userName = this.userInfo.firstName + " " + this.userInfo.lastName;
          this.userRoles = this.userInfo.roles.map(r => r.name).join(', ');
          this.GetDashboardData();})
          
  }
  else
  {
    this.GetDashboardData();
  }
   
}
 
GetDashboardData(){
  this.forecastItemService.getDashboardDate(this.userInfo.id).subscribe(response => {   
    this.forecastState = response;   
      response.forEach(element => {
        switch(element.stateName)
        {
          case  this.commonService.configurations.ForecastStatus.DataEntry :  this.chartData.datasets[0].data[0] = element.count ; break ;
          case  this.commonService.configurations.ForecastStatus.AwaitingReview :  this.chartData.datasets[0].data[1] = element.count ; break ;
          case  this.commonService.configurations.ForecastStatus.Submitted :  this.chartData.datasets[0].data[2]= element.count ; break ;
          case  this.commonService.configurations.ForecastStatus.InProcess :  this.chartData.datasets[0].data[3] = element.count ; break ;
          case  this.commonService.configurations.ForecastStatus.Completed :  this.chartData.datasets[0].data[4] = element.count ; break ;
          case  this.commonService.configurations.ForecastStatus.DelegateReview:  this.chartData.datasets[0].data[5] = element.count ; break ; 
          case this.commonService.configurations.ForecastStatus.Procurement :  this.chartData.datasets[0].data[6] = element.count ; break ; 
          case this.commonService.configurations.ForecastStatus.Archived :  this.chartData.datasets[0].data[7] = element.count ; break ; 
        }
        this.datLoaded = true;
      });
    },
    error => {
        this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
    },
    () => {  
     // this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: "Success" });

    } );
}
   

}
