import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { ConfigurationSettings } from '../configuration-settings';
import { catchError, map, tap } from 'rxjs/operators';
import { UserClient, JobFunction, UserModel } from './forecast-api-client.service';

@Injectable()
export class SecurityService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private options = { headers: this.headers };
    constructor(private _http: HttpClient, private userClient: UserClient) { }
    /* Validating User */
    public validateUser(username: string): any {
        //let url = ConfigurationSettings.REST_API_URL + "/api/user/email/" + username;

        //return this._http.get(url)
        //    .pipe(
        //        tap(x => console.log("Validated User")));
        var user = this.userClient.getByEmailId(username);
        return user;
    }

    public getUser(): UserModel {
        if (localStorage['loggedInUserModel']) {
            return UserModel.fromJS(JSON.parse(localStorage['loggedInUserModel']));
        };
        return null;
    }
}
