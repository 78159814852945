<div >
  <p-dataView #dv id="ForecastDestinations" [value]="ForecastDestinations"   layout="grid"
    styleClass="hover">
    <p-header style="border:none; height:50px;background-color: #f4f4f4;" id="ForecastDestinationsHeader">
      <div class="md:col-12" >
        <button pButton type="button" id="btnAddForecastLocation" label="Add Forecast Location"
        icon="fa fa-plus" iconPos="right" (click)="OpenPopup()"></button>
      </div>
    </p-header>
      <ng-template let-forecastLocationItem pTemplate="gridItem" id="templateForecastDestinations"  *ngIf="ForecastDestinations">
          <div style="padding:.5em" class="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-3 resShadow" >
              <p-panel [styleClass]="getTitleStyle(forecastLocationItem)" id="{{forecastLocationItem.id}}" [style]="{'text-align':'left'}">
                <ng-template pTemplate="header" style="width: 100%;padding: unset !important;">
                    <div class="grid" style="margin-top: 0.1rem !important;width: 100%;margin-left:0px !important; ">
                      <div class="col-7">
                        <span class="form-panel-subheader">RL-{{forecastLocationItem.id}} </span>
                      </div>
                      <div class="col-5">
                        <span aria-hidden="true" [title]="'Delete'" class="p-button-icon-left p-clickable fa fa-trash clsTrash" [ngClass]="{'btnDisabled': shouldLocationDeleteBeDisabled(forecastLocationItem)||forecastLocationItem.inventoryOrderStateName==='Outsourced'||forecastLocationItem.inventoryOrderStateName==='Cancelled'}" id="{{'ForecastLocationDelete' + forecastLocationItem.id}}" (click)="DeleteForecastLocation(forecastLocationItem)"></span>
                        <span aria-hidden="true" [title]="'Edit'" class="p-button-icon-left p-clickable fa fa-pencil clsEdit" [ngClass]="{'btnDisabled': forecastLocationItem.inventoryOrderStateName==='Outsourced'||forecastLocationItem.inventoryOrderStateName==='Cancelled'}" (click)="OpenPopup(forecastLocationItem)" id="{{'ForecastLocationEdit' + forecastLocationItem.id}}"></span>
                        <span aria-hidden="true" [title]="'Copy'" class="p-button-icon-left p-clickable fa fa-clone clsClone"  [class.disabled]="disableActionButtons" (click)="OpenPopup(forecastLocationItem, true)" id="{{'ForecastLocationCopy' + forecastLocationItem.id}}"></span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="content">
                  <div style="height: 225px;overflow-y: auto" id="{{'forecastItem' + forecastLocationItem.id}}" (click)="OpenPopup(forecastLocationItem)">
                      <div class="forecastItem-detail"><span class="form-label-header">Country: </span><span
                              class="form-label-content" id="{{'Country' + forecastLocationItem.id}}" >{{forecastLocationItem.destinationCountryName}}</span></div>
                              <div class="forecastItem-detail"><span class="form-label-header">Quantity: </span><span
                                class="form-label-content" id="{{'quantity' + forecastLocationItem.id}}" >{{forecastLocationItem.quantity}} {{forecastLocationItem.quantityUomName}}</span></div>
                      <div class="forecastItem-detail"><span class="form-label-header">Date Needed: </span><span style="font-size:11px;"
                              id="{{'dateNeeded' + forecastLocationItem.id}}" >{{forecastLocationItem.dateNeeded | date: 'dd-MMM-yyyy'}}</span><span style="font-size:11px;"> - {{forecastLocationItem.dateNeededLatest | date: 'dd-MMM-yyyy'}}</span></div>
                                  <div class="forecastItem-detail" style="clear: both;"><span class="form-label-header">Program No: </span><span
                                    class="form-label-content" id="{{'programNumber' + forecastLocationItem.id}}" >{{forecastLocationItem.programNumber}}</span></div>
                                  <div class="forecastItem-detail"><span class="form-label-header">Wh Loc: </span><span
                                    class="form-label-content" id="{{'whLoc' + forecastLocationItem.id}}" >{{ getWarehouseLocation(forecastLocationItem.inventoryLocationId, forecastLocationItem.forecastTypeId)}}</span></div>
                                    <div class="forecastItem-detail"><span class="form-label-header">Lot: </span><span
                                      class="form-label-content" id="{{'lotNumber' + forecastLocationItem.id}}" >{{forecastLocationItem.lotNumber}}</span></div>
                                      <div class="forecastItem-detail"><span class="form-label-header">Manufacturer: </span><span
                                      class="form-label-content" id="{{'manufacturer' + forecastLocationItem.id}}" >{{forecastLocationItem.manufacturer}} </span></div>
                                      <div class="forecastItem-detail"><span class="form-label-header">Labels: </span><span
                                        class="form-label-content" id="{{'label' + forecastLocationItem.id}}"  [pTooltip]="forecastLocationItem.raceLabels?.replaceAll('||','\r\n')">
                                        {{(forecastLocationItem.raceLabels && forecastLocationItem.raceLabels.length > 20) ? forecastLocationItem.raceLabels.substring(0,20)?.replaceAll('||',', ') +'...': forecastLocationItem.raceLabels}} 
                                      </span></div>
                              <div class="forecastItem-detail"><span class="form-label-header">Status: </span><span
                                class="form-label-content" id="{{'Status' + forecastLocationItem.id}}" >{{forecastLocationItem.inventoryOrderStateName}}</span></div>
                                <div class="forecastItem-detail"><span class="form-label-header">Requester(s): </span>
                                  <div class="form-label-content" style="display: inline-grid;">
                                  <span *ngFor="let forecastItemDestinationRequester of forecastLocationItem.forecastItemDestinationRequester"
                                   id="{{'SampleRequester' + forecastLocationItem.id}}" >{{forecastItemDestinationRequester.requestingUserName}}</span></div></div>

                  </div>
                  </ng-template>
              </p-panel>
          </div>
    </ng-template>
  </p-dataView>
</div>

<p-toast></p-toast>


<p-dialog id="dialogAddLocation"  header="Add Location" [(visible)]="displayDialog"
  [styleClass]="'clsDialogAddLocationStyle'" [draggable]="true"
  [style.minWidth]="'670px'" [style.minHeight]="'630px'" [modal]="true"
    [dismissableMask]="true">
    <form [style.minHeight]="'580px'" [formGroup]="forecastDestinationForm" id="formForecastDestination" >
      <div class="grid" hidden>
        <div class="md:col-3"><label for="Id">Id</label>
          <input type="hidden" id="hdnForecastLocationId" formControlName="Id">
          <input type="hidden"  id="hdnUOMId" formControlName="UOMId">
          <input type="hidden" id="hdnTrackingId" formControlName="trackingId">

        </div>
        <div class="md:col-9"></div>

      </div>
      <div class="grid">
        <div class="md:col-3"><label for="country" style="font-weight: bold;">*Country</label></div>
        <div class="md:col-9">
          <p-autoComplete  id="autoCompleteDestinationCountry" [suggestions]="filteredCountriesSingle"
          (completeMethod)="filterCountrySingle($event)" (onSelect)="countrySelect($event)"
          field="displayName" [size]="30"  formControlName="country"
            placeholder="Destination Country" [minLength]="1"   [disabled]="disableControls || disableCountry">
          </p-autoComplete>
        </div>
      </div>
      <div class="grid">
        <div class="md:col-3"><label for="quantity" style="font-weight: bold;">*Quantity</label></div>
        <div class="md:col-9">
          <div class="grid">
            <div class="md:col-4">
              <input type="text" id="numberDestinationQuantity" min="1"  [attr.disabled]="disableControls ? '' : null" required formControlName="quantity" pInputText class="ui-inputtext"  >
            </div>
            <div class="md:col-8" >
              <p-selectButton formControlName="QuantityUom" [disabled]="!canUomBeChanged()" id="selectForecastLocationQuantityUOM" [options]="quantUoms"
              optionLabel="uomDisplay"></p-selectButton>
            </div>
          </div>
          <div *ngIf="!forecastDestinationForm.controls['quantity'].valid&&forecastDestinationForm.controls['quantity'].dirty">
            <p-message severity="error" *ngIf="forecastDestinationForm.get('quantity').hasError('required')" text="Quantity is required" ></p-message>
            <p-message severity="error" *ngIf="forecastDestinationForm.get('quantity').errors.min"  text="Quantity should be greater than 0.0001" ></p-message>
            <p-message severity="error" *ngIf="forecastDestinationForm.get('quantity').errors.max"  text="Quantity should not exceed existing quantity" ></p-message>
            <p-message severity="error" *ngIf="forecastDestinationForm.controls['quantity'].errors?.pattern"  text="Quantity is invalid" ></p-message>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="md:col-3"><label for="DateNeeded" style="font-weight: bold;"><span *ngIf='!showDateNeededRange'>*Date Needed</span><span *ngIf='showDateNeededRange'>*Earliest Date Needed <sup><i class="fa fa-info-circle" [title]="commonService.configurations.EarliestDateNeededCaption"></i></sup></span></label></div>
        <div class="md:col-9"> <p-calendar id="calendarDestinationDateNeeded"  name="DateNeeded" formControlName="dateNeeded"
          [minDate]="minDate" [inputStyle]="{'width':'245px'}" [defaultDate]="minDate" [showIcon]="true"  dateFormat="dd-M-yy" [disabled]="disableControls"
         ></p-calendar>
         <p-message severity="error" text="Needed Date is required" *ngIf="!forecastDestinationForm.controls['dateNeeded'].valid&&forecastDestinationForm.controls['dateNeeded'].dirty"></p-message>

      </div>
    </div>
    <div class="grid">
      <div class="md:col-3"><label for="DateNeededLatest" style="font-weight: bold;"><span>*Latest Date Needed <sup><i class="fa fa-info-circle" [title]="commonService.configurations.LatestDateNeededCaption"></i></sup></span></label></div>
      <div class="md:col-9"> <p-calendar id="calendarDestinationDateNeeded2"  name="DateNeededLatest" formControlName="dateNeededLatest"
        [minDate]="minDate2" [maxDate]="maxDate" [inputStyle]="{'width':'245px'}" [defaultDate]="dateFiveMonths" [showIcon]="true"  dateFormat="dd-M-yy" [disabled]="disableControls"
       ></p-calendar>
       <p-message severity="error" text="Latest Date Needed is required" *ngIf="!forecastDestinationForm.controls['dateNeededLatest'].valid&&forecastDestinationForm.controls['dateNeededLatest'].dirty"></p-message>
      </div>
  </div>
      <div class="grid">
        <div class="md:col-3"><label for="ProgramNo">Program No</label></div>
        <div class="md:col-9">
          <input type="text" style="width:245px;" formControlName="programNumber"  [attr.disabled]="disableControls ? '' : null"
          pInputText class="ui-inputtext" id="numberDestinationProgramNumber" >
            <p-message severity="error"
            *ngIf="((forecastDestinationForm.controls['programNumber'].touched) && (forecastDestinationForm.controls['programNumber'].errors?.pattern))"
         text="Program number is Invalid"></p-message>
        </div>
      </div>
      <div class="grid">
        <div class="md:col-3"><label for="LotNumber">Lot</label></div>
        <div class="md:col-9">
          <input style="width:245px;" oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
           type="text" formControlName="lotNumber"  [attr.disabled]="disableControls ? '' : null"
          pInputText class="ui-inputtext" id="lotNumber" >
            <p-message severity="error"
            *ngIf="((forecastDestinationForm.controls['lotNumber'].touched) && (forecastDestinationForm.controls['lotNumber'].errors?.pattern))"
         text="Lot number is Invalid"></p-message>
        </div>
      </div>
      <div class="grid" *ngIf="false">
        <div class="md:col-3"><label for="PackSize">Pack Size</label></div>
        <div class="md:col-9">
          <div class="grid">
            <div class="md:col-4">
              <input type="text" id="numberDestinationPackageSize"  [attr.disabled]="disableControls ? '' : null" formControlName="packSizeValue"
              pInputText class="ui-inputtext">
            </div>
            <div class="md:col-8" >
              <p-selectButton formControlName="PackageQuantityUom"  [disabled]="disableControls" id="selectForecastLocationPackageQuantityUOM" [options]="quantUoms"
              optionLabel="uomDisplay"></p-selectButton>
            </div>
          </div>
            <p-message severity="error"
            *ngIf="((forecastDestinationForm.controls['packSizeValue'].touched) && (forecastDestinationForm.controls['packSizeValue'].errors?.pattern))"
          text="Pack Size is Invalid"></p-message>
         </div>
      </div>
      <div class="grid">
          <div class="md:col-3"><label for="Manufacturer">Manufacturer</label></div>
          <div class="md:col-9">
            <input type="text" style="width:245px;" id="txtDestinationManufacturer"  [attr.disabled]="disableControls ? '' : null" formControlName="manufacturer"
            pInputText class="ui-inputtext">
          </div>

        </div>
        <div  class="grid">
          <div class="md:col-3"><label for="txtDestinationLabels">Labels</label></div>
          <div class="md:col-9" [pTooltip]="forecastLocationModel.raceLabels && forecastLocationModel.raceLabels?.replaceAll('||','\r\n')" 
                [tooltipStyleClass]="'customClass'" style="width:245px;">
            <input type="text" style="width:245px; text-overflow: ellipsis;" id="txtDestinationLabels" 
            [attr.readonly]="true" formControlName="raceLabels"
            pInputText class="ui-inputtext">
          </div>
        </div>
        <div class="grid">
          <div class="md:col-3"><label for="SampleRequester">Sample Requester</label></div>
          <div class="md:col-9">
            <p-autoComplete id="txtDestinationSampleRequesterName" formControlName="sampleRequesterName"
            [suggestions]="filteredUsers" (completeMethod)="FilterUser($event)" inputStyle="height: 30px;"
            field="secondaryEmail"  [disabled]="disableControls || isBypassInventory" [size]="30" (onSelect)="SampleRequesterSelected($event)" [(ngModel)]="selectedUser">
            </p-autoComplete>

          </div>

        </div>
        <div class="grid">
          <div class="md:col-3"> </div>
          <div class="md:col-9" style="display: contents;">
        <div class="clsFilterDiv" *ngFor="let sampleRequester of sampleRequesters">
          {{ sampleRequester.secondaryEmail }}
          <span aria-hidden="true" [title]="'Delete'" class="p-button-icon-left p-clickable fa fa-trash clsTrash"   (click)="DeleteSampleRequester(sampleRequester)" style="margin-left: 3px;"></span>
       </div>
      </div>

    </div>
    </form>
  <p-footer>

    <button type="button" pButton icon="fa fa-check" id="btnAddPopupForecastLocation" [disabled]="!forecastDestinationForm.valid" (click)="Save()" label="Save"   ></button>
    <!-- <button type="button" pButton icon="fa fa-save" (click)="update()" *ngIf="isEditable" label="Update"></button> -->
    <!--<button type="button" pButton icon="fa-remove" (click)="Delete()" [hidden]="!isEditable" label="Delete"></button>-->
    <!--This delete moved into grid column beside edit button.-->
    <button type="button" pButton icon="fa fa-close" id="btnCancelPopupForecastLocation" (click)="cancel()" label="Cancel"></button>
  </p-footer>
</p-dialog>
