import { Component, OnInit } from '@angular/core';
import { CountryClient, CountryModel } from '../services/forecast-api-client.service';
import { UserInfoService, RequestorModel } from '../services/user-info.service';
import { CheckboxCellRendererComponent } from '../custom/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { concatMap } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
  providers: [UserInfoService, ConfirmationService, MessageService]
})
export class CountryComponent implements OnInit {
  public context;
  public requestor: RequestorModel;
  public countryModels : any[];
  public displayDialog: boolean = false;
  public isEditable: boolean = false;
  public country = new CountryModel();
  public columnDefs = [
    // {headerName: 'ID', field: 'id', sortable: true, filter: true},
    {headerName: 'Country', field: 'displayName', sortable: true, filter: true, width: 300},
    {headerName: 'Code', field: 'countryCode', sortable: true, filter: true, width: 130},
    {headerName: 'Is Prohibited', 
     field: 'isProhibited', 
     sortable: true, 
     filter: true,
     cellRenderer: 'checkboxCellRenderer',
     width: 140
    }
  ];
  public frameworkComponents = {
    checkboxCellRenderer: CheckboxCellRendererComponent
  };

  constructor(public countryClient: CountryClient,
    public userInfoService: UserInfoService,
    private appComponent: AppComponent,
    private confirmationService: ConfirmationService,
    private messageService: MessageService)
  {
    this.context = { componentParent: this };
  }

  ngOnInit() {
    this.appComponent.currentSelection = 'country';
    this.loadData();
    // this.requestor = this.userInfoService.getRequestor();
  }
  public  loadData() {
    this.countryClient.getCountries()
    .subscribe(data => {
      console.log(data);
      this.countryModels = data;
    },
    error => {
      console.log(error);
    });
  }
  public  showDialog() {
    this.country = new CountryModel();
    this.displayDialog = true;
  }
  public  save() {
    console.log(this.country);
    this.createToDb(this.country);
    this.displayDialog = false;
  }
  public  createToDb(country) {
    const createCountry = this.countryClient.postCountry(country)
    .pipe (
      concatMap(result => {
        return this.countryClient.getCountries();
      })
    );
    createCountry.subscribe((data) => {
      this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
      this.countryModels = data;
    },
    error => {
      console.log(error);
    });
  }
  public  cancel() {
    this.displayDialog = false;
  }
  public checkBoxUpdated(country: CountryModel) {
    console.log(country);
    const updateCountry = this.countryClient.putCountry(country.id, country)
    .pipe (
      concatMap(result => {
        return this.countryClient.getCountries();
      })
    );
    updateCountry.subscribe((data) => {
      this.messageService.add({ severity: 'success', summary: '', detail: 'Data saved successfully' })
      this.countryModels = data;
    },
    error => {
      console.log(error);
    });
  }
}
