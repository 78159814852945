import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountryClient, FormulationTypesClient, OrderStatusClient, ProcurementClient, QuantityUomClient, UnitOfMeasureClient,SampleInventoryLocationClient, ConcentrationUomClient, UserModel } from '../forecast-api-client.service';
@Injectable()
export class ProcurementDetailsResolver implements Resolve<Observable<any>> {
    constructor(
        private orderStatusClient: OrderStatusClient,
        private quantityUomClient: QuantityUomClient, 
        private formulationTypeClient: FormulationTypesClient,
        private countryClient: CountryClient,
        private procurementClient: ProcurementClient, 
        private sampleInventoryLocation:SampleInventoryLocationClient,
        private cuomService: ConcentrationUomClient,
        private sourcingUnitOfMeasure:UnitOfMeasureClient ){}

    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) : Observable<any> {
        let id = route.params['id'];
        const openCountryPopup: boolean = (route.params['popupCountry']).toLowerCase() === 'true';
        return forkJoin(
            this.orderStatusClient.getOrderStatus(), 
            this.formulationTypeClient.get2(true),
            this.quantityUomClient.get3(true),
            this.countryClient.getCountries(),
            this.sourcingUnitOfMeasure.getUnitOfMeasures(true),
            this.sampleInventoryLocation.getSampleInventoryLocations(),
            this.cuomService.get(true),
            this.procurementClient.getOrderItem(id)
        ).pipe(
            map(([orderStatus,formulationTypes,uom,countries,sourcingUnitOfMeasure,sampleInventoryLocations,
                cUom,procurementDetails]) =>
            {
                const defaultOrderBy = ['New', 'Follow-up', 'Ordered', 'Received','Partial Receipt', 'In Inventory',"Cancelled", "Outsourced"];
                orderStatus = orderStatus.sort((a, b) => {
                    return defaultOrderBy.indexOf(a.status) - defaultOrderBy.indexOf(b.status);
                });
                procurementDetails.orderItemComment = procurementDetails.orderItemComment
                                                        .sort((a, b) => b.enteredAt.getTime() - a.enteredAt.getTime());
                return {orderStatus, formulationTypes, uom, countries,sourcingUnitOfMeasure, sampleInventoryLocations,
                    cUom,procurementDetails,openCountryPopup};
            }
            )
        )
    }
}
