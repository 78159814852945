import { Component, OnInit, Renderer2 } from '@angular/core';
import { CheckboxCellRendererComponent } from '../custom/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { QuantityUomService } from '../services/quantityuom.service';
import { QuantityUomModel } from '../models/quantityuom';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../loading/res-loading.service';
import {CommonService} from '../services/common.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-quantity-uom',
  templateUrl: './quantity-uom.component.html',
  styleUrls: ['./quantity-uom.component.scss'],
  providers: [QuantityUomService, MessageService]
})
export class QuantityUomComponent implements OnInit {
  public context;
  public gridApi;
  public gridColumnApi;
  public quantityUomModel: QuantityUomModel = new QuantityUomModel();
  public displayDialog: boolean = false; // Responsible for opening/Closing popup
  public IsEdit: boolean = false; // To differenciate API Calls
  public IsCheckboxClicked: boolean = false; // to differenciate row click and checkbox click event
  public headerText: string = ''; // Popup header text
  public columnDefs = [
    {headerName: 'Abbreviation', field: 'uomDisplay', sortable: true, filter: true, width: 150},
    {headerName: 'Description', field: 'description', sortable: true, filter: true, width: 260},
    { headerName: 'Is Enabled', field: 'isEnabled', sortable: true,filter: true, cellRenderer: 'checkboxCellRenderer', width: 130 }
  ];
  public frameworkComponents = {
    checkboxCellRenderer: CheckboxCellRendererComponent
  };

  public rowData: Array<QuantityUomModel>;

constructor(public quoms: QuantityUomService,
  public appComponent: AppComponent,
  public messageService: MessageService,public renderer:Renderer2,public commonService:CommonService) {
              this.context = { componentParent: this };
  }

  ngOnInit() {
    //Get list of Quantity UoMs
    this.appComponent.currentSelection = 'quantityuom';
    this.quoms.getQuantityUoms().subscribe(response => this.rowData = response,
      error => {
          this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
      },
      () => { console.log(this.rowData); } );
}
/// <summary>
/// popup close event
/// </summary>
public cancel() {
  this.displayDialog = false;
}
/// <summary>
/// event to open popup to add concentration QoM
/// </summary>
public CreateUOMQuantity() {
  this.IsEdit = false;
  this.quantityUomModel = new QuantityUomModel();
  this.quantityUomModel.isEnabled= false;
  this.headerText =this.commonService.configurations.QuantityUOMAddPopupHeader;
  this.displayDialog = true;
  setTimeout(() => {
    this.renderer.selectRootElement("#UomDisplay").focus();
  }, 20);
}
/// <summary>
/// grid row click event
/// </summary>
public EditUOMQuantity(event: any) { 
   if(!this.IsCheckboxClicked){
  this.IsEdit = true;
  this.quantityUomModel.id = event.data.Id;
  this.quantityUomModel.description = event.data.Description;
  this.quantityUomModel.uomDisplay = event.data.UomDisplay;
  this.quantityUomModel.isEnabled = event.data.IsEnabled;
  this.headerText =this.commonService.configurations.QuantityUOMEditPopupHeader;
  this.displayDialog = true;
  setTimeout(() => {
    this.renderer.selectRootElement("#UomDisplay").focus();
  }, 20);
   }
   this.IsCheckboxClicked= false;
 }
 /// <summary>
/// Add or update Quantity QoM
/// </summary>
public save()
{
  
  if(!this.IsEdit){
    this.quoms.checkDuplicate(this.quantityUomModel).subscribe(res => {

    if(res == false){
        this.quoms.createQuantityUom(this.quantityUomModel).subscribe(res => console.log(res),
          error => {
              console.log(error);
              this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message })
          },
          () => {
              this.messageService.add({ severity:this.commonService.configurations.MessageServiceSeveritySuccess, summary: '', detail: this.commonService.configurations.DataSaved })
              this.ngOnInit();
              this.displayDialog = false;
          });
      }
      else
      {
        this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: this.commonService.configurations.DuplicateAbbreviation })
      }
  });

  }
  else
  {    
    this.updateQuantityUom(this.quantityUomModel);
  }
}
 /// <summary>
/// Is Enabled Checkbox click event
/// </summary>
public checkBoxUpdated(quantityUomModel: QuantityUomModel) {
  this.IsCheckboxClicked = true;
   this.updateQuantityUom(quantityUomModel);
  }

/// <summary>
/// Update Quantity QoM API calls
/// </summary>
public updateQuantityUom(quantityUomModel:QuantityUomModel)
{
  this.quoms.updateQuantityUom(quantityUomModel).subscribe(res => console.log(res),
  error => {
      console.log(error);
      this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message })

  },
  () => {
      this.messageService.add({ severity: this.commonService.configurations.MessageServiceSeveritySuccess, summary: '', detail:  this.commonService.configurations.DataSaved})
      this.ngOnInit();
      this.displayDialog = false;
  });
}

  
public onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
}
