<ng-container *ngIf="showLoggedInMessage">
  <div class="grid">
    <div class="col-3"></div>
    <div class="col-6 text-center">
      <p-card class="loggedIn">
        <h2>Login Successful... Redirecting...</h2>
      </p-card>
    </div>
    <div class="col-3"></div>
  </div>
</ng-container>
