import { ForecastTypeClient, SampleInventoryLocationClient, SampleInventoryLocationModel } from './../services/forecast-api-client.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import {MenuItem} from 'primeng/api';
import {SelectItem} from 'primeng/api';
import {SelectItemGroup, ConfirmationService} from 'primeng/api';
import { CommonService } from '../services/common.service';
import { ForecastlocationService } from '../services/forecastlocation.service';
import { ForecastLocationModel } from '../models/forecastLocation.model';
import { CountryClient, ForecastItemDestinationModel, ForecastItemDestinationRequesterModel,
   UserClient,ForecastItemDestinationClient, ForecastItemModel, UserModel, InventoryStateClient, InventoryStateModel, ForecastTypeModel} from '../services/forecast-api-client.service';
import { UntypedFormControl, UntypedFormBuilder, Validators, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ReadVarExpr } from '@angular/compiler';
import { RaceLabelsClient } from '../services/gsscommon-api-client.service';

@Component({
  selector: 'app-forecast-location',
  templateUrl: './forecast-location.component.html',
  styleUrls: ['./forecast-location.component.scss'],
  providers: [ MessageService,CommonService,ForecastlocationService]
})
export class ForecastLocationComponent implements OnInit {
  selectedUser:UserModel;
  disableControls:boolean =false;
  disableCountry:boolean =false;
  @Input() ForecastItem: ForecastItemModel;
  @Input() ForecastDestinations:Array<ForecastItemDestinationModel>;
  @Input() UserInfo: UserModel;
  @Input() disableActionButtons:boolean;
  @Output() AddDestination =  new EventEmitter<ForecastItemDestinationModel>();
  @Output() UpdateDestination = new EventEmitter<any>();
  @Output() RemoveDestination = new EventEmitter<any>();
  @Input() quantUoms:any ;
  @Input() isForecastCopy:boolean;
  @Input() selectedquantUomId:number;
  @Input() forecastStatus:string;
  @Input() showDateNeededRange:boolean;
  @Input() isBypassInventory:boolean;
  UOM:string ;
    forecastDestinationForm: UntypedFormGroup;
  displayDialog:boolean = false;
  oldItem:ForecastItemDestinationModel = new ForecastItemDestinationModel();
  countryModels:any;
  public defaultMinDate = new Date();
  public minDate = new Date();
  public minDate2 =new Date();
  public maxDate = this.addDays(365);
  public dateFiveMonths = new Date();
  protected DEFAULT_GLOBAL_AREA = 'DEFAULT GLOBAL AREA';
  forecastLocationModel:ForecastItemDestinationModel = new ForecastItemDestinationModel();
  forecastLocationData:Array<ForecastItemDestinationModel>;
  isEdit:boolean = false;
  disabledDates =[];
  filteredCountriesSingle: any[];
  filteredUsers:UserModel[];
  users:UserModel[];
  sampleRequesters:UserModel[];
  public sampleInventoryLocations: SampleInventoryLocationModel[] = [];
  public forecastTypes: ForecastTypeModel[] = [];
  public inventoryStates: Array<InventoryStateModel>;
  public toDoState: InventoryStateModel;
  public selectedRaceLabel:string;
  constructor(public datepipe: DatePipe,public fb: UntypedFormBuilder,
    public confirmationService:ConfirmationService ,
    public messageService: MessageService,
    public commonService:CommonService,
    public forecastlocationService:ForecastlocationService,
    public inventoryStateClient: InventoryStateClient,
    public userClient: UserClient,
    public countryClient: CountryClient,
    public forecastItemDestinationClient:ForecastItemDestinationClient,
    public sampleInventoryLocation: SampleInventoryLocationClient,
    public raceLabelsClient: RaceLabelsClient,
    public forecastTypeClient: ForecastTypeClient){
    this.Getform();
  }

ngOnChanges()
{
  console.log(this.ForecastDestinations);
  this.UOM =  this.quantUoms.find(uom => uom.id === this.selectedquantUomId).uomDisplay;
}
ngOnInit() {
  this.getInventoryStateList();
  this.getSampleInventoryLocations();
  this.getStorageLocations();
  this.selectedquantUomId = this.ForecastItem.quantityUnitOfMeasureId;
  this.UOM = this.ForecastItem.quantityUnitOfMeasureName;
  this.defaultMinDate.setDate(new Date().getDate() + 46 );
  this.minDate.setDate(new Date().getDate() + 46 );
  this.minDate2.setDate(new Date().getDate() + 46 );
  this.dateFiveMonths.setDate(new Date().getDate() + 196 );
  this.UOM = this.quantUoms.find(uom => uom.id === this.selectedquantUomId).uomDisplay;
  this.userClient.getUsers()
      .subscribe(data => {
          data.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
          this.users = data.filter(x => x.isActive  == true ||
            x.id == this.ForecastItem.forecastingUserId ||
            x.id == this.ForecastItem.reviewerUserId ||
            x.id == this.ForecastItem.delegatorUserId ||
            this.ForecastDestinations.some(fd => fd.forecastItemDestinationRequester
              .some(fidr => fidr.requesterUserId === x.id)));
      },
      error => {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Error when loading users. Check with Admin.' });
          console.log(error);
        });
}
public shouldLocationDeleteBeDisabled(forecastLocationItem: ForecastItemDestinationModel) {
  const rv = (((this.UserInfo.id === this.ForecastItem.forecastingUserId ||
                (this.ForecastItem.reviewerUserId !== undefined && this.UserInfo.id === this.ForecastItem.reviewerUserId) ||
                (this.ForecastItem.delegatorUserId !== undefined && this.UserInfo.id === this.ForecastItem.delegatorUserId)) &&
                forecastLocationItem.inventoryOrderStateName === 'To Do') || this.isMaterialPlanner());
  return !rv;
}

addDays(numOfDays: number, date = new Date()) {
  const dateCopy = new Date(date.getTime());

  dateCopy.setDate(dateCopy.getDate() + numOfDays);

  return dateCopy;
}

public getInventoryStateList() {
  this.inventoryStateClient.getInventoryStates()
   .subscribe(data => {
  //  data.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
  console.log(data);
    this.inventoryStates = data;
    this.toDoState = this.inventoryStates.find(a => a.stateName === 'To Do');

   });
}

public isMaterialPlanner() {
    return this.UserInfo.roles.some(r => r.name === 'Material Planner');
  }
  public cancel()
  {
   this.displayDialog = false;
  }
  public OpenPopup(location:ForecastItemDestinationModel = null, cloneFlag: boolean = false)
  {
    if(this.users == undefined)
    {
      this.userClient.getUsers()
      .subscribe(data => {
          data.forEach(u => u.secondaryEmail = u.firstName + " " + u.lastName);
          this.users = data.filter(x => x.isActive == true ||
            x.id == this.ForecastItem.forecastingUserId ||
            x.id == this.ForecastItem.reviewerUserId ||
            x.id == this.ForecastItem.delegatorUserId ||
            location.forecastItemDestinationRequester.some(fidr => fidr.requesterUserId === x.id));
      },
      error => {
          this.messageService.add({ severity: 'error', summary: '', detail: 'Error when loading users. Check with Admin.' });
          console.log(error);
        });
    }
    this.forecastLocationModel = new ForecastItemDestinationModel();

    this.countryClient.getCountries()
    .subscribe(data => {
      this.countryModels = data.filter(x => (!x.isProhibited) || (location !== null && location.destinationCountryId === x.id));
      if (cloneFlag) {
        this.forecastLocationModel.inventoryOrderStateName = this.toDoState.stateName;
        this.forecastLocationModel.inventoryOrderStateId = this.toDoState.id;
        this.forecastLocationModel.inventoryOrderState = this.toDoState;
        this.forecastLocationModel.destinationCountryId = location.destinationCountryId;
//        this.forecastLocationModel.packSizeUomId = location.packSizeUomId;
//        this.forecastLocationModel.packSizeUomName = location.packSizeUomName;
//        this.forecastLocationModel.packSizeValue = location.packSizeValue;
        this.forecastLocationModel.programNumber = location.programNumber;
        this.forecastLocationModel.lotNumber = location.lotNumber;
        this.forecastLocationModel.forecastItemId = location.forecastItemId;
        this.forecastLocationModel.quantity = location.quantity;
        this.forecastLocationModel.quantityUnitOfMeasureId = location.quantityUnitOfMeasureId;
        this.forecastLocationModel.dateNeeded = location.dateNeeded;
        this.forecastLocationModel.dateNeededLatest = location.dateNeededLatest;
        this.forecastLocationModel.inventoryLocationId = null;
        this.forecastLocationModel.inventoryLocation = null;
        this.forecastLocationModel.sampleRequesterName = location.sampleRequesterName;
        this.forecastLocationModel.forecastItemDestinationRequester = [];
        location.forecastItemDestinationRequester.forEach(x => {
          const req = new ForecastItemDestinationRequesterModel();
          req.requesterUserId = x.requesterUserId;
          req.requestingUserName = x.requestingUserName;
          req.email = x.email;
          this.forecastLocationModel.forecastItemDestinationRequester.push(req);
        });
        this.forecastLocationModel.manufacturer = location.manufacturer;
        this.forecastLocationModel.raceLabels = location.raceLabels;
        this.isEdit = false;
        this.SetData();
        this.forecastDestinationForm.setErrors(null);
      }
      else {
        if (location == null)
        {
          this.isEdit = false;
          this.disableCountry = false;
          this.forecastDestinationForm.reset(this.forecastDestinationForm.value);
          this.forecastLocationModel.destinationCountryId = 0;
//          this.forecastLocationModel.packSizeUomId = this.selectedquantUomId;
          this.forecastLocationModel.inventoryOrderStateName = this.toDoState.stateName;
          this.forecastLocationModel.inventoryOrderStateId = this.toDoState.id;
          this.forecastLocationModel.inventoryOrderState = this.toDoState;
          this.forecastLocationModel.inventoryLocationId = null;
          this.forecastLocationModel.inventoryLocation = null;
          this.forecastLocationModel.dateNeeded = this.defaultMinDate;
          this.forecastLocationModel.dateNeededLatest = this.dateFiveMonths;

          this.forecastLocationModel.lotNumber = this.ForecastItem.repoName === "CORE" ? this.ForecastItem.lotId : this.ForecastItem.lotNumber;
              this.SetData();
        }
        else {
          this.isEdit = true;
          this.oldItem = location;
          this.forecastLocationModel = location;
          this.SetData();
          this.forecastDestinationForm.setErrors(null);
          this.disableCountry =  (this.commonService.configurations.InventoryOrderStatus.InStock == location.inventoryOrderStateName
            || this.commonService.configurations.InventoryOrderStatus.ToOrder == location.inventoryOrderStateName
            || this.commonService.configurations.InventoryOrderStatus.Ordered  == location.inventoryOrderStateName) ;
          this.disableControls  = (location.inventoryOrderStateName == 'Cancelled' || this.forecastStatus == 'Cancelled');
        }
      }
      this.displayDialog = true;
    },
    error => {
      console.log(error);
    });
  }
  public canUomBeChanged() {
      let states = ["Data Entry", "Delegate Review", "Awaiting Review", "Material Approval"];
      return this.forecastLocationModel.inventoryOrderStateName !== 'Cancelled' && states.some(s => s === this.forecastStatus);
    }
  filterCountrySingle(event) {
    let query = event.query;
    this.filteredCountriesSingle = this.filterCountry(query, this.countryModels);
  }

  countrySelect(event){
    let productName = this.ForecastItem.productName;
    if(this.ForecastItem.productName.indexOf(' ') > -1){
      productName = this.ForecastItem.productName.substring(0,this.ForecastItem.productName.indexOf(' '))
    }
    this.raceLabelsClient.retrieveLabelsForCompositionByCountry(
      productName,
      event.countryCode,
      event.displayName).subscribe((response)=>{
        console.log(response);
        if(response.countryLabels &&  response.countryLabels.length != 0){
          (<UntypedFormControl>this.forecastDestinationForm.controls['raceLabels'])
          .setValue(response.countryLabels[0].labels.join(', '), { onlySelf: true });
          this.forecastLocationModel.raceLabels = response.countryLabels[0].labels.join('||');
          this.selectedRaceLabel = response.countryLabels[0].labels.join('||');
        }
        else{
          (<UntypedFormControl>this.forecastDestinationForm.controls['raceLabels'])
          .setValue("", { onlySelf: true });
          this.forecastLocationModel.raceLabels = "";
        }
    });
  }

  filterCountry(query, countries: any[]):any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered : any[] = [];
    for(let i = 0; i < countries.length; i++) {
        let country = countries[i];
        if (country.displayName.toUpperCase() !== 'DEFAULT GLOBAL AREA' &&
            country.displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0) {
            filtered.push(country);
        }
    }
    return filtered;
}
DeleteSampleRequester(sampleRequester:UserModel)
{
  this.sampleRequesters= this.sampleRequesters.filter(x => x.id != sampleRequester.id);
}
SampleRequesterSelected(event)
{
   this.sampleRequesters.push(event);
   this.selectedUser = null;
   return false;
}
FilterUser(event) {
  //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
   this.filteredUsers =[];
  for(let i = 0; i < this.users.length; i++) {
      let user = this.users[i];
      if (user.secondaryEmail.toLowerCase().indexOf(event.query.toLowerCase()) >= 0  ) {

        if(this.sampleRequesters != null && this.sampleRequesters.length > 0)
    {
      var isAlreadyExist = false;
      this.sampleRequesters.forEach(element => {
           if(user.id == element.id)
           {
             isAlreadyExist = true;
           }
      });
      if(!isAlreadyExist)
      {
        this.filteredUsers.push(user);
      }
    }
    else
    {
          this.filteredUsers.push(user);
    }
      }
  }

}
  SetData()
  {
    this.Getform();
    let ftt = this.countryModels.find(ft => ft.id == this.forecastLocationModel.destinationCountryId);


    (<UntypedFormControl>this.forecastDestinationForm.controls['country'])
    .setValue(ftt, { onlySelf: true });
     (<UntypedFormControl>this.forecastDestinationForm.controls['quantity'])
    .setValue(this.forecastLocationModel.quantity, { onlySelf: true });
    (<UntypedFormControl>this.forecastDestinationForm.controls['dateNeeded'])
    .setValue(this.forecastLocationModel.dateNeeded, { onlySelf: true });
      (<UntypedFormControl>this.forecastDestinationForm.controls['dateNeededLatest'])
      .setValue(this.forecastLocationModel.dateNeededLatest, { onlySelf: true });
    var requesters =[];
    if(this.forecastLocationModel.forecastItemDestinationRequester != undefined){
      this.forecastLocationModel.forecastItemDestinationRequester.forEach((ele)=>{
          requesters.push(this.users.find(u => u.id == ele.requesterUserId));
      });
    }
    this.sampleRequesters = requesters;
//    (<FormControl>this.forecastDestinationForm.controls['packSizeValue'])
//    .setValue(this.forecastLocationModel.packSizeValue, { onlySelf: true });
    (<UntypedFormControl>this.forecastDestinationForm.controls['programNumber'])
    .setValue(this.forecastLocationModel.programNumber, { onlySelf: true });
    (<UntypedFormControl>this.forecastDestinationForm.controls['lotNumber'])
    .setValue(this.forecastLocationModel.lotNumber, { onlySelf: true });
    (<UntypedFormControl>this.forecastDestinationForm.controls['manufacturer'])
    .setValue(this.forecastLocationModel.manufacturer, { onlySelf: true });
    (<UntypedFormControl>this.forecastDestinationForm.controls['raceLabels'])
    .setValue(this.forecastLocationModel.raceLabels?.replace(/\|{2}/g,", "), { onlySelf: true });
    (<UntypedFormControl>this.forecastDestinationForm.controls['id'])
    .setValue(this.forecastLocationModel.id, { onlySelf: true });
    (<UntypedFormControl>this.forecastDestinationForm.controls['trackingId'])
    .setValue(this.forecastLocationModel.trackingId, { onlySelf: true });

//    let psUOM = this.quantUoms.find(uom => uom.id == this.forecastLocationModel.packSizeUomId);
//    (<FormControl>this.forecastDestinationForm.controls['PackageQuantityUom'])
//    .setValue(psUOM == null ? this.quantUoms[0] : psUOM, { onlySelf: true });

    let qtUOM = this.quantUoms.find(uom => uom.id == this.forecastLocationModel.quantityUnitOfMeasureId);
    (<UntypedFormControl>this.forecastDestinationForm.controls['QuantityUom'])
    .setValue(qtUOM == null ? this.quantUoms[0] : qtUOM, { onlySelf: true });

    var statusArray =['In Stock','Ordered','To Order','Follow Up'];
    if(statusArray.indexOf(this.forecastLocationModel.inventoryOrderStateName) >= 0)
    {
      this.minDate = this.forecastLocationModel.dateNeeded;
      this.forecastDestinationForm.controls["quantity"].setValidators([Validators.required ,Validators.min(.0001),Validators.max(this.forecastLocationModel.quantity),Validators.pattern('^[0-9]{0,6}\\.?[0-9]{0,4}$')]);
    }
  }
  Getform()
  {
    this.forecastDestinationForm = this.fb.group({
      'country': new UntypedFormControl('',Validators.required),
      'quantity':new UntypedFormControl('',[Validators.required ,Validators.min(.0001),Validators.pattern('^[0-9]{0,6}\\.?[0-9]{0,3}$')] ),
      'dateNeeded': new UntypedFormControl('',Validators.required),
      'dateNeededLatest': new UntypedFormControl('',Validators.required ),
      'sampleRequesterName': new UntypedFormControl([]),
//      'packSizeValue': new FormControl('',Validators.pattern('^[0-9]{0,6}\\.?[0-9]{0,3}$')),
      'programNumber': new UntypedFormControl('',Validators.pattern("^[A-Za-z0-9]+$")),
      'lotNumber': new UntypedFormControl('',Validators.pattern("^[A-Za-z0-9- ]{0,20}$")),
//      'PackageQuantityUom': new FormControl(''),
      'QuantityUom': new UntypedFormControl(''),
      'manufacturer': new UntypedFormControl(''),
      'raceLabels': new UntypedFormControl(''),
      'id': new UntypedFormControl('')  ,
      'UOMId': new UntypedFormControl(''),
      'trackingId': new UntypedFormControl('')

    });
        this.forecastDestinationForm.get("dateNeeded").valueChanges.subscribe(selectedValue => {
          if (selectedValue != null) {
            let dn = this.addDays(150, selectedValue);
            (<UntypedFormControl>this.forecastDestinationForm.controls['dateNeededLatest'])
              .setValue(dn, { onlySelf: true });
            this.minDate2 = selectedValue;
          }
          else{
            this.minDate2 = this.defaultMinDate;
          }
        });
  }

  DeleteForecastLocation(location:ForecastItemDestinationModel) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this forecast location?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.RemoveDestination.emit(location);
        this.displayDialog = false;
      }});
  }
  Save()
  {
    var destinationData  = this.GetData();
    if(this.isEdit){

      if(this.ForecastDestinations.filter(x => x.destinationCountryId === destinationData.destinationCountryId
        && this.datepipe.transform( x.dateNeeded, 'yyyy-MM-dd') == this.datepipe.transform(destinationData.dateNeeded , 'yyyy-MM-dd')
        && x.trackingId != destinationData.trackingId
        ).length > 0)
        {
          this.messageService.add({severity:'error', summary:'Duplicate', detail:'Cannot add destination with same country and needed date'});
          return ;
        }
        destinationData.inventoryOrderStateId = this.oldItem.inventoryOrderStateId;
        destinationData.inventoryOrderStateName = this.oldItem.inventoryOrderStateName;
        if(destinationData.id != undefined && !this.isForecastCopy)
        {
        this.forecastItemDestinationClient.updateDestinationMin(destinationData.id,destinationData)
        .subscribe((resp)=>{
         // console.log("resp",resp);
          this.messageService.add({ severity:this.commonService.configurations.MessageServiceSeveritySuccess, summary: 'Location is Saved successfully', detail: this.commonService.configurations.DataSaved })
        },
        error => {
            this.messageService.add({ severity: 'error', summary: '', detail: 'Error when saving data. Check with Admin.' });
            console.log(error);
          });
        }

     this.UpdateDestination.emit([this.oldItem, destinationData]);
    }
    else
    {
      if(this.ForecastDestinations.filter(x => x.destinationCountryId == destinationData.destinationCountryId
        && this.datepipe.transform( x.dateNeeded, 'yyyy-MM-dd') == this.datepipe.transform(destinationData.dateNeeded , 'yyyy-MM-dd')
        ).length > 0)
        {
          this.messageService.add({severity:'error', summary:'Duplicate', detail:'Cannot add destination with same country and needed date'});
          return ;
        }
        destinationData.trackingId = this.ForecastDestinations.length +1;
        destinationData.inventoryOrderStateName = this.toDoState.stateName;
        destinationData.inventoryOrderStateId = this.toDoState.id;
        destinationData.inventoryOrderState = this.toDoState;

    this.AddDestination.emit(destinationData);
    }
    this.displayDialog = false;

  }
  GetData()
  {
    var destinationData = new ForecastItemDestinationModel() ;
    var countryName = (document.getElementById("autoCompleteDestinationCountry") as HTMLInputElement).getElementsByTagName("input")[0].value;
    if(countryName != undefined && countryName != null && countryName.length > 0)
    {
        let ctry  =  this.countryModels.find(ft => ft.displayName.toLowerCase() === countryName.toLowerCase());
        if(ctry != undefined && ctry != null)
        (<UntypedFormControl>this.forecastDestinationForm.controls['country']).setValue(ctry, { onlySelf: true });
    }
    destinationData.destinationCountryId = this.forecastDestinationForm.get('country').value.id;
    destinationData.destinationCountryName = this.forecastDestinationForm.get('country').value.displayName;
    destinationData.destinationCountryCode = this.forecastDestinationForm.get('country').value.countryCode;
    destinationData.quantity = Number(this.forecastDestinationForm.get('quantity').value);
    destinationData.dateNeeded = new Date(this.forecastDestinationForm.get('dateNeeded').value.setHours(0,0,0,0)); // Set time to 00:00:00
    //if (this.showDateNeededRange) {
      destinationData.dateNeededLatest = new Date(this.forecastDestinationForm.get('dateNeededLatest').value.setHours(0,0,0,0)); // Set time to 00:00:00
    //}
    var drm = [];
    if( this.sampleRequesters != null && this.sampleRequesters.length > 0)
    {
      this.sampleRequesters.forEach(element => {
        var dr = new ForecastItemDestinationRequesterModel();
        dr.requesterUserId = element.id;
        dr.requestingUserName = element.firstName +" "+ element.lastName;
        drm.push(dr);
      });
    }
    destinationData.forecastItemDestinationRequester = drm;
//    destinationData.packSizeValue = this.forecastDestinationForm.get('packSizeValue').value;
    destinationData.programNumber = this.forecastDestinationForm.get('programNumber').value;
    destinationData.lotNumber = this.forecastDestinationForm.get('lotNumber').value;
    destinationData.manufacturer = this.forecastDestinationForm.get('manufacturer').value;
    destinationData.raceLabels = this.forecastDestinationForm.get('raceLabels').value;
    if(destinationData.raceLabels?.indexOf('||') == -1){
      destinationData.raceLabels = this.selectedRaceLabel;
    }
    destinationData.id = this.forecastDestinationForm.get('id').value;
//    destinationData.packSizeUomId =    this.forecastDestinationForm.get('PackageQuantityUom').value.id;
//    destinationData.packSizeUomName =    this.forecastDestinationForm.get('PackageQuantityUom').value.uomDisplay;
    destinationData.quantityUnitOfMeasureId = this.forecastDestinationForm.get('QuantityUom').value.id;
    destinationData.quantityUomName = this.forecastDestinationForm.get('QuantityUom').value.uomDisplay;
    destinationData.trackingId =  this.forecastDestinationForm.get('trackingId').value;
    return destinationData;

  }
  DataSavedSuccessfully()
  {
    this.messageService.add({ severity:this.commonService.configurations.MessageServiceSeveritySuccess, summary: '', detail: this.commonService.configurations.DataSaved })
    this.ngOnInit();
    this.displayDialog = false;
  }
  public getTitleStyle(forecastItemDestinationModel: ForecastItemDestinationModel) {
    switch (forecastItemDestinationModel.inventoryOrderStateName)
    {
      case  this.commonService.configurations.InventoryOrderStatus.ToDo :  return 'clsToDoPanelTitleBar' ; break ;
      case  this.commonService.configurations.InventoryOrderStatus.InStock :  return 'clsInStockPanelTitleBar' ; break ;
      case  this.commonService.configurations.InventoryOrderStatus.ToOrder :  return 'clsToOrderPanelTitleBar' ; break ;
      case  this.commonService.configurations.InventoryOrderStatus.Ordered :  return 'clsOrderedPanelTitleBar' ; break ;
      case  this.commonService.configurations.InventoryOrderStatus.Cancelled : return 'clsCancelledPanelTitleBar' ; break ;
      case  this.commonService.configurations.InventoryOrderStatus.Outsourced : return 'clsOutsourcedPanelTitleBar' ; break ;
    }
    return 'clsToDoPanelTitleBar';
  }

  getSampleInventoryLocations() {
    this.sampleInventoryLocation.getSampleInventoryLocations()
      .subscribe(data => {
        this.sampleInventoryLocations = data;
        console.log("SampleInventoryLocs....", this.sampleInventoryLocations);
      });
  }

  getStorageLocations(){
    this.forecastTypeClient.getForecastTypes().subscribe(x => {
      this.forecastTypes = x;
      console.log("forecastTypes...", this.forecastTypes);
    });
  }

  getWarehouseLocation(inventoryLocationId, forecastTypeId){
    let warehouseLocation = '';
    if(inventoryLocationId)
    {
        const inventoryLocation = this.sampleInventoryLocations.find(x=>x.id == inventoryLocationId);
        const forecastType = this.forecastTypes.find(x=>x.id == forecastTypeId);

        if(inventoryLocation && forecastType){
            warehouseLocation= inventoryLocation.locationName + '/' + forecastType.forecastType1;
        }
    }

    return warehouseLocation;
  }

}
