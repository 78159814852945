<p-dialog id="dialogInventoryModal" header="{{materialNameHeader}}" [(visible)]="displayDialog"
    [styleClass]="'clsDialogStyle'" [draggable]="false" [style.width]="'1050px'" [style.minWidth]="'600px'"
    [style.minHeight]="'600px'" [modal]="true" [dismissableMask]="'true'">
    <div class="inventory-details" style="background-color: lightgrey;">
      <div id="inventory-breakdown-div">
        <table *ngIf="this.materialInfo" id="inventory-breakdown">
           <tr>
             <th></th>
             <th></th>
             <th>Physical</th>
             <th>Reserved</th>
             <th>Available</th>
           </tr>
           <tbody>
           <tr>
             <td rowspan=3 class="mi-bold">Stock</td>
             <td>Indianapolis</td>
             <td>{{this.inventoryBreakDown.STOCK.INDY.physical | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td>{{this.inventoryBreakDown.STOCK.INDY.reserved | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td>{{this.inventoryBreakDown.STOCK.INDY.available| number : '1.3-3'  }} {{inventoryUOM}}</td>
           </tr>
           <tr>
             <td>{{ euCenterFeature ? "France" : "SGS" }}</td>
             <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.STOCK.FRANCE.physical  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.STOCK.FRANCE.reserved  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.STOCK.FRANCE.available | number : '1.3-3'  }} {{inventoryUOM}}</td>

             <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.STOCK.SGS.physical  | number : '1.3-3'    }} {{inventoryUOM}}</td>
             <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.STOCK.SGS.reserved  | number : '1.3-3'    }} {{inventoryUOM}}</td>
             <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.STOCK.SGS.available | number : '1.3-3'    }} {{inventoryUOM}}</td>
           </tr>
           <tr class="mi-bold">
             <td>Sub-Total</td>
             <td>{{this.inventoryBreakDown.STOCK.SUB.physical  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td>{{this.inventoryBreakDown.STOCK.SUB.reserved  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td>{{this.inventoryBreakDown.STOCK.SUB.available | number : '1.3-3'  }} {{inventoryUOM}}</td>
           </tr>
         </tbody>
        <tbody [hidden]="this.materialType == this.commonService.configurations?.Coformulant || this.materialType == this.commonService.configurations?.FieldInert">
           <tr>
             <td rowspan=3 class="mi-bold">GLP</td>
             <td>Indianapolis</td>
             <td>{{this.inventoryBreakDown.GLP.INDY.physical  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td>{{this.inventoryBreakDown.GLP.INDY.reserved  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td>{{this.inventoryBreakDown.GLP.INDY.available | number : '1.3-3'  }} {{inventoryUOM}}</td>
           </tr>
           <tr>
             <td>{{ euCenterFeature ? "France" : "SGS" }}</td>
             <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.GLP.FRANCE.physical  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.GLP.FRANCE.reserved  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td *ngIf="euCenterFeature">{{this.inventoryBreakDown.GLP.FRANCE.available | number : '1.3-3'  }} {{inventoryUOM}}</td>

             <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.GLP.SGS.physical  | number : '1.3-3'    }} {{inventoryUOM}}</td>
             <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.GLP.SGS.reserved  | number : '1.3-3'    }} {{inventoryUOM}}</td>
             <td *ngIf="!euCenterFeature">{{this.inventoryBreakDown.GLP.SGS.available | number : '1.3-3'    }} {{inventoryUOM}}</td>
           </tr>
           <tr class="mi-bold">
             <td>Sub-Total</td>
             <td>{{this.inventoryBreakDown.GLP.SUB.physical  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td>{{this.inventoryBreakDown.GLP.SUB.reserved  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td>{{this.inventoryBreakDown.GLP.SUB.available | number : '1.3-3'  }} {{inventoryUOM}}</td>
           </tr>
         </tbody>
        <tbody [hidden]="this.materialType == this.commonService.configurations?.Coformulant || this.materialType == this.commonService.configurations?.FieldInert">
           <tr class="mi-bold">
             <td>Grand Total</td>
             <td></td>
             <td> {{materialInfo.totalPhysical | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td> {{ (materialInfo.totalIndyReserved + (euCenterFeature ? materialInfo.totalFranceReserved : materialInfo.totalSGSReserved ))  | number : '1.3-3'  }} {{inventoryUOM}}</td>
             <td> {{ inventoryTotalAvailable | number : '1.3-3' }} {{inventoryUOM}}</td>
           </tr>
        </tbody>
         </table>
         </div>
        <div class="grid" style="margin-left:1px;margin-top:1px;">
            <div class="inventory-disclaimer">
                <div class="grid" style="height:100%;">
                    <div class="col-offset-1 col-10"
                        style="text-align: left;line-height: normal;color: black;padding-left:5px; padding-right: 5px;">
                        <span><strong>Disclaimer:</strong>"Total Available" and sum of "Available Qty." may not equal one another. The quantity
                            under "Total Available" shows the current amount available to order in CPSS (without a
                            forecast). "Total Available" accounts for all reservations for this material. "Available
                            Qty." by lot only, as shown below, accounts for reservations sent for that particular lot,
                            and does not account for reservations sent without a specific lot assigned.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid" style="margin-top:10px;">
            <div class="col-2">
                <label style="font-weight: bold;">*Unit of Measure</label>
            </div>
            <div class="col-5">
                <p-selectButton [(ngModel)]="selectedUom" (onChange)="onUnitOfMeasureChange($event)"
                    [options]="uomOptions" optionLabel="uomDisplay" id="selectButtonOrderItemctrnUOM"></p-selectButton>
            </div>
            <div class="col-3">
              <p-selectButton [options]="inventoryList"
            [(ngModel)]="selectedInventoryType" optionLabel="name" optionValue="value"></p-selectButton>
            </div>
            <div class="col-2">
              <button type="button" pButton icon="pi pi-download"
                pTooltip="Download Reconciliation Report" class="btn-icons" style="margin-left: 1px;" id="btnDownload" *ngIf="isAdministrator || isMaterialPlanner"
                tooltipPosition="bottom" (click)="downloadReconcileReport()"></button>
              <button type="button" pButton icon="pi pi-refresh"
              pTooltip="Click to Reconcile" class="btn-icons"  id="btnRefresh" style="margin-left: 1px;" *ngIf="isAdministrator || isMaterialPlanner"
              tooltipPosition="bottom" (click)="reconcileAndRefresh()"></button>
            </div>

        </div>
        <p-table [value]="materialInfo?.compositeInventoryByLot">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="selectedMaterialRepoName=='FACTS' || selectedMaterialRepoName == 'COFORM' || selectedMaterialRepoName == 'BIONEXUS'" pSortableColumn="lotNumber"> Lot No <p-sortIcon field="lotNumber"></p-sortIcon></th>
                    <th *ngIf="selectedMaterialRepoName=='CORE'" pSortableColumn="lotId"> Lot No <p-sortIcon field="lotId"></p-sortIcon></th>
                    <th pSortableColumn="manufacturerLotNumber"> Mfg Lot No <p-sortIcon field="manufacturerLotNumber"></p-sortIcon></th>
                    <th pSortableColumn="dateOfManufacture"> Date of Mfg <p-sortIcon field="dateOfManufacture"></p-sortIcon></th>
                    <th pSortableColumn="inventoryType"> Inventory Type <p-sortIcon field="inventoryType"></p-sortIcon></th>
                    <th pSortableColumn="inventoryLocation"> Warehouse Loc. <p-sortIcon field="inventoryLocation"></p-sortIcon></th>
                    <th pSortableColumn="expirationDate"> Exp Date <p-sortIcon field="expirationDate"></p-sortIcon></th>
                    <th pSortableColumn="available" style="text-align: right !important;"> Available Qty <p-sortIcon field="available"></p-sortIcon></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr *ngIf="showRow(rowData)">
                    <td>
                        <span *ngIf="selectedMaterialRepoName=='FACTS'||selectedMaterialRepoName=='COFORM'||selectedMaterialRepoName=='BIONEXUS'">{{rowData['lotNumber']}}</span>
                        <span *ngIf="selectedMaterialRepoName=='CORE'">{{rowData['lotId']}}</span>
                    </td>
                    <td>
                        {{ rowData['manufacturerLotNumber'] }}
                    </td>
                    <td>
                        {{ rowData['dateOfManufacture'] | date:"dd-MMM-yyyy" :'UTC'}}
                    </td>
                    <td>
                        {{ rowData['inventoryType'] }}
                    </td>
                    <td>
                      {{ euCenterFeature ? rowData['inventoryLocation']  : (rowData['inventoryLocation'] == "Indy" ? "Indianapolis" : rowData['inventoryLocation']) }}
                    </td>
                    <td>
                        {{ rowData['expirationDate'] | date:"dd-MMM-yyyy" :'UTC'}}
                    </td>
                    <td style="text-align: right !important;">
                        {{(rowData['available'] < 0 ? 0 : rowData['available']) | number : '1.3-3' }} {{inventoryUOM}}
                            </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <p-footer>
        <div class="row">
            <div class="col-md-10" style="text-align: initial;font-style: italic;"></div>
            <div class="col-md-2"><button type="button" pButton icon="fa fa-close" id="btnCancelPopup"
                    (click)="displayDialog=false" label="Close"></button></div>
        </div>
    </p-footer>
</p-dialog>
