import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ForecastItemService } from '../services/forecastItem.service';
import { MessageService,ConfirmationService } from 'primeng/api';
import { CommonService } from '../services/common.service';
import { DatePipe } from '@angular/common';

import { SecurityService } from '../services/security.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CountryClient, UserClient, ForecastStateModel, ForecastStateClient, ForecastItemClient,
  SearchFormulaModel, SearchCriteriaModel, SearchClient, ForecastItemModel, ForecastItemDestinationModel, UserModel, ForecastMaterialApprovalModel, ApproveForecastModel }
    from '../services/forecast-api-client.service';
import { Filter } from '../models/filter.model';
import { FormControl, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { AppComponent } from '../app.component';
import { DataViewModule, DataView } from 'primeng/dataview';
import { MsalService } from "@azure/msal-angular";
import { getDateMeta } from '@fullcalendar/core';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
  providers: [ConfirmationService]
})
export class ApprovalsComponent implements OnInit {
  userModel:UserModel = new UserModel();
  userInfo:UserModel ;
  totalCount: number;
  pageIndex: number=1;
  PAGE_SIZE: number = 25;
  forecastApprovals:Array<ForecastMaterialApprovalModel>;
  selectedRejectReason: string;
  rejectComment: string;
  rejectReasons: Array<any> = [
    {name: 'Select a reason for rejection', code: 0},
    {name: 'Inappropriate destinations', code: 1} , 
    {name: 'Chemical cannot be exported', code: 2}
  ,{name: 'Incorrect amount requested', code: 3}, 
  {name: 'Missing permit / Wrong document', code: 4}];
  showRejectCommentDialog: boolean;
  showApproveConfirmationDialog: boolean;
  isDontShowApproveDialog: boolean;
  targetForecastItemId: number;
  disableOnApprove = false;
  disableOnReject = false;
  @ViewChild('dv') dataView: DataView;

  constructor(public datepipe: DatePipe,
    private forecastItemService: ForecastItemService,
    private confirmationService:ConfirmationService ,
    private messageService: MessageService,
    private commonService:CommonService,
    private securityService:SecurityService,
    private authService:MsalService,
    private router: Router,
    private userClient: UserClient,
    private forecastStateClient:ForecastStateClient,
    private forecastItemClient: ForecastItemClient,
    private appComponent: AppComponent,
    private activatedRoute: ActivatedRoute,
    private searchClient: SearchClient,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,public countryClient: CountryClient) { }

  ngOnInit(): void {
    this.appComponent.currentSelection = 'approvals';
    this.activatedRoute.data
    .subscribe((allData) => {
      console.log(allData);
    this.userInfo = this.securityService.getUser();
    if(this.userInfo === undefined || this.userInfo === null)
    {
      var loggedInUsername = sessionStorage.getItem("loggedInUsername");
      if (loggedInUsername !== undefined && loggedInUsername !== null) {
        this.securityService.validateUser(loggedInUsername)
            .subscribe(data => {
              this.userInfo = data; 
              this.getData();
        },
        error => {
          this.messageService.add({ severity: 'info', summary: 'User not logged in', detail: 'Your session has expired.' });
//          this.authService.loginRedirect();
        });
      } else {
        this.messageService.add({ severity: 'info', summary: 'User not logged in', detail: 'Your session has expired.' });
//        this.authService.loginRedirect();
      }
    } else {
      this.getData();
    }
  });
  }
  pageChanged(event: any) {
    this.pageIndex = (event.first / this.PAGE_SIZE) + 1;
    this.getData(false);
  }


  getData(setCount: boolean = true): void {
    if (this.userInfo === null || this.userInfo === undefined || this.userInfo.id === undefined) {
      this.messageService.add({
        severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: 'Your session has expired. Please login again.' });
        this.router.navigate(['login']);
      return;
    }
     this.forecastItemClient.getForecastItemsAwaitingApproval(this.userInfo.id, this.pageIndex-1, this.PAGE_SIZE) //)
//     this.forecastItemClient.getForecastItemsAwaitingApproval(26, this.pageIndex-1, this.PAGE_SIZE) //)
      .subscribe(data => {
        this.forecastApprovals = data.approvals;
        if (setCount ) {
          this.pageIndex = 1;
          this.totalCount = data.totalCount;
          this.dataView.first = 0;
        }
        console.log(data);
     },
     error => {
       this.messageService.add({
         severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
         this.router.navigate(['login']);
     });
  }
  rejectItem(forecastItemId: number) {
    this.targetForecastItemId = forecastItemId;
    this.rejectComment = '';
    this.selectedRejectReason = '';
    this.showRejectCommentDialog = true;
  }

  approveItemConfirmation(forecastItemId: number) {
    this.targetForecastItemId = forecastItemId;
    var dontShowApproverDlg = sessionStorage.getItem("dontshowApproveConfirmation");
    if(dontShowApproverDlg !=  'hide'){
    this.showApproveConfirmationDialog = true;
    }
    else
    {
      this.approveItem();
    }
  }

  approveItem() {
    this.disableOnApprove = true
    var dontShowApproverDlg = sessionStorage.getItem("dontshowApproveConfirmation");
    if(dontShowApproverDlg !=  'hide') {
      sessionStorage.setItem("dontshowApproveConfirmation", this.isDontShowApproveDialog ? 'hide' : 'show');
    }
    let approval = new ApproveForecastModel();
    approval.approvalTimeStamp = new Date();
    approval.approvedByUserId = this.userInfo.id;
    approval.isApproved = true;
    approval.comment = "Approved";
    approval.forecastItemId = this.targetForecastItemId;
    this.forecastItemClient.forecastApproval(approval)
    .subscribe(data => {
      this.getData();
      this.disableOnApprove = false;
      console.log("success");
   },
   error => {
     this.messageService.add({
       severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
       this.disableOnApprove = false;
   });
   this.CloseApproveConfirmation();
  }

  CloseApproveConfirmation(){
    this.showApproveConfirmationDialog = false;  
  }

  onChangeSelectedRejectReason(): void {
    this.rejectComment = this.selectedRejectReason['code'] == 0 ? '' : this.selectedRejectReason['name'];
  }
   RejectForecastWithComment() {
    this.disableOnReject = true;
    this.showRejectCommentDialog = false;
    let approval = new ApproveForecastModel();
    approval.approvalTimeStamp = new Date();
    approval.approvedByUserId = this.userInfo.id;
    approval.isApproved = false;
    approval.comment = this.rejectComment;
    approval.forecastItemId = this.targetForecastItemId;
    this.forecastItemClient.forecastApproval(approval)
    .subscribe(data => {
      this.getData();
      console.log("success");
      this.disableOnReject = false;
   },
   error => {
     this.messageService.add({
       severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
       this.disableOnReject = false;
   });
   }

   ReturnForReview() {
    this.showRejectCommentDialog = false;
    let approval = new ApproveForecastModel();
    approval.approvalTimeStamp = new Date();
    approval.approvedByUserId = this.userInfo.id;
    approval.isApproved = false;
    approval.comment = this.rejectComment;
    approval.forecastItemId = this.targetForecastItemId;
      this.forecastItemClient.updateForecastItemStatus(approval)
      .subscribe(data => {
        this.getData();
        console.log("success");
    },
    error => {
      this.messageService.add({
        severity: this.commonService.configurations.MessageServiceSeverityError, summary: '', detail: error.message });
    });
  }
}

